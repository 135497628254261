import { Linking, Platform, Text, View } from "react-native";
import { useAppSelector } from "../hooks/redux";
import { useTranslations } from "../hooks/useTranslations";
import PopUp from "./PopUp";
import PrimaryButton from "./utils/primary_button";

interface Props {
  children: React.ReactNode;
}

const UpgradeChecker = ({ children }: Props) => {
  const { upgrade_required } = useAppSelector(
    (state) => state.configuration.app
  );
  const { alert_upgrade_required, alert_upgrade_go_to_store } =
    useTranslations();

  function handleGoToStore() {
    const url = Platform.select({
      ios: "itms-apps://apps.apple.com/tr/app/times-tables-lets-learn/id1642944868?l=tr",
      android:
        "http://play.google.com/store/apps/details?id=com.goodsleeper.cbt",
    });
    if (url) {
      Linking.canOpenURL(url).then(
        (supported) => supported && Linking.openURL(url)
      );
    }
  }

  if (upgrade_required) {
    return (
      <PopUp isVisible={true}>
        <View style={{ paddingVertical: 20 }}>
          <Text>{alert_upgrade_required}</Text>
        </View>
        <PrimaryButton
          onPress={handleGoToStore}
          label={alert_upgrade_go_to_store}
        />
      </PopUp>
    );
  }

  return <>{children}</>;
};

export default UpgradeChecker;
