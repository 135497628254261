import { FC } from "react";
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Colors } from "../../constants/colors";

interface IProps {
  label: string;
  selected?: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | null | undefined;
}

const RangeSelectRadio: FC<IProps> = ({ label, selected = false, onPress }) => {
  return (
    <View
      style={[
        styles.answerContainer,
        selected && { backgroundColor: Colors.primary },
      ]}
    >
      <Pressable style={{ width: "100%" }} onPress={onPress}>
        <View style={styles.answerInner}>
          <View
            style={[
              styles.answerRadio,
              selected && { borderColor: Colors.white },
            ]}
          >
            {selected && <View style={styles.answerRadioInner}></View>}
          </View>
          <Text
            textBreakStrategy="balanced"
            style={[styles.answerText, selected && { color: Colors.white }]}
          >
            {label}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

export default RangeSelectRadio;

const styles = StyleSheet.create({
  answerContainer: {
    width: (100 - 4) / 5 + "%",
    backgroundColor: Colors.lightgray,
    borderRadius: 4,
    overflow: "hidden",
  },
  answerInner: {
    width: "100%",
    paddingVertical: 15,
    paddingHorizontal: 2,
    alignItems: "center",
  },
  answerRadio: {
    width: 21,
    height: 21,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Colors.gray,
    backgroundColor: Colors.white,
    marginBottom: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  answerRadioInner: {
    width: 15,
    height: 15,
    borderRadius: 8,
    borderColor: Colors.primary,
    borderWidth: 4,
    backgroundColor: Colors.white,
  },
  answerText: {
    textAlign: "center",
    fontSize: 10,
    lineHeight: 13,
    color: Colors.dark,
    flexWrap: "wrap",
    width: "100%",
  },
});
