import { FC } from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Colors } from "../../constants/colors";

export interface PrimaryButtonProps {
  disabled?: boolean;
  loading?: boolean;
  color?: string;
  small?: boolean;
  children: React.ReactNode;
}

interface Props extends PrimaryButtonProps {
  onPress?: () => void;
}

const PrimaryButton = ({
  disabled = false,
  loading = false,
  color = Colors.primary,
  small = false,
  children,
  onPress,
}: Props) => {
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: color as string,
        },
        disabled && { backgroundColor: Colors.gray },
        loading && { backgroundColor: Colors.primary_light },
      ]}
    >
      {loading ? (
        <ActivityIndicator
          color={Colors.white}
          style={{ paddingVertical: 13 }}
          size={small ? "small" : "large"}
        />
      ) : (
        <Pressable
          onPress={onPress}
          disabled={disabled}
          style={[styles.pressArea, small && styles.buttonSmall]}
          android_ripple={{ color: Colors.primary_dark }}
        >
          <Text style={[styles.text, small && styles.textSmall]}>
            {children}
          </Text>
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: Colors.primary,
    borderRadius: 100,
    overflow: "hidden",
    marginVertical: 5,
  },
  pressArea: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  buttonSmall: {
    paddingHorizontal: 0,
  },
  text: {
    textAlign: "center",
    color: Colors.white,
    fontSize: 18,
    paddingVertical: 17,
    fontWeight: "700",
    lineHeight: 28,
  },
  textSmall: {
    fontSize: 16,
    paddingVertical: 12,
    lineHeight: 22,
  },
  icon: {
    color: Colors.white,
    marginRight: 15,
  },
});

export default PrimaryButton;
