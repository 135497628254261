import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../constants/colors";
import { useLocalizedFormatter } from "../hooks/dates";
import { Note } from "../types/v2/note";
import CustomIcon from "./CustomIcon";

const NoteListItem: FC<Note> = ({ content, created }) => {
  const formatter = useLocalizedFormatter();

  const formattedDate = formatter(created, "d MMMM yyyy, HH:mm");

  return (
    <View style={styles.container}>
      <View style={styles.title_container}>
        <Text style={styles.date}>{formattedDate}</Text>
        <CustomIcon
          name="community:arrow-top-right-bold-outline"
          size={22}
          style={styles.icon}
        />
      </View>
      <Text style={styles.text}>{content.slice(0, 150)}</Text>
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: Colors.green_transparent,
    marginVertical: 8,
    paddingVertical: 22,
    paddingHorizontal: 16,
  },
  title_container: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  icon: {
    color: Colors.green,
  },
  date: {
    fontSize: 16,
    fontWeight: "700",
    color: Colors.green,
  },
  text: {
    color: Colors.dark,
    opacity: 0.6,
  },
});

export default NoteListItem;
