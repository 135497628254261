import {
  createElement
} from "react-native";
import { Colors } from "../../constants/colors";

interface Props {
  onChange: (date: string) => void;
  value: string | null;
  readOnly?: boolean;
  defaultValue?: string;
  minValue?: string;
  locale?: string;
  label_save?: string;
}

const DatePicker = ({
  onChange,
  value,
  readOnly,
  defaultValue,
  minValue,
}: Props) => {
  return (
    <>
      {createElement("input", {
        value: value || defaultValue,
        type: "date",
        min: minValue,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value),
        disabled: readOnly,
        style: {
          fontFamily: "arial",
          fontSize: 14,
          borderRadius: 4,
          borderWidth: 0,
          backgroundColor: Colors.lightgray,
          height: 40,
          lineHeight: 18,
        },
      })}
    </>
  );
};

export default DatePicker;
