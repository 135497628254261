
export const userRoutes = {
  GUEST: (language: string, timezone?: string) => {
    return {
      url: "/users/guest",
      method: "post",
      data: {
        language,
        timezone,
      },
    };
  },
  VERIFY: (code: string) => ({
    url: "/users/verify",
    method: "POST",
    data: code,
  }),
  RESEND_VERIFICATION_EMAIL: {
    url: "/users/resend-verification-email",
    method: "POST",
  },
  // url encoded data
  SIGNIN: (data: string) => {
    return {
      url: "/signin/access-token",
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  },
  SIGNUP: (data: any) => {
    return {
      url: "/signup",
      method: "POST",
      data,
    };
  },
  PASSWORD_RECOVERY: (email: string) => {
    return {
      method: "post",
      url: "/password-recovery/",
      data: { email },
    };
  },
  RESET_PASSWORD: (code: string, new_password: string) => {
    return {
      method: "post",
      url: "/reset-password/",
      data: {
        code,
        new_password,
      },
    };
  },
  FB_SIGNUP: (token: string) => ({
    url: "/signup/facebook",
    method: "POST",
    data: {
      access_token: token,
    },
  }),
  FB_SIGNIN: (token: string) => ({
    url: "/signin/facebook",
    method: "POST",
    data: {
      access_token: token,
    },
  }),
  GOOGLE_SIGNUP: (token: string) => ({
    url: "/signup/google",
    method: "POST",
    data: {
      id_token: token,
    },
  }),
  GOOGLE_SIGNIN: (token: string) => ({
    url: "/signin/google",
    method: "POST",
    data: {
      id_token: token,
    },
  }),
  APPLE_SIGNUP: (token: string, name?: string) => ({
    url: "/signup/apple",
    method: "POST",
    data: {
      id_token: token,
      name,
    },
  }),
  APPLE_SIGNIN: (token: string) => ({
    url: "/signin/apple",
    method: "POST",
    data: {
      id_token: token,
    },
  }),
  ME: {
    method: "GET",
    url: "/api/v1/cbti/me",
  },
  READ_CONSENTS: {
    method: "GET",
    url: "/users/consents",
  },
  SET_CONSENTS: (consents: { [key: string]: boolean }) => ({
    method: "PUT",
    url: "/users/consents",
    data: consents,
  }),
  DELETE_ACCOUNT: {
    method: "DELETE",
    url: "/users/me",
  },
  SUBSCRIBE: {
    method: "POST",
    url: "/users/me/subscription",
  },
  GET_SUBSCRIPTION: {
    method: "GET",
    url: "/users/me/subscription",
  },
  DELETE_SUBSCRIPTION: {
    method: "DELETE",
    url: "/users/me/subscription",
  },
  USER_CONFIGURATION: {
    url: "/api/v1/cbti/me/edit-configuration",
    method: "GET",
  },
  EDIT_PROFILE: (data: any) => ({
    url: "/api/v1/cbti/me",
    method: "PUT",
    data,
  }),
  CHANGE_PASSWORD: (current_password: string, new_password: string) => ({
    url: "/users/me/change-password",
    method: "POST",
    data: {
      current_password,
      new_password,
    },
  }),
};
