import React from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Text, View
} from "react-native";
import { Colors } from "../../constants/colors";
import Slider from "./Slider";

type Props = {
  isLoading: boolean;
  disabled?: boolean;
  trackLength: number;
  currentPosition: number;
  onSeek: (value: number) => void;
};

const formatDuration = (duration: number) => {
  const seconds = Math.round(duration % 60);
  const minutes = Math.floor(duration / 60);
  const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${minutesStr}:${secondsStr}`;
};

function SeekBar(props: Props) {

  const handleChange = (value: number) => {
    props.onSeek(value)
  }

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{formatDuration(props.currentPosition)}</Text>
      <Slider
        disabled={props.isLoading  || props.disabled}
        maxValue={props.trackLength}
        minValue={0}
        value={props.currentPosition}
        onChangeFinish={handleChange}
      />
      {props.isLoading ? (
        <ActivityIndicator color={Colors.primary} />
      ) : (
        <Text style={styles.text}>{formatDuration(props.trackLength)}</Text>
      )}
    </View>
  );
}

SeekBar.defaultProps = {
  currentPosition: 0,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  slider: {
    flex: 1,
  },
  text: {
    fontSize: 12,
    width: 40,
  },
});

export default SeekBar;
