import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { Colors } from "../constants/colors";
import { Translations } from "../types/v2/manifest";
import { DailyHabit } from "../types/v2/sleepDiary";
import CustomIcon from "./CustomIcon";

interface Props extends DailyHabit {
  translations: Translations;
}

const HabitsSummary: FC<Props> = ({
  data,
  icon,
  primary_text_label,
  secondary_text_label,
  translations,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.description_container}>
        <CustomIcon
          name={icon}
          size={28}
          style={{ marginRight: 20 }}
          color={Colors.primary}
        />
        <View>
          <Text style={styles.headline}>
            {translations[primary_text_label]}
          </Text>
          <Text style={styles.sub_headline}>
            {translations[secondary_text_label]}
          </Text>
        </View>
      </View>
      <View style={styles.icons_container}>
        {data.map((value, index) => (
          <View style={styles.icon_container} key={index}>
            {value !== null && (
              <View style={styles.circle_container}>
                <AnimatedCircularProgress
                  width={10}
                  size={20}
                  fill={value * 100}
                  rotation={0}
                  tintColor={"#59B6B3"}
                  backgroundColor={Colors.lightgray}
                />
                {value === 1 && (
                  <View style={styles.checkmark_container}>
                    <CustomIcon
                      name="community:check-bold"
                      size={14}
                      color={Colors.white}
                    />
                  </View>
                )}
              </View>
            )}
          </View>
        ))}
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    paddingLeft: 30,
    marginTop: 20,
  },
  description_container: {
    flexDirection: "row",
    alignItems: "center",
  },
  headline: {
    fontSize: 14,
    lineHeight: 28,
    color: Colors.dark,
    fontWeight: "700",
  },
  sub_headline: {
    fontSize: 11,
    lineHeight: 16,
    color: Colors.dark,
    opacity: 0.6,
  },
  icons_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  icon_container: {
    width: 108,
    paddingLeft: 10,
    paddingVertical: 10,
  },
  circle_container: {
    position: "relative",
    width: 20,
    height: 20,
  },
  checkmark_container: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 11,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default HabitsSummary;
