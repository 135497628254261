import { useNavigation, useNavigationState } from "@react-navigation/native";
import { FC, useMemo } from "react";
import {
  ImageBackground,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { SessionPreview } from "../../types/v2/therapy";
import CustomIcon from "../CustomIcon";

interface Props extends SessionPreview {
  isLast: boolean;
  onPress?: () => void;
}

const SessionTeaser: FC<Props> = ({
  uuid,
  status,
  template,
  isLast,
  progress = 0,
}) => {
  const navigation = useNavigation<any>();
  const {
    sessions_proceed_cta,
    sessions_session_status_in_progress,
    sessions_session_status_locked,
    sessions_session_status_completed,
    payments_buy_subscription_cta,
  } = useTranslations();
  const { subscription } = useAppSelector((state) => state.therapyParticipant);

  const statusText = useMemo(() => {
    if (subscription === null) {
      return payments_buy_subscription_cta;
    }
    switch (status) {
      case "in_progress":
        return sessions_session_status_in_progress;
      case "locked":
        return sessions_session_status_locked;
      case "elements_completed":
      case "completed":
        return sessions_session_status_completed;
      default:
        return "";
    }
  }, [subscription, status]);

  function handlePress() {
    if (subscription === null) {
      navigation.navigate(SCREEN.Payments);
    } else if (status !== "locked") {
      navigation.navigate(SCREEN.Session, { uuid });
    }
  }

  return (
    <Pressable style={styles.container} onPress={handlePress}>
      <View style={styles.indicator_container}>
        <View
          style={[styles.indicator_dot, { backgroundColor: Colors.primary }]}
        ></View>
        {!isLast && (
          <View style={styles.indicator_line}>
            <View
              style={[
                styles.indicator_line_inner,
                progress > 0 && { height: progress * 100 + "%" },
              ]}
            ></View>
          </View>
        )}
      </View>
      <View style={styles.content_container}>
        <View style={styles.content_headline}>
          <Text style={[styles.title]}>{template.name}</Text>
          <Text style={styles.time}>{template.duration_text}</Text>
        </View>
        <View style={styles.lock_container}>
          <CustomIcon
            name={status === "locked" ? "lock-outline" : "lock-open"}
            size={14}
            style={{ marginRight: 10 }}
            color={Colors.darkgray}
          />
          <Text style={styles.lock_text}>{statusText}</Text>
        </View>
        <View>
          <ImageBackground
            source={
              status === "locked"
                ? require("../../assets/images/session_bg_gray.png")
                : require("../../assets/images/session_bg.png")
            }
            resizeMode="cover"
            style={styles.button_container}
          >
            {status !== "locked" &&
              status !== "elements_completed" &&
              status !== "completed" && (
                <View style={styles.button}>
                  <CustomIcon
                    name="trending-up"
                    size={30}
                    color={Colors.white}
                    style={{ marginRight: 18 }}
                  />
                  <Text style={styles.continue_text}>
                    {sessions_proceed_cta}
                  </Text>
                </View>
              )}
          </ImageBackground>
        </View>
        <View style={styles.description_container}>
          <Text style={styles.description_title}>{template.topic}</Text>
          <Text style={styles.description_text}>{template.description}</Text>
        </View>
      </View>
    </Pressable>
  );
};

export default SessionTeaser;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 30,
    flexDirection: "row",
    maxWidth: 800,
  },
  indicator_container: {
    alignItems: "center",
    marginRight: 20,
    width: 8,
  },
  indicator_dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: Colors.gray,
  },
  indicator_line: {
    width: 2,
    flex: 1,
    backgroundColor: Colors.gray,
  },
  indicator_line_inner: {
    backgroundColor: Colors.primary,
    width: 2,
  },
  //
  content_container: {
    flex: 1,
    paddingBottom: 34,
    marginTop: -4,
  },
  content_headline: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  //
  lock_container: {
    marginTop: 4,
    marginBottom: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  lock_text: {
    fontSize: 14,
    color: Colors.dark,
    opacity: 0.6,
  },
  //
  title: {
    fontSize: 18,
    fontWeight: "700",
    color: Colors.dark,
    lineHeight: 21,
  },
  time: {
    fontSize: 14,
    color: Colors.dark,
    opacity: 0.6,
    lineHeight: 17,
    fontWeight: "400",
  },
  text: {
    color: Colors.dark,
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "300",
  },
  //
  button_container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 120,
    position: "relative",
  },
  button: {
    backgroundColor: Colors.primary,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 4,
  },
  continue_text: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "700",
    color: Colors.white,
  },
  //
  description_container: {
    marginTop: 22,
  },
  description_title: {
    fontSize: 14,
    color: Colors.dark,
    fontWeight: "700",
    lineHeight: 17,
  },
  description_text: {
    fontSize: 14,
    color: Colors.dark,
    fontWeight: "300",
    lineHeight: 22,
    marginTop: 9,
  },
});
