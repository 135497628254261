import { FC } from "react";
import { StyleSheet, Text } from "react-native";
import { Colors } from "../../constants/colors";

interface IProps {
  text: string;
  size?: "small" | "medium" | "large";
}

const MainHeading: FC<IProps> = ({ text, size = "small" }) => {
  return (
    <Text
      style={[
        styles.text,
        size === "large" && styles.text_large,
        size === "medium" && styles.text_medium,
      ]}
    >
      {text}
    </Text>
  );
};

export default MainHeading;

const styles = StyleSheet.create({
  text: {
    fontSize: 30,
    lineHeight: 40,
    fontWeight: "600",
    color: Colors.primary,
  },
  text_large: {
    fontSize: 50,
    lineHeight: 56,
  },
  text_medium: {
    fontSize: 40,
    lineHeight: 48,
  },
});
