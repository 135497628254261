import { FC } from "react";
import { ResultsComparison as ResultsComparisonProps } from "../../types/v2/slides/slide_content";
import ResultsComparisonInner from "../ResultsComparison";

interface Props extends ResultsComparisonProps {
  translations: { [key: string]: string };
}

const ResultsComparison: FC<Props> = (props) => {
  const { translations } = props;
  return (
    <ResultsComparisonInner
      header={props.header !== null ? translations[props.header] : undefined}
      before_value={props.before_value || undefined}
      before_value_unit={props.before_value_unit || undefined}
      before_value_color={props.before_value_color || undefined}
      before_label={
        props.before_label !== null
          ? translations[props.before_label]
          : undefined
      }
      before_label_color={props.before_label_color || undefined}
      after_value={props.after_value || undefined}
      after_value_unit={props.after_value_unit || undefined}
      after_value_color={props.after_value_color || undefined}
      after_label={
        props.after_label !== null ? translations[props.after_label] : undefined
      }
      after_label_color={props.after_label_color || undefined}
      arrow_color={props.arrow_color || undefined}
    />
  );
};

export default ResultsComparison;
