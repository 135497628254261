import { useNavigation } from "@react-navigation/native";
import { StyleSheet, Text, View } from "react-native";
import Markdown from "react-native-markdown-display";
import { Colors } from "../../constants/colors";
import { SCREEN } from "../../navigation/screens";
import { SubscriptionOffer as SubscriptionOfferProps } from "../../types/v2/slides/slide_content";
import PrimaryButton from "../utils/primary_button";

interface Props extends SubscriptionOfferProps {
  label_buy_subscription: string;
}

const SubscriptionOffer = (props: Props) => {
  const navigation = useNavigation<any>();

  const handleBuy = () => {
    navigation.navigate(SCREEN.Payments);
  };

  return (
    <View style={[styles.container]}>
      <Text style={styles.prefix}>{props.header}</Text>
      <Text style={styles.heading}>{props.plan_name}</Text>
      <Markdown
        rules={{
          paragraph: (node: any, children: any, parent: any) => (
            <Text key={node.key} style={styles.subheading}>
              {children}
            </Text>
          ),
          strong: (node: any, children: any, parent: any) => (
            <Text key={node.key} style={styles.subheading}>
              {children}
            </Text>
          ),
        }}
      >
        {props.content}
      </Markdown>
      <Text style={styles.price}>{props.subcontent}</Text>
      <PrimaryButton
        label={props.label_buy_subscription}
        reverse
        onPress={handleBuy}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: -30,
    paddingHorizontal: 20,
    paddingVertical: 40,
    backgroundColor: Colors.primary,
  },
  prefix: {
    fontSize: 12,
    lineHeight: 14,
    color: Colors.white,
    fontWeight: "700",
    textTransform: "uppercase",
  },
  heading: {
    fontSize: 50,
    lineHeight: 60,
    fontWeight: "700",
    color: Colors.white,
    marginTop: 10,
    marginBottom: 5,
  },
  subheading: {
    fontSize: 18,
    lineHeight: 26,
    fontWeight: "700",
    color: Colors.white,
  },
  price: {
    fontSize: 30,
    lineHeight: 32,
    fontWeight: "700",
    color: Colors.white,
    marginVertical: 20,
  },
  more: {
    fontSize: 14,
    lineHeight: 22,
    color: Colors.white,
    fontWeight: "700",
    marginTop: 30,
    marginBottom: 15,
    paddingVertical: 10,
    paddingHorizontal: 10,
    textAlign: "center",
  },
});

export default SubscriptionOffer;
