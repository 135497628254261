import { createAsyncThunk } from "@reduxjs/toolkit";
import formatISO from "date-fns/formatISO";
import sub from "date-fns/sub";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { SurveyWithActions } from "../../../types/v2/survey";

export const getDiary = createAsyncThunk<
  SurveyWithActions,
  string | undefined,
  IThunkApi
>("sleepDiary/getDiary", async (maybeDate, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  let date: string;
  if (maybeDate === undefined) {
    const day = new Date();
    date = formatISO(sub(day, { days: 1 }), { representation: "date" });
  } else {
    date = maybeDate;
  }
  const { data } = await call(
    authorize(ENDPOINTS.session.FILL_DIARY(date), token)
  );
  return data;
});
