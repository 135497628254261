import { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { ENDPOINTS } from "../../api/config";
import Link from "../../components/controls/Link";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import CustomText from "../../components/typography/text";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import Input from "../../components/utils/input";
import PrimaryButton from "../../components/utils/primary_button";
import { useAppSelector } from "../../hooks/redux";
import { useAuthorizedCall } from "../../hooks/useAuthorizedCall";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { RootState } from "../../store/redux";

const ResetPasswordScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.ResetPassword>) => {
  const {
    auth_signin_password_remind_cta,
    auth_password_reset_verification_content,
    auth_password_reset_placeholder_verification_code,
    auth_password_reset_placeholder_password,
    auth_password_reset_header,
    auth_back_to_main_screen,
    auth_signin_subheader,
    auth_email_validation_placeholder_verification_code,
    auth_password_required,
    password_requirements,
  } = useAppSelector((state: RootState) => state.manifest.translations);
  const manifest = useTranslations();

  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState<string | null>(null);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const call = useAuthorizedCall();

  const resetPassword = async () => {
    let hasErrors = false;

    if (!code) {
      setCodeError(auth_email_validation_placeholder_verification_code);
      hasErrors = true;
    } else setCodeError(null);
    if (!password) {
      setPasswordError(auth_password_required);
      hasErrors = true;
    } else {
      setPasswordError(null);
    }

    if (loading || hasErrors) return;

    setLoading(true);
    try {
      const { data, status } = await call(
        ENDPOINTS.user.RESET_PASSWORD(code, password)
      );
      if (status === 200) {
        navigation.navigate(SCREEN.Login);
        return;
      }
    } catch (error: any) {
      setPasswordError(error.response.data.detail);
    }
    setLoading(false);
  };

  return (
    <Container>
      <View style={styles.container}>
        <View>
          <MainHeading text={auth_signin_password_remind_cta} size="large" />
          <View style={{ marginTop: 10 }}>
            <SubHeading text={auth_signin_subheader} />
          </View>
          <Divider />
          <CustomText text={auth_password_reset_verification_content} />
          <View style={{ marginTop: 20 }}>
            <Input
              value={code}
              setValue={setCode}
              placeholder={auth_password_reset_placeholder_verification_code}
              error={codeError}
              keyboardType="numeric"
            />
            <Input
              value={password}
              setValue={setPassword}
              placeholder={auth_password_reset_placeholder_password}
              error={passwordError ? manifest[passwordError] : null}
              type="password"
              postfix={password_requirements}
            />
          </View>
        </View>
        <View style={styles.action_container}>
          <Link
            to={{ screen: SCREEN.EmailPasswordLogin }}
            style={{ alignSelf: "center" }}
          >
            {auth_back_to_main_screen}
          </Link>
          <View style={{ marginTop: 20 }}>
            <PrimaryButton
              label={auth_password_reset_header}
              loading={loading}
              onPress={resetPassword}
            />
          </View>
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flex: 1,
  },
  link: {
    textDecorationLine: "underline",
    marginTop: 8,
  },
  action_container: {
    justifyContent: "flex-end",
  },
});

export default ResetPasswordScreen;
