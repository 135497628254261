import { FC } from "react";
import { View, Text, StyleSheet, useWindowDimensions } from "react-native";
import { Colors } from "../../constants/colors";

interface IProps {
  primary_text: string;
  secondary_text: string;
}

const DiarySummaryTexts: FC<IProps> = ({ primary_text, secondary_text }) => {
  const { width } = useWindowDimensions()
  return (
    <View style={[styles.container, { width: width }]}>
      <Text style={styles.primary}>{primary_text}</Text>
      <Text style={styles.secondary}>{secondary_text}</Text>
    </View>
  );
};

export default DiarySummaryTexts;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 15,
  },
  primary: {
    fontSize: 18,
    lineHeight: 21,
    color: Colors.primary,
    fontWeight: "700",
  },
  secondary: {
    fontSize: 14,
    lineHeight: 28,
    color: Colors.dark,
    opacity: 0.6,
  },
});
