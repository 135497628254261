import { createSlice } from "@reduxjs/toolkit";
import { IRelaxation } from "../../components/relaxations_carousel/relaxations_carousel.types";
import { fetchRelaxations } from "./thunk_functions/fetchRelaxations";

interface RelaxationsState {
  needRefresh: boolean;
  loading: boolean;
  error: boolean;
  byId: { [uuid: string]: IRelaxation };
  byCategory: { [category: string]: string[] };
}

const initialState: RelaxationsState = {
  needRefresh: true,
  loading: false,
  error: false,
  byId: {},
  byCategory: {},
};

const relaxationsSlice = createSlice({
  name: "relaxations",
  initialState: initialState,
  reducers: {
    martAsState(state) {
      state.needRefresh = true;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRelaxations.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchRelaxations.fulfilled, (state, action) => {
        state.loading = false;
        state.needRefresh = false;
        state.byId = action.payload.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.uuid]: curr,
          }),
          {}
        );
        state.byCategory = action.payload.reduce((prev, curr) => {
          const category = curr.category.name;
          if (!prev[category]) {
            return {
              ...prev,
              [category]: [curr.uuid],
            };
          }
          return {
            ...prev,
            [category]: [...prev[category], curr.uuid],
          };
        }, {} as { [category: string]: string[] });
      })
      .addCase(fetchRelaxations.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const RelaxationsActions = relaxationsSlice.actions;
export const relaxationsReducer = relaxationsSlice.reducer;
