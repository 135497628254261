import { useIsFocused } from "@react-navigation/native";
import { FC, useEffect, useState } from "react";
import { RefreshControl, ScrollView, Text, View } from "react-native";
import CustomTopTabs from "../components/custom_top_tabs/custom_top_tabs";
import DiarySummaryDays from "../components/diary_summary_days/diary_summary_days";
import HabitsSummary from "../components/HabitsSummary";
import Loader from "../components/Loader";
import SleepQuality from "../components/sleep_quality/sleep_quality";
import Chart from "../components/stacked_barplot/stacked_barplot";
import DiarySummaryTexts from "../components/typography/diary_summary_texts";
import MainHeading from "../components/typography/main_heading";
import Divider from "../components/utils/divider";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { useTranslations } from "../hooks/useTranslations";
import { RootBottomTabScreenProps } from "../navigation/BottomTabNavigation.types";
import { SCREEN } from "../navigation/screens";
import { getSleepDiarySummary } from "../store/redux/thunk_functions/sleepDiarySummary";
import { BarDescription } from "../types/charts";

const Spacer = ({ children }: any) => {
  return <View style={{ marginHorizontal: 30 }}>{children}</View>;
};

const SleepDiarySummaryScreen: FC<
  RootBottomTabScreenProps<SCREEN.SleepDiarySummary>
> = ({ navigation }) => {
  const [activeTab, setActiveTab] = useState<"daily" | "weekly">("daily");
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const translations = useTranslations();
  const { needRefresh, content, loading, errorMessage } = useAppSelector(
    (state) => state.sleepDiarySummary
  );

  useEffect(() => {
    const fetchSummary = async () => {
      await dispatch(getSleepDiarySummary());
    };
    if (isFocused && needRefresh) {
      fetchSummary();
    }
  }, [isFocused, needRefresh]);

  function handleBarPress(bar: BarDescription, index: number) {
    if (content !== undefined) {
      const date = content.tabs[index].key;
      // @ts-ignore
      navigation.navigate(SCREEN.Survey, { date, type: "sleep_diary" });
    }
  }

  const handleRefresh = () => {
    dispatch(getSleepDiarySummary());
  };

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      refreshControl={
        <RefreshControl
          refreshing={loading && content === undefined}
          onRefresh={handleRefresh}
        />
      }
    >
      <View
        style={{
          paddingHorizontal: 30,
          marginTop: 30,
          marginBottom: 15,
        }}
      >
        <MainHeading text={translations.sleep_calendar_summary_summary} />
      </View>
      {loading && content === undefined && <Loader />}
      {errorMessage !== undefined && (
        <View
          style={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ width: "50%", textAlign: "center" }}>
            {translations[errorMessage]}
          </Text>
        </View>
      )}
      {errorMessage === undefined && content !== undefined && (
        <View style={{ flexGrow: 1 }}>
          <View style={{ height: 60 }}>
            <CustomTopTabs
              activeTab={activeTab}
              setActive={setActiveTab}
              tabs={[
                {
                  value: "daily",
                  label: translations.sleep_calendar_summary_daily,
                },
                /*{
                    value: "weekly",
                    label: translations.sleep_calendar_summary_weekly,
                  },*/
              ]}
            />
          </View>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, paddingBottom: 30 }}
            bounces={false}
            horizontal
          >
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
              }}
              bounces={false}
            >
              <DiarySummaryDays
                tabs={content.tabs}
                translations={translations}
              />
              <DiarySummaryTexts
                primary_text={
                  translations.sleep_calendar_summary_sleep_parameters_primary
                }
                secondary_text={
                  translations.sleep_calendar_summary_sleep_parameters_secondary
                }
              />
              <Chart
                {...content.sleep_parameters.chart_data}
                onBarPress={handleBarPress}
              />
              <Spacer>
                <Divider />
              </Spacer>
              <SleepQuality
                data={content.sleep_quality.data}
                primary_text_label={content.sleep_quality.primary_text_label}
                secondary_text_label={
                  content.sleep_quality.secondary_text_label
                }
              />
              <Spacer>
                <Divider />
              </Spacer>
              <DiarySummaryTexts
                primary_text={translations[content.habits.primary_text_label]}
                secondary_text={
                  translations[content.habits.secondary_text_label]
                }
              />
              {content.habits.data.map((habit) => (
                <HabitsSummary
                  {...habit}
                  key={habit.primary_text_label}
                  translations={translations}
                />
              ))}
            </ScrollView>
          </ScrollView>
        </View>
      )}
    </ScrollView>
  );
};

export default SleepDiarySummaryScreen;
