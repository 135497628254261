import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { ScrollView, StyleSheet, View } from "react-native";
import MainHeading from "../components/typography/main_heading";
import { Colors } from "../constants/colors";
import { useTranslations } from "../hooks/useTranslations";
import BeliefsScreen from "../screens/patientCard/BeliefsScreen";
import InsomniaMapScreen from "../screens/patientCard/InsomniaMapScreen";
import TherapyProfileScreen from "../screens/patientCard/TherapyProfileScreen";
import { PatientCardTabParamList } from "./PatientCardTabNavigation.types";
import { SCREEN } from "./screens";

const Tab = createMaterialTopTabNavigator<PatientCardTabParamList>();

export const PatientCardTabNavigation = () => {
  const {
    menu_bottom_therapy_folder,
    therapy_folder_tab_profile,
    therapy_folder_tab_insomnia_map,
    therapy_folder_tab_beliefs
  } = useTranslations();

  return (
    <View style={{ flex: 1}}>
      <View style={styles.headingContainer}>
        <MainHeading text={menu_bottom_therapy_folder} />
      </View>
      <Tab.Navigator
        screenOptions={{
          swipeEnabled: false,
          tabBarScrollEnabled: true,
          tabBarActiveTintColor: Colors.primary,
          tabBarInactiveTintColor: Colors.gray,
          tabBarLabelStyle: {
            fontSize: 18,
            fontWeight: "bold",
            textTransform: "capitalize",
          },
          tabBarItemStyle: {
            width: "auto",
            paddingHorizontal: 30,
          },
        }}
      >
        <Tab.Screen
          name={SCREEN.TherapyProfile}
          options={{
            title: therapy_folder_tab_profile,
          }}
          component={TherapyProfileScreen}
        />
        <Tab.Screen
          name={SCREEN.InsomniaMap}
          options={{
            title: therapy_folder_tab_insomnia_map,
          }}
          component={InsomniaMapScreen}
        />
        <Tab.Screen
          name={SCREEN.Beliefs}
          options={{
            title: therapy_folder_tab_beliefs,
          }}
          component={BeliefsScreen}
        />
      </Tab.Navigator>
    </View>
  );
};

const styles = StyleSheet.create({
  headingContainer: {
    marginHorizontal: 30,
    marginTop: 30,
    marginBottom: 15,
  },
});
