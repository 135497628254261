import { FC } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  RefreshControl,
  ScrollView,
} from "react-native";
import { SlideContent } from "../types/v2/slides/slide_content";
import Slide from "./slides/Slide";
import Container from "./utils/container";

interface Props {
  loading?: boolean;
  content?: SlideContent[];
  translations: { [key: string]: string };
  onRefresh?: () => void;
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

const Dashboard: FC<Props> = ({
  loading = false,
  content = [],
  translations = {},
  onRefresh,
  onScroll,
}) => {
  return (
    <ScrollView
      onScroll={onScroll}
      scrollEventThrottle={16}
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={onRefresh} />
      }
    >
      <Container fullWidth={Platform.OS !== "web"}>
        {content != null && (
          <Slide content={content} translations={translations} />
        )}
      </Container>
    </ScrollView>
  );
};

export default Dashboard;
