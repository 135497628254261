import { createSlice } from "@reduxjs/toolkit";
import { Article } from "../../types/v2/article";
import { fetchArticles } from "./thunk_functions/fetchArticles";

interface LibraryState {
  needRefresh: boolean;
  loading: boolean;
  error: boolean;
  byId: { [uuid: string]: Article };
  byCategory: { [category: string]: string[] };
}

const initialState: LibraryState = {
  needRefresh: true,
  loading: false,
  error: false,
  byId: {},
  byCategory: {},
};

const librarySlice = createSlice({
  name: "library",
  initialState: initialState,
  reducers: {
    markAsStale(state) {
      state.needRefresh = true;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArticles.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchArticles.fulfilled, (state, action) => {
      state.loading = false;
      state.needRefresh = false;
      state.byId = action.payload.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.uuid]: curr,
        }),
        {}
      );
      state.byCategory = action.payload.reduce((prev, curr) => {
        const category = curr.category.name;
        if (!prev[category]) {
          return {
            ...prev,
            [category]: [curr.uuid],
          };
        }
        return {
          ...prev,
          [category]: [...prev[category], curr.uuid],
        };
      }, {} as { [category: string]: string[] });
    });
    builder.addCase(fetchArticles.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const LibraryActions = librarySlice.actions;
export const libraryReducer = librarySlice.reducer;
