import { Pressable, StyleSheet } from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";

interface Props {
  isPaused: boolean;
  disabled?: boolean;
  onPress?: () => void;
}

function PlayPauseButton(props: Props) {
  const handlePress = () => {
    if (!props.disabled && props.onPress !== undefined) {
      props.onPress();
    }
  };

  return (
    <Pressable
      style={({ pressed }) => [
        styles.container,
        props.disabled && styles.disabled,
        pressed && { opacity: 0.6 },
      ]}
      onPress={handlePress}
    >
      <CustomIcon
        name={!props.isPaused ? "community:pause" : "community:play"}
        size={30}
        color={Colors.white}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 48,
    height: 48,
    borderRadius: 30,
    backgroundColor: Colors.primary,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
  },
  disabled: {
    backgroundColor: Colors.gray,
  },
});

export default PlayPauseButton;
