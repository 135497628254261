import { FC } from "react";
import { NativeSyntheticEvent, StyleSheet, TextInput, TextInputFocusEventData } from "react-native";
import { Colors } from "../../constants/colors";

interface Props {
  value: number | null;
  onChange: (value: number | null) => void;
  readOnly?: boolean;
}

const IntegerPicker: FC<Props> = ({ value, onChange, readOnly }) => {
  const valueAsString = value === null ? undefined : value.toString();

  const handleChange = (text: string) => {
    const parsedValue = Number.parseInt(text);
    let answer: number | null;
    if (Number.isNaN(parsedValue)) {
      answer = null;
    } else {
      answer = parsedValue;
    }
    onChange(answer);
  };

  const handleBlur = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
    if (value === null) {
      onChange(0)
    }
  }

  return (
    <TextInput
      editable={!readOnly}
      style={styles.input}
      keyboardType={"number-pad"}
      value={valueAsString}
      onChangeText={handleChange}
      onBlur={handleBlur}
    />
  );
};

const styles = StyleSheet.create({
  input: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: Colors.lightgray,
    textAlign: "center",
    fontSize: 18,
    lineHeight: 21,
  },
});

export default IntegerPicker;
