import { useIsFocused } from "@react-navigation/native";
import { FC, useEffect, useRef } from "react";
import {
  LayoutRectangle,
  RefreshControl,
  ScrollView,
  View,
} from "react-native";
import DayPlanItem from "../../components/DayPlanItem";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { TherapyTabScreenProps } from "../../navigation/TherapyTabNavigation.types";
import { getDayPlan } from "../../store/redux/thunk_functions/dayPlan";

const DayPlanScreen: FC<TherapyTabScreenProps<SCREEN.DayPlan>> = () => {
  const translations = useTranslations();
  const isFocused = useIsFocused();
  const containerRef = useRef<ScrollView | null>(null);
  const todayPos = useRef<LayoutRectangle | null>();
  const dispatch = useAppDispatch();
  const { loading, needRefresh, items } = useAppSelector(
    (state) => state.dayPlan
  );
  const { subscription } = useAppSelector((state) => state.therapyParticipant);

  const loadDayPlan = async () => {
    await dispatch(getDayPlan());
  };

  const scrollToToday = () => {
    setTimeout(() => {
      containerRef.current?.scrollTo({
        y: todayPos.current?.y,
        animated: true,
      });
    }, 500);
  };

  useEffect(() => {
    if (isFocused && needRefresh && subscription !== null) {
      loadDayPlan();
    }
  }, [isFocused, needRefresh, subscription]);

  useEffect(() => {
    scrollToToday();
  }, [items]);

  return (
    <ScrollView
      style={{
        flexGrow: 1,
        position: "relative",
        marginBottom: 0,
        paddingTop: 10,
      }}
      ref={containerRef}
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={loadDayPlan} />
      }
    >
      {items.map((item, index) => {
        return (
          <View
            key={index}
            onLayout={(event) => {
              if (item.is_today && item.daytime === "day") {
                todayPos.current = event.nativeEvent.layout;
              }
            }}
          >
            <DayPlanItem {...item} translations={translations} />
          </View>
        );
      })}
    </ScrollView>
  );
};

export default DayPlanScreen;
