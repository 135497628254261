import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { IArticle } from "../../../types/survey";

export const fetchArticles = createAsyncThunk<IArticle[], undefined, IThunkApi>(
  "library/fetchArticles",
  async (_, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call<IArticle[]>(
      authorize(ENDPOINTS.tools.GET_ARTICLES(), token)
    );
    return data;
  }
);
