import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import Arrow from "./Arrow";
import TextContainer from "./TextContainer";

interface Props {
  header?: string;
  after_label?: string;
  after_label_color?: string;
  after_value_color?: string;
  after_value_unit?: string;
  after_value?: string;
  before_label_color?: string;
  before_label?: string;
  before_value_color?: string;
  before_value?: string;
  before_value_unit?: string;
  arrow_color?: string;
}

const ResultsComparison: FC<Props> = ({
  header,
  after_label,
  after_label_color,
  after_value_color,
  after_value_unit,
  after_value,
  before_label_color,
  before_label,
  before_value_color,
  before_value,
  before_value_unit,
  arrow_color,
}) => {
  return (
    <View style={styles.container}>
      {header !== undefined && <Text style={styles.header}>{header}</Text>}
      <View style={styles.innerContainer}>
        <TextContainer
          value={before_value}
          valueUnit={before_value_unit}
          valueColor={before_value_color}
          label={before_label}
          labelColor={before_label_color}
        />
        <Arrow color={arrow_color} />
        <TextContainer
          value={after_value}
          valueUnit={after_value_unit}
          valueColor={after_value_color}
          label={after_label}
          labelColor={after_label_color}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    fontWeight: "700",
    color: Colors.dark,
    marginBottom: 15,
  },
  container: {
    marginVertical: 5,
  },
  innerContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
});

export default ResultsComparison;
