import { useIsFocused } from "@react-navigation/native";
import { FC, useEffect } from "react";
import { FlatList, Pressable } from "react-native";
import { useSelector } from "react-redux";
import FloatingMenu from "../../components/FloatingMenu";
import GoBack from "../../components/GoBack";
import NoteListItem from "../../components/NoteListItem";
import MainHeading from "../../components/typography/main_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { RootState } from "../../store/redux";
import { getNotes } from "../../store/redux/thunk_functions/notes";

const NotesScreen: FC<RootStackScreenProps<SCREEN.Notes>> = ({
  navigation,
}) => {
  const isFocused = useIsFocused();
  const { tools_notepad } = useSelector(
    (state: RootState) => state.manifest.translations
  );
  const { needRefresh, byId, ids, loading } = useAppSelector(
    (state) => state.notes
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh && isFocused) {
      dispatch(getNotes());
    }
  }, [needRefresh, isFocused]);

  const notes = ids.map((id) => byId[id]);

  const handlePress = (uuid: string) => {
    navigation.navigate(SCREEN.Note, { uuid: uuid });
  };

  return (
    <>
      <Container>
        <GoBack />
        <MainHeading text={tools_notepad} size={"large"} />
        <Divider />
        <FlatList
          data={notes}
          renderItem={({ item }) => (
            <Pressable
              key={item.uuid}
              onPress={handlePress.bind(this, item.uuid)}
            >
              <NoteListItem {...item} />
            </Pressable>
          )}
        />
      </Container>
      <FloatingMenu items={["note"]} />
    </>
  );
};

export default NotesScreen;
