import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StaticContentState } from "../../types/content";
import { getDashboard } from "./thunk_functions/dashboard";

const initialState: StaticContentState = {
  needRefresh: true,
  loading: false,
  error: false,
  content: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    markAsStale: (state) => {
      state.needRefresh = true;
      return state;
    },
    reset(state) {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboard.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    }),
      builder.addCase(getDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.needRefresh = false;
        state.content = action.payload;
        state.timestamp = Date.now();
      }),
      builder.addCase(getDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.content = null;
      });
  },
});

export const DashboardActions = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
