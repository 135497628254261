import { StackActionType, useLinkProps } from "@react-navigation/native";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import { FC } from "react";
import { RootStackParamList } from "../../navigation/StackNavigation.types";
import PrimaryButton, { PrimaryButtonProps } from "./PrimaryButton";
import { sendAnalyticsEventAsync } from "../../utils/analytics";

interface Props extends PrimaryButtonProps {
  to: To<RootStackParamList>;
  action?: StackActionType;
  eventName?: string;
  eventParams?: Record<string, any>;
}

const PrimaryButtonLink: FC<Props> = ({
  to,
  action,
  children,
  eventName,
  eventParams,
  ...rest
}) => {
  const { onPress, ...props } = useLinkProps({ to, action });

  const handlePress = async () => {
    try {
      if (eventName !== undefined) {
        await sendAnalyticsEventAsync(eventName, eventParams);
        onPress();
      } else {
        onPress()
      }
    } catch (error) {
      onPress();
    }
  };

  return (
    <PrimaryButton onPress={handlePress} {...props} {...rest}>
      {children}
    </PrimaryButton>
  );
};

export default PrimaryButtonLink;
