import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface Props {
  grow?: boolean;
  safeAreaInsets?: boolean;
  safeAreaInsetsBottom?: boolean;
  fullWidth?: boolean;
  children: any;
}

const Container: FC<Props> = ({
  children,
  grow = true,
  safeAreaInsets = true,
  safeAreaInsetsBottom = false,
}) => {
  const insets = useSafeAreaInsets();
  return (
    <View
      style={[
        styles.container,
        grow && { flex: 1 },
        {
          paddingTop: safeAreaInsets ? insets.top : 0,
          paddingBottom: safeAreaInsetsBottom ? insets.bottom : 0,
        },
      ]}
    >
      {children}
    </View>
  );
};

export default Container;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 30,
    paddingHorizontal: 30,
  },
});
