import dayjs from "dayjs";
import { FC } from "react";
import { Text, useWindowDimensions, View } from "react-native";
import { Colors } from "../../constants/colors";
import { SleepParametersPlot as SleepParametersPlotProps } from "../../types/v2/slides/slide_content";
import BarPart from "./BarPart";
import styles from "./SleepParametersPlot.styles";

interface Props extends SleepParametersPlotProps {
  label_awakenings: string;
  label_fall_asleep: string;
  label_sleep_time: string;
  label_in_bed_after_wake_up: string;
  label_sleep_efficiency: string;
}

const Norm2Color: { [key: string]: string } = {
  above: Colors.green,
  below: Colors.purple,
  within: Colors.black,
};

const SleepParametersPlot: FC<Props> = ({
  awakenings,
  awakenings_norm,
  fall_asleep,
  fall_asleep_norm,
  from_bed,
  in_bed_after_wake_up,
  in_bed_after_wake_up_norm,
  sleep_efficiency,
  sleep_efficiency_norm,
  to_bed,
  total_in_bed_time,
  total_sleep_time,
  total_sleep_time_norm,
  label_awakenings,
  label_fall_asleep,
  label_in_bed_after_wake_up,
  label_sleep_efficiency,
  label_sleep_time,
}) => {
  const { width } = useWindowDimensions();

  const calcBarHeight = (amount: number) => {
    return (amount / total_in_bed_time) * 220;
  };

  const calcTime = (amount: number) => {
    const hours = Math.floor(amount / 3600);
    const minutes = Math.floor((amount % 3600) / 60);
    return hours > 0 ? hours + "h " + minutes + "min" : minutes + "min";
  };

  return (
    <View style={{ width, marginLeft: -30 }}>
      <View style={styles.percent_container}>
        <Text
          style={[styles.percent, { color: Norm2Color[sleep_efficiency_norm] }]}
        >
          {Math.floor(sleep_efficiency * 100)}
          <Text style={{ fontSize: 24 }}>%</Text>
        </Text>
        <View style={{ flex: 1 }}>
          <Text style={styles.bold_text}>{label_sleep_efficiency}</Text>
          {Boolean(from_bed !== null && to_bed !== null) && (
            <View style={styles.frames_container}>
              <Text>
                {dayjs(to_bed).format("HH:mm")} -{" "}
                {dayjs(from_bed).format("HH:mm")}
              </Text>
              <Text style={styles.bold_text}>
                {calcTime(dayjs(from_bed).diff(dayjs(to_bed), "second"))}
              </Text>
            </View>
          )}
        </View>
      </View>
      <View style={styles.bar_container}>
        <BarPart
          height={calcBarHeight(fall_asleep)}
          color={Colors.plot_lightblue}
          legend={label_fall_asleep}
          time={calcTime(fall_asleep)}
          labelColor={Norm2Color[fall_asleep_norm]}
        />
        <BarPart
          height={calcBarHeight(total_sleep_time)}
          color={Colors.primary}
          legend={label_sleep_time}
          time={calcTime(total_sleep_time)}
          labelColor={Norm2Color[total_sleep_time_norm]}
        />
        <BarPart
          height={calcBarHeight(awakenings)}
          color={Colors.plot_azure}
          legend={label_awakenings}
          time={calcTime(awakenings)}
          labelColor={Norm2Color[awakenings_norm]}
        />
        <BarPart
          height={calcBarHeight(in_bed_after_wake_up)}
          color={Colors.purple}
          legend={label_in_bed_after_wake_up}
          time={calcTime(in_bed_after_wake_up)}
          labelColor={Norm2Color[in_bed_after_wake_up_norm]}
        />
      </View>
    </View>
  );
};

export default SleepParametersPlot;
