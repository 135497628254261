import { format, set } from "date-fns";

export function parseTimeStr(timeStr: string): Date {
  try {
    const date = new Date(timeStr);
    timeStr = format(date, "HH:mm");
  } catch (error: any) {}
  const [hours, minutes] = timeStr.split(":").map((item) => parseInt(item));
  const dt = set(new Date(), { hours, minutes });
  return dt;
}
