import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../../types/redux";
import { retrieveObject } from "../../storage";
import { ManifestState, STORE_KEY } from "../manifest";

export const restoreManifestState = createAsyncThunk<
  Partial<ManifestState>,
  undefined,
  IThunkApi
>("manifest/restore", async () => {
  const data = await retrieveObject(STORE_KEY);
  return data as Partial<ManifestState>;
});
