import { useState } from "react";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import AudioPlayer from "../../components/AudioPlayer";
import CustomIcon from "../../components/CustomIcon";
import RelaxationsCarousel from "../../components/relaxations_carousel/relaxation_carousel";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import CustomMarkdown from "../../components/utils/markdown";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { RootState } from "../../store/redux";
import * as Sentry from "../../utils/sentry"

const RelaxationScreen = ({
  navigation,
  route,
}: RootStackScreenProps<SCREEN.Relaxation>) => {
  const [isGuidePaused, setIsGuidePaused] = useState<boolean>(true);
  const [isRelaxationPaused, setIsRelaxationPaused] = useState<boolean>(true);

  const {
    params: { uuid },
  } = route;

  const { byId, byCategory } = useAppSelector((state) => state.relaxations);

  const relaxation = byId[uuid];
  const similar = byCategory[relaxation.category.name].map(
    (uuid) => byId[uuid]
  );

  const {
    relaxations_header,
    relaxations_similar,
    relaxations_instruction,
    relaxations_relaxation,
  } = useSelector((state: RootState) => state.manifest.translations);

  const onGuideIsPausedChange = (isPaused: boolean) => {
    setIsGuidePaused(isPaused);
    if (!isPaused) {
      setIsRelaxationPaused(true);
    }
  };

  const onRelaxationIsPausedChange = (isPaused: boolean) => {
    setIsRelaxationPaused(isPaused);
    if (!isPaused) {
      setIsGuidePaused(true);
    }
  };

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Image source={{ uri: relaxation.image_url }} style={styles.image} onError={(error) => {
        Sentry.captureException(error)
      }}/>
      <Container>
        <View style={styles.relaxation_bar}>
          <Text style={styles.relaxations_text}>{relaxations_header}</Text>
          <Pressable onPress={() => navigation.goBack()}>
            <CustomIcon name="close" size={26} color={Colors.primary} />
          </Pressable>
        </View>
        <Text style={styles.title}>{relaxation.name}</Text>
        <CustomMarkdown text={relaxation.description || ""} />
        {relaxation.guide_url && (
          <>
            <Text style={styles.audioType}>{relaxations_instruction}</Text>
            <AudioPlayer
              source={relaxation.guide_url}
              isPaused={isGuidePaused}
              onIsPausedChange={onGuideIsPausedChange}
            />
          </>
        )}
        {relaxation.url && (
          <>
            <Text style={styles.audioType}>{relaxations_relaxation}</Text>
            <AudioPlayer
              source={relaxation.url}
              isPaused={isRelaxationPaused}
              onIsPausedChange={onRelaxationIsPausedChange}
            />
          </>
        )}
        <Divider />
        <RelaxationsCarousel
          category={relaxations_similar}
          relaxations={similar}
        />
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  audioType: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "700",
    color: Colors.dark,
    marginVertical: 10,
  },
  image: {
    width: "100%",
    height: 260,
    resizeMode: "cover",
  },
  title: {
    fontSize: 30,
    lineHeight: 36,
    fontWeight: "700",
    color: Colors.dark,
  },
  relaxation_bar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 5,
  },
  relaxations_text: {
    fontSize: 18,
    lineHeight: 21,
    fontWeight: "700",
    color: Colors.primary,
  },
});

export default RelaxationScreen;
