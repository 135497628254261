import React, { useEffect } from "react";
import { FlatList } from "react-native";
import FlashcardsCarousel from "../../components/flashcards/FlashcardsCarousel";
import GoBack from "../../components/GoBack";
import MainHeading from "../../components/typography/main_heading";
import Divider from "../../components/utils/divider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { fetchFlashcards } from "../../store/redux/thunk_functions/fetchFlashcards";
import Container from "../../components/utils/container";

const FlashcardsScreen = ({}: RootStackScreenProps<SCREEN.Flashcards>) => {
  const { flashcards_heading } = useAppSelector(
    (state) => state.manifest.translations
  );
  const { needRefresh, byCategory, byId, loading } = useAppSelector(
    (state) => state.flashcards
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh) {
      dispatch(fetchFlashcards());
    }
  }, [needRefresh]);

  const categories = Object.keys(byCategory).sort();

  return (
    <Container>
      <FlatList
        refreshing={loading}
        style={{
          flexGrow: 1,
        }}
        ListHeaderComponent={
          <>
            <GoBack />
            <MainHeading text={flashcards_heading} size="large" />
            <Divider/>
          </>
        }
        data={categories}
        renderItem={({ item }) => (
          <FlashcardsCarousel
            key={item}
            category={item}
            flashcards={byCategory[item].map((uuid) => byId[uuid])}
          />
        )}
        ItemSeparatorComponent={Divider}
      />
    </Container>
  );
};

export default FlashcardsScreen;
