import { FC, useState } from "react";
import { Pressable, Text, View } from "react-native";
import Modal from "react-native-modal";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";
import { select_styles } from "./select.styles";

interface IProps {
  value?: string;
  setValue: (arg: string) => void;
  placeholder: string;
  error?: string | null;
  options: {
    color: string | null;
    comment: string | null;
    label: string;
    value: string;
  }[];
}

const Select: FC<IProps> = ({
  value,
  setValue,
  placeholder,
  error,
  options = [],
}) => {
  const [popupOpen, togglePopup] = useState(false);

  return (
    <View>
      <View style={[{ marginVertical: 10 }]}>
        <Pressable
          style={[select_styles.container]}
          onPress={() => togglePopup(true)}
        >
          <View style={[select_styles.label_container]}>
            <Text style={select_styles.label}> {placeholder}</Text>
          </View>
          <Text style={select_styles.input}>
            {value === undefined
              ? ""
              : options.find((el) => el.value === value)?.label}
          </Text>
          <CustomIcon name={"keyboard-arrow-down"} style={select_styles.icon} />
        </Pressable>
      </View>
      <Modal
        isVisible={popupOpen}
        style={select_styles.modal}
        onBackdropPress={() => togglePopup(false)}
      >
        <View style={{ backgroundColor: Colors.white }}>
          {options.map((o) => {
            return (
              <Pressable
                style={select_styles.modal_item}
                key={o.value}
                onPress={() => {
                  setValue(o.value);
                  togglePopup(false);
                }}
              >
                <Text>{o.label}</Text>
              </Pressable>
            );
          })}
        </View>
      </Modal>
    </View>
  );
};

export default Select;
