import { Pressable, StyleSheet, Text, View } from "react-native";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { Colors } from "../../constants/colors";
import { useLocalizedFormatter } from "../../hooks/dates";
import { useAppSelector } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";

const SubscriptionSettingsScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.SubscriptionSettings>) => {
  const {
    settings_main_header,
    settings_subscription_your_plan,
    settings_subscription_valid_to,
    settings_main_auth_subscription,
  } = useTranslations();
  const { subscription } = useAppSelector((state) => state.therapyParticipant);
  const formatter = useLocalizedFormatter();

  const endDate = subscription?.end_date
    ? formatter(subscription.end_date, "d MMMM yyyy")
    : "-";

  return (
    <Container>
      <View style={{}}>
        <Pressable
          style={{ paddingVertical: 2, marginBottom: 15 }}
          onPress={() => navigation.goBack()}
        >
          <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
        </Pressable>
      </View>

      <MainHeading text={settings_main_auth_subscription} size={"small"} />
      <View style={{ marginTop: 10 }}>
        <SubHeading text={settings_main_header} />
      </View>
      <Divider />

      <Text style={style.section}>{settings_subscription_your_plan}</Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 15,
        }}
      >
        <Text style={style.bold_text}>
          {subscription?.plan.display_name || "-"}
        </Text>
        <Text style={style.bold_text}>
          {`${settings_subscription_valid_to} ${endDate}`}
        </Text>
      </View>
    </Container>
  );
};

const style = StyleSheet.create({
  section: {
    textTransform: "uppercase",
    color: Colors.darkgray,
  },
  bold_text: {
    fontWeight: "700",
  },
});

export default SubscriptionSettingsScreen;
