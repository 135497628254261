import { FC } from "react";
import { StyleSheet, View } from "react-native";
import Animated, { FadeIn, FadeOut } from "react-native-reanimated";
import { Question } from "../../types/v2/questions";
import { SlideContent, SlideContentProps } from "../../types/v2/slides/slide_content";
import SlideContentWrapper from "./SlideContentWrapper";

interface Props {
  content: SlideContent[];
  hidden?: boolean[];
  questions?: { [itemUuid: string]: Question }
  translations: { [key: string]: string };
  readOnly?: boolean;
  onUpdate?: (uuid: string, value: any) => void;
}

const AnimatedView = Animated.createAnimatedComponent(View);

const Slide: FC<Props> = ({
  hidden,
  questions = {},
  content,
  readOnly = false,
  translations = {},
  onUpdate,
}) => {
  if (hidden === undefined) {
    hidden = new Array(content.length).fill(false);
  }

  return (
    <View style={styles.container}>
      {content.map((element, idx) => (
        <AnimatedView
          entering={FadeIn.delay(idx * 20)}
          exiting={FadeOut.delay(idx * 20)}
          key={element.uuid}
        >
          <SlideContentWrapper
            contentProps={element as SlideContentProps}
            translations={translations}
            hidden={hidden![idx]}
            question={questions[element.uuid]}
            readOnly={readOnly}
            onUpdate={onUpdate}
          />
        </AnimatedView>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
    paddingBottom: 60,
  },
});

export default Slide;
