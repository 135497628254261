import { FC } from "react";
import CarouselBlueprint from "../carousel_blueprint/carousel_blueprint";
import { IRelaxationsCarousel } from "./relaxations_carousel.types";
import RelaxationItem from "./relaxation_item/relaxation_item";

const RelaxationsCarousel: FC<IRelaxationsCarousel> = ({
  category,
  relaxations,
}) => {
  return (
    <CarouselBlueprint
      data={relaxations}
      Element={RelaxationItem}
      height={220}
      text={category}
    />
  );
};

export default RelaxationsCarousel;
