import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EAuthType } from "../../types/auth";
import { logout, restoreAuthState } from "./thunk_functions/auth";

export const STORE_KEY = "@auth";

export interface StoredAuthState {
  loginType?: EAuthType;
  token?: string;
}

export interface AuthState extends StoredAuthState {
  isLoggedIn: boolean;
  isLoaded: boolean;
}

const initialState: AuthState = {
  isLoggedIn: false,
  isLoaded: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isLoggedIn = true;
      state.isLoaded = true;
    },
    logout: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(restoreAuthState.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(logout.fulfilled, (state, action) => {
        return action.payload
      });
  },
});

export const AuthActions = authSlice.actions;

export const authReducer = authSlice.reducer;
