import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { ApiError } from "../../../types/v2/api";
import { SleepDiarySummary } from "../../../types/v2/sleepDiary";
import { getMessageKey } from "../../../utils/apiErrors";

export const getSleepDiarySummary = createAsyncThunk<
  SleepDiarySummary,
  undefined,
  IThunkApi
>("sleepDiarySummary/get", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  try {
    const { data } = await call(authorize(ENDPOINTS.session.READ_DIARY, token));
    return data;
  } catch (error: any) {
    const axiosError = error as AxiosError<ApiError>;
    const apiError = axiosError.response?.data;
    let errorKey = "unknown_error";
    if (apiError !== undefined) {
      errorKey = getMessageKey(apiError);
    }
    return thunkApi.rejectWithValue(errorKey);
  }
});
