import { createSlice } from "@reduxjs/toolkit";
import { SessionElement, SessionPreview } from "../../types/v2/therapy";
import { getSession, getTherapy } from "./thunk_functions/therapy";

interface TherapyState {
  needRefresh: boolean;
  loading: boolean;
  sessionIds: string[];
  sessionById: { [id: string]: SessionPreview };
  sessionElementById: { [id: string]: SessionElement };
  sessionElementsIds: {
    [id: string]: string[];
  };
}

const initialState: TherapyState = {
  needRefresh: true,
  loading: false,
  sessionIds: [],
  sessionById: {},
  sessionElementById: {},
  sessionElementsIds: {},
};

const therapySlice = createSlice({
  name: "therapy",
  initialState: initialState,
  reducers: {
    markAsStale(state) {
      state.needRefresh = true;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTherapy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTherapy.fulfilled, (state, action) => {
        state.loading = false;
        state.needRefresh = false;
        const therapy = action.payload;
        state.sessionIds = therapy.sessions.map((session) => session.uuid);
        state.sessionById = therapy.sessions.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.uuid]: curr,
          }),
          {}
        );
        state.sessionElementsIds = therapy.sessions.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.uuid]: [],
          }),
          {}
        );
      })
      .addCase(getSession.fulfilled, (state, action) => {
        const { elements, ...rest } = action.payload;
        state.sessionById[rest.uuid] = rest;
        state.sessionElementsIds[rest.uuid] = elements.map(
          (element) => element.uuid
        );
        const elementsByUuid = elements.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.uuid]: curr,
          }),
          {}
        );
        state.sessionElementById = {
          ...state.sessionElementById,
          ...elementsByUuid,
        };
      });
  },
});

export const TherapyActions = therapySlice.actions;
export const therapyReducer = therapySlice.reducer;
