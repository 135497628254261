import { useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  Text,
  View
} from "react-native";
import { useDispatch } from "react-redux";
import { ENDPOINTS } from "../../api/config";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { useAuthorizedCall } from "../../hooks/useAuthorizedCall";
import { useAxios } from "../../hooks/useAxios";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
// import { setTimezone } from "../../store/redux/user";

const TimezoneSettingsScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.TimezoneSettings>) => {
  const { data, loading: loadingTimezones } = useAxios<string[]>(
    ENDPOINTS.settings.GET_TIMEZONES
  );
  const { user } = useAppSelector((state) => state.therapyParticipant);
  const { settings_main_header, settings_main_auth_change_timezone } =
    useAppSelector((state) => state.manifest.translations);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const call = useAuthorizedCall();

  const [userTimezone, setUserTimezone] = useState(user?.timezone || "UTC");

  const saveUserTimezone = async (timezone: string) => {
    if (loading) return;
    setUserTimezone(timezone);
    setLoading(true);
    try {
      const { status } = await call(
        ENDPOINTS.settings.EDIT_USER({
          timezone: timezone,
        })
      );
      // dispatch(setTimezone(timezone));
    } catch (e: any) {
      console.log(e.response);
    }
    setLoading(false);
  };

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <View style={{}}>
          <Pressable
            style={{ paddingVertical: 2, marginBottom: 15 }}
            onPress={() => navigation.goBack()}
          >
            <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
          </Pressable>
        </View>

        <MainHeading text={settings_main_auth_change_timezone} size={"small"} />
        <View style={{ marginTop: 10 }}>
          <SubHeading text={settings_main_header} />
        </View>
        <Divider />
        {loadingTimezones && (
          <ActivityIndicator
            size="small"
            color={Colors.primary}
            style={{ marginVertical: 10 }}
          />
        )}
        <View>
          {data !== undefined &&
            data.map((l) => {
              return (
                <Pressable
                  onPress={() => saveUserTimezone(l)}
                  key={l}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    marginVertical: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.lightgray,
                  }}
                >
                  <CustomIcon
                    name={"community:check-bold"}
                    color={l === userTimezone ? Colors.primary : Colors.gray}
                    size={24}
                  />
                  <Text
                    style={{
                      fontWeight: "700",
                      fontSize: 14,
                      color: Colors.dark,
                      marginLeft: 20,
                    }}
                  >
                    {l}
                  </Text>
                </Pressable>
              );
            })}
        </View>
      </Container>
    </ScrollView>
  );
};

export default TimezoneSettingsScreen;
