import { FC } from "react";
import { Text, useWindowDimensions, View } from "react-native";
import plotStyles from "./SleepParametersPlot.styles";
import styles from "./BarPart.styles";

interface IProps {
  height: number;
  color: string;
  labelColor?: string;
  legend: string;
  time: string;
}

const BarPart: FC<IProps> = ({ height, color, legend, time, labelColor }) => {
  const { width } = useWindowDimensions();

  return (
    <View style={[styles.bar_segment, { height }]}>
      <View
        style={[
          styles.bar_element,
          styles.top_bar_element,
          { backgroundColor: color },
        ]}
      ></View>
      <View style={[styles.bar_values, { width: width - 60 - 10 - 20 }]}>
        <View style={styles.legend_element}>
          <View
            style={[styles.legend_circle, { backgroundColor: color }]}
          ></View>
          <Text style={{ lineHeight: 14 }}>{legend}</Text>
        </View>
        <Text
          style={[plotStyles.bold_text, { lineHeight: 14, color: labelColor }]}
        >
          {time}
        </Text>
      </View>
    </View>
  );
};

export default BarPart;
