import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";

interface Props {
  value?: string;
  valueUnit?: string;
  valueColor?: string;
  label?: string;
  labelColor?: string;
}

function TextContainer(props: Props) {
  return (
    <View style={styles.container}>
      {props.value !== undefined ? (
        <Text style={[styles.largeText, { color: props.valueColor }]}>
          {props.value}
          <Text style={styles.smallText}>{props.valueUnit}</Text>
        </Text>
      ) : null}
      {props.label !== undefined ? (
        <Text
          style={[
            styles.label,
            props.value === null && styles.labelNoValue,
            { color: props.labelColor },
          ]}
        >
          {props.label}
        </Text>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    maxWidth: 120,
  },
  largeText: {
    fontSize: 60,
    fontWeight: "600",
    lineHeight: 72,
    color: Colors.dark,
    height: 80,
  },
  smallText: {
    fontSize: 20,
  },
  label: {
    textAlign: "center",
    fontSize: 14,
    color: Colors.dark,
    opacity: 0.6,
  },
  labelNoValue: {
    height: 80,
    lineHeight: 72,
    paddingHorizontal: 10,
  },
});

export default TextContainer;
