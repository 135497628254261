import React, { FC, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Colors } from "../constants/colors";
import { UserSummaryItem as UserSummaryItemProps } from "../types/v2/userSummary";
import CustomIcon from "./CustomIcon";
import PopUp from "./PopUp";

interface Props extends UserSummaryItemProps {
  bordered: boolean;
}

const UserSummaryItem: FC<Props> = ({
  primary_text,
  secondary_texts,
  alert,
  bordered = true,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupText, setPopupText] = useState<string>();

  const handleOpen = (text: string) => {
    setPopupText(text);
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupText(undefined);
    setPopupOpen(false);
  };

  return (
    <>
      <PopUp isVisible={popupOpen} onClose={handleClose}>
        <Text>{popupText}</Text>
      </PopUp>
      <View style={[styles.container, bordered && styles.border]}>
        {primary_text !== null && (
          <Text style={styles.primaryText}>{primary_text}</Text>
        )}
        {secondary_texts !== null &&
          secondary_texts.map((secondaryTextItem, index) => (
            <View
              key={index}
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={[
                  styles.secondaryText,
                  secondaryTextItem.color !== null && {
                    color: secondaryTextItem.color,
                  },
                ]}
              >
                {secondaryTextItem.text}
              </Text>
              {secondaryTextItem.help_text != null && (
                <Pressable
                  hitSlop={30}
                  onPress={handleOpen.bind(this, secondaryTextItem.help_text)}
                >
                  {({ pressed }) => (
                    <CustomIcon
                      name="community:information-outline"
                      size={24}
                      color={pressed ? Colors.primary_light : Colors.primary}
                    />
                  )}
                </Pressable>
              )}
            </View>
          ))}
        {alert !== null && (
          <View style={styles.alert}>
            <Text style={styles.alertText}>{alert.text}</Text>
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: Colors.white, padding: 6, flex: 1 },
  border: {
    borderWidth: 1,
    borderColor: Colors.mediumgray,
    borderRadius: 8,
    elevation: 2,
  },

  primaryText: {
    fontWeight: "700",
    color: Colors.dark,
    lineHeight: 22,
    marginVertical: 3,
    fontSize: 14,
  },
  secondaryText: {
    color: Colors.dark,
    lineHeight: 22,
    marginVertical: 3,
    fontSize: 14,
    flex: 1,
  },
  alert: {
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: Colors.green_transparent,
    marginVertical: 15,
    borderRadius: 8,
  },
  alertText: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: "700",
    color: Colors.green,
  },
});

export default UserSummaryItem;
