import React, { useEffect, useRef, useState } from "react";
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import Carousel, { ICarouselInstance } from "react-native-reanimated-carousel";
import GoBack from "../../../components/GoBack";
import PaymentPlanCard, {
  IPaymentPlanCard,
} from "../../../components/payment_plan_card/payment_plan_card";
import MainHeading from "../../../components/typography/main_heading";
import SubHeading from "../../../components/typography/sub_heading";
import Container from "../../../components/utils/container";
import PrimaryButton from "../../../components/utils/primary_button";
import { Colors } from "../../../constants/colors";
import { useContainerWidth } from "../../../hooks/useContainerWidth";
import PromoCodeModal from "./PromoCodeModal";

interface CheckoutProps {
  plans: IPaymentPlanCard[];
  loading: boolean;
  onCheckout: (
    planUuid: string,
    promoCode?: string,
    paymentLink?: string
  ) => void;
  onSetPromoCode?: (code: string) => Promise<number | undefined>;
  promoCode?: {
    placeholder: string;
    text: string;
    buttonText: string;
    addText: string;
  };
  header?: string;
  subheader?: string;
  makePaymentText?: string;
  planUnavailableText?: string;
}

function CheckoutLayout(props: CheckoutProps) {
  const startIdx = Math.max(
    props.plans.findIndex((plan) => plan.recommended),
    0
  );

  const ref = useRef<ICarouselInstance | null>(null);
  const width = useContainerWidth();
  const [promoCodeModalOpen, setPromoCodeModalOpen] = useState<boolean>(false);
  const [promoCodeError, setPromoCodeError] = useState<string>();
  const [index, setIndex] = useState<number>(startIdx);

  const handleAddPromoCodeButtonPress = () => {
    setPromoCodeModalOpen(true);
  };

  const handleAddPromo = async (code: string) => {
    try {
      if (props.onSetPromoCode) {
        const maybeIndex = await props.onSetPromoCode(code);
        if (maybeIndex != undefined && ref.current) {
          ref.current.scrollTo({ index: maybeIndex, animated: true });
        }
      }
      setPromoCodeModalOpen(false);
      setPromoCodeError(undefined);
    } catch (error: any) {
      setPromoCodeError(error.message);
      setPromoCodeModalOpen(false);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ index: startIdx, animated: false });
    }
  }, []);

  return (
    <>
      {props.promoCode != undefined && (
        <PromoCodeModal
          isVisible={promoCodeModalOpen}
          onClose={() => setPromoCodeModalOpen(false)}
          onSave={(code: string) => handleAddPromo(code)}
          placeholder={props.promoCode.placeholder}
          text={props.promoCode.text}
          buttonText={props.promoCode.buttonText}
        />
      )}

      <ScrollView contentContainerStyle={{ flexGrow: 1, position: "relative" }}>
        <Container grow safeAreaInsetsBottom={false}>
          <GoBack />
          {props.header != undefined && (
            <MainHeading text={props.header} size={"large"} />
          )}
          {props.subheader != undefined && (
            <View style={{ marginTop: 10 }}>
              <SubHeading text={props.subheader} />
            </View>
          )}
          <View
            style={{
              marginTop: 10,
              flex: 1,
              alignItems: "center",
            }}
          >
            <Carousel
              vertical={false}
              ref={(c) => (ref.current = c)}
              data={props.plans}
              width={width}
              windowSize={width}
              height={460}
              onSnapToItem={(index) => setIndex(index)}
              loop={false}
              renderItem={({ item, index }) => (
                <PaymentPlanCard key={`payment-${index}`} {...item} />
              )}
              panGestureHandlerProps={{
                activeOffsetX: [-10, 10],
              }}
              mode="parallax"
              modeConfig={{
                parallaxScrollingOffset: 200,
              }}
            />
          </View>
          <View>
            {promoCodeError !== undefined && (
              <Text
                style={{
                  paddingVertical: 10,
                  color: "red",
                  textAlign: "center",
                }}
              >
                {promoCodeError}
              </Text>
            )}
          </View>
          {props.plans.length > 0 && (
            <View style={styles.buttonContainer}>
              {props.plans.length > 0 &&
                !props.loading &&
                props.promoCode != undefined && (
                  <>
                    <Pressable onPress={handleAddPromoCodeButtonPress}>
                      <Text style={styles.addPromoCode}>
                        {props.promoCode.addText}
                      </Text>
                    </Pressable>
                  </>
                )}
              <PrimaryButton
                label={
                  props.plans[index].buyable
                    ? props.makePaymentText
                    : props.planUnavailableText
                }
                onPress={() =>
                  props.onCheckout(
                    props.plans[index].uuid,
                    undefined,
                    props.plans[index].payment_link!
                  )
                }
                disabled={props.loading || !props.plans[index].buyable}
                loading={props.loading}
              />
            </View>
          )}
        </Container>
      </ScrollView>
    </>
  );
}

export default CheckoutLayout;

const styles = StyleSheet.create({
  buttonContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  addPromoCode: {
    color: Colors.primary,
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 22,
    textAlign: "center",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
});
