import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Notifications from "expo-notifications";
import * as SplashScreen from "expo-splash-screen";
import { FC, useEffect } from "react";
import { Linking, Text, View } from "react-native";
import CustomIcon from "../components/CustomIcon";
import PopUp from "../components/PopUp";
import { useNetworkConnection } from "../hooks/connection";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import EmailPasswordLoginScreen from "../screens/auth/EmailPasswordLoginScreen";
import EmailPasswordSignUpScreen from "../screens/auth/EmailPasswordSignUpScreen";
import EmailValidationScreen from "../screens/auth/EmailValidationScreen";
import ForgotPasswordScreen from "../screens/auth/ForgotPasswordScreen";
import LoginScreen from "../screens/auth/LoginScreen";
import ResetPasswordScreen from "../screens/auth/ResetPasswordScreen";
import SignUpScreen from "../screens/auth/SignUpScreen";
import ContactAndHelpScreen from "../screens/help/ContactAndHelpScreen";
import FAQScreen from "../screens/help/FAQScreen";
import IssueReportScreen from "../screens/help/IssueReportScreen";
import OnboardingScreen from "../screens/OnboardingScreen";
import PaymentScreen from "../screens/PaymentsScreen";
import ProfileScreen from "../screens/ProfileScreen";
import LanguageSettingsScreen from "../screens/settings/LanguageSettingsScreen";
import NotificationSettingsScreen from "../screens/settings/NotificationsSettingsScreen";
import PasswordChangeScreen from "../screens/settings/PasswordChangeScreen";
import SettingsScreen from "../screens/settings/SettingsScreen";
import SubscriptionSettingsScreen from "../screens/settings/SubscriptionSettingsScreen";
import TimezoneSettingsScreen from "../screens/settings/TimezoneSettingsScreen";
import SurveyScreen from "../screens/SurveyScreen";
import SessionScreen from "../screens/therapy/SessionScreen";
import ArticleScreen from "../screens/tools/ArticleScreen";
import FlashcardsScreen from "../screens/tools/FlashcardsScreen";
import LibraryScreen from "../screens/tools/LibraryScreen";
import NoteScreen from "../screens/tools/NoteScreen";
import NotesScreen from "../screens/tools/NotesScreen";
import RelaxationScreen from "../screens/tools/RelaxationScreen";
import RelaxationsScreen from "../screens/tools/RelaxationsScreen";
import SleepReportScreen from "../screens/tools/SleepReportScreen";
import ThoughtsScreen from "../screens/tools/ThougtsScreen";
import { restoreAppState } from "../store/redux/thunk_functions/restoreAppState";
import DrawerNavigation from "./DrawerNavigation";
import { SCREEN } from "./screens";
import { RootStackParamList } from "./StackNavigation.types";
import Splash from "../screens/SplashScreen";
import ThankYouScreen from "../screens/ThankYouScreen";

const Stack = createNativeStackNavigator<RootStackParamList>();

SplashScreen.preventAutoHideAsync();

const StackNavigation: FC = () => {
  const {
    auth: { isLoggedIn, isLoaded },
    therapyParticipant: { user, subscription },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const connected = useNetworkConnection();

  const hasSubscription = subscription != null;
  const isVerified = user != undefined && user.is_verified;

  const init = async () => {
    await dispatch(restoreAppState());
  };

  useEffect(() => {
    init();
  }, []);

  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  useEffect(() => {
    if (
      isLoggedIn &&
      lastNotificationResponse &&
      lastNotificationResponse.notification.request.content.data.url &&
      lastNotificationResponse.actionIdentifier ===
        Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      Linking.openURL(
        lastNotificationResponse.notification.request.content.data.url as any
      );
    }
  }, [lastNotificationResponse, isLoggedIn]);

  if (!isLoaded) {
    return <Splash />;
  }

  return (
    <>
      <PopUp isVisible={!connected && isLoaded}>
        <View
          style={{
            alignItems: "center",
          }}
        >
          <CustomIcon size={40} name="wifi-off" />
          <Text style={{ marginTop: 20 }}>No network connection</Text>
        </View>
      </PopUp>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          animation: "slide_from_left",
        }}
      >
        {isLoggedIn ? (
          isVerified ? (
            <Stack.Group>
              <Stack.Screen
                name={SCREEN.DrawerNavigation}
                component={DrawerNavigation}
              />

              <Stack.Screen name={SCREEN.Session} component={SessionScreen} />

              <Stack.Screen
                name={SCREEN.Relaxations}
                component={RelaxationsScreen}
              />
              <Stack.Screen
                name={SCREEN.Relaxation}
                component={RelaxationScreen}
              />
              <Stack.Screen name={SCREEN.Library} component={LibraryScreen} />
              <Stack.Screen name={SCREEN.Article} component={ArticleScreen} />
              <Stack.Screen name={SCREEN.Notes} component={NotesScreen} />
              <Stack.Screen name={SCREEN.Note} component={NoteScreen} />
              <Stack.Screen name={SCREEN.Thoughts} component={ThoughtsScreen} />
              <Stack.Screen
                name={SCREEN.Flashcards}
                component={FlashcardsScreen}
              />
              <Stack.Screen
                name={SCREEN.SleepReport}
                component={SleepReportScreen}
              />
              <Stack.Screen
                name={SCREEN.Habits}
                // @ts-ignore
                component={SurveyScreen}
                initialParams={{
                  type: "habits",
                }}
              />
              <Stack.Screen
                name={SCREEN.SleepDiary}
                // @ts-ignore
                component={SurveyScreen}
                initialParams={{
                  type: "sleep_diary",
                }}
              />
              <Stack.Screen
                name={SCREEN.Thought}
                // @ts-ignore
                component={SurveyScreen}
                initialParams={{
                  type: "thought_diary",
                }}
              />

              <Stack.Screen name={SCREEN.Profile} component={ProfileScreen} />
              <Stack.Screen name={SCREEN.Settings} component={SettingsScreen} />
              <Stack.Screen
                name={SCREEN.NotificationsSettings}
                component={NotificationSettingsScreen}
              />
              <Stack.Screen
                name={SCREEN.PasswordChange}
                component={PasswordChangeScreen}
              />
              <Stack.Screen
                name={SCREEN.LanguageSettings}
                component={LanguageSettingsScreen}
              />
              <Stack.Screen
                name={SCREEN.TimezoneSettings}
                component={TimezoneSettingsScreen}
              />
              <Stack.Screen
                name={SCREEN.SubscriptionSettings}
                component={SubscriptionSettingsScreen}
              />

              <Stack.Screen
                name={SCREEN.ContactAndHelp}
                component={ContactAndHelpScreen}
              />
              <Stack.Screen name={SCREEN.FAQ} component={FAQScreen} />
              <Stack.Screen
                name={SCREEN.IssueReport}
                component={IssueReportScreen}
              />
              {!hasSubscription && (
                <Stack.Screen
                  name={SCREEN.Payments}
                  component={PaymentScreen}
                />
              )}
            </Stack.Group>
          ) : (
            <Stack.Group>
              <Stack.Screen
                name={SCREEN.EmailValidation}
                component={EmailValidationScreen}
              />
            </Stack.Group>
          )
        ) : (
          <Stack.Group>
            <Stack.Screen
              name={SCREEN.Onboarding}
              component={OnboardingScreen}
            />
            <Stack.Screen name={SCREEN.Login} component={LoginScreen} />
            <Stack.Screen
              name={SCREEN.EmailPasswordLogin}
              component={EmailPasswordLoginScreen}
            />
            <Stack.Screen
              name={SCREEN.ForgotPassword}
              component={ForgotPasswordScreen}
            />
            <Stack.Screen
              name={SCREEN.ResetPassword}
              component={ResetPasswordScreen}
            />
            <Stack.Screen name={SCREEN.SignUp} component={SignUpScreen} />
            <Stack.Screen
              name={SCREEN.EmailPasswordSingUp}
              component={EmailPasswordSignUpScreen}
            />
          </Stack.Group>
        )}
        <Stack.Group navigationKey={isLoggedIn ? "user" : "guest"}>
          <Stack.Screen name={SCREEN.Survey} component={SurveyScreen} />
          <Stack.Screen
            name={SCREEN.IntroSurvey}
            // @ts-ignore
            component={SurveyScreen}
            initialParams={{
              type: "intro_survey",
            }}
          />
          <Stack.Screen name={SCREEN.ThankYou} component={ThankYouScreen} />
          <Stack.Screen
            name={SCREEN.InsomniaChecker}
            // @ts-ignore
            component={SurveyScreen}
            initialParams={{
              type: "insomnia_checker",
            }}
          />
        </Stack.Group>
      </Stack.Navigator>
    </>
  );
};

export default StackNavigation;
