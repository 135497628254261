import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { SurveyWithActions } from "../../../types/v2/survey";

interface Args {
  uuid?: string;
}

export const getOrCreateThought = createAsyncThunk<
  SurveyWithActions,
  Args,
  IThunkApi
>("survey/getOrCreateThought", async ({ uuid }, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  if (uuid !== undefined) {
    const { data } = await call(
      authorize(ENDPOINTS.tools.GET_THOUGHT(uuid), token)
    );
    return data;
  }
  const { data } = await call(
    authorize(ENDPOINTS.tools.CREATE_THOUGHT(), token)
  );
  return data;
});
