import React, { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { Tooltip as TooltipProps } from "../../types/v2/slides/slide_content";
import CustomIcon from "../CustomIcon";

const Tooltip: FC<TooltipProps> = ({ color, icon, text, title }) => {
  return (
    <View style={[styles.container, { backgroundColor: color + "19" }]}>
      <View style={styles.title_container}>
        <CustomIcon name={icon} color={color} size={34} />
        <Text style={[styles.title, { color }]}>{title}</Text>
      </View>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 8,
    marginVertical: 30,
  },
  title_container: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    fontWeight: "700",
    lineHeight: 22,
    fontSize: 14,
    marginLeft: 17,
  },
  text: {
    color: Colors.dark,
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "700",
  },
});

export default Tooltip;
