export const therapyFolderRoutes = {
  PROFILE: {
    url: "/api/v1/cbti/therapy-folder/profile",
    method: "GET",
  },
  INSOMNIA_MAP: {
    url: "/api/v1/cbti/therapy-folder/insomnia-map",
    method: "GET",
  },
  BELIEFS: {
    url: "/api/v1/cbti/therapy-folder/your-beliefs",
    method: "GET",
  },
};
