import { FC, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { UserSummary as UserSummaryProps } from "../../types/v2/slides/slide_content";
import CarouselBlueprint from "../carousel_blueprint/carousel_blueprint";
import CustomIcon from "../CustomIcon";
import PopUp from "../PopUp";
import UserSummaryItem from "../UserSummaryItem";

const UserSummary: FC<UserSummaryProps> = ({
  title,
  icon,
  items,
  horizontal,
  help_text,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleClose = () => {
    setPopupOpen(false);
  };

  const handleOpen = () => {
    setPopupOpen(true);
  };

  return (
    <>
      <PopUp isVisible={popupOpen} onClose={handleClose}>
        <Text style={styles.popupText}>{help_text}</Text>
      </PopUp>
      <View style={styles.container}>
        <View style={styles.icon_container}>
          {icon !== null && <CustomIcon name={icon} size={24} />}
        </View>
        <View style={{ flex: 1 }}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
            {help_text != null && (
              <Pressable hitSlop={30} onPress={handleOpen}>
                {({ pressed }) => (
                  <CustomIcon
                    name="community:information-outline"
                    size={24}
                    color={pressed ? Colors.primary_light : Colors.primary}
                  />
                )}
              </Pressable>
            )}
          </View>
          {!horizontal && (
            <View>
              {items.map((item, idx) => {
                return (
                  <View
                    style={{ marginBottom: 15 }}
                    key={`${idx}=${item.primary_text}`}
                  >
                    <UserSummaryItem {...item} bordered={false}/>
                  </View>
                );
              })}
            </View>
          )}
          {horizontal && (
              <CarouselBlueprint
                height={250}
                data={items}
                text={""}
                Element={UserSummaryItem}
              />
          )}
        </View>
      </View>
    </>
  );
};
export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginVertical: 15,
  },

  icon_container: {
    marginRight: 20,
  },
  titleContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  title: {
    fontSize: 18,
    lineHeight: 21,
    fontWeight: "700",
    color: Colors.dark,
  },
  popupText: {
    fontSize: 14,
    lineHeight: 22,
    marginTop: 15,
  },
});

export default UserSummary;
