export const surveyRoutes = {
  GET_SURVEY: (uuid: string, action?: string) => {
    let url = `/api/v1/surveys/${uuid}`;
    if (action !== undefined) {
      url += `?action=${encodeURIComponent(action)}`;
    }
    return {
      url,
      method: "GET",
    };
  },
  INTRO_SURVEY: {
    url: "/api/v1/cbti/intro-survey",
    method: "GET",
  },
  INTRO_SURVEY_SUMMARY: {
    url: "/api/v1/cbti/intro-survey-summary",
    method: "GET",
  },
  INSOMNIA_CHECKER: {
    url: "/api/v1/cbti/insomnia-checker",
    method: "GET",
  },
  NEXT_SLIDE: (id: string) => {
    return {
      url: `/api/v1/surveys/${id}?action=next_slide`,
      method: "GET",
    };
  },
  PREV_SLIDE: (id: string) => {
    return {
      url: `/api/v1/surveys/${id}`,
      method: "PUT",
      data: {
        action: "prev_slide",
      },
    };
  },
  SET_ANSWERS: (id: string, answers: any, slide_uuid?: string) => {
    return {
      url: `/api/v1/surveys/${id}/answers`,
      method: "PUT",
      data: {
        answers,
        slide_uuid,
      },
    };
  },
  SECONDARY_ACTION: (id: string, action: string) => ({
    url: `/api/v1/surveys/${id}`,
    method: "PUT",
    data: {
      action,
    },
  }),
};
