import { StyleSheet } from "react-native";
import { Colors } from "../../constants/colors";

export const thought_styles = StyleSheet.create({
  container: {
    padding: 20,
    width: "100%",
    backgroundColor: Colors.purple_transparent,
    borderRadius: 8,
    marginVertical: 10,
  },
  title: {
    fontSize: 14,
    lineHeight: 22,
    textTransform: "uppercase",
    fontWeight: "700",
    color: Colors.purple,
  },
  detail: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "400",
    color: Colors.dark,
  },
  text: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "300",
    color: Colors.dark,
    marginTop: 20,
    marginBottom: 15,
  },
  action: {
    color: Colors.primary,
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "400",
  },
  arrow: {
    color: Colors.purple,
  },
});
