import { FC } from "react";
import { Text, View } from "react-native";
import { SummaryList as SummaryListProps } from "../../types/v2/slides/slide_content";

interface Props extends SummaryListProps {
  translations: { [key: string]: string };
}

const SummaryList: FC<Props> = ({ translations, items }) => {
  return (
    <View style={{ marginVertical: 15 }}>
      {items.map((item, idx) => {
        return (
          <View
            key={idx}
            style={{
              flexDirection: "row",
              marginBottom: 7,
              alignItems: "center",
            }}
          >
            <Text style={{ flex: 1 }}>{translations[item.header]}</Text>
            <Text
              style={[
                { fontWeight: "700" },
                item.color !== null && { color: item.color },
              ]}
            >
              {item.value}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export default SummaryList;
