import { Image, StyleSheet, Text, View } from "react-native";
import { FC } from "react";
import { useNavigation } from "@react-navigation/native";
import { UserInfo as UserInfoProps } from "../../types/v2/slides/slide_content";
import { Colors } from "../../constants/colors";

interface Props extends UserInfoProps {
  label_edit_profile: string;
}

const UserInfo: FC<Props> = ({
  age,
  avatar_url,
  email,
  gender,
  name,
  label_edit_profile,
}) => {
  const navigation = useNavigation<any>();

  return (
    <View style={styles.container}>
      <View style={styles.avatar_container}>
        <Image
          source={{ uri: avatar_url }}
          style={styles.avatar}
          resizeMode={"cover"}
        />
      </View>
      <View>
        <Text style={styles.name}>{name}</Text>
        <View style={{ marginVertical: 5 }}>
          <Text style={styles.data}>{email}</Text>
          <Text style={styles.data}>
            {age}, {gender}
          </Text>
        </View>
        <Text
          style={styles.edit}
          onPress={() => navigation.navigate("Profile")}
        >
          {label_edit_profile}
        </Text>
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  avatar_container: {
    width: 60,
    marginRight: 20,
    justifyContent: "center",
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: Colors.lightgray,
  },
  name: {
    fontSize: 18,
    lineHeight: 21,
    fontWeight: "700",
    color: Colors.dark,
  },
  data: {
    fontSize: 14,
    lineHeight: 20,
    color: Colors.dark,
    opacity: 0.6,
  },
  edit: {
    fontSize: 14,
    lineHeight: 20,
    color: Colors.primary,
  },
});

export default UserInfo;
