import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Manifest } from "../../types/v2/manifest";
import { fetchManifest } from "./thunk_functions/fetchManifest";
import { restoreManifestState } from "./thunk_functions/restoreManifestState";

export const STORE_KEY = "@manifest";

export interface ManifestState {
  language: string | null;
  translations: Manifest;
}

const initialState: ManifestState = {
  language: null,
  translations: {},
};

const manifestSlice = createSlice({
  name: "manifest",
  initialState,
  reducers: {
    setManifest: (state, action: PayloadAction<any>) => {
      state = { ...action.payload };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchManifest.fulfilled, (state, action) => {
        return { ...action.payload };
      })
      .addCase(restoreManifestState.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      });
  },
});

export const { setManifest } = manifestSlice.actions;

export default manifestSlice.reducer;
