import { FC } from "react";
import { View } from "react-native";
import { Text as TextProps } from "../../types/v2/slides/slide_content";
import CustomMarkdown from "../utils/markdown";

const Text: FC<TextProps> = ({ text }) => (
  <View style={{ maxWidth: 800 }}>
    <CustomMarkdown text={text} />
  </View>
);

export default Text;
