import AsyncStorage from "@react-native-async-storage/async-storage";

export const storeData = async (key: string, value: string) => {
  try {
    await AsyncStorage.setItem(key, value);
    return true;
  } catch (error) {
    return false;
  }
};

export const retrieveData = async (key: string): Promise<string | null> => {
  try {
    const value = await AsyncStorage.getItem(key);
    return value;
  } catch (error) {
    return null;
  }
};

export const retrieveObject = async (key: string): Promise<object> => {
  const rawData = await retrieveData(key);
  if (rawData === null) {
    return {};
  }
  try {
    return JSON.parse(rawData);
  } catch (error) {
    return {};
  }
};

export const storeObject = async (key: string, value: object) => {
  try {
    const rawData = JSON.stringify(value);
    return await storeData(key, rawData);
  } catch (error) {
    return false;
  }
};

export const removeData = async (key: string) => {
  try {
    await AsyncStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
};
