import React, { useState } from "react";
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  View,
} from "react-native";
import Animated from "react-native-reanimated";
import { Colors } from "../../constants/colors";

const MARKER_RADIUS = 12;

interface Props {
  minValue: number;
  maxValue: number;
  value: number;
  disabled?: boolean;
  onChangeFinish?: (value: number) => void;
}

function Slider(props: Props) {
  const [width, setWidth] = useState(0);

  const handlePress = (event: GestureResponderEvent) => {
    const time = (event.nativeEvent.locationX / width) * props.maxValue;
    if (props.onChangeFinish && !props.disabled) {
      props.onChangeFinish(time);
    }
  };

  const position =
    props.maxValue > 0
      ? (props.value / props.maxValue) * width - MARKER_RADIUS
      : -MARKER_RADIUS;

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        height: 48
      }}
      onLayout={(event) => {
        setWidth(event.nativeEvent.layout.width);
      }}
    >
      <Pressable hitSlop={30} onPress={handlePress}>
        <View style={[styles.track, { flex: 1 }]} />
        <Animated.View
          style={[
            styles.marker,
            props.disabled && styles.disabled,
            {
              transform: [
                {
                  translateX: position,
                },
              ],
            },
          ]}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  track: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: 4,
    backgroundColor: Colors.mediumgray,
    zIndex: 10,
  },
  marker: {
    zIndex: 30,
    borderWidth: 3,
    position: "absolute",
    left: 0,
    top: -MARKER_RADIUS,
    width: 2 * MARKER_RADIUS,
    height: 2 * MARKER_RADIUS,
    borderRadius: MARKER_RADIUS,
    backgroundColor: Colors.primary,
    alignSelf: "center",
    borderColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  disabled: {
    backgroundColor: Colors.gray,
  },
});

export default Slider;
