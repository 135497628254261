import { StyleSheet, useWindowDimensions, View } from "react-native";
import { Colors } from "../constants/colors";
import StackNavigation from "../navigation/StackNavigation";

function AppContainer() {
  const { width } = useWindowDimensions();

  if (width < 1268) {
    return <StackNavigation />;
  }

  return (
    <View style={styles.outer}>
      <View style={styles.margin} />
      <View style={[styles.inner, width > 800 && { minWidth: 800 }]}>
        <StackNavigation />
      </View>
      <View style={styles.margin} />
    </View>
  );
}

const styles = StyleSheet.create({
  outer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  inner: {
    flex: 1,
    maxWidth: 1000,
  },
  margin: {
    backgroundColor: Colors.lightgray,
    flex: 1,
    zIndex: 30,
  },
});

export default AppContainer;
