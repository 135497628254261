import { differenceInSeconds, format, sub } from "date-fns";
import React, { FC, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { SleepRestrictions } from "../../types/v2/questions";
import { parseTimeStr } from "../../utils/dates";
import TimePicker from "./TimePicker";

interface Props {
  value: SleepRestrictions;
  onChange: (value: SleepRestrictions) => void;
  readOnly: boolean;
  recommendedInBedTime?: number;
  acceptableDeviation?: number;
  labelFromBedContent: string;
  labelFromBedSubcontent: string;
  labelToBedSubcontent: string;
  labelToBedContent: string;
  labelTooLargeDeviation: string;
}

const SleepRestrictionsPicker: FC<Props> = ({
  value,
  readOnly,
  recommendedInBedTime = 3600 * 8,
  acceptableDeviation = 1800,
  labelFromBedContent,
  labelFromBedSubcontent,
  labelToBedContent,
  labelToBedSubcontent,
  labelTooLargeDeviation,
  onChange,
}) => {
  const [tooBroad, setTooBroad] = useState<boolean>(false);

  const changeToBedTime = (timeStr: string) => {
    let fromBedDate = parseTimeStr(value.from_bed!);
    let toBedDate = parseTimeStr(timeStr);
    if (timeStr > value.from_bed!) {
      toBedDate = sub(toBedDate, { days: 1 });
      timeStr = format(toBedDate, "HH:mm");
    }
    const diff = differenceInSeconds(fromBedDate, toBedDate);
    if (Math.abs(diff - recommendedInBedTime) > acceptableDeviation) {
      setTooBroad(true);
    } else {
      setTooBroad(false);
    }

    onChange({ from_bed: value.from_bed, to_bed: timeStr });
  };

  const changeFromBedTime = (timeStr: string) => {
    const fromBedDate = parseTimeStr(timeStr);
    const toBedDate = sub(fromBedDate, { seconds: recommendedInBedTime });
    const toBedTimeStr = format(toBedDate, "HH:mm");

    setTooBroad(false);
    onChange({ from_bed: timeStr, to_bed: toBedTimeStr });
  };

  return (
    <View>
      <View>
        <Text style={{ fontWeight: "700", marginBottom: 10 }}>
          {labelFromBedContent}
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text>{labelFromBedSubcontent}</Text>
          <TimePicker
            onChange={changeFromBedTime}
            value={value.from_bed}
            readOnly={readOnly}
            defaultValue="06:00"
          />
        </View>
      </View>
      {value.from_bed !== null && (
        <View style={{ marginTop: 10 }}>
          <Text style={{ fontWeight: "700", marginBottom: 10 }}>
            {labelToBedContent}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text>{labelToBedSubcontent}</Text>
            <TimePicker
              onChange={changeToBedTime}
              value={value.to_bed}
              readOnly={readOnly}
            />
          </View>
        </View>
      )}
      {tooBroad && (
        <View style={styles.tooBroadTooltip}>
          <Text>{labelTooLargeDeviation}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  tooBroadTooltip: {
    backgroundColor: Colors.primary_light,
    paddingHorizontal: 12,
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 10,
  },
});

export default SleepRestrictionsPicker;
