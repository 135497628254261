import { useNavigation } from "@react-navigation/native";
import { FC } from "react";
import { Pressable, View } from "react-native";
import { Colors } from "../constants/colors";
import CustomIcon from "./CustomIcon";

interface Props {
  onPress?: () => void;
}

const GoBack: FC<Props> = ({ onPress }) => {
  const navigation = useNavigation<any>();

  const handlePress = () => {
    if (onPress !== undefined) {
      onPress();
    } else {
      navigation.goBack();
    }
  };
  return (
    <Pressable
      style={({ pressed }) => ({
        paddingVertical: 2,
        marginBottom: 15,
        opacity: pressed ? 0.5 : 1,
      })}
      onPress={handlePress}
      hitSlop={20}
    >
      <CustomIcon name="chevron-left" color={Colors.primary} size={38} />
    </Pressable>
  );
};

export default GoBack;
