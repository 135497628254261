import { Platform } from "react-native";
import Constants from "expo-constants";
import * as Sentry from "sentry-expo";

export const routingInstrumentation =
  Platform.OS !== "web"
    ? new Sentry.Native.ReactNavigationInstrumentation()
    : undefined;

export function captureException(error: any) {
  if (Constants.debugMode) {
    console.error(error);
  } else if (Platform.OS === "web") {
    Sentry.Browser.captureException(error);
  } else {
    Sentry.Native.captureException(error);
  }
}

export function captureMessage(message: string) {
  if (Platform.OS === "web") {
    Sentry.Browser.captureMessage(message);
  } else {
    Sentry.Native.captureMessage(message);
  }
}

export function initialize() {
  Sentry.init({
    enabled: Constants.expoConfig?.extra?.sentry?.enabled ?? false,
    dsn: Constants.expoConfig?.extra?.sentry?.dsn,
    tracesSampleRate:
      Constants.expoConfig?.extra?.sentry?.environment !== "production"
        ? 1.0
        : 0.1,
    environment:
      Constants.expoConfig?.extra?.sentry?.environment || "development",
    integrations:
      Platform.OS !== "web"
        ? [
            new Sentry.Native.ReactNativeTracing({
              routingInstrumentation,
            }),
          ]
        : [],
  });
}

export function setUser(user: Record<string, any>) {
  if (Platform.OS === "web") {
    Sentry.Browser.setUser(user);
  } else {
    Sentry.Native.setUser(user);
  }
}

export function wrap(app: any) {
  if (Platform.OS === "web") {
    return app;
    return Sentry.Browser.wrap(app);
  } else {
    return Sentry.Native.wrap(app);
  }
}
