import React, { FC, ReactNode, useState } from "react";
import { useWindowDimensions, View } from "react-native";
import {
  SlideContent,
  Tabs as TabsProps,
} from "../../types/v2/slides/slide_content";
import CustomTopTabs from "../custom_top_tabs/custom_top_tabs";

interface Props extends TabsProps {
  renderer: (content: SlideContent) => ReactNode;
}

const Tabs: FC<Props> = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { width } = useWindowDimensions();

  if (props.items.length === 0) {
    return null;
  }

  const { content } = props.items[activeTab];

  return (
    <View>
      <View style={{ width, marginLeft: -30 }}>
        <CustomTopTabs
          activeTab={activeTab.toString()}
          setActive={(tab) => setActiveTab(parseInt(tab))}
          tabs={props.items.map((item, idx) => ({
            value: idx.toString(),
            label: item.name,
          }))}
        />
      </View>
      {content.map((item, idx) => (
        <React.Fragment key={`${item.type}-${idx}`}>
          {props.renderer(item)}
        </React.Fragment>
      ))}
    </View>
  );
};

export default Tabs;
