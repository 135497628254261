import { Image, Pressable, StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";
import { FC } from "react";
import { Avatar as AvatarType } from "../../types/v2/avatar";

interface Props {
  avatar: AvatarType | null;
  selected?: boolean;
  onPress?: () => void;
}

const Avatar: FC<Props> = ({ avatar, selected, onPress }) => {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.container, selected && { borderColor: Colors.primary }]}
    >
      <Image source={{ uri: avatar?.url }} style={{ width: 60, height: 60 }} />
    </Pressable>
  );
};

export default Avatar;

const styles = StyleSheet.create({
  container: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 10,
    marginVertical: 10,
    overflow: "hidden",
    borderWidth: 3,
    borderColor: "transparent",
  },
});
