import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { DrawerActions } from "@react-navigation/native";
import { FC, useState } from "react";
import { Platform, View } from "react-native";
import ContinueSession from "../components/ContinueSession";
import CustomIcon from "../components/CustomIcon";
import FloatingMenu from "../components/FloatingMenu";
import SubscriptionOffer from "../components/SubscriptionOffer";
import { Colors } from "../constants/colors";
import { useAppSelector } from "../hooks/redux";
import { useTranslations } from "../hooks/useTranslations";
import DashboardScreen from "../screens/DashboardScreen";
import SleepDiarySummaryScreen from "../screens/SleepDiarySummaryScreen";
import { BottomTabParamList } from "./BottomTabNavigation.types";
import { RootDrawerScreenProps } from "./DrawerNavigation.types";
import { PatientCardTabNavigation } from "./PatientCardTabNavigation";
import { SCREEN } from "./screens";
import TherapyTabNavigation from "./TherapyTabNavigation";

const BottomTabs = createBottomTabNavigator<BottomTabParamList>();

const Dummy = () => null;

const BottomTabNavigation: FC<
  RootDrawerScreenProps<SCREEN.BottomTabNavigation>
> = ({ navigation }) => {
  const [initialRoute, setInitialRoute] = useState<string | undefined>(
    undefined
  );
  const {
    menu_bottom_home,
    menu_bottom_therapy,
    menu_bottom_sleep_diary,
    menu_bottom_therapy_folder,
    menu_bottom_more,
    payments_buy_subscription_cta,
    sessions_proceed_cta,
  } = useTranslations();
  const {
    therapyParticipant: { subscription, therapy_status },
    ui: { floatingMenuButtonVisible, floatingMenuOpen },
  } = useAppSelector((state) => state);

  const isSessionInProgress =
    therapy_status !== undefined &&
    !therapy_status.current_session.all_elements_completed;

  const handleSubscriptionOfferOnPress = () => {
    // @ts-ignore
    navigation.navigate(SCREEN.Payments);
  };

  const handleContinueSessionOnPress = () => {
    const uuid = therapy_status?.current_session.uuid;
    if (uuid !== undefined) {
      // @ts-ignore
      navigation.navigate(SCREEN.Session, { uuid });
    }
  };

  return (
    <View
      style={{
        height: "100%",
        flex: 1,
        overflow: Platform.OS === "web" ? "hidden" : undefined,
      }}
    >
      <BottomTabs.Navigator
        screenOptions={{
          tabBarActiveTintColor: Colors.primary,
          headerShown: false,
          tabBarStyle: {
            paddingBottom: 10,
            height: 60,
          },
        }}
        initialRouteName={initialRoute as any}
      >
        <BottomTabs.Screen
          name={SCREEN.Dashboard}
          component={DashboardScreen}
          options={{
            title: menu_bottom_home,
            tabBarIcon: ({ color, size }) => (
              <CustomIcon size={size} name="house" color={color} />
            ),
          }}
        />
        <BottomTabs.Screen
          name={SCREEN.Therapy}
          component={TherapyTabNavigation}
          options={{
            title: menu_bottom_therapy,
            tabBarIcon: ({ color, size }) => (
              <CustomIcon
                size={size}
                name="community:format-list-checks"
                color={color}
              />
            ),
          }}
        />
        <BottomTabs.Screen
          name={SCREEN.SleepDiarySummary}
          component={SleepDiarySummaryScreen}
          options={{
            title: menu_bottom_sleep_diary,
            tabBarIcon: ({ color, size }) => (
              <CustomIcon size={size} name="king-bed" color={color} />
            ),
          }}
        />
        <BottomTabs.Screen
          name={SCREEN.PatientCard}
          component={PatientCardTabNavigation}
          options={{
            title: menu_bottom_therapy_folder,
            tabBarIcon: ({ color, size }) => (
              <CustomIcon
                size={size}
                name="community:card-account-details"
                color={color}
              />
            ),
          }}
        />
        <BottomTabs.Screen
          name={SCREEN.More}
          component={Dummy}
          options={{
            title: menu_bottom_more,
            tabBarIcon: ({ color, size }) => (
              <CustomIcon size={size} name="menu" color={color} />
            ),
          }}
          listeners={() => ({
            tabPress: (e) => {
              e.preventDefault();
              navigation.dispatch(DrawerActions.toggleDrawer());
            },
          })}
        />
      </BottomTabs.Navigator>
      {floatingMenuButtonVisible && (
        <>
          {subscription ? (
            isSessionInProgress ? (
              <ContinueSession
                label={sessions_proceed_cta}
                onPress={handleContinueSessionOnPress}
              />
            ) : (
              <FloatingMenu
                items={["habits", "note", "sleep_diary", "thought_diary"]}
              />
            )
          ) : (
            <SubscriptionOffer
              label={payments_buy_subscription_cta}
              onPress={handleSubscriptionOfferOnPress}
            />
          )}
        </>
      )}
    </View>
  );
};

export default BottomTabNavigation;
