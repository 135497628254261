import React, { FC } from "react";
import {
  StyleSheet, View
} from "react-native";
import { Colors } from "../../constants/colors";
import { useContainerWidth } from "../../hooks/useContainerWidth";
import { Choice } from "../../types/v2/questionTemplate";
import CustomIcon from "../CustomIcon";
import Slider from "../Slider";

export const emotes = [
  "sentiment-very-dissatisfied",
  "sentiment-dissatisfied",
  "sentiment-satisfied",
  "sentiment-satisfied-alt",
  "sentiment-very-satisfied",
];

interface Props {
  choices: Choice<string>[];
  value: string | null;
  readOnly?: boolean;
  onChange: (value: string) => void;
}

const QualityPicker: FC<Props> = ({
  choices,
  value,
  readOnly = false,
  onChange,
}) => {
  const width = useContainerWidth()
  const midIdx = Math.floor(choices.length / 2);
  const idx =
    value === null
      ? null
      : choices.findIndex((choice) => choice.value === value);

  const handleChange = (idx: number) => {
    onChange(choices[idx].value);
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <Slider
            width={width - 110}
            steps={choices.length}
            startStep={midIdx}
            value={idx}
            onChange={handleChange}
            disabled={readOnly}
          />
        </View>
        <CustomIcon name={emotes[idx !== null ? idx : midIdx]} size={32} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    fontWeight: "300",
    fontSize: 18,
    lineHeight: 21,
    color: Colors.dark,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: -5,
    position: "relative",
  },
  innerContainer: {
    position: "relative",
  },
  trackContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    justifyContent: "center",
  },
  trackInnerContainer: {
    height: 4,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  verticalBar: {
    width: 4,
    height: 8,
    backgroundColor: Colors.dark,
    position: "absolute",
    zIndex: 12,
  },
  previewBlock: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 20,
  },
  barContainer: {
    width: "50%",
    height: 4,
    backgroundColor: Colors.mediumgray,
  },
  barInnerLeft: {
    position: "absolute",
    right: 0,
    top: 0,
    height: 4,
    backgroundColor: "#F99F0E",
  },
  barInnerRight: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 0,
    height: 4,
    backgroundColor: "#59B6B3",
  },
});

export default React.memo(QualityPicker, (prev, curr) => {
  const eq =
    prev.choices === curr.choices &&
    prev.onChange === curr.onChange &&
    prev.readOnly === curr.readOnly &&
    prev.value === curr.value;
  return eq;
});
