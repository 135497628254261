import { FloatingMenuItem } from "../components/FloatingMenu/FloatingMenu";
import { SCREEN } from "../navigation/screens";
import { useAppSelector } from "./redux";
import { useTranslations } from "./useTranslations";

export type AvailableMenuItem =
  | "thought_diary"
  | "sleep_diary"
  | "note"
  | "habits";

export function useFloatingMenu(
  activeItems: AvailableMenuItem[]
): FloatingMenuItem[] {
  const {
    therapyParticipant: { therapy_status },
  } = useAppSelector((state) => state);
  const {
    context_menu_add_habits,
    context_menu_add_note,
    context_menu_add_sleep_diary,
    context_menu_add_thought_diary,
  } = useTranslations();

  const result: FloatingMenuItem[] = [];

  if (
    activeItems.includes("thought_diary") &&
    therapy_status?.thought_diary_enabled
  ) {
    result.push({
      icon: "community:brain",
      text: context_menu_add_thought_diary,
      route: SCREEN.Survey,
      params: { type: "thought_diary" },
    });
  }
  if (
    activeItems.includes("sleep_diary") &&
    therapy_status?.sleep_diary_enabled
  ) {
    result.push({
      icon: "king-bed",
      text: context_menu_add_sleep_diary,
      route: SCREEN.Survey,
      params: { type: "sleep_diary" },
    });
  }
  if (activeItems.includes("note")) {
    result.push({
      icon: "community:pencil",
      text: context_menu_add_note,
      route: SCREEN.Note,
    });
  }
  if (activeItems.includes("habits")) {
    result.push({
      icon: "community:walk",
      text: context_menu_add_habits,
      route: SCREEN.Survey,
      params: { type: "habits" },
    });
  }
  return result;
}
