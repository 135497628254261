import { FC } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";

interface Props {
  value: boolean | null;
  onChange: (value: boolean) => void;
  readOnly?: boolean;
  label?: string;
}

const CheckboxPicker: FC<Props> = ({ value, readOnly, label, onChange }) => {
  const handlePress = () => {
    if (readOnly) {
      return;
    }
    onChange(!value);
  };

  return (
    <Pressable onPress={handlePress}>
      <View style={styles.container}>
        <View style={styles.checkbox}>
          {value && (
            <CustomIcon
              name="community:checkbox-marked"
              size={20}
              color={Colors.primary}
            />
          )}
          {!value && (
            <CustomIcon
              name="community:checkbox-blank-outline"
              size={20}
              color={Colors.primary}
            />
          )}
        </View>
        <Text>{label}</Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    paddingVertical: 5,
  },
  checkbox: {
    marginRight: 5,
  },
});

export default CheckboxPicker;
