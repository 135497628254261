import React, { FC } from "react";
import { StyleSheet, Text } from "react-native";
import { Choice } from "../../types/v2/questionTemplate";
import ChoiceButton, { EChoiceType } from "./ChoiceButton";

interface Props {
  choices: Choice<string>[];
  value: string[];
  onChange: (values: string[]) => void;
  multi?: boolean;
  maxChoices?: number;
  readOnly?: boolean;
}

const ChoiceList: FC<Props> = (props) => {
  const maxChoices = props.multi
    ? props.maxChoices !== undefined
      ? props.maxChoices
      : props.choices.length
    : 1;

  const handlePress = (pressedValue: string) => {
    if (props.readOnly) {
      return;
    }
    if (props.value.includes(pressedValue)) {
      props.onChange(props.value.filter((value) => value !== pressedValue));
    } else if (props.multi && props.value.length < maxChoices) {
      props.onChange([...props.value, pressedValue]);
    } else if (!props.multi) {
      props.onChange([pressedValue]);
    }
  };
  return (
    <>
      {props.choices
        .filter(
          (choice) => !props.readOnly || props.value.includes(choice.value)
        )
        .map((choice) => (
          <React.Fragment key={choice.value.toString()}>
            <ChoiceButton
              text={choice.label}
              selected={props.value.includes(choice.value)}
              onPress={() => handlePress(choice.value)}
              key={choice.value.toString()}
              color={choice.color || undefined}
              type={
                props.multi
                  ? EChoiceType.MULTIPLE_CHOICE
                  : EChoiceType.SINGLE_CHOICE
              }
            />
            {choice.comment !== null && props.readOnly && (
              <Text style={styles.comment}>{choice.comment}</Text>
            )}
          </React.Fragment>
        ))}
    </>
  );
};

const styles = StyleSheet.create({
  comment: {
    marginVertical: 10,
  },
});

export default ChoiceList;
