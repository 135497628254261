import { useNavigation } from "@react-navigation/native";
import { FC } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Colors } from "../../../constants/colors";
import { useAppDispatch } from "../../../hooks/redux";
import { SurveyActions } from "../../../store/redux/survey";
import { ISessionElement } from "../../../types/session";
import CustomMarkdown from "../../utils/markdown";
import Doc from "./additional_content/doc";
import FlashCards from "./additional_content/flashcards";
import Video from "./additional_content/video";

export enum EAdditionalSessionContent {
  FLASHCARDS = "flashcards",
  DOC = "doc",
  VIDEO = "video",
}

interface IProps extends ISessionElement {
  isLast: boolean;
  isFirst?: boolean;
  started?: boolean;
  additionalContent?: EAdditionalSessionContent;
}

const SessionCardElement: FC<IProps> = ({
  completed,
  status,
  survey_uuid,
  template,
  isLast,
  started = false,
  progress = 0,
  additionalContent,
  isFirst,
}) => {
  const navigation = useNavigation<any>();
  const dispatch = useAppDispatch();
  const renderAdditionalContent = () => {
    switch (additionalContent) {
      case EAdditionalSessionContent.FLASHCARDS:
        return <FlashCards active={started} />;
      case EAdditionalSessionContent.DOC:
        return <Doc active={started} />;
      case EAdditionalSessionContent.VIDEO:
        return <Video active={started} />;
      default:
        break;
    }
  };

  const handlePress = () => {
    if (status !== "locked") {
      dispatch(SurveyActions.reset())
      navigation.navigate("Survey", { uuid: survey_uuid })
    }
  };

  return (
    <Pressable
      style={styles.container}
      onPress={handlePress}
    >
      <View style={styles.indicator_container}>
        <View
          style={[
            styles.indicator_dot,
            progress > 0 && { backgroundColor: Colors.primary },
          ]}
        ></View>
        {!isLast && (
          <View style={styles.indicator_line}>
            <View
              style={[
                styles.indicator_line_inner,
                progress > 0 && { height: progress * 100 + "%" },
              ]}
            ></View>
          </View>
        )}
      </View>
      <View style={styles.content_container}>
        <View style={styles.content_headline}>
          <Text style={styles.title}>{template.name}</Text>
          <Text style={styles.time}>{template.duration_text}</Text>
        </View>
        {/* <Text style={styles.text}>{template.description}</Text> */}
        <CustomMarkdown text={template.description} />
        {renderAdditionalContent()}
      </View>
    </Pressable>
  );
};

export default SessionCardElement;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 30,
    flexDirection: "row",
  },
  indicator_container: {
    alignItems: "center",
    marginRight: 20,
    width: 8,
  },
  indicator_dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: Colors.gray,
  },
  indicator_line: {
    width: 2,
    flex: 1,
    backgroundColor: Colors.gray,
  },
  indicator_line_inner: {
    backgroundColor: Colors.primary,
    width: 2,
  },
  content_container: {
    flex: 1,
    paddingBottom: 34,
    marginTop: -4,
    paddingRight: 15,
  },
  content_headline: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    fontWeight: "700",
    color: Colors.dark,
    lineHeight: 17,
  },
  time: {
    fontSize: 14,
    color: Colors.dark,
    opacity: 0.6,
    lineHeight: 17,
    fontWeight: "400",
  },
  text: {
    color: Colors.dark,
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "300",
  },
});
