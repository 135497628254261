import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../../../constants/colors";
import CustomIcon from "../../../CustomIcon";

interface IProps {
  active?: boolean;
}

const Video: FC<IProps> = ({ active }) => {
  return (
    <View style={styles.container}>
      <View style={styles.icon_container}>
        <CustomIcon
          name="play-circle-outline"
          size={40}
          style={[active ? { color: Colors.primary } : { color: Colors.gray }]}
        />
      </View>
      <View>
        <Text style={styles.title}>
          Dr N. Med. {"\n"}
          Małgorzata Fornal - Pawłowska{" "}
        </Text>
        <Text style={[styles.play, active && { color: Colors.primary }]}>
          Odtwórz wideo
        </Text>
      </View>
    </View>
  );
};

export default Video;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    flex: 1,
  },
  icon_container: {
    width: 60,
    height: 60,
    borderRadius: 6,
    backgroundColor: Colors.lightgray,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
  },
  title: {
    fontSize: 14,
    color: Colors.dark,
    lineHeight: 22,
    flex: 1,
  },
  play: {
    color: Colors.gray,
    fontWeight: "700",
    lineHeight: 17,
    marginTop: 3,
  },
});
