import { Audio, AVPlaybackStatusSuccess } from "expo-av";
import { useEffect, useRef, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import AVControls from "./AVControls";
import * as Sentry from "../utils/sentry";
import { useTranslations } from "../hooks/useTranslations";
import { Colors } from "../constants/colors";

interface Props {
  source: string;
  isPaused: boolean;
  onIsPausedChange?: (isPaused: boolean) => void;
}

function AudioPlayer({ isPaused, source, onIsPausedChange }: Props) {
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const [trackLength, setTrackLength] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const player = useRef<Audio.Sound | null>(null);
  const { unknown_error } = useTranslations();

  async function play() {
    if (player.current !== null) {
      await player.current.playAsync();
    }
  }

  async function pause() {
    if (player.current !== null) {
      await player.current.pauseAsync();
    }
  }

  async function initialize(autoPlay: boolean = false) {
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      staysActiveInBackground: true,
    });
    try {
      const { sound } = await Audio.Sound.createAsync(
        {
          uri: source,
        },
        undefined,
        (data) => {
          const status = data as AVPlaybackStatusSuccess;
          if (status.isLoaded) {
            setCurrentPosition(status.positionMillis / 1000);
          }
        }
      );
      player.current = sound;
      const status = await sound.getStatusAsync();
      if (status.isLoaded) {
        setTrackLength((status.durationMillis || 0) / 1000);
        setIsLoaded(true);
        if (autoPlay) {
          await play();
        }
      } else {
        setError(true);
        if (status.error) {
          Sentry.captureException(error);
        }
      }
    } catch (error: any) {
      setError(true)
      Sentry.captureException(error);
    }
  }

  const onPressPlayPause = async () => {
    if (onIsPausedChange) {
      onIsPausedChange(!isPaused);
    }
  };

  const onSeek = (value: number) => {
    if (player.current !== null) {
      player.current.setPositionAsync(value * 1000);
    }
  };

  useEffect(() => {
    if (isPaused) {
      pause();
    } else {
      play();
    }
  }, [isPaused]);

  useEffect(() => {
    initialize(!isPaused);
    return () => {
      if (player.current !== null) {
        player.current.unloadAsync();
      }
    };
  }, []);

  return (
    <View>
      <AVControls
        disabled={!isLoaded}
        trackLength={trackLength}
        isPaused={isPaused}
        position={currentPosition}
        onSeek={onSeek}
        onPressPlayPause={onPressPlayPause}
      />
      {error ? <Text style={styles.error}>{unknown_error}</Text> : null}
    </View>
  );
}

const styles = StyleSheet.create({
  error: {
    fontSize: 10,
    color: Colors.error,
  },
});

export default AudioPlayer;
