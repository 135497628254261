import { AxiosRequestConfig } from "axios";

export function authorize(
  config: AxiosRequestConfig,
  token?: string
): AxiosRequestConfig {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
}
