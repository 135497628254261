import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { HabitQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import CustomIcon from "../CustomIcon";
import CirclePicker from "../inputs/CirclePicker";

const Habit: FC<QuestionProps<number, HabitQuestion>> = ({
  onChange,
  question,
  readOnly,
}) => {
  const {
    template: {
      choices,
      content,
      subcontent,
      fulfillment_threshold,
      is_negative,
      icon,
    },
    answer: value,
  } = question;

  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <View style={styles.iconContainer}>
          <CustomIcon name={icon} size={26} color={Colors.primary} />
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.textContent}>{content}</Text>
          {readOnly && <Text style={styles.textSubcontent}>{subcontent}</Text>}
        </View>
      </View>
      <CirclePicker
        choices={choices}
        onChange={onChange}
        value={value}
        fullillmentThreshold={
          fulfillment_threshold !== null ? fulfillment_threshold : undefined
        }
        isNegative={is_negative}
        readOnly={readOnly}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  labelContainer: { flex: 1, flexDirection: "row", alignItems: "center" },
  iconContainer: {
    marginRight: 23,
  },
  textContainer: {
    flex: 1,
  },
  textContent: {
    fontSize: 14,
    lineHeight: 18,
    color: Colors.dark,
    fontWeight: "700",
  },
  textSubcontent: {
    fontSize: 11,
    lineHeight: 16,
    color: Colors.dark,
    opacity: 0.6,
  },
});

export default Habit;
