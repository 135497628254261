import { useIsFocused } from "@react-navigation/native";
import { FC, useEffect } from "react";
import { RefreshControl, ScrollView } from "react-native";
import Slide from "../../components/slides/Slide";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { PatientCardTabScreenProps } from "../../navigation/PatientCardTabNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { getTherapyFolderInsomniaMap } from "../../store/redux/thunk_functions/therapyFolder";

const InsomniaMapScreen: FC<
  PatientCardTabScreenProps<SCREEN.InsomniaMap>
> = () => {
  const isFocused = useIsFocused();
  const { needRefresh, content, loading } = useAppSelector(
    (state) => state.therapyFolder.insomniaMap
  );
  const translations = useTranslations();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh && isFocused) {
      dispatch(getTherapyFolderInsomniaMap());
    }
  }, [needRefresh, isFocused]);

  const handleRefresh = () => {
    dispatch(getTherapyFolderInsomniaMap());
  };

  return (
    <ScrollView
      contentContainerStyle={{
        paddingTop: 30,
        paddingHorizontal: 30,
      }}
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
      }
    >
      <Slide content={content} translations={translations} />
    </ScrollView>
  );
};

export default InsomniaMapScreen;
