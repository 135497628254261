import { ENDPOINTS } from "../api/config";
import { AuthActions, STORE_KEY } from "../store/redux/auth";
import { refreshUserState } from "../store/redux/thunk_functions/user";
import { TherapyParticipantActions } from "../store/redux/user";
import { storeObject } from "../store/storage";
import { EAuthType } from "../types/auth";
import { Token } from "../types/v2/token";
import { useAppDispatch } from "./redux";
import { useCall } from "./useAuthorizedCall";

export function useEmailPasswordSignup() {
  const call = useCall();
  const dispatch = useAppDispatch();

  return async (
    username: string,
    email: string,
    password: string,
    consents: { [key: string]: boolean }
  ) => {
    const consentsResponse = await call(ENDPOINTS.user.SET_CONSENTS(consents));
    if (consentsResponse.required_consents_accepted) {
      const { access_token: token } = await call<Token>(
        ENDPOINTS.user.SIGNUP({
          username,
          email,
          password,
          password_confirmation: password,
        })
      );
      await storeObject(STORE_KEY, { token, loginType: EAuthType.EMAIL });
      dispatch(AuthActions.login(token));
    }
  };
}

export function useEmailPasswordLogin() {
  const call = useCall();
  const dispatch = useAppDispatch();

  return async (email: string, password: string) => {
    const params = new URLSearchParams();
    params.append("username", email);
    params.append("password", password);

    const { access_token: token, user } = await call<Token>(
      ENDPOINTS.user.SIGNIN(params.toString())
    );
    await storeObject(STORE_KEY, { token, loginType: EAuthType.EMAIL });
    await dispatch(AuthActions.login(token));
    await dispatch(TherapyParticipantActions.setUser(user));
    await dispatch(refreshUserState());
  };
}
