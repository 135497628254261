import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { CircleBarProps } from "../../types/therapy_progress";
import { Translations } from "../../types/v2/manifest";
import CustomText from "../typography/text";
import CircleBar from "./CircleBar";

type Mode = "session" | "therapy";

interface Props {
  translations: Translations;
  session_progress?: number;
  therapy_progress: number;
  current_week?: string;
  comment_heading_therapy_progress?: string;
  comment_content_therapy_progress?: string;
  comment_heading_session_progress?: string;
  comment_content_session_progress?: string;
}

const sessionsCircleBarProps: CircleBarProps = {
  barColor: Colors.primary,
  radius: 192 / 2,
  barWidth: 12,
  progress: 0,
};

const therapyCircleBarProps: CircleBarProps = {
  barColor: Colors.green,
  radius: 244 / 2,
  barWidth: 7,
  progress: 0,
};

const TherapyProgress = (props: Props) => {
  const { translations } = props;
  const navigation = useNavigation<any>();

  const hasSessionProgress = props.session_progress !== undefined;

  const [mode, setMode] = useState<Mode>(
    hasSessionProgress ? "session" : "therapy"
  );

  const handleModeChange = () => {
    if (hasSessionProgress) {
      setMode(mode == "therapy" ? "session" : "therapy");
    }
  };

  const handleGoToTherapyFolder = () => {
    navigation.navigate("PatientCard");
  };

  const percValue = Math.round(
    (mode == "session" ? props.session_progress! : props.therapy_progress) * 100
  );

  const percColor =
    mode == "session"
      ? sessionsCircleBarProps.barColor
      : therapyCircleBarProps.barColor;

  const sessionCircleBarOffset =
    therapyCircleBarProps.radius - sessionsCircleBarProps.radius;

  const commentHeadingKey = props[`comment_heading_${mode}_progress`];
  const commentContentKey = props[`comment_content_${mode}_progress`];

  return (
    <View style={styles.container}>
      <Pressable onPress={handleModeChange} hitSlop={10}>
        <View style={styles.chartContainer}>
          <View style={styles.chartElement}>
            <CircleBar
              {...therapyCircleBarProps}
              progress={props.therapy_progress}
              barColor={
                mode == "therapy"
                  ? therapyCircleBarProps.barColor
                  : styles.inactiveBar.color
              }
            />
          </View>
          <View
            style={[
              styles.chartElement,
              { top: sessionCircleBarOffset, left: sessionCircleBarOffset },
            ]}
          >
            {props.session_progress !== undefined && (
              <CircleBar
                {...sessionsCircleBarProps}
                progress={props.session_progress}
                barColor={
                  mode == "session"
                    ? sessionsCircleBarProps.barColor
                    : styles.inactiveBar.color
                }
              />
            )}
          </View>
          <View style={[styles.chartElement, styles.percentContainer]}>
            <View style={styles.percentInnerContainer}>
              <Text style={[styles.percentValue, { color: percColor }]}>
                {percValue}
                <Text style={styles.percentUnit}>%</Text>
              </Text>
            </View>
          </View>
          <View style={[styles.chartElement, styles.bottomTextContainer]}>
            <View style={styles.bottomTextInnerContainer}>
              <View style={{ flex: 1 }}>
                {props.session_progress !== undefined &&
                  props.current_week !== undefined && (
                    <Text
                      style={[
                        styles.bottomText,
                        {
                          color:
                            mode == "session"
                              ? sessionsCircleBarProps.barColor
                              : styles.inactiveBar.color,
                        },
                      ]}
                    >
                      {translations[props.current_week]}
                    </Text>
                  )}
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={[
                    styles.bottomText,
                    {
                      color:
                        mode == "therapy"
                          ? therapyCircleBarProps.barColor
                          : styles.inactiveBar.color,
                    },
                  ]}
                >
                  {translations.therapy_progress_therapy_progress_label}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Pressable>
      {commentHeadingKey && (
        <Text style={styles.commentHeading}>
          {translations[commentHeadingKey] || commentHeadingKey}
        </Text>
      )}
      {commentContentKey && (
        <Text style={styles.commentContent}>
          {translations[commentContentKey] || commentContentKey}
        </Text>
      )}
      <Pressable onPress={handleGoToTherapyFolder}>
        <View style={{ paddingVertical: 20 }}>
          <CustomText
            text={translations.therapy_progress_go_to_therapy_folder}
            link
          />
        </View>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    paddingVertical: 30,
    maxWidth: 800,
  },
  chartContainer: {
    position: "relative",
    width: 245,
    height: 245,
  },
  inactiveBar: {
    color: "#979797",
  },
  chartElement: {
    position: "absolute",
    width: 244,
    height: 244,
  },
  percentContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  percentInnerContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  percentValue: {
    fontSize: 70,
    fontWeight: "600",
  },
  percentUnit: {
    fontSize: 18,
  },
  bottomTextContainer: {
    alignItems: "center",
  },
  bottomTextInnerContainer: {
    position: "absolute",
    bottom: 22,
    height: 40,
    width: 110,
    alignItems: "center",
  },
  bottomText: {
    fontSize: 11,
    fontWeight: "600",
  },
  commentHeading: {
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 26,
    textAlign: "center",
    color: Colors.dark,
  },
  commentContent: {
    textAlign: "center",
  },
});

export default TherapyProgress;
