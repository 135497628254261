import { FC } from "react";
import { View } from "react-native";
import { Flashcards as FlashcardsProps } from "../../types/v2/slides/slide_content";
import Flashcard from "../flashcards/Flashcard";
import FlashcardsCarousel from "../flashcards/FlashcardsCarousel";

const Flashcards: FC<FlashcardsProps> = ({ horizontal, flashcards }) => {
  if (horizontal) {
    return <FlashcardsCarousel flashcards={flashcards} />;
  }
  return (
    <View>
      {flashcards.map((flashcard) => {
        return (
          <View key={flashcard.uuid} style={{ marginVertical: 5 }}>
            <Flashcard {...flashcard} key={flashcard.uuid} />
          </View>
        );
      })}
    </View>
  );
};

export default Flashcards;
