import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../../types/redux";
import { DashboardActions } from "../dashboard";
import { DayPlanActions } from "../dayPlan";
import { FlashcardsActions } from "../flashcards";
import { LibraryActions } from "../library";
import { OnboardingActions } from "../onboarding";
import { RelaxationsActions } from "../relaxations";
import { SleepDiarySummaryActions } from "../sleepDiarySummary";
import { TherapyActions } from "../therapy";
import { TherapyFolderBeliefsActions } from "../therapyFolderBeliefs";
import { TherapyFolderInomniaMapActions } from "../therapyFolderInsomniaMap";
import { TherapyFolderProfileActions } from "../therapyFolderProfile";
import { TherapyParticipantActions } from "../user";
import {
  getAppConfiguration,
  getLanguageConfiguration,
  getProfileConfiguration
} from "./configuration";

export const markAllAsStale = createAsyncThunk<void, undefined, IThunkApi>(
  "other/markAllAsStale",
  (_, { dispatch }) => {
    dispatch(DashboardActions.markAsStale());
    dispatch(DayPlanActions.markAsStale());
    dispatch(FlashcardsActions.markAsStale());
    dispatch(LibraryActions.markAsStale());
    dispatch(OnboardingActions.markAsStale());
    dispatch(RelaxationsActions.martAsState());
    dispatch(SleepDiarySummaryActions.markAsStale());
    dispatch(TherapyActions.markAsStale());
    dispatch(TherapyFolderBeliefsActions.markAsStale());
    dispatch(TherapyFolderInomniaMapActions.markAsStale());
    dispatch(TherapyFolderProfileActions.markAsStale());
  }
);

export const refreshAll = createAsyncThunk<void, undefined, IThunkApi>(
  "other/refreshAll",
  (_, { dispatch }) => {
    dispatch(markAllAsStale());
    dispatch(getAppConfiguration());
    dispatch(getProfileConfiguration());
    dispatch(getLanguageConfiguration());
  }
);

export const resetAll = createAsyncThunk<void, undefined, IThunkApi>(
  "other/resetAll",
  (_, { dispatch }) => {
    dispatch(DashboardActions.reset());
    dispatch(DayPlanActions.reset());
    dispatch(FlashcardsActions.reset());
    dispatch(LibraryActions.reset());
    dispatch(OnboardingActions.reset());
    dispatch(RelaxationsActions.reset());
    dispatch(SleepDiarySummaryActions.reset());
    dispatch(TherapyActions.reset());
    dispatch(TherapyFolderBeliefsActions.reset());
    dispatch(TherapyFolderInomniaMapActions.reset());
    dispatch(TherapyFolderProfileActions.reset());
    dispatch(TherapyParticipantActions.reset());
  }
);
