import { useEffect, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { Colors } from "../constants/colors";
import { retrieveData } from "../store/storage";

const LOGOS: { [key: string]: any } = {
  default: require("../assets/splash-screen.png"),
};

const SplashScreen = () => {
  const [flavour, setFlavour] = useState<string>();

  useEffect(() => {
    const getFlavour = async () => {
      setFlavour((await retrieveData("@flavour")) || "default");
    };
    getFlavour();
  }, []);

  if (flavour === undefined) {
    return null;
  }

  const logo = LOGOS[flavour || "default"];

  return (
    <View style={styles.container}>
      <Image
        source={logo}
        style={{ width: "50%", flex: 1, maxWidth: 400 }}
        resizeMode="contain"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.lightgray,
  },
});

export default SplashScreen;
