import { StyleSheet } from "react-native";
import { Colors } from "../../constants/colors";

export const select_styles = StyleSheet.create({
  container: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.lightgray,
    borderRadius: 4,
    height: 58,
  },
  label_container: {
    paddingHorizontal: 20,
  },
  label: {
    fontSize: 14,
    color: Colors.darkgray,
    lineHeight: 17,
    fontWeight: "400",
  },
  input: {
    flex: 1,
    fontSize: 14,
    lineHeight: 17,
    color: Colors.dark,
    paddingVertical: 15,
    fontWeight: "700",
  },
  icon: {
    fontSize: 25,
    marginRight: 10,
    color: Colors.darkgray,
  },
  error: {
    fontSize: 11,
    lineHeight: 13,
    fontWeight: "400",
    color: Colors.error,
  },
  modal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  modal_item: {
    width: "100%",
    backgroundColor: Colors.white,
    paddingVertical: 22,
    paddingHorizontal: 15,
    borderBottomColor: Colors.lightgray,
    borderBottomWidth: 1,
  },
});
