import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../../../constants/colors";
import CustomIcon from "../../../CustomIcon";

interface IProps {
  active?: boolean;
}

const FlashCards: FC<IProps> = ({ active }) => {
  return (
    <View style={styles.container}>
      <View style={styles.icon_container}>
        <CustomIcon
          name="inbox"
          size={40}
          style={[active ? { color: Colors.primary } : { color: Colors.gray }]}
        />
      </View>
      <Text
        style={[styles.title, active && { color: Colors.primary, opacity: 1 }]}
      >
        Pokaż fiszki
      </Text>
    </View>
  );
};

export default FlashCards;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  icon_container: {
    width: 60,
    height: 60,
    borderRadius: 6,
    backgroundColor: Colors.lightgray,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
  },
  title: {
    fontSize: 14,
    fontWeight: "700",
    color: Colors.dark,
    opacity: 0.4,
    lineHeight: 17,
  },
});
