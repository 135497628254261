import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { IFlashCard } from "../../../types/survey";

export const fetchFlashcards = createAsyncThunk<
  IFlashCard[],
  undefined,
  IThunkApi
>("flashcards/fetchFlashcards", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call<IFlashCard[]>(
    authorize(ENDPOINTS.tools.GET_FLASHCARDS, token)
  );
  return data;
});
