import { useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import ConfirmDelete from "../../components/confirm_delete/confirm_delete";
import CustomIcon from "../../components/CustomIcon";
import ErrorPopup from "../../components/error_popup/error_popup";
import MainHeading from "../../components/typography/main_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import Input from "../../components/utils/input";
import PrimaryButton from "../../components/utils/primary_button";
import { Colors } from "../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { SCREEN } from "../../navigation/screens";
import {
  createNote,
  deleteNote,
  updateNote,
} from "../../store/redux/thunk_functions/notes";
import { useTranslations } from "../../hooks/useTranslations";
import { NotestActions } from "../../store/redux/notes";

const NoteScreen = ({
  route,
  navigation,
}: RootStackScreenProps<SCREEN.Note>) => {
  const { byId } = useAppSelector((state) => state.notes);
  const {
    notepad_add_note_heading,
    notepad_placeholder,
    unknown_error,
    edit,
    save,
  } = useTranslations();
  const { params } = route;
  const uuid = params === undefined ? undefined : params.uuid;
  const dispatch = useAppDispatch();
  const [content, setContent] = useState<string>(
    uuid !== undefined ? byId[uuid].content : ""
  );
  const [editable, setEditable] = useState(uuid === undefined);
  const [confirmModal, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const editing = uuid !== undefined;

  const saveNote = async () => {
    setLoading(true);
    const promise = editing
      ? dispatch(updateNote({ ...byId[uuid], content }))
      : dispatch(createNote(content));
    await promise;
    setLoading(false);
    navigation.goBack();
  };

  const removeNote = async () => {
    setLoading(true);
    await dispatch(deleteNote(uuid!));
    dispatch(NotestActions.markAsStale())
    setLoading(false);
    navigation.goBack();
  };

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <MainHeading text={notepad_add_note_heading} />
          <Pressable onPress={() => navigation.goBack()}>
            <CustomIcon name="close" color={Colors.primary} size={32} />
          </Pressable>
        </View>
        <Divider />
        <Input
          value={content}
          setValue={setContent}
          placeholder={notepad_placeholder}
          numberOfLines={20}
          editable={editable}
        />
        {editable && (
          <View style={{ flex: 1, justifyContent: "flex-end" }}>
            <PrimaryButton
              label={save}
              onPress={saveNote}
              disabled={loading}
              loading={loading}
            />
          </View>
        )}
        {!editable && (
          <View style={{ flex: 1, justifyContent: "flex-end" }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flex: 1, marginRight: 30 }}>
                <PrimaryButton
                  label={edit}
                  onPress={setEditable.bind(this, true)}
                  disabled={loading}
                  loading={loading}
                />
              </View>
              <Pressable onPress={toggleModal.bind(this, true)}>
                <CustomIcon
                  name="community:trash-can-outline"
                  size={34}
                  color={Colors.error}
                />
              </Pressable>
            </View>
          </View>
        )}
        <ConfirmDelete
          dismiss={toggleModal.bind(this, false)}
          confirm={removeNote}
          visible={confirmModal}
        />
        {error !== null && (
          <ErrorPopup
            visible={error !== null}
            dismiss={setError.bind(this, null)}
            text={error}
          />
        )}
      </Container>
    </ScrollView>
  );
};
export default NoteScreen;
