import formatISO from "date-fns/formatISO";

export const toolsRoutes = {
  GET_RELAXATIONS: (category?: string) => {
    const url =
      category !== undefined
        ? `/api/v1/cbti/relaxations${`?category=${category}`}`
        : `/api/v1/cbti/relaxations`;
    return {
      url,
      method: "GET",
    };
  },
  SINGLE_RELAXATION: (id: string) => ({
    url: `/api/v1/cbti/relaxations/${id}`,
    method: "GET",
  }),
  ADD_LISTEN_TIME: (uuid: string, duration: number) => {
    const date = formatISO(new Date(), { representation: "date" });
    return {
      url: `/api/v1/cbti/relaxations/${uuid}/listening`,
      method: "POST",
      data: {
        date,
        duration,
      },
    };
  },
  GET_ARTICLES: (category?: string) => {
    const url =
      category !== undefined
        ? `/api/v1/cbti/library${`?category=${category}`}`
        : `/api/v1/cbti/library`;
    return {
      url,
      method: "GET",
    };
  },
  SINGLE_ARTICLE: (uuid: string) => ({
    url: `/api/v1/cbti/library/${uuid}`,
    method: "GET",
  }),
  GET_THOUGHTS: {
    url: "/api/v1/cbti/thought-diary",
    method: "GET",
  },
  GET_SINGLE_THOUGHTS: (uuid: string) => ({
    url: `/api/v1/cbti/thought-diary/${uuid}`,
    method: "GET",
  }),
  ADD_THOUGHT: () => ({
    url: "/api/v1/cbti/thought-diary",
    method: "POST",
  }),
  GET_THOUGHT: (uuid: string) => ({
    url: `/api/v1/cbti/thought-diary/${uuid}`,
    method: "GET",
  }),
  CREATE_THOUGHT: () => ({
    url: "/api/v1/cbti/thought-diary",
    method: "POST",
  }),
  DELETE_THOUGHT: (uuid: string) => ({
    url: `/api/v1/cbti/thought-diary/${uuid}`,
    method: "DELETE",
  }),
  GET_FLASHCARDS: {
    url: "/api/v1/cbti/flashcards",
    method: "GET",
  },
  GET_NOTES: {
    url: "/api/v1/cbti/notes",
    method: "GET",
  },
  CREATE_NOTE: (content: string) => ({
    url: "/api/v1/cbti/notes",
    method: "POST",
    data: {
      content,
    },
  }),
  GET_SINGLE_NOTE: (uuid: string) => ({
    url: `/api/v1/cbti/notes/${uuid}`,
    method: "GET",
  }),
  UPDATE_NOTE: (uuid: string, content: string) => ({
    url: `/api/v1/cbti/notes/${uuid}`,
    method: "PUT",
    data: {
      content,
    },
  }),
  DELETE_NOTE: (uuid: string) => ({
    url: `/api/v1/cbti/notes/${uuid}`,
    method: "DELETE",
  }),
  SLEEP_REPORT: (data: { tools: string[]; sessions: string[] }) => ({
    url: `/api/v1/cbti/sleep-report`,
    method: "POST",
    data,
  }),
  GET_HABITS: (date: string) => ({
    url: `/api/v1/cbti/habits/${date}`,
    method: "GET",
  }),
  UPDATE_HABITS: (date: string, answers: any) => ({
    url: `/api/v1/cbti/habits/${date}`,
    method: "PUT",
    data: {
      answers,
    },
  }),
};
