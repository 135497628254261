import { StyleSheet } from "react-native";
import { Colors } from "../../constants/colors";

export const error_popup_styles = StyleSheet.create({
  modal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  container: {
    backgroundColor: Colors.white,
    maxHeight: "90%",
    position: "relative",
  },
  text: {
    fontSize: 22,
    fontWeight: "700",
    color: Colors.dark,
    paddingBottom: 20,
    textAlign: "center",
    borderBottomColor: Colors.lightgray,
    borderBottomWidth: 1,
    paddingHorizontal: 30,
    paddingTop: 20,
  },
});
