import { FC, useEffect, useState } from "react";
import { Pressable, Text, View } from "react-native";
import { ENDPOINTS } from "../../api/config";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { Colors } from "../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useCall } from "../../hooks/useAuthorizedCall";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { getLanguageConfiguration } from "../../store/redux/thunk_functions/configuration";
import { fetchManifest } from "../../store/redux/thunk_functions/fetchManifest";
import { refreshAll } from "../../store/redux/thunk_functions/other";
import { TherapyParticipantActions } from "../../store/redux/user";
import { User } from "../../types/v2/user";

export interface ILanguage {
  code: string;
  name: string;
}

const LanguageSettingsScreen: FC<
  RootStackScreenProps<SCREEN.LanguageSettings>
> = ({ navigation }) => {
  const { languages, languagesLoaded } = useAppSelector(
    (state) => state.configuration
  );
  const { user } = useAppSelector((state) => state.therapyParticipant);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { settings_main_header, settings_main_auth_change_language } =
    useTranslations();
  const [userLanguage, setUserLanguage] = useState(user?.language || "en");
  const call = useCall();

  const saveUserLanguage = async (lang: string) => {
    if (loading) {
      return;
    }
    setUserLanguage(lang);
    setLoading(true);
    try {
      const updatedUser = await call<User>(
        ENDPOINTS.settings.EDIT_USER({
          language: lang,
        })
      );
      dispatch(TherapyParticipantActions.setUser(updatedUser));
      await dispatch(fetchManifest({ language: updatedUser.language }));
      dispatch(refreshAll());
    } catch (e: any) {
      console.log(e.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!languagesLoaded) {
      dispatch(getLanguageConfiguration());
    }
  }, [languagesLoaded]);

  return (
    <Container>
      <View style={{}}>
        <Pressable
          style={{ paddingVertical: 2, marginBottom: 15 }}
          onPress={() => navigation.goBack()}
        >
          <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
        </Pressable>
      </View>

      <MainHeading text={settings_main_auth_change_language} size={"small"} />
      <View style={{ marginTop: 10 }}>
        <SubHeading text={settings_main_header} />
      </View>
      <Divider />
      <View>
        {languages.map((lang) => {
          return (
            <Pressable
              onPress={() => saveUserLanguage(lang.code)}
              key={lang.code}
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingVertical: 12,
                marginVertical: 1,
                borderBottomWidth: 1,
                borderBottomColor: Colors.lightgray,
              }}
            >
              <CustomIcon
                name={"community:check-bold"}
                color={
                  lang.code === userLanguage ? Colors.primary : Colors.gray
                }
                size={24}
              />
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: 14,
                  color: Colors.dark,
                  marginLeft: 20,
                }}
              >
                {lang.name}
              </Text>
            </Pressable>
          );
        })}
      </View>
    </Container>
  );
};

export default LanguageSettingsScreen;
