import { FC } from "react";
import { TextAreaQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import TextAreaPicker from "../inputs/TextAreaPicker";
import QuestionWrapper from "./QuestionWrapper";

const TextArea: FC<QuestionProps<string, TextAreaQuestion>> = ({
  onChange,
  question,
  readOnly,
}) => {
  const {
    template: { content, subcontent },
    answer: value,
  } = question;

  return (
    <QuestionWrapper
      content={content || undefined}
      subcontent={subcontent || undefined}
    >
      <TextAreaPicker onChange={onChange} value={value} readOnly={readOnly} />
    </QuestionWrapper>
  );
};

export default TextArea;
