import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { useLocalizedFormatter } from "../../hooks/dates";
import { SessionHeader as SessionHeaderProps } from "../../types/v2/slides/slide_content";
import CustomIcon from "../CustomIcon";
import Divider from "../utils/divider";

const SessionHeader: FC<SessionHeaderProps> = ({ name, number, date }) => {
  const format = useLocalizedFormatter();
  let formattedDate;
  try {
    formattedDate = format(date);
  } catch (error) {
    formattedDate = "";
  }
  const iconName = `community:numeric-${number}-box-outline`;

  return (
    <>
      <View style={styles.container}>
        <CustomIcon name={iconName} size={14} color={Colors.darkgray} />
        <Text style={styles.text}>
          {name} - {formattedDate}
        </Text>
      </View>
      <Divider marginless />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
  },
  text: {
    fontSize: 14,
    lineHeight: 28,
    color: Colors.darkgray,
    marginLeft: 15,
  },
  textInner: {
    marginLeft: 15,
  },
});

export default SessionHeader;
