import { FC } from "react";
import { Platform, Pressable, StyleSheet, Text, View } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";
import { Colors } from "../../constants/colors";
import { useTranslations } from "../../hooks/useTranslations";
import { Flashcard as FlashcardBaseProps } from "../../types/v2/flashcard";
import CustomIcon from "../CustomIcon";
import CustomMarkdown from "../utils/markdown";

interface FlashcardProps extends FlashcardBaseProps {
  maxHeight?: number;
  setMaxHeight?: (value: number) => void;
}

const Flashcard: FC<FlashcardProps> = ({
  answer,
  choices,
  question,
  maxHeight,
  setMaxHeight,
}) => {
  const { flashcards_show_answer_cta, flashcards_show_question_cta } =
    useTranslations();
  const offset = useSharedValue(0);

  const animatedFront = useAnimatedStyle(() => {
    return {
      transform: [{ rotateY: offset.value * 180 + "deg" }],
    };
  });
  const animatedBack = useAnimatedStyle(() => {
    return {
      transform: [{ rotateY: (offset.value + 1) * 180 + "deg" }],
    };
  });

  const flipCard = () => {
    if (offset.value === 0) offset.value = withSpring(1, { stiffness: 50 });
    else offset.value = withSpring(0, { stiffness: 50 });
  };

  const updateHeight = (value: number) => {
    setMaxHeight !== undefined && setMaxHeight(value);
  };

  return (
    <Pressable onPress={flipCard} style={[{ width: "100%" }]}>
      <Animated.View style={[styles.flipCard, animatedFront]}>
        <View style={{ padding: 15, flex: 1 }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.question}>{question}</Text>
            {choices && <CustomMarkdown text={choices} />}
          </View>
          <View style={styles.action_box}>
            <CustomIcon
              name="community:arrow-right"
              color={Colors.primary}
              size={20}
              style={{ marginRight: 5 }}
            />
            <Text style={styles.action_text}>{flashcards_show_answer_cta}</Text>
          </View>
        </View>
      </Animated.View>

      <Animated.View
        style={[styles.flipCard, styles.flipCardBack, animatedBack]}
      >
        <View style={{ padding: 15, flex: 1, elevation: 6 }}>
          <View style={{ flex: 1, marginBottom: 15 }}>
            <Text style={styles.answer}>{answer}</Text>
          </View>
          <View style={styles.action_box}>
            <CustomIcon
              name="community:arrow-right"
              color={Colors.primary}
              size={20}
              style={{ marginRight: 5 }}
            />
            <Text style={styles.action_text}>
              {flashcards_show_question_cta}
            </Text>
          </View>
        </View>
      </Animated.View>
    </Pressable>
  );
};

export default Flashcard;

const styles = StyleSheet.create({
  flipCard: {
    width: "100%",
    backfaceVisibility: "hidden",
    backgroundColor: Colors.white,
    elevation: 4,
    height: 380,
    borderWidth: 1,
    borderColor: Colors.mediumgray,
    borderRadius: 8,
  },
  flipCardBack: {
    position: "absolute",
    top: 0,
    backgroundColor: Colors.white,
  },
  question: {
    fontSize: 16,
    fontWeight: "700",
    lineHeight: 22,
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
  },
  answer: {
    fontSize: 14,
    lineHeight: 22,
  },
  action_box: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  action_text: {
    fontSize: 16,
    fontWeight: "700",
    color: Colors.primary,
  },
});
