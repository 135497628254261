import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  ImageBackground,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Shadow } from "react-native-shadow-2";
import CustomIcon from "../../components/CustomIcon";
import Loader from "../../components/Loader";
import SessionCardElement from "../../components/sessions/session_card_element/session_card_element";
import MainHeading from "../../components/typography/main_heading";
import PrimaryButton from "../../components/utils/primary_button";
import { Colors } from "../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { getSession } from "../../store/redux/thunk_functions/therapy";

const SessionScreen = ({
  navigation,
  route,
}: RootStackScreenProps<SCREEN.Session>) => {
  const isFocused = useIsFocused();
  const { uuid } = route.params;
  const {
    therapyParticipant: { subscription },
    manifest: {
      translations: { payments_buy_subscription_cta, next },
    },
    therapy: { sessionById, sessionElementById, sessionElementsIds },
  } = useAppSelector((state) => state);
  const insets = useSafeAreaInsets();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const session = sessionById[uuid];
  const elements = (sessionElementsIds[uuid] || []).map(
    (id) => sessionElementById[id]
  );

  const loaded = elements.length > 0;

  useEffect(() => {
    const load = async (isFocused: boolean) => {
      if (isFocused) {
        setLoading(true);
        await dispatch(getSession(uuid));
        setLoading(false);
      }
    };
    load(isFocused);
  }, [isFocused]);

  const handleRefresh = () => {
    dispatch(getSession(uuid));
  };

  const openSurveyInProgress = () => {
    const inProgress = elements.find((e) => e.status === "in_progress");
    if (inProgress !== undefined) {
      navigation.navigate(SCREEN.Survey, {
        uuid: inProgress.survey_uuid,
        type: "generic",
      });
    }
  };
  return (
    <View
      style={[
        { flex: 1 },
        session?.status === "in_progress" && { paddingBottom: 100 },
      ]}
    >
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View style={{ height: 190 }}>
          <ImageBackground
            source={require("../../assets/images/session_bg.png")}
            resizeMode="cover"
            style={styles.bg_image}
          >
            <View
              style={{ marginHorizontal: 30, marginVertical: insets.top + 10 }}
            >
              <View>
                <Pressable
                  style={{ paddingVertical: 2, marginBottom: 8 }}
                  onPress={() => navigation.goBack()}
                >
                  <CustomIcon
                    name="chevron-left"
                    color={Colors.primary}
                    size={26}
                  />
                </Pressable>
              </View>
              <MainHeading text={session?.template.name || ""} />
              {subscription === null && (
                <View style={styles.lock_container}>
                  <CustomIcon
                    name="community:lock-outline"
                    size={18}
                    style={{ marginRight: 10 }}
                  />
                  <Text style={styles.lock_text}>
                    {payments_buy_subscription_cta}
                  </Text>
                </View>
              )}
            </View>
          </ImageBackground>
        </View>

        <View style={styles.overview_container}>
          <Text style={styles.overview_title}>{session?.template.topic}</Text>
          <Text style={styles.overview_time}>
            {session?.template.duration_text}
          </Text>
        </View>

        <View style={{ paddingBottom: 20 }}>
          {loading && !loaded && <Loader />}
          {elements.map((e, index) => {
            return (
              <SessionCardElement
                {...e}
                isLast={false}
                started={false}
                key={e.template.uuid}
                isFirst={index === 0}
              />
            );
          })}
        </View>
      </ScrollView>
      {session?.status === "in_progress" && !loading && (
        <View style={styles.button_container}>
          <Shadow style={styles.shadow_style}>
            <PrimaryButton label={next} onPress={openSurveyInProgress} />
          </Shadow>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  bg_image: {
    width: "100%",
    height: 190,
  },
  lock_container: {
    marginTop: 7,
    flexDirection: "row",
    alignItems: "center",
  },
  lock_text: {
    fontSize: 18,
    fontWeight: "600",
    color: Colors.dark,
  },
  overview_container: {
    marginHorizontal: 30,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.gray,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
    flex: 1,
  },
  overview_title: {
    fontSize: 18,
    color: Colors.primary,
    fontWeight: "700",
    lineHeight: 21,
    flex: 1,
  },
  overview_subtitle: {
    fontSize: 14,
    fontWeight: "200",
    lineHeight: 28,
    color: Colors.dark,
    opacity: 0.6,
  },
  overview_time: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 17,
    color: Colors.dark,
    opacity: 0.6,
  },
  button_container: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: Colors.white,
  },
  shadow_style: {
    width: "100%",
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
});

export default SessionScreen;
