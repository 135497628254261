import { AxiosRequestConfig } from "axios";

export const notificationsUrls = {
  create: (token: string) =>
    ({
      url: "/notifications/token",
      method: "POST",
      data: {
        token,
        type: "expo"
      },
    } as AxiosRequestConfig),
};
