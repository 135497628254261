export enum SCREEN {
  Splash = "Splash",

  Main = "Main",
  DrawerNavigation = "DrawerNavigation",
  BottomTabNavigation = "BottomTabNavigation",

  SessionsTabs = "Therapy", // alias to Therapy
  DiarySummary = "SleepDiarySummary", // alias

  Relaxations = "Relaxations",
  Relaxation = "Relaxation",
  Library = "Library",
  Article = "Artice",
  Notes = "Notes",
  Note = "Note",
  Thoughts = "Thoughts",
  Thought = "Thought",
  Flashcards = "Flashcards",
  SleepReport = "SleepReport",
  SleepDiary = "SleepDiary",
  Habits = "Habits",
  Profile = "Profile",
  Settings = "Settings",
  NotificationsSettings = "NotificationsSettings",
  PasswordChange = "PasswordChange",
  LanguageSettings = "LanguageSettings",
  TimezoneSettings = "TimezoneSettings",
  SubscriptionSettings = "SubscriptionSettings",
  ContactAndHelp = "ContactAndHelp",
  IssueReport = "IssueReport",
  FAQ = "FAQ",
  Payments = "Payments",
  Onboarding = "Onbarding",
  Login = "Login",
  EmailPasswordLogin = "EmailPasswordLogin",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",
  SignUp = "SignUp",
  EmailPasswordSingUp = "EmailPasswordSignUp",
  EmailValidation = "EmailValidation",
  IntroSurvey = "IntroSurvey",
  Survey = "Survey",
  InsomniaChecker = "InsomniaChecker",

  Dashboard = "Dashboard",
  Therapy = "Therapy",
  SleepDiarySummary = "SleepDiarySummary",
  PatientCard = "PatientCard",
  More = "More",

  Sessions = "Sessions",
  DayPlan = "DayPlan",
  Session = "Session",

  TherapyProfile = "TherapyProfile",
  InsomniaMap = "InsomniaMap",
  Beliefs = "Beliefs",

  ThankYou = "ThankYou",
}
