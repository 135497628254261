import React from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import Modal from "react-native-modal";
import Close from "../../../components/Close";
import { Colors } from "../../../constants/colors";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (code: string) => void;
  placeholder?: string;
  text?: string;
  buttonText: string;
}

function PromoCodeModal(props: Props) {
  const [promoCode, setPromoCode] = React.useState<string>("");

  return (
    <Modal isVisible={props.isVisible}>
      <View style={styles.modalView}>
        <View style={{ position: "absolute", right: 10, top: 10 }}>
          <Close onPress={props.onClose} />
        </View>
        <Text style={{ paddingVertical: 20 }}>{props.text}</Text>
        <TextInput
          style={styles.input}
          onChangeText={(text) => setPromoCode(text)}
          value={promoCode}
          placeholder={props.placeholder}
        />
        <View style={{ paddingTop: 20 }}>
          <Pressable onPress={() => props.onSave(promoCode)} hitSlop={10}>
            <Text style={styles.addPromoCode}>{props.buttonText}</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  addPromoCode: {
    color: Colors.primary,
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 22,
    textAlign: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  input: {
    width: "100%",
    padding: 5,
    height: 40,
    borderRadius: 4,
    backgroundColor: Colors.lightgray,
    fontSize: 18,
    lineHeight: 21,
  },
});

export default PromoCodeModal;
