import { FC } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { Colors } from "../../constants/colors";
import { Choice } from "../../types/v2/questionTemplate";
import CustomIcon from "../CustomIcon";

interface Props {
  choices: Choice<number>[];
  value: number | null;
  fullillmentThreshold?: number;
  isNegative?: boolean;
  onChange: (value: number) => void;
  readOnly?: boolean;
}

function calcFill(
  value: number,
  fulfillmentThreshold?: number,
  isNegative?: boolean
) {
  if (fulfillmentThreshold == undefined) {
    return 0;
  }
  const numerator = isNegative
    ? Math.max(0, fulfillmentThreshold - value)
    : Math.min(fulfillmentThreshold, value);
  return (numerator / fulfillmentThreshold) * 100;
}

const CirclePicker: FC<Props> = (props) => {
  const handlePress = (action: "increase" | "decrease") => {
    if (props.readOnly) {
      return;
    }

    const currentIdx = Math.max(
      props.choices.findIndex((choice) => choice.value === props.value),
      0
    );
    const maxIdx = props.choices.length - 1;
    const minIdx = 0;

    let newIdx = currentIdx;
    if (action == "increase" && currentIdx < maxIdx) {
      newIdx++;
    } else if (action == "decrease" && currentIdx > minIdx) {
      newIdx--;
    }
    props.onChange(props.choices[newIdx].value);
  };

  const fill = calcFill(
    props.value || 0,
    props.fullillmentThreshold,
    props.isNegative
  );

  const label = props.choices.find(
    (choice) => choice.value === props.value
  )?.label;

  return (
    <View style={styles.container}>
      {!props.readOnly && (
        <Pressable
          style={styles.button}
          onPress={() => handlePress("decrease")}
          hitSlop={20}
        >
          <CustomIcon
            name="community:minus"
            size={20}
            color={Colors.darkgray}
          />
        </Pressable>
      )}
      <View style={styles.score}>
        <AnimatedCircularProgress
          width={20}
          size={40}
          fill={fill}
          rotation={0}
          tintColor={"#59b6b381"}
          backgroundColor={Colors.lightgray}
        />
        <View style={styles.scoreAbsolute}>
          {props.readOnly && fill >= 100 ? (
            <CustomIcon
              name="community:check-bold"
              size={14}
              color={Colors.white}
            />
          ) : (
            <Text style={styles.scoreText}>{label || 0}</Text>
          )}
        </View>
      </View>
      {!props.readOnly && (
        <Pressable
          style={styles.button}
          onPress={() => handlePress("increase")}
          hitSlop={20}
        >
          <CustomIcon name="community:plus" size={20} color={Colors.darkgray} />
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  score: {
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  scoreAbsolute: {
    position: "absolute",
    zIndex: 11,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  scoreText: {
    fontSize: 14,
    lineHeight: 30,
    color: Colors.dark,
  },
});

export default CirclePicker;
