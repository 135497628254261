import { StackActionType, useLinkProps } from "@react-navigation/native";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import { FC } from "react";
import { Text, TextProps } from "react-native";
import { Colors } from "../../constants/colors";
import { RootStackParamList } from "../../navigation/StackNavigation.types";

interface Props extends TextProps {
  to: To<RootStackParamList>;
  action?: StackActionType;
}

const Link: FC<Props> = ({ to, action, style, children, ...rest }) => {
  const { onPress, ...props } = useLinkProps({
    to,
    action,
  });

  return (
    <Text
      style={[{ paddingVertical: 4, color: Colors.primary }, style]}
      onPress={onPress}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Link;
