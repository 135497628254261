import { FC } from "react";
import { MultiChoiceQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import ChoiceList from "../inputs/ChoiceList";
import QuestionWrapper from "./QuestionWrapper";

const MultiChoice: FC<QuestionProps<string[], MultiChoiceQuestion>> = ({
  onChange,
  question,
  readOnly,
}) => {
  const {
    template: { choices, content, subcontent, max_answers },
    answer: value,
  } = question;

  return (
    <QuestionWrapper
      content={content || undefined}
      subcontent={subcontent || undefined}
    >
      <ChoiceList
        choices={choices}
        onChange={onChange}
        value={value === null ? [] : value}
        readOnly={readOnly}
        maxChoices={max_answers || undefined}
        multi
      />
    </QuestionWrapper>
  );
};

export default MultiChoice;
