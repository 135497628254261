import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCalendars, getLocales } from "expo-localization";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { TherapyParticipant } from "../../../types/v2/user";
import { createGuestAccount, isGuest } from "../../../utils/auth";
import {
  removeData,
  retrieveData,
  retrieveObject,
  storeObject,
} from "../../storage";
import { AuthState, StoredAuthState, STORE_KEY } from "../auth";
import {
  STORE_KEY as USER_STORE_KEY,
  TherapyParticipantActions,
} from "../user";
import { getAppConfiguration } from "./configuration";
import { fetchManifest } from "./fetchManifest";
import * as Sentry from "../../../utils/sentry";
import { AxiosError } from "axios";

export const restoreAuthState = createAsyncThunk<AuthState, void, IThunkApi>(
  "auth/restoreAuthState",
  async (_, thunkApi) => {
    await thunkApi.dispatch(getAppConfiguration());
    const { token, loginType } = (await retrieveObject(
      STORE_KEY
    )) as StoredAuthState;

    if (token === undefined) {
      const language = getLocales()[0]?.languageCode || "pl";
      const timezone = getCalendars()[0].timeZone || "Europe/Warsaw";
      const { access_token: guestToken } = await createGuestAccount(
        language,
        timezone
      );
      await storeObject(STORE_KEY, { token });
      thunkApi.dispatch(fetchManifest({ language, token }));

      return {
        token: guestToken,
        isLoaded: true,
        isLoggedIn: false,
      };
    }

    if (!isGuest(token)) {
      try {
        const pushToken = await retrieveData("push_token");
        if (pushToken !== null) {
          await call(
            authorize(ENDPOINTS.notifications.create(pushToken), token)
          );
        }
      } catch (error: any) {
        console.warn((error as AxiosError).response?.data);
        Sentry.captureException(error);
      }

      try {
        const { data } = await call<TherapyParticipant>(
          authorize(ENDPOINTS.user.ME, token)
        );

        storeObject(USER_STORE_KEY, data);

        thunkApi.dispatch(TherapyParticipantActions.update(data));
      } catch (error: any) {
        await removeData(STORE_KEY);
        await removeData(USER_STORE_KEY);
        const timezone = getCalendars()[0].timeZone;
        const deviceLanguage = getLocales()[0]?.languageCode || "pl";
        const { access_token: token } = await createGuestAccount(
          deviceLanguage,
          timezone || "Europe/Warsaw"
        );
        return {
          token,
          isLoaded: true,
          isLoggedIn: false,
        };
      }
    }

    const isLoaded = true;
    const isLoggedIn = token === undefined ? false : !isGuest(token);
    return {
      loginType,
      token,
      isLoaded,
      isLoggedIn,
    };
  }
);

export const logout = createAsyncThunk<AuthState, undefined, IThunkApi>(
  "auth/logout",
  async (_, thunkApi) => {
    const {
      manifest: { language },
    } = thunkApi.getState();

    await removeData(STORE_KEY);
    await removeData(USER_STORE_KEY);
    const timezone = getCalendars()[0].timeZone || "Europe/Warsaw";
    const deviceLanguage = getLocales()[0]?.languageCode || "pl";
    const { access_token: token } = await createGuestAccount(
      language || deviceLanguage,
      timezone
    );
    return {
      token,
      isLoaded: true,
      isLoggedIn: false,
    };
  }
);

