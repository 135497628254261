import { logout } from "../store/redux/thunk_functions/auth";
import { resetAll } from "../store/redux/thunk_functions/other";
import { useAppDispatch } from "./redux";

export function useLogout() {
  const dispatch = useAppDispatch();

  const _logout = async () => {
    await dispatch(logout());
    await dispatch(resetAll());
  };

  return _logout;
}
