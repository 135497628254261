import { FC } from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";

interface IProps {
  label?: string;
  onPress?: () => void;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  color?: string;
  small?: boolean;
  reverse?: boolean;
}

const PrimaryButton: FC<IProps> = ({
  label,
  onPress,
  disabled = false,
  loading = false,
  icon,
  color,
  small = false,
  reverse = false,
}) => {
  return (
    <View
      style={[
        styles.container,
        disabled && { backgroundColor: Colors.gray },
        loading && { backgroundColor: Colors.primary_light },
        Boolean(color) && { backgroundColor: color },
        reverse && { backgroundColor: Colors.white },
      ]}
    >
      {loading ? (
        <ActivityIndicator
          color={Colors.white}
          style={{ paddingVertical: 13 }}
          size={small ? "small" : "large"}
        />
      ) : (
        <Pressable
          onPress={onPress}
          disabled={disabled}
          style={[
            styles.pressArea,
            small && styles.buttonSmall,
            Boolean(icon) && { justifyContent: "flex-start" },
          ]}
          android_ripple={{ color: Colors.primary_dark }}
        >
          {icon && <CustomIcon name={icon} size={22} style={styles.icon} />}
          <Text
            style={[
              styles.text,
              small && styles.textSmall,
              reverse && { color: Colors.primary },
            ]}
          >
            {label}
          </Text>
        </Pressable>
      )}
    </View>
  );
};

export default PrimaryButton;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: Colors.primary,
    borderRadius: 100,
    overflow: "hidden",
    marginVertical: 5,
  },
  pressArea: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  buttonSmall: {
    paddingHorizontal: 0,
  },
  text: {
    textAlign: "center",
    color: Colors.white,
    fontSize: 18,
    paddingVertical: 17,
    fontWeight: "700",
    lineHeight: 28,
  },
  textSmall: {
    fontSize: 16,
    paddingVertical: 12,
    lineHeight: 22,
  },
  icon: {
    color: Colors.white,
    marginRight: 15,
  },
});
