import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Choice } from "../../types/v2/questionTemplate";
import ChoiceButton from "./ChoiceButton";

interface Props {
  choices: Choice<boolean>[];
  value: boolean | null;
  onChange: (value: boolean) => void;
  readOnly?: boolean;
}

const BooleanPicker: FC<Props> = (props) => {
  const handlePress = (pressedValue: boolean) => {
    if (props.readOnly) {
      return;
    }
    props.onChange(pressedValue);
  };

  return (
    <View style={styles.container}>
      {props.choices.slice(0, 2).map((choice) => (
        <View style={styles.buttonContainer} key={choice.value.toString()}>
          <ChoiceButton
            text={choice.label}
            selected={props.value == choice.value}
            onPress={() => handlePress(choice.value)}
            center
          />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonContainer: {
    width: "45%",
  },
});

export default BooleanPicker;
