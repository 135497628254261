import { useEffect, useState } from "react";
import { BackHandler, Platform, ScrollView, Text, View } from "react-native";
import Toast from "react-native-root-toast";
import { ENDPOINTS } from "../../api/config";
import GoBack from "../../components/GoBack";
import SignupAgreementPopup from "../../components/signup_agreement_popup";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import CustomText from "../../components/typography/text";
import AuthButton from "../../components/utils/auth_button";
import Container from "../../components/utils/container";
import { useApple, useFacebook, useGoogle } from "../../hooks/providers";
import { useAppDispatch } from "../../hooks/redux";
import { useCall } from "../../hooks/useAuthorizedCall";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { EAuthType } from "../../types/auth";
import { Token } from "../../types/v2/token";
import { iOSDevice, isSafari } from "../../utils/browser";

const SignUpScreen = ({ navigation }: RootStackScreenProps<SCREEN.SignUp>) => {
  const {
    auth_signup_cta,
    auth_signup_subheader,
    auth_signup_content,
    auth_signup_google,
    auth_signup_facebook,
    auth_signup_apple,
    auth_signup_email,
    or_txt,
  } = useTranslations();
  const translations = useTranslations();
  const [popupVisible, setPopupVisible] = useState(false);
  const [registerType, setRegisterType] = useState<EAuthType>(EAuthType.EMAIL);
  const call = useCall();
  const handleError = (error: any) => {
    let msg = error.message;
    if (msg === undefined) {
      const messageKey =
        error?.response?.data?.detail?.message || "unknown_error";
      let msg = translations[messageKey];
    }
    Toast.show(msg);
  };

  const [googleSignupDisabled, googleSignup] = useGoogle(
    (idToken: string) => call<Token>(ENDPOINTS.user.GOOGLE_SIGNUP(idToken)),
    handleError
  );
  const [facebookSignupDisabled, facebookSignup] = useFacebook(
    (accessToken: string) => call<Token>(ENDPOINTS.user.FB_SIGNUP(accessToken)),
    handleError
  );
  const appleSignup = useApple(
    (idToken: string, name?: string) =>
      call<Token>(ENDPOINTS.user.APPLE_SIGNUP(idToken, name)),
    handleError
  );

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", moveBack);
    return () => {
      BackHandler.removeEventListener("hardwareBackPress", moveBack);
    };
  });

  const moveBack = () => {
    navigation.goBack();
    return true;
  };

  const register = () => {
    setPopupVisible(false);

    switch (registerType) {
      case EAuthType.FACEBOOK:
        facebookSignup();
        break;
      case EAuthType.GOOGLE:
        googleSignup();
        break;
      case EAuthType.APPLE:
        appleSignup();
        break;
      default:
        break;
    }
  };

  const togglePopup = (type: EAuthType) => {
    setPopupVisible(true);
    setRegisterType(type);
  };

  return (
    <ScrollView>
      <Container>
        <GoBack />
        <MainHeading text={auth_signup_cta} size="large" />
        <View style={{ marginTop: 10 }}>
          <SubHeading text={auth_signup_subheader} />
        </View>
        <View style={{ marginTop: 10 }}>
          <CustomText text={auth_signup_content} />
        </View>
        <View style={{ marginTop: 20 }}>
          {!iOSDevice ? (
            <>
              <AuthButton
                disabled={googleSignupDisabled}
                text={auth_signup_google}
                type={EAuthType.GOOGLE}
                onPress={togglePopup.bind(this, EAuthType.GOOGLE)}
              />
              <AuthButton
                text={auth_signup_facebook}
                type={EAuthType.FACEBOOK}
                onPress={togglePopup.bind(this, EAuthType.FACEBOOK)}
              />
            </>
          ) : null}
          {Platform.OS === "ios" ? (
            <AuthButton
              text={auth_signup_apple}
              type={EAuthType.APPLE}
              onPress={togglePopup.bind(this, EAuthType.APPLE)}
            />
          ) : null}
          <Text style={{ textAlign: "center", marginVertical: 20 }}>
            {or_txt}
          </Text>
          <AuthButton
            text={auth_signup_email}
            type={EAuthType.EMAIL}
            onPress={() => navigation.navigate(SCREEN.EmailPasswordSingUp)}
          />
        </View>

        <SignupAgreementPopup
          visible={popupVisible}
          setPopupVisible={setPopupVisible}
          action={register}
        />
      </Container>
    </ScrollView>
  );
};

export default SignUpScreen;
