import { FC } from "react";
import { StyleSheet, Text } from "react-native";
import { Colors } from "../../constants/colors";

interface IProps {
  text: string;
}

const SubHeading: FC<IProps> = ({ text }) => {
  return <Text style={styles.text}>{text}</Text>;
};

export default SubHeading;

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    lineHeight: 21,
    color: Colors.dark,
    fontWeight: "bold",
  },
});
