import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Platform } from "react-native";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { Checkout } from "../../../types/checkout";
import { IThunkApi } from "../../../types/redux";
import { Plan } from "../../../types/v2/payments";

export const getPlans = createAsyncThunk<Plan[], string | undefined, IThunkApi>(
  "payments/getPlans",
  async (promoCode, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call<Plan[]>(
      authorize(ENDPOINTS.payments.GET_PLANS(promoCode), token)
    );

    return data;
  }
);

export const addPromoCode = createAsyncThunk<boolean, string, IThunkApi>(
  "payments/addPromoCode",
  async (code, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();

    let isFreeAccess = false;
    try {
      const { data } = await call(
        authorize(ENDPOINTS.payments.VERIFY_PROMO_CODE(code), token)
      );
      isFreeAccess = data.type === "free_access";
    } catch (error: any) {
      const axiosError = error as AxiosError;
      return false;
    }

    if (Platform.OS === "ios" && !isFreeAccess) {
      return false;
    }

    const plans = await thunkApi.dispatch(getPlans(code));
    return true;
  }
);

interface CheckoutArgs {
  planUuid: string;
  promoCode?: string;
}

export const checkout = createAsyncThunk<Checkout, CheckoutArgs, IThunkApi>(
  "payments/checkout",
  async (args, thunkApi) => {
    const { planUuid, promoCode } = args;
    const {
      auth: { token },
    } = thunkApi.getState();

    const { data } = await call(
      authorize(ENDPOINTS.payments.CHECKOUT(planUuid, promoCode), token)
    );
    return data;
  }
);
