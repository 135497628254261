import { FC } from "react";
import {
  BooleanQuestion,
  CheckboxQuestion,
  DateQuestion,
  HabitQuestion,
  IntegerQuestion,
  IntensityQuestion,
  MultiChoiceQuestion,
  QualityQuestion,
  SingleChoiceQuestion,
  SleepRestrictionsQuestion,
  SliderQuestion,
  TextAreaQuestion,
  TimeQuestion,
} from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import { SleepRestrictions as SleepRestrictionsType } from "../../types/v2/questions";
import Boolean from "../questions/Boolean";
import Checkbox from "../questions/Checkbox";
import Date from "../questions/Date";
import Habit from "../questions/Habit";
import Integer from "../questions/Integer";
import Intensity from "../questions/Intensity";
import MultiChoice from "../questions/MultiChoice";
import SingleChoice from "../questions/SingleChoice";
import Slider from "../questions/Slider";
import TextArea from "../questions/TextArea";
import Time from "../questions/Time";
import SleepRestrictions from "../questions/SleepRestrictions";
import Quality from "../questions/Quality";

interface Props extends QuestionProps {
  translations: { [key: string]: string };
}

const Question: FC<Props> = (props) => {
  const { translations } = props;
  switch (props.question.type) {
    case "single_choice":
      return <SingleChoice
        {...(props as QuestionProps<string, SingleChoiceQuestion>)}
      />;
    case "multi_choice":
      return <MultiChoice
        {...(props as QuestionProps<string[], MultiChoiceQuestion>)}
      />;
    case "intensity_picker":
      return <Intensity {...(props as QuestionProps<number, IntensityQuestion>)} />;
    case "boolean":
      return <Boolean {...(props as QuestionProps<boolean, BooleanQuestion>)} />;
    case "slider":
      return <Slider {...(props as QuestionProps<number, SliderQuestion>)} />;
    case "habit":
      return <Habit {...(props as QuestionProps<number, HabitQuestion>)} />;
    case "time_picker":
      return <Time {...(props as QuestionProps<string, TimeQuestion>)} />;
    case "date_picker":
      return <Date
        {...(props as QuestionProps<string, DateQuestion>)}
        labelSave={translations.save}
        locale="pl" // TODO
      />;
    case "integer_picker":
      return <Integer {...(props as QuestionProps<number, IntegerQuestion>)} />;
    case "text_area":
      return <TextArea {...(props as QuestionProps<string, TextAreaQuestion>)} />;
    case "checkbox":
      return <Checkbox {...(props as QuestionProps<boolean, CheckboxQuestion>)} />;
    case "sleep_restrictions_picker":
      return <SleepRestrictions
        {...(props as QuestionProps<
          SleepRestrictionsType,
          SleepRestrictionsQuestion
        >)}
        labelFromBedContent={translations.sleep_restrictions_from_bed_content}
        labelFromBedSubcontent={
          translations.sleep_restrictions_from_bed_subcontent
        }
        labelToBedContent={translations.sleep_restrictions_to_bed_content}
        labelToBedSubcontent={translations.sleep_restrictions_to_bed_subcontent}
        labelTooLargeDeviation={
          translations.sleep_restrictions_too_large_deviation
        }
      />;
    case "quality_picker":
      return <Quality {...(props as QuestionProps<string, QualityQuestion>)} />;
    default:
      return null;
  }
};

export default Question;
