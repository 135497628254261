import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./auth";
import { configurationReducer } from "./configuration";
import { dashboardReducer } from "./dashboard";
import { dayPlayReducer } from "./dayPlan";
import { flashcardsReducer } from "./flashcards";
import { libraryReducer } from "./library";
import manifestSlice from "./manifest";
import { notesReducer } from "./notes";
import { onboardingReducer } from "./onboarding";
import { paymentsReducer } from "./payments";
import { relaxationsReducer } from "./relaxations";
import { sleepDiarySummaryReducer } from "./sleepDiarySummary";
import { surveyReducer } from "./survey";
import { therapyReducer } from "./therapy";
import { therapyFolderReducer } from "./therapyFolder";
import { thoughtsReducer } from "./thoughts";
import uiReducer from "./ui";
import { therapyParticipantReducer } from "./user";

export const store = configureStore({
  reducer: {
    therapy: therapyReducer,
    manifest: manifestSlice,
    onboarding: onboardingReducer,
    therapyParticipant: therapyParticipantReducer,
    therapyFolder: therapyFolderReducer,
    dashboard: dashboardReducer,
    payments: paymentsReducer,
    ui: uiReducer,
    library: libraryReducer,
    configuration: configurationReducer,
    survey: surveyReducer,
    flashcards: flashcardsReducer,
    relaxations: relaxationsReducer,
    auth: authReducer,
    notes: notesReducer,
    thoughts: thoughtsReducer,
    sleepDiarySummary: sleepDiarySummaryReducer,
    dayPlan: dayPlayReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
