import { Pressable } from "react-native";
import { Colors } from "../constants/colors";
import CustomIcon from "./CustomIcon";

interface Props {
  onPress: () => void;
}

export function Close(props: Props) {
  return (
    <Pressable
      hitSlop={10}
      android_ripple={{ radius: 20 }}
      onPress={props.onPress}
    >
      <CustomIcon name="close" size={38} color={Colors.primary} />
    </Pressable>
  );
}

export default Close;
