import { FC } from "react";
import { Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { Article as ArticleProps } from "../../types/v2/article";
import Article from "../Articles/Article";
import CarouselBlueprint from "../carousel_blueprint/carousel_blueprint";

interface Props {
  text?: string;
  articles: ArticleProps[];
}

const Articles: FC<Props> = ({ articles, text }) => {
  return (
    <View>
      {text !== undefined && (
        <View style={{ marginBottom: 20 }}>
          <Text
            style={{
              fontSize: 18,
              lineHeight: 26,
              color: Colors.primary,
              fontWeight: "700",
            }}
          >
            {text}
          </Text>
        </View>
      )}
      <CarouselBlueprint
        data={articles}
        height={350}
        Element={Article}
        text=""
      />
    </View>
  );
};

export default Articles;
