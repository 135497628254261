import React, { FC } from "react";
import { IFlashCard } from "../../types/survey";
import CarouselBlueprint from "../carousel_blueprint/carousel_blueprint";
import Flashcard from "./Flashcard";

interface IProps {
  category?: string;
  flashcards: IFlashCard[];
}

const FlashcardsCarousel: FC<IProps> = ({ flashcards, category }) => {
  return (
    <CarouselBlueprint
      data={flashcards}
      height={400}
      Element={Flashcard}
      text={category || ""}
    />
  );
};

export default FlashcardsCarousel;
