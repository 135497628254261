import { FC } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { Colors } from "../../constants/colors";
import { useTranslations } from "../../hooks/useTranslations";
import { RootState } from "../../store/redux";
import CustomMarkdown from "../utils/markdown";

export interface IPaymentPlanCard {
  uuid: string;
  tag: string;
  display_name: string;
  display_name_annotations: string;
  description: string;
  original_price_value: number;
  price_value: number | string;
  currency_symbol: string;
  recommended: boolean;
  recommended_text: string;
  features: {
    name: string;
    description: string;
  }[];
  display: boolean;
  buyable: boolean;
  payment_link: string | null;
}

function hasPromo(
  originalPrice: number,
  currentPrice: number | string
): boolean {
  if (typeof currentPrice == "number") {
    return currentPrice < originalPrice;
  }
  const [value, currency] = currentPrice.split(" ");
  return Math.floor(parseFloat(value)) < originalPrice;
}

const PaymentPlanCard: FC<IPaymentPlanCard> = ({
  description,
  display_name,
  display_name_annotations,
  features,
  price_value,
  original_price_value,
  currency_symbol,
  recommended,
  recommended_text,
}) => {
  const translations = useTranslations();
  const isPromo = hasPromo(original_price_value, price_value);
  return (
    <View style={[styles.container]}>
      <View
        style={[
          styles.inner_container,
          recommended && { backgroundColor: Colors.featured_color },
        ]}
      >
        <View
          style={{ paddingTop: 40, paddingHorizontal: 20, paddingBottom: 20 }}
        >
          <Text style={styles.title}>
            {display_name}
            {display_name_annotations && (
              <Text style={{ fontSize: 15 }}> {display_name_annotations}</Text>
            )}
          </Text>
          <View style={{ marginTop: -6 }}>
            <CustomMarkdown text={description} />
          </View>
          {isPromo && (
            <Text
              style={{
                textDecorationLine: "line-through",
                color: Colors.darkgray,
              }}
            >
              {original_price_value.toFixed(2)}
              {currency_symbol}
            </Text>
          )}
          {Platform.OS == "ios" ? (
            <Text style={styles.price}>{price_value}</Text>
          ) : (
            <Text style={styles.price}>
              {(price_value as number).toFixed(2)}
              {currency_symbol}
            </Text>
          )}
        </View>
        <View style={styles.line}></View>
        <View
          style={{ paddingTop: 20, paddingHorizontal: 20, paddingBottom: 60 }}
        >
          {features.map((f) => (
            <View style={styles.feature_container} key={f.name}>
              <Text style={styles.feature_name}>{f.name}</Text>
              <Text style={styles.feature_description}>{f.description}</Text>
            </View>
          ))}
        </View>
        {recommended && (
          <View style={styles.featured_box}>
            <Text style={styles.feature_text}>
              {translations[recommended_text]}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default PaymentPlanCard;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 15,
    flex: 1,
  },
  inner_container: {
    backgroundColor: Colors.lightgray,
    borderRadius: 8,
    position: "relative",
    overflow: "hidden",
    flex: 1,
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.primary_transparent,
  },
  title: {
    fontSize: 30,
    lineHeight: 32,
    fontWeight: "700",
    color: Colors.primary,
  },
  price: {
    fontSize: 30,
    lineHeight: 32,
    color: Colors.dark,
    fontWeight: "300",
    marginTop: 7,
  },
  feature_container: {
    marginVertical: 10,
  },
  feature_name: {
    fontWeight: "700",
    color: Colors.dark,
    lineHeight: 18,
    fontSize: 14,
  },
  feature_description: {
    color: Colors.dark,
    lineHeight: 18,
    fontSize: 12,
  },
  featured_box: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 30,
    backgroundColor: Colors.primary_transparent,
    alignItems: "center",
    justifyContent: "center",
  },
  feature_text: {
    textAlign: "center",
    fontSize: 11,
    fontWeight: "700",
    color: Colors.primary,
  },
});
