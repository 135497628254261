import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Plan } from "../../types/v2/payments";
import { getPlans } from "./thunk_functions/payments";

interface PaymentsState {
  needRefresh: boolean;
  loading: boolean;
  plans: Plan[];
  processing: boolean;
  promoCode?: string;
}

const initialState: PaymentsState = {
  needRefresh: true,
  loading: false,
  plans: [],
  processing: false,
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState: initialState,
  reducers: {
    setPromoCode: (state, action: PayloadAction<string | undefined>) => {
      state.promoCode = action.payload;
    },
    setProcessing: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        processing: action.payload,
      };
    },
    setPlans: (state, action: PayloadAction<Plan[]>) => {
      return {
        ...state,
        plans: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.needRefresh = false;
        state.plans = action.payload;
      });
  },
});

export const PaymentActions = paymentsSlice.actions;
export const paymentsReducer = paymentsSlice.reducer;
