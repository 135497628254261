import { useState } from "react";
import { ENDPOINTS } from "../api/config";
import { CheckoutSessionSummary } from "../types/checkout";
import { sendAnalyticsEventAsync } from "../utils/analytics";
import { useCall } from "./useAuthorizedCall";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function useLogPurchase(
  checkoutSessionId?: string
): [() => Promise<void>, () => void] {
  const call = useCall();

  let attempts = 0;
  let subscribed = true;

  const unsubscribe = () => (subscribed = false);
  const logPurchase = async () => {
    console.debug("logPurchase", {
      checkoutSessionId,
    });
    while (checkoutSessionId !== undefined && subscribed && attempts < 10) {
      try {
        const {
          coupon,
          currency,
          discount,
          plan_id,
          plan_name,
          plan_price,
          transaction_id,
          value,
        } = await call<CheckoutSessionSummary>(
          ENDPOINTS.payments.GET_CHECKOUT_SESSION(checkoutSessionId)
        );

        const payload = {
          transaction_id,
          value,
          currency,
          coupon,
          items: [
            {
              item_id: plan_id,
              item_name: plan_name,
              coupon,
              discount,
              price: plan_price,
              quantity: 1,
            },
          ],
        };

        await sendAnalyticsEventAsync("purchase", payload);
        subscribed = false;
      } catch (error) {
        attempts += 1;
        await sleep(1000);
      }
    }
  };

  return [logPurchase, unsubscribe];
}
