import { createElement } from "react-native";
import { Colors } from "../../constants/colors";

interface Props {
  onChange: (time: string) => void;
  value: string | null;
  readOnly?: boolean;
  defaultValue?: string;
}

const TimePicker = (props: Props) => {
  const { defaultValue, value, onChange, readOnly } = props;
  return (
    <>
      {createElement("input", {
        value: value || defaultValue,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value),
        disabled: readOnly,
        type: "time",
        step: 300,
        style: {
          fontFamily: "arial",
          fontSize: 14,
          borderRadius: 4,
          borderWidth: 0,
          backgroundColor: Colors.lightgray,
          height: 40,
          lineHeight: 18,
        },
      })}
    </>
  );
};

export default TimePicker;
