import { createSlice } from "@reduxjs/toolkit";
import { DayPlanItem } from "../../types/v2/dayPlan";
import { getDayPlan } from "./thunk_functions/dayPlan";

interface DayPlanState {
  needRefresh: boolean;
  loading: boolean;
  items: DayPlanItem[];
}

const initialState: DayPlanState = {
  needRefresh: true,
  loading: false,
  items: [],
};

const dayPlanSlice = createSlice({
  name: "dayPlan",
  initialState: initialState,
  reducers: {
    markAsStale(state) {
      state.needRefresh = true;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDayPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDayPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.needRefresh = false;
        state.items = action.payload.items;
      });
  },
});

export const DayPlanActions = dayPlanSlice.actions;
export const dayPlayReducer = dayPlanSlice.reducer;
