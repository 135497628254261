import { add, compareAsc, sub } from "date-fns";
import { R } from "react-native-shadow-2";
import { EContentTypes, EQuestionType } from "../types/survey";
import { Question, SleepRestrictionsQuestion } from "../types/v2/questions";
import { Slide } from "../types/v2/slides/slide";
import * as SlideContent from "../types/v2/slides/slide_content";
import { parseTimeStr } from "./dates";
import { getAnswers } from "./slides";

function validatePrecautions(answers: { [key: string]: any }): boolean {
  return Object.values(answers).every((ans) => ans !== null && ans === true);
}

function validateAllAnswered(answers: { [key: string]: any }): boolean {
  return Object.values(answers).every((ans) => ans !== null);
}

const questions = {
  fromBed: "a4cb9058-7ba5-47b3-b11e-32f0561edc75",
  toBed: "d6290d12-c064-4b39-aea9-a28cc5a7cef4",
  fallAsleep: "dd76c668-db2e-47c1-a27b-1258d0d11c26",
  awakenings: "48d5bd34-ee46-4ef9-9722-e06188ef3996",
  numberOfAwakenings: "cf9a95d9-b585-439b-83d4-92200c4d036a",
  inBedAfterWakeUp: "bcdefd9f-7f9d-4742-8a36-19a86ad4d58a",
};

function validateSleepWindow(answers: { [key: string]: any }): boolean {
  const fromBedStr = answers[questions.fromBed];
  const toBedStr = answers[questions.toBed];
  const fallAsleep = answers[questions.fallAsleep];
  const awakenings = answers[questions.awakenings];
  const numberOfAwakenings = answers[questions.numberOfAwakenings];
  const inBedAfterWakeUp = answers[questions.inBedAfterWakeUp];

  const slideAnswers = [
    fromBedStr,
    toBedStr,
    fallAsleep,
    numberOfAwakenings,
    inBedAfterWakeUp,
  ];

  if (slideAnswers.some((answer) => answer !== null && answer !== undefined)) {
    if (slideAnswers.some((answer) => answer === null)) {
      return false;
    }
    if (fromBedStr === toBedStr) {
      return false;
    }

    if (numberOfAwakenings > 0 && awakenings === null) {
      return false;
    }

    const fromBed = parseTimeStr(fromBedStr);
    let toBed: Date;
    if (toBedStr > fromBedStr) {
      toBed = sub(parseTimeStr(toBedStr), { days: 1 });
    } else {
      toBed = parseTimeStr(toBedStr);
    }
    const sleeplessTime: Duration = {
      minutes:
        fallAsleep +
        inBedAfterWakeUp +
        (numberOfAwakenings > 0 ? awakenings : 0),
    };

    if (compareAsc(add(toBed, sleeplessTime), fromBed) > -1) {
      return false;
    }
  }

  return true;
}

const validators: { [tag: string]: (answers: any) => boolean } = {
  sleep_window: validateSleepWindow,
  precautions: validatePrecautions,
  your_goals_evaluation: validateAllAnswered
};

export function hasAnswer(question: Question): boolean {
  if (question.type === EQuestionType.SLEEP_RESTRICTIONS_PICKER) {
    const sr = question as SleepRestrictionsQuestion;
    return sr.answer.from_bed !== null && sr.answer.to_bed !== null;
  }
  return question.answer !== null;
}

export function validateAnswers(slide: Slide): boolean {
  for (let item of slide.content) {
    if (item.type === EContentTypes.QUESTION) {
      const question = (item as SlideContent.Question).question;
      if (question.required && !hasAnswer(question)) {
        return false;
      }
    }
  }

  const additionalValidator = validators[slide.template.tag];
  if (additionalValidator !== undefined) {
    return additionalValidator(getAnswers(slide.content));
  }

  return true;
}
