import { FC } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";
import GoBack from "../GoBack";

interface Props {
  onClose?: () => void;
  onPrevious?: () => void;
}

const TopControls: FC<Props> = ({ onClose, onPrevious }) => {
  return (
    <View style={styles.container}>
      {onClose !== undefined && (
        <Pressable style={styles.control} onPress={onClose}>
          <CustomIcon name="close" color={Colors.primary} size={38} />
        </Pressable>
      )}
      {onPrevious !== undefined && <GoBack onPress={onPrevious} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  control: {
    paddingVertical: 2,
    marginBottom: 15,
  },
});

export default TopControls;
