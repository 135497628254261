import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import CustomSwitch from "./custom_switch";

interface IProps {
  name: string;
  text?: string;
  value: boolean;
  setValue: (arg: boolean) => void;
  bold?: boolean;
  required?: boolean;
  showMore?: boolean;
}

const Switch: FC<IProps> = ({
  name,
  text,
  value,
  setValue,
  bold = false,
  required = false,
  showMore = false,
}) => {
  return (
    <View style={styles.container}>
      {required && (
        <View style={styles.required_container}>
          <Text style={{ color: "#BF4A00" }}>*</Text>
        </View>
      )}
      <View style={styles.textContainer}>
        <Text style={styles.name}>{name}</Text>
        {Boolean(text && showMore) && (
          <Text style={[styles.text, bold && { fontWeight: "bold" }]}>
            {text}
          </Text>
        )}
      </View>
      <View
        style={{
          height: "100%",
        }}
      >
        <CustomSwitch value={value} setValue={setValue} />
      </View>
    </View>
  );
};

export default Switch;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    marginVertical: 10,
  },
  textContainer: {
    flex: 1,
  },
  name: {
    fontSize: 14,
    fontWeight: "700",
    paddingTop: 3,
    lineHeight: 22,
  },
  text: {
    fontSize: 10,
    fontWeight: "400",
    paddingTop: 3,
  },
  required_container: {
    width: 15,
    paddingTop: 4,
    alignItems: 'center'
  },
});
