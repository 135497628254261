import React, { FC } from "react";
import { StyleSheet, View } from "react-native";
import PlayPauseButton from "./PlayPauseButton";
import SeekBar from "./SeekBar";

interface Props {
  disabled?: boolean;
  isPaused?: boolean;
  position?: number;
  loading?: boolean;
  trackLength?: number;
  onPressPlayPause?: () => void;
  onSeek: (value: number) => void;
}

const AVControls: FC<Props> = ({
  disabled = false,
  isPaused = true,
  position = 0,
  loading = false,
  trackLength = 0,
  onPressPlayPause,
  onSeek,
}) => {
  return (
    <View style={styles.container}>
      <PlayPauseButton
        disabled={disabled}
        isPaused={isPaused}
        onPress={onPressPlayPause}
      />
      <SeekBar
        disabled={disabled}
        isLoading={loading}
        trackLength={trackLength}
        onSeek={onSeek}
        currentPosition={position}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
});

export default AVControls;
