import { FC } from "react";
import { View } from "react-native";
import Input from "../utils/input";

interface Props {
  value: string | null;
  onChange: (value: string) => void;
  readOnly?: boolean;
  placeholder?: string;
}

const TextAreaPicker: FC<Props> = ({
  value,
  onChange,
  readOnly,
  placeholder = "",
}) => {
  return (
    <View>
      <Input
        editable={!readOnly}
        placeholder={placeholder}
        setValue={onChange}
        value={value || ""}
        numberOfLines={10}
      />
    </View>
  );
};

export default TextAreaPicker;
