import { FC } from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { Colors } from "../../constants/colors";
import { useContainerWidth } from "../../hooks/useContainerWidth";
import { Choice } from "../../types/v2/questionTemplate";
import Slider from "../Slider";

interface Props {
  choices: Choice<number>[];
  value: number | null;
  onChange: (value: number) => void;
  readOnly?: boolean;
}

const SliderPicker: FC<Props> = ({ choices, value, onChange, readOnly }) => {
  const width = useContainerWidth();
  const choiceIdx = choices.findIndex((choice) => choice.value === value);
  const choice = choices[choiceIdx];
  const label = choice !== undefined ? choice.label : null;
  const valueLabel = choice !== undefined ? `${choice.value}%` : null;

  const handleChange = (idx: number) => {
    onChange(choices[idx].value);
  };

  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.valueLabel}>{valueLabel}</Text>
      </View>
      <Slider
        width={width - 60}
        steps={choices.length}
        startStep={0}
        value={choiceIdx > -1 ? choiceIdx : null}
        onChange={handleChange}
        disabled={readOnly}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 15,
  },
  labelContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  label: {
    fontSize: 14,
    color: Colors.dark,
  },
  valueLabel: {
    fontSize: 14,
    color: Colors.dark,
    fontWeight: "500",
  },
});

export default SliderPicker;
