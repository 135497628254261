import { FC } from "react";
import { TimeQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import TimePicker from "../inputs/TimePicker";
import QuestionWrapperInline from "./QuestionWrapperInline";

const Time: FC<QuestionProps<string, TimeQuestion>> = ({
  onChange,
  question,
  readOnly,
}) => {
  const {
    template: { content, subcontent },
    answer: value,
  } = question;

  return (
    <QuestionWrapperInline
      content={content || undefined}
      subcontent={subcontent || undefined}
    >
      <TimePicker onChange={onChange} value={value} readOnly={readOnly} />
    </QuestionWrapperInline>
  );
};

export default Time;
