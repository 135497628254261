import { FC, useState } from "react";
import {
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import SignupConsents from "../../components/signup_consents";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import CustomText from "../../components/typography/text";
import Divider from "../../components/utils/divider";
import Input from "../../components/utils/input";
import PrimaryButton from "../../components/utils/primary_button";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { useEmailPasswordSignup } from "../../hooks/auth";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { validateEmail } from "../../utils/email";

const EmailPasswordSignUpScreen: FC<
  RootStackScreenProps<SCREEN.EmailPasswordSingUp>
> = () => {
  const {
    auth_signup_cta,
    auth_signup_subheader,
    auth_signup_content,
    auth_signin_placeholder_email,
    auth_password_reset_placeholder_password,
    auth_signup_placeholder_name,
    auth_consent_show_content,
    password_requirements,
    auth_consent_hide_content,
    auth_name_required,
    auth_email_required,
    auth_password_required,
    auth_email_invalid_format,
  } = useAppSelector((state) => state.manifest.translations);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [userConsents, setUserConsents] = useState<{ [key: string]: boolean }>(
    {}
  );
  const signup = useEmailPasswordSignup();

  const handleSignUp = async () => {
    setLoading(true);
    let hasErrors = false;
    if (!name) {
      setNameError(auth_name_required);
      hasErrors = true;
    } else setNameError(null);
    if (!email) {
      setEmailError(auth_email_required);
      hasErrors = true;
    } else {
      if (!validateEmail(email)) setEmailError(auth_email_invalid_format);
      else setEmailError(null);
    }
    if (!password) {
      setPasswordError(auth_password_required);
      hasErrors = true;
    } else {
      setPasswordError(null);
    }

    if (hasErrors) {
      setLoading(false);
      return;
    }

    try {
      await signup(name, email, password, userConsents);
    } catch (error: any) {
      setPasswordError(error.message || "unknown_error");
    }

    setLoading(false);
  };

  return (
    <>
      <KeyboardAvoidingView style={{ flex: 1, height: "100%" }} enabled={true}>
        <ScrollView contentContainerStyle={{ flexGrow: 1, padding: 30 }}>
          <View style={[styles.container, { height: "100%" }]}>
            <View>
              <MainHeading text={auth_signup_cta} size="large" />
              <View style={{ marginTop: 10 }}>
                <SubHeading text={auth_signup_subheader} />
              </View>
              <Divider />
              <View style={{ marginTop: 10 }}>
                <CustomText text={auth_signup_content} />
              </View>
              <View style={{ marginTop: 20 }}>
                <Input
                  value={name}
                  setValue={setName}
                  placeholder={auth_signup_placeholder_name}
                  error={nameError}
                />
                <Input
                  value={email}
                  setValue={setEmail}
                  placeholder={auth_signin_placeholder_email}
                  error={emailError}
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect={false}
                />
                <Input
                  value={password}
                  setValue={setPassword}
                  placeholder={auth_password_reset_placeholder_password}
                  type="password"
                  postfix={password_requirements}
                  error={passwordError}
                />
              </View>
              <View style={{ marginTop: 22 }}>
                <SignupConsents
                  userConsents={userConsents}
                  setUserConsents={setUserConsents}
                  setIsValid={setDisabled}
                  setLoading={setLoading}
                  showMore={showMore}
                />
              </View>
            </View>
            <View>
              <Text
                style={styles.showMore}
                onPress={() => setShowMore(!showMore)}
              >
                {showMore
                  ? auth_consent_hide_content
                  : auth_consent_show_content}
              </Text>
            </View>
            <View style={{ marginTop: 2 }}>
              <PrimaryButton
                label={auth_signup_cta}
                onPress={handleSignUp}
                disabled={loading || disabled}
                loading={loading}
              />
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flex: 1,
  },
  link: {
    textDecorationLine: "underline",
    marginTop: 8,
  },
  showMore: {
    color: Colors.primary,
    fontSize: 11,
    fontWeight: "400",
    lineHeight: 22,
    textAlign: "center",
    paddingVertical: 8,
    paddingHorizontal: 15,
  },
});

export default EmailPasswordSignUpScreen;
