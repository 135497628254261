import { Audio, ResizeMode, Video } from "expo-av";
import { FC, useEffect, useRef, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import Thumbnail from "./Thumbnail";
import { VideoPlayerProps } from "./VideoPlayer.types";

const VideoPlayer: FC<VideoPlayerProps> = ({ source }) => {
  const video = useRef<Video | null>(null);
  const [started, setStarted] = useState(false);

  const handleStart = () => {
    setStarted(true);
    video?.current?.playAsync();
  };

  useEffect(() => {
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
    });
  }, []);

  return (
    <View>
      {!started ? <Thumbnail onPress={handleStart} /> : null}
      <Video
        ref={video}
        source={{
          uri: source,
        }}
        resizeMode={ResizeMode.CONTAIN}
        style={{
          width: "100%",
          flex: 1,
          aspectRatio: 16 / 9,
        }}
        videoStyle={{
          height: Platform.OS == "web" ? "100%" : undefined,
          maxWidth: Platform.OS == "web" ? "100%" : undefined,
        }}
        onLoad={async () => {
          await video.current?.pauseAsync();
        }}
        useNativeControls
        shouldPlay
      />
    </View>
  );
};

const styles = StyleSheet.create({
  thumbnail: {},
});

export default VideoPlayer;
