import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";

interface Props {
  marginless?: boolean;
}

const Divider: FC<Props> = ({ marginless }) => {
  return <View style={[styles.line, marginless && styles.marginless]}></View>;
};

export default Divider;

const styles = StyleSheet.create({
  line: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.mediumgray,
    marginVertical: 30,
  },
  marginless: {
    marginVertical: 0,
  },
});
