import { FC } from "react";
import { StyleSheet } from "react-native";
import { Colors } from "../../constants/colors";
import { DateQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import DatePicker from "../inputs/DatePicker";
import QuestionWrapperInline from "./QuestionWrapperInline";

interface Props extends QuestionProps<string, DateQuestion> {
  labelSave: string;
  locale: string;
}

const Date: FC<Props> = ({
  onChange,
  question,
  readOnly,
  labelSave = "Save",
  locale = "pl",
}) => {
  const {
    template: { content, subcontent, min_value },
    answer: value,
  } = question;

  return (
    <QuestionWrapperInline
      content={content || undefined}
      subcontent={subcontent || undefined}
    >
      <DatePicker
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        minValue={min_value || undefined}
        label_save={labelSave}
        locale={locale}
      />
    </QuestionWrapperInline>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  labelContainer: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    lineHeight: 28,
    color: Colors.dark,
    fontWeight: "700",
  },
  subtitle: {
    fontSize: 11,
    lineHeight: 16,
    color: Colors.dark,
    opacity: 0.6,
  },
});

export default Date;
