import { createSlice } from "@reduxjs/toolkit";
import { SlideContent } from "../../types/v2/slides/slide_content";
import { getTherapyFolderInsomniaMap } from "./thunk_functions/therapyFolder";

interface StaticContentState {
  needRefresh: boolean;
  loading: boolean;
  error: boolean;
  content: SlideContent[];
}

const initialState: StaticContentState = {
  needRefresh: true,
  loading: false,
  error: false,
  content: [],
};

const therapyFolderInsomniaMapSlice = createSlice({
  name: "therapyFolderInsomniaMap",
  initialState: initialState,
  reducers: {
    markAsStale: (state) => {
      state.needRefresh = true;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTherapyFolderInsomniaMap.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getTherapyFolderInsomniaMap.fulfilled, (state, action) => {
        state.loading = false;
        state.needRefresh = false;
        state.content = action.payload;
      })
      .addCase(getTherapyFolderInsomniaMap.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const TherapyFolderInomniaMapActions =
  therapyFolderInsomniaMapSlice.actions;
export const therapyFolderInsomniaMapReducer =
  therapyFolderInsomniaMapSlice.reducer;
