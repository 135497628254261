import { StyleSheet, View } from "react-native";
import Avatar from "./Avatar";
import { FC } from "react";
import { Avatar as AvatarType } from "../../types/v2/avatar";

interface IProps {
  avatars: AvatarType[];
  value: AvatarType | null;
  onChange: (avatar: AvatarType) => void;
}

const AvatarSelector: FC<IProps> = ({ avatars, onChange, value }) => {
  return (
    <View style={styles.container}>
      {avatars.length > 0 &&
        avatars.map((avatar) => {
          return (
            <Avatar
              key={avatar.uuid}
              selected={value !== null && value.uuid === avatar.uuid}
              onPress={() => onChange(avatar)}
              avatar={avatar}
            />
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
});

export default AvatarSelector;
