import { StyleSheet } from "react-native";
import { Colors } from "../../../constants/colors";

export const relaxation_item_styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: 150,
    resizeMode: "cover",
  },
  description_container: {
    padding: 15,
    backgroundColor: Colors.white,
  },
  name: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 22,
    color: Colors.dark,
  },
  duration: {
    fontSize: 14,
    lineHeight: 17,
    color: Colors.dark,
    fontWeight: "200",
    marginTop: 5,
  },
});
