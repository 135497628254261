import { Link } from "@react-navigation/native";
import { ResizeMode } from "expo-av";
import {
  Image,
  Linking,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import MainHeading from "../components/typography/main_heading";
import SubHeading from "../components/typography/sub_heading";
import Container from "../components/utils/container";
import Divider from "../components/utils/divider";
import { Colors } from "../constants/colors";
import { SCREEN } from "../navigation/screens";
import { RootStackScreenProps } from "../types/navigation";
import { sendAnalyticsEventAsync } from "../utils/analytics";
import { useEffect } from "react";
import { useLogPurchase } from "../hooks/useLogPurchase";

const ThankYouScreen = ({
  navigation,
  route,
}: RootStackScreenProps<SCREEN.ThankYou>) => {
  const [logPurchase, unsubscribe] = useLogPurchase(route.params?.cid);

  useEffect(() => {
    logPurchase();
    return () => unsubscribe();
  }, []);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, paddingVertical: 30 }}>
      <Container>
        <MainHeading text="Dziękujemy za zakup!" />
        <View style={{ marginTop: 10 }}>
          <SubHeading text="Cieszymy się, że do nas dołączyłeś i mamy nadzieję, że terapia bezsenności przyniesie Ci ulgę w codziennym życiu." />
        </View>
        <Divider />
        <Text style={styles.section}>Następne kroki</Text>
        <Text style={styles.step}>1. Pobierz aplikację na telefon</Text>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "nowrap",
          }}
        >
          <View style={styles.storeTile}>
            <Pressable
              onPress={() => {
                Linking.openURL(
                  "https://play.google.com/store/apps/details?id=com.goodsleeper.cbt&gl=PL"
                );
              }}
              style={{
                maxWidth: 646,
                width: "100%",
                height: "auto",
                aspectRatio: 646 / 250,
              }}
            >
              <Image
                source={require("../assets/images/google-play-badge.png")}
                style={{
                  maxWidth: 646,
                  width: "100%",
                  height: "auto",
                  aspectRatio: 646 / 250,
                }}
                resizeMode={ResizeMode.COVER}
              />
            </Pressable>
          </View>
          <View style={styles.storeTile}>
            <Pressable
              onPress={() => {
                Linking.openURL(
                  "https://apps.apple.com/us/app/goodsleeper/id1642944868?itsct=apps_box_link&itscg=30200"
                );
              }}
              style={{
                maxWidth: 646,
                width: "100%",
                height: "auto",
                aspectRatio: 646 / 215,
              }}
            >
              <Image
                source={require("../assets/images/app-store-badge.png")}
                style={{
                  maxWidth: 646,
                  width: "100%",
                  height: "auto",
                  aspectRatio: 646 / 215,
                }}
                resizeMode={ResizeMode.COVER}
              />
            </Pressable>
          </View>
        </View>
        <Text style={styles.step}>
          2. Zaloguj się używając danych podanych podczas rejestracji
        </Text>
        <Text style={styles.step}>
          3. Rozpocznij terapię zgodnie z instrukcjami w aplikacji
        </Text>
        <Text>
          Masz pytania?{" "}
          <Link
            to={{ screen: SCREEN.ContactAndHelp }}
            style={{ color: Colors.primary }}
          >
            Zapraszamy do kontatku
          </Link>
        </Text>
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  section: {
    textTransform: "uppercase",
    color: Colors.darkgray,
  },
  step: {
    paddingVertical: 10,
  },
  storeTile: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
});

export default ThankYouScreen;
