import { createSlice } from "@reduxjs/toolkit";
import { OnboardingCarouselItem } from "../../types/onboarding";
import { fetchOnboardingCarousel } from "./thunk_functions/fetchOnboardingCarousel";

interface OnboardingState {
  needRefresh: boolean;
  loading: boolean;
  items: OnboardingCarouselItem[];
}

const initialState: OnboardingState = {
  needRefresh: true,
  loading: false,
  items: [],
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    markAsStale(state) {
      state.needRefresh = true;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOnboardingCarousel.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading = false;
      state.needRefresh = false;
    });
  },
});

export const OnboardingActions = onboardingSlice.actions;
export const onboardingReducer = onboardingSlice.reducer;
