import React, { FC } from "react";
import MaterialCommunityIcon from "@expo/vector-icons/MaterialCommunityIcons";
import MaterialIcon from "@expo/vector-icons/MaterialIcons";

const CustomIcon: FC<any> = (props) => {
  let prefix: string | undefined;
  let iconName: string;
  const { name, ...rest } = props;
  if (props.name.includes(":")) {
    [prefix, iconName] = name.split(":");
  } else {
    iconName = name;
  }
  return (
    <>
      {prefix === "community" ? (
        <MaterialCommunityIcon name={iconName} {...rest} />
      ) : (
        <MaterialIcon name={iconName} {...rest} />
      )}
    </>
  );
};

export default CustomIcon;
