import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { Session, TherapyPreview } from "../../../types/v2/therapy";

export const getTherapy = createAsyncThunk<
  TherapyPreview,
  undefined,
  IThunkApi
>("therapy/get", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(
    authorize(ENDPOINTS.session.CREATE_SESSION, token)
  );
  return data;
});

export const getSession = createAsyncThunk<
  Session,
  string,
  IThunkApi
>("therapy/getSession", async (uuid, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(
    authorize(ENDPOINTS.session.READ_SESSION(uuid), token)
  );
  return data;
})
