import { FC, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { ENDPOINTS } from "../api/config";
import { useAuthorizedCall } from "../hooks/useAuthorizedCall";
import { useTranslations } from "../hooks/useTranslations";
import { IConsent } from "../types/consent";
import CustomSwitch from "./utils/custom_switch";
import Switch from "./utils/switch";

interface IProps {
  userConsents: { [key: string]: boolean };
  setUserConsents: (arg: { [key: string]: boolean }) => void;
  setIsValid: (arg: boolean) => void;
  setLoading: (arg: boolean) => void;
  showMore?: boolean;
}

const SignupConsents: FC<IProps> = ({
  userConsents,
  setUserConsents,
  setIsValid,
  setLoading,
  showMore = false,
}) => {
  const { auth_consent_select_all } = useTranslations();
  const [consents, setConsents] = useState<IConsent[]>([]);
  const [acceptAll, setAcceptAll] = useState(false);
  const call = useAuthorizedCall();

  useEffect(() => {
    const readConsents = async () => {
      try {
        const { data, status } = await call(ENDPOINTS.user.READ_CONSENTS);
        if (status === 200) {
          setConsents(data.current_values);
          const newUserConsents: { [key: string]: boolean } = {};
          let allAccepted = true;
          let requiredAccepted = true;
          for (const consent of data.current_values as IConsent[]) {
            newUserConsents[consent.consent_type] = consent.accepted;
            if (!consent.accepted) allAccepted = false;
            if (consent.required && !consent.accepted) requiredAccepted = false;
          }
          setIsValid(!requiredAccepted);
          setAcceptAll(allAccepted);
          setUserConsents(newUserConsents);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    readConsents();
  }, []);

  const toggleAll = (val: boolean) => {
    const consentsCopy = { ...userConsents };
    if (val) {
      Object.keys(consentsCopy).forEach((e) => {
        consentsCopy[e] = true;
      });
      setIsValid(false);
    } else {
      Object.keys(consentsCopy).forEach((e) => {
        consentsCopy[e] = false;
      });
      setIsValid(true);
    }
    setUserConsents(consentsCopy);
    setAcceptAll(val);
  };

  const setConsent = (id: string, value: boolean) => {
    const consentsCopy = { ...userConsents };
    consentsCopy[id] = value;
    if (!value) setAcceptAll(false);

    let shouldBeDisabled = false;
    for (let consent of consents) {
      if (consent.required && consentsCopy[consent.consent_type] === false)
        shouldBeDisabled = true;
    }

    let shouldAcceptAll = true;
    Object.values(consentsCopy).forEach((c) => {
      if (!c) shouldAcceptAll = false;
    });
    setAcceptAll(shouldAcceptAll);

    setUserConsents(consentsCopy);
    setIsValid(shouldBeDisabled);
  };

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={[styles.name]}>{auth_consent_select_all}</Text>
        </View>
        <View>
          <CustomSwitch
            value={acceptAll}
            setValue={() => toggleAll(!acceptAll)}
          />
        </View>
      </View>
      {consents.length > 0 &&
        consents.map((c) => {
          return (
            <Switch
              name={c.name}
              text={c.content}
              value={userConsents[c.consent_type]}
              setValue={setConsent.bind(
                this,
                c.consent_type,
                !userConsents[c.consent_type]
              )}
              key={c.name}
              required={c.required}
              showMore={showMore}
            />
          );
        })}
    </View>
  );
};

export default SignupConsents;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  textContainer: {
    width: "84%",
  },
  name: {
    fontSize: 18,
    fontWeight: "700",
    paddingTop: 3,
    lineHeight: 22,
  },
  text: {
    fontSize: 10,
    fontWeight: "400",
    paddingTop: 3,
  },
});
