import { createSlice } from "@reduxjs/toolkit";
import { SleepDiarySummary } from "../../types/v2/sleepDiary";
import { getSleepDiarySummary } from "./thunk_functions/sleepDiarySummary";

interface SleepDiarySummaryState {
  needRefresh: boolean;
  loading: boolean;
  content?: SleepDiarySummary;
  errorMessage?: string;
}

const initialState: SleepDiarySummaryState = {
  needRefresh: true,
  loading: false,
};

const sleepDiarySummarySlice = createSlice({
  name: "sleepDiarySummary",
  initialState: initialState,
  reducers: {
    markAsStale(state) {
      state.needRefresh = true;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSleepDiarySummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSleepDiarySummary.fulfilled, (state, action) => {
        state.needRefresh = false;
        state.loading = false;
        state.content = action.payload;
        state.errorMessage = undefined
      })
      .addCase(getSleepDiarySummary.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      });
  },
});

export const SleepDiarySummaryActions = sleepDiarySummarySlice.actions;
export const sleepDiarySummaryReducer = sleepDiarySummarySlice.reducer;
