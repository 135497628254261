import { FC, useEffect } from "react";
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import Markdown from "react-native-markdown-display";
import {
  useAnimatedProps,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import Svg, { Defs, LinearGradient, Path, Stop } from "react-native-svg";
import { Colors } from "../../constants/colors";
import { SurveyScore as SurveyScoreProps } from "../../types/v2/slides/slide_content";
import Animated from "react-native-reanimated";

const rules = {
  paragraph: (node: any, children: any, parent: any, styles: any) => (
    <Text
      key={node.key}
      style={[
        styles.paragraph,
        {
          textAlign: "center",
          fontWeight: "700",
          fontSize: 18,
          lineHeight: 26,
          flex: 1,
          color: Colors.dark,
        },
      ]}
    >
      {children}
    </Text>
  ),
};

const MAX_SIZE = 244;

interface BarProps {
  size?: number;
  radius?: number;
  strokeWidth?: number;
  fill: number;
  startColor?: string;
  endColor?: string;
  style?: StyleProp<ViewStyle>;
  opacity?: number;
  animated?: boolean;
}

const AnimatedPath = Animated.createAnimatedComponent(Path);

const Bar: FC<BarProps> = ({
  size = MAX_SIZE,
  radius = 120,
  strokeWidth = 4,
  fill,
  style,
  opacity = 1,
  animated = true,
}) => {
  const currentFill = useSharedValue(animated ? 0 : fill);

  const makeD = () => {
    const offset = (3 / 4) * Math.PI;
    const startAngle = offset;
    const totalLen = (3 / 2) * Math.PI;
    const len = fill * totalLen;
    const endAngle = startAngle + len;
    const x = size / 2;
    const y = size / 2;

    const startX = x + Math.cos(startAngle) * radius;
    const startY = y + Math.sin(startAngle) * radius;
    const endX = x + Math.cos(endAngle) * radius;
    const endY = y + Math.sin(endAngle) * radius;
    const largeArc = fill > 2 / 3 ? 1 : 0;

    return [
      "M",
      startX,
      startY,
      "A",
      radius,
      radius,
      0,
      largeArc,
      1,
      endX,
      endY,
    ].join(" ");
  };

  const animatedProps = useAnimatedProps(() => {
    const offset = (3 / 4) * Math.PI;
    const startAngle = offset;
    const totalLen = (3 / 2) * Math.PI;
    const len = currentFill.value * totalLen;
    const endAngle = startAngle + len;
    const x = size / 2;
    const y = size / 2;

    const startX = x + Math.cos(startAngle) * radius;
    const startY = y + Math.sin(startAngle) * radius;
    const endX = x + Math.cos(endAngle) * radius;
    const endY = y + Math.sin(endAngle) * radius;
    const largeArc = fill > 2 / 3 ? 1 : 0;

    return {
      d: [
        "M",
        startX,
        startY,
        "A",
        radius,
        radius,
        0,
        largeArc,
        1,
        endX,
        endY,
      ].join(" "),
    };
  });

  useEffect(() => {
    if (animated) {
      currentFill.value = withTiming(fill, {
        duration: 1500,
      });
    }
  }, []);

  return (
    <Svg width={size} height={size} style={style}>
      <Defs>
        <LinearGradient
          id="grad"
          x1="239.522"
          y1="-6.33329"
          x2="-7.56331"
          y2="-6.33329"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0" stopColor={Colors.purple} />
          <Stop offset="0.455693" stopColor="#6887D6" />
          <Stop offset="1" stopColor={Colors.green} />
        </LinearGradient>
      </Defs>
      {Platform.OS !== "web" ? (
        <AnimatedPath
          stroke="url(#grad)"
          strokeWidth={strokeWidth}
          animatedProps={animatedProps}
          opacity={opacity}
          fillRule="evenodd"
          clipRule="evenodd"
          fill={Colors.white}
        />
      ) : (
        <Path
          stroke="url(#grad)"
          strokeWidth={strokeWidth}
          d={makeD()}
          opacity={opacity}
          fillRule="evenodd"
          clipRule="evenodd"
          fill={Colors.white}
        />
      )}
    </Svg>
  );
};

const SurveyScore: FC<SurveyScoreProps> = ({ result, max_result, text }) => {
  return (
    <View style={styles.container}>
      <View
        style={{
          width: MAX_SIZE,
          height: MAX_SIZE,
        }}
      >
        <Bar style={styles.bar} fill={1} strokeWidth={5} animated={false} />
        <Bar
          style={styles.bar}
          opacity={0.3}
          fill={result / max_result}
          strokeWidth={20}
          radius={110}
          animated={Platform.OS !== "web"}
        />
        <View style={styles.scoreContainer}>
          <Text style={styles.score}>
            <Text style={styles.scoreLarge}>{result}</Text>/{max_result}
          </Text>
        </View>
      </View>
      <View style={styles.descriptionContainer}>
        <Markdown rules={rules}>{text}</Markdown>
      </View>
    </View>
  );
};

export default SurveyScore;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginVertical: 20,
    maxWidth: 800,
  },
  bar: {
    position: "absolute",
  },
  scoreContainer: {
    position: "absolute",
    width: MAX_SIZE,
    height: MAX_SIZE,
    justifyContent: "center",
    alignItems: "center",
  },
  scoreLarge: {
    color: Colors.primary,
    fontWeight: "700",
    fontSize: 60,
  },
  score: {
    color: Colors.primary,
    fontWeight: "700",
    fontSize: 20,
  },
  text: {
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 26,
    flex: 1,
    marginLeft: 20,
    color: Colors.dark,
  },
  percent: {
    fontSize: 30,
    lineHeight: 36,
    fontWeight: "500",
    color: Colors.dark,
  },
  descriptionContainer: {
    width: "100%",
  },
});
