import { useState } from "react";
import { KeyboardAvoidingView, StyleSheet, View } from "react-native";
import { ENDPOINTS } from "../../api/config";
import Link from "../../components/controls/Link";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import CustomText from "../../components/typography/text";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import Input from "../../components/utils/input";
import PrimaryButton from "../../components/utils/primary_button";
import { useAuthorizedCall } from "../../hooks/useAuthorizedCall";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";

const ForgotPasswordScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.ForgotPassword>) => {
  const {
    auth_password_reset_header,
    auth_password_reset_content,
    auth_signin_placeholder_email,
    auth_password_reset_send_email,
    auth_back_to_main_screen,
    auth_signin_subheader,
  } = useTranslations();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const call = useAuthorizedCall();

  const recoverPassword = async () => {
    let hasErrors = false;
    if (!email) {
      setEmailError("Wpisz adres email");
      hasErrors = true;
    } else setEmailError(null);
    if (loading || hasErrors) return;
    setLoading(true);
    try {
      const { data, status } = await call(
        ENDPOINTS.user.PASSWORD_RECOVERY(email)
      );
      if (status === 200) navigation.navigate(SCREEN.ResetPassword);
    } catch (error: any) {
      const err = { ...error };
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Container>
      <View style={styles.container}>
        <View>
          <MainHeading text={auth_password_reset_header} size="large" />
          <View style={{ marginTop: 10 }}>
            <SubHeading text={auth_signin_subheader} />
          </View>
          <Divider />
          <CustomText text={auth_password_reset_content} />
          <View style={{ marginTop: 20 }}>
            <Input
              value={email}
              setValue={setEmail}
              placeholder={auth_signin_placeholder_email}
              error={emailError}
              type="emailAddress"
              keyboardType="email-address"
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="email"
            />
          </View>
        </View>
        <View style={styles.action_container}>
          <Link
            to={{ screen: SCREEN.Onboarding }}
            style={{ alignSelf: "center" }}
          >
            {auth_back_to_main_screen}
          </Link>
          <View style={{ marginTop: 10 }}>
            <PrimaryButton
              label={auth_password_reset_send_email}
              loading={loading}
              onPress={recoverPassword}
            />
          </View>
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flex: 1,
  },
  link: {
    textDecorationLine: "underline",
    marginTop: 8,
  },
  action_container: {
    justifyContent: "flex-end",
  },
});

export default ForgotPasswordScreen;
