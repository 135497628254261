import { FC } from "react";
import { ScrollView, useWindowDimensions } from "react-native";
import { StackedBarplot as StackedBarplotProps } from "../../types/v2/slides/slide_content";
import Chart from "../stacked_barplot/stacked_barplot";

const StackedBarplot: FC<StackedBarplotProps> = (props) => {
  const { width } = useWindowDimensions();

  return (
    <ScrollView horizontal={true} style={{ width, marginLeft: -30 }}>
      <Chart {...props} />
    </ScrollView>
  );
};

export default StackedBarplot;
