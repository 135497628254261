import { useEffect, useState } from "react";
import { useAuthorizedCall } from "./useAuthorizedCall";

export function useAxios<D>(
  req: { url: string; method: string; data?: any; headers?: any },
  focused: boolean = true
) {
  const [responseData, setResponseData] = useState<D | undefined>();
  const [status, setStatus] = useState<number | undefined>();
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState(true);
  const call = useAuthorizedCall()

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data, status } = await call(req);
      setResponseData(data);
      setStatus(status);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (focused) fetchData();
  }, [focused]);

  return { data: responseData, status, error, loading };
}
