export const paymentsRoutes = {
  GET_PLANS: (promo_code?: string) => ({
    url: promo_code
      ? `/payments/plans?promo_code_name=${promo_code}`
      : "/payments/plans",
    method: "GET",
  }),
  CHECKOUT: (uuid: string, promo_code?: string) => ({
    url: "/payments/checkout",
    method: "POST",
    data: {
      plan_uuid: uuid,
      promo_code_name: promo_code,
    },
  }),
  PAYMENTS_WEBHOOK: {
    url: "/payments/webhook",
    method: "POST",
  },
  PUBLISHABLE_KEY: {
    url: "/payments/publishable-key",
    method: "GET",
  },
  VERIFY_APPLE_IN_APP_PURCHASE: (receipt_data: string) => ({
    url: "/payments/apple/verify-receipt",
    method: "POST",
    data: {
      receipt_data,
    },
  }),
  VERIFY_PROMO_CODE: (code: string) => ({
    url: "/payments/promo-codes/verify",
    method: "POST",
    data: { code },
  }),
  GET_CHECKOUT_SESSION: (id: string) => ({
    url: `/payments/sessions/${id}`,
    method: "GET",
  }),
};
