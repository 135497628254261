import { useNavigation } from "@react-navigation/native";
import { FC } from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { IArticle } from "../../types/survey";
import CustomIcon from "../CustomIcon";

const Article: FC<IArticle> = ({
  abstract,
  title,
  uuid,
  storage_image_url,
}) => {
  const navigation = useNavigation<any>();
  const { library_read_more_cta } = useTranslations();

  const handleOpen = () => {
    navigation.navigate(SCREEN.Article, { uuid });
  };

  return (
    <Pressable style={styles.container} onPress={handleOpen}>
      <View
        style={{
          height: 160,
          backgroundColor: Colors.gray,
          borderTopEndRadius: 8,
          borderTopLeftRadius: 8,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {storage_image_url ? (
          <Image
            source={{ uri: storage_image_url }}
            style={{
              width: "100%",
              height: 160,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          />
        ) : (
          <CustomIcon
            name="community:bookshelf"
            size={40}
            color={Colors.darkgray}
          />
        )}
      </View>
      <View style={{ padding: 8 }}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.text}>
          {abstract.length > 100 ? abstract.slice(0, 97) + "..." : abstract}
        </Text>
        <View style={styles.more}>
          <Text style={{ color: Colors.primary }}>{library_read_more_cta}</Text>
        </View>
      </View>
    </Pressable>
  );
};

export default Article;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.mediumgray,
    borderRadius: 8,
  },
  icon: {
    marginBottom: 23,
  },
  title: {
    fontSize: 14,
    lineHeight: 22,
    color: Colors.dark,
    fontWeight: "bold",
    marginTop: 15,
  },
  text: {
    fontSize: 14,
    lineHeight: 22,
    color: Colors.dark,
    fontWeight: "200",
    marginTop: 5,
  },
  more: {
    fontSize: 14,
    lineHeight: 22,
    color: Colors.primary,
    fontWeight: "300",
    marginTop: 15,
    paddingVertical: 6,
  },
});
