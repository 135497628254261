import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem
} from "@react-navigation/drawer";
import { FC } from "react";
import { Text, View } from "react-native";
import CustomIcon from "../components/CustomIcon";
import { Colors } from "../constants/colors";
import { DrawerMenuSection } from "../types/v2/navigation/drawer";
import { TherapyParticipant } from "../types/v2/user";

interface Props extends DrawerContentComponentProps {
  translations: { [key: string]: string };
  therapyState: TherapyParticipant;
  routes: DrawerMenuSection[];
  onLogout: () => void;
}

const DrawerContent: FC<Props> = ({
  translations,
  therapyState,
  onLogout,
  routes,
  ...props
}) => {
  return (
    <DrawerContentScrollView {...props}>
      {routes.map((section) => {
        return (
          <View key={section.title} style={{ marginTop: 20 }}>
            <Text
              style={{
                marginHorizontal: 20,
                fontSize: 14,
                color: Colors.darkgray,
                fontWeight: "200",
              }}
            >
              {translations[section.title]}
            </Text>
            {section.links
              .filter((item) => !item.hidden || !item.hidden(therapyState))
              .map((item) => {
                const isLocked = item.locked && item.locked(therapyState);
                const handlePress = () => {
                  if (isLocked) {
                    return null;
                  }
                  return props.navigation.navigate(item.screen as any);
                };
                return (
                  <DrawerItem
                    key={item.screen}
                    label={() => (
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <View
                          style={{
                            width: 25,
                            alignItems: "center",
                            marginRight: 10,
                          }}
                        >
                          <CustomIcon
                            size={22}
                            name={item.icon}
                            color={isLocked ? Colors.darkgray : Colors.primary}
                          />
                        </View>
                        <Text
                          style={{
                            color: isLocked ? Colors.darkgray : Colors.dark,
                            fontSize: 14,
                          }}
                        >
                          {translations[item.title]}
                        </Text>
                      </View>
                    )}
                    onPress={handlePress}
                  />
                );
              })}
          </View>
        );
      })}
      <DrawerItem
        label={() => (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ width: 25, alignItems: "center", marginRight: 10 }}>
              <CustomIcon
                size={22}
                name={"power-settings-new"}
                color={Colors.primary}
              />
            </View>
            <Text style={{ color: Colors.dark, fontSize: 14 }}>
              {translations.menu_drawer_logout}
            </Text>
          </View>
        )}
        onPress={onLogout}
      />
    </DrawerContentScrollView>
  );
};

export default DrawerContent;
