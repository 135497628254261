import { Platform, Pressable, StyleSheet, Text, View } from "react-native";
import ListLink from "../../components/controls/ListLink";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";

const SettingsScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.Settings>) => {
  const {
    settings_main_header,
    settings_main_subheader,
    settings_main_auth_change_password,
    settings_main_auth_change_language,
    settings_main_auth_change_timezone,
    settings_main_notifications_push,
    settings_main_notifications_email,
    settings_main_notifications_header,
    settings_main_auth_subscription,
    menu_drawer_account,
  } = useTranslations();
  const { user } = useAppSelector((state) => state.therapyParticipant);

  return (
    <Container>
      <View style={{}}>
        <Pressable
          style={{ paddingVertical: 2, marginBottom: 15 }}
          onPress={() => navigation.goBack()}
        >
          <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
        </Pressable>
      </View>
      <MainHeading text={settings_main_header} size={"large"} />
      <View style={{ marginTop: 10 }}>
        <SubHeading text={settings_main_subheader} />
      </View>
      <Divider />
      <View>
        <Text style={style.section}>{menu_drawer_account}</Text>
        <ListLink to={{ screen: SCREEN.SubscriptionSettings }}>
          {settings_main_auth_subscription}
        </ListLink>
        <ListLink to={{ screen: SCREEN.PasswordChange }}>
          {settings_main_auth_change_password}
        </ListLink>
        {user?.can_change_language && (
          <ListLink to={{ screen: SCREEN.LanguageSettings }}>
            {settings_main_auth_change_language}
          </ListLink>
        )}
        {/*<SettingsLink
          route={"Timezone"}
          text={settings_main_auth_change_timezone}
        />*/}
        {Platform.OS !== "web" ? (
          <View style={{ marginTop: 30 }}>
            <Text style={style.section}>
              {settings_main_notifications_header}
            </Text>
            {/*<SettingsLink
            route={"NotificationSettings"}
            text={settings_main_notifications_push}
          />*/}
            <ListLink to={{ screen: SCREEN.NotificationsSettings }}>
              {settings_main_notifications_push}
            </ListLink>
          </View>
        ) : null}
      </View>
    </Container>
  );
};

const style = StyleSheet.create({
  section: {
    textTransform: "uppercase",
    color: Colors.darkgray,
  },
});

export default SettingsScreen;
