import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Avatar } from "../../types/v2/avatar";

import {
  Gender,
  Subscription,
  TherapyParticipant,
  User,
} from "../../types/v2/user";
import {
  refreshUserState,
  restoreTherapyParticipantState,
  updateProfile,
} from "./thunk_functions/user";

const initialState: Partial<TherapyParticipant> = {};

export const STORE_KEY = "@therapyParticipant";

const therapyParticipantSlice = createSlice({
  name: "therapyParticipant",
  initialState: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    update: (state, action: PayloadAction<TherapyParticipant>) => {
      return action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      if (state.user !== undefined) {
        state.user.name = action.payload;
      }
    },
    setAvatar: (state, action: PayloadAction<Avatar>) => {
      if (state.user !== undefined) {
        state.user.avatar = action.payload;
      }
    },
    setGender: (state, action: PayloadAction<Gender>) => {
      if (state.intro_survey !== undefined) {
        state.intro_survey.gender = action.payload;
      }
    },
    setPronouns: (state, action: PayloadAction<string>) => {
      if (state.intro_survey !== undefined) {
        state.intro_survey.pronouns = action.payload;
      }
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setAge: (state, action: PayloadAction<string>) => {
      if (state.intro_survey !== undefined) {
        state.intro_survey.age = action.payload;
      }
    },
    logout: (state) => {
      return initialState;
    },
    unsubscribe: (state) => {
      state.subscription = undefined;
    },
    subscribe: (state, action: PayloadAction<Subscription>) => {
      state.subscription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshUserState.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(restoreTherapyParticipantState.fulfilled, (state, action) => {
        if (action.payload !== null) {
          return action.payload;
        }
        return state;
      });
  },
});

export const TherapyParticipantActions = therapyParticipantSlice.actions;
export const therapyParticipantReducer = therapyParticipantSlice.reducer;
