import { NavigationContainer } from "@react-navigation/native";
import axios from "axios";
import { createURL } from "expo-linking";
import jwtDecode from "jwt-decode";
import { useEffect, useRef } from "react";
import { StatusBar } from "react-native";
import {
  GestureHandlerRootView,
  enableExperimentalWebImplementation,
} from "react-native-gesture-handler";
import { RootSiblingParent } from "react-native-root-siblings";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider } from "react-redux";
import { CONFIG } from "./api/config";
import AppContainer from "./app-container";
import UpgradeChecker from "./components/UpgradeChecker";
import NavigationTheme from "./navigation/theme";
import { store } from "./store/redux";
import { retrieveData } from "./store/storage";
import * as AuthProviders from "./utils/auth-providers";
import * as PushNotifications from "./utils/push_notification_helper";
import * as Sentry from "./utils/sentry";
PushNotifications.initialize();
Sentry.initialize();
AuthProviders.configureGoogleSignin();
AuthProviders.configureFacebookSignin();

enableExperimentalWebImplementation(false);

retrieveData("@access_token")
  .then((token) => {
    if (token) {
      const { sub } = jwtDecode<{ sub: string }>(token);
      Sentry.setUser({
        id: sub,
      });
    }
  })
  .catch((error) => {});

axios.defaults.baseURL = CONFIG.url;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";

function App() {
  const navigation = useRef<any>(null);
  const requestPermission = PushNotifications.usePushPermission();

  useEffect(() => {
    requestPermission();

    return () => {};
  }, []);

  return (
    <>
      <NavigationContainer
        theme={NavigationTheme}
        ref={navigation}
        onReady={() => {
          if (Sentry.routingInstrumentation !== undefined) {
            Sentry.routingInstrumentation.registerNavigationContainer(
              navigation
            );
          }
        }}
        linking={{ enabled: true, prefixes: [createURL("/")] }}
      >
        <RootSiblingParent>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <SafeAreaProvider>
              <StatusBar backgroundColor={"transparent"} translucent />
              <UpgradeChecker>
                <AppContainer />
              </UpgradeChecker>
            </SafeAreaProvider>
          </GestureHandlerRootView>
        </RootSiblingParent>
      </NavigationContainer>
    </>
  );
}

const ReduxApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default Sentry.wrap(ReduxApp);
