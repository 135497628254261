import { Pressable, StyleSheet, Text } from "react-native";
import { Colors } from "../constants/colors";

interface Props {
  onPress?: () => void;
  color?: string;
  disabled?: boolean;
}

const Link: React.FC<Props> = ({ onPress, color, children, disabled }) => {
  return (
    <Pressable onPress={onPress} hitSlop={10} disabled={disabled}>
      <Text
        style={[
          styles.link,
          {
            color: disabled ? Colors.darkgray : color ? color : Colors.primary,
          },
        ]}
      >
        {children}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  link: {
    paddingVertical: 17,
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 28,
  },
});

export default Link;
