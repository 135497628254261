import { FC } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import Modal from "react-native-modal";
import { Colors } from "../../constants/colors";
import { useTranslations } from "../../hooks/useTranslations";

interface IProps {
  visible: boolean;
  dismiss: () => void;
  confirm: () => void;
}

const ConfirmDelete: FC<IProps> = ({ confirm, dismiss, visible }) => {
  const { alert_drop, alert_stay, alert_unsaved_changes } = useTranslations();

  return (
    <Modal isVisible={visible} style={styles.modal} onBackdropPress={dismiss}>
      <View style={styles.container}>
        <Text style={styles.text}>{alert_unsaved_changes}</Text>
        <View style={styles.buttons}>
          <Pressable onPress={confirm} style={styles.btn}>
            <Text
              style={[
                styles.btn_text,
                {
                  color: Colors.error,
                  borderRightWidth: 1,
                  borderRightColor: Colors.lightgray,
                },
              ]}
            >
              {alert_drop}
            </Text>
          </Pressable>
          <Pressable onPress={dismiss} style={styles.btn}>
            <Text style={styles.btn_text}>{alert_stay}</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmDelete;

const styles = StyleSheet.create({
  modal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  container: {
    backgroundColor: Colors.white,
    maxHeight: "90%",
    position: "relative",
  },
  text: {
    fontSize: 22,
    fontWeight: "700",
    color: Colors.dark,
    paddingBottom: 20,
    textAlign: "center",
    borderBottomColor: Colors.lightgray,
    borderBottomWidth: 1,
    paddingHorizontal: 30,
    paddingTop: 20,
  },
  buttons: {
    flexDirection: "row",
    width: "100%",
  },
  btn: {
    width: "50%",
  },
  btn_text: {
    textAlign: "center",
    fontSize: 15,
    paddingTop: 22,
    paddingBottom: 22,
    fontWeight: "700",
  },
});
