import { createSlice } from "@reduxjs/toolkit";
import { Thought } from "../../types/thought";
import { getThoughts } from "./thunk_functions/getThoughts";

interface ThoughtsState {
  needRefresh: boolean;
  loading: boolean;
  error: boolean;
  thoughts: Thought[];
}

const initialState: ThoughtsState = {
  needRefresh: true,
  loading: false,
  error: false,
  thoughts: [],
};

const thoughtsSlice = createSlice({
  name: "thoughts",
  initialState: initialState,
  reducers: {
    markAsStale(state) {
      state.needRefresh = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThoughts.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getThoughts.fulfilled, (state, action) => {
        state.needRefresh = false;
        state.loading = false;
        state.error = false;
        state.thoughts = action.payload;
      })
      .addCase(getThoughts.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const ThoughtsActions = thoughtsSlice.actions;
export const thoughtsReducer = thoughtsSlice.reducer;
