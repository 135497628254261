import { FC } from "react";
import { Pressable, Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Colors } from "../../constants/colors";
import { custom_top_tabs_styles } from "./custom_top_tabs.styles";

interface ITab {
  value: string;
  label: string;
}

interface IProps {
  activeTab: string;
  setActive: (arg: any) => void;
  tabs: ITab[];
}

const CustomTopTabs: FC<IProps> = ({ activeTab, setActive, tabs }) => {
  return (
    <ScrollView
      contentContainerStyle={custom_top_tabs_styles.container}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      {tabs.map((t) => (
        <Pressable
          key={t.value}
          style={[
            custom_top_tabs_styles.menu_item,
            activeTab !== t.value && { borderBottomColor: Colors.gray },
          ]}
          android_ripple={{ color: Colors.darkgray, radius: 200 }}
          onPress={() => setActive(t.value)}
        >
          <Text
            style={[
              custom_top_tabs_styles.tab_title,
              activeTab !== t.value && { color: Colors.gray },
            ]}
          >
            {t.label}
          </Text>
        </Pressable>
      ))}
    </ScrollView>
  );
};

export default CustomTopTabs;
