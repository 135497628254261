import { useEffect, useState } from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import AvatarSelector from "../components/AvatarSelector";
import CustomIcon from "../components/CustomIcon";
import Link from "../components/Link";
import PopUp from "../components/PopUp";
import Select from "../components/select/select";
import MainHeading from "../components/typography/main_heading";
import SubHeading from "../components/typography/sub_heading";
import Container from "../components/utils/container";
import Divider from "../components/utils/divider";
import Input from "../components/utils/input";
import CustomMarkdown from "../components/utils/markdown";
import PrimaryButton from "../components/utils/primary_button";
import { Colors } from "../constants/colors";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { useTranslations } from "../hooks/useTranslations";
import { SCREEN } from "../navigation/screens";
import { getProfileConfiguration } from "../store/redux/thunk_functions/configuration";
import {
  deleteProfile,
  refreshUserState,
  updateProfile
} from "../store/redux/thunk_functions/user";
import { TherapyParticipantActions } from "../store/redux/user";
import { RootStackScreenProps } from "../types/navigation";
import { Avatar } from "../types/v2/avatar";
import { Gender } from "../types/v2/user";

const ProfileScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.Profile>) => {
  const [
    deleteAccountConfirmationVisible,
    setDeleteAccountConfirmationVisible,
  ] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const {
    edit_profile_header,
    edit_profile_subheader,
    edit_profile_your_avatar_header,
    edit_profile_your_avatar_subheader,
    edit_profile_your_data_header,
    edit_profile_your_data_subheader,
    edit_profile_your_data_name_placeholder,
    edit_profile_your_data_age_placeholder,
    edit_profile_your_data_gender_placeholder,
    edit_profile_your_data_pronouns_placeholder,
    edit_profile_save,
    edit_profile_delete_account_cta,
    edit_profile_delete_account_confirmation_text,
    edit_profile_delete_account_confirmation_cta,
  } = useTranslations();
  const {
    configuration: { profile: configuration },
    therapyParticipant: { user, intro_survey },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const handleDeleteAccount = async () => {
    setDeleting(true);
    await dispatch(deleteProfile());
  };

  const saveSettings = async () => {
    setLoading(true);
    await dispatch(updateProfile());
    setLoading(false);
    navigation.goBack();
  };

  const handleNameChange = (value: string) => {
    dispatch(TherapyParticipantActions.setUserName(value));
  };

  const handleAgeChange = (value: string) => {
    dispatch(TherapyParticipantActions.setAge(value));
  };

  const handlePronounsChange = (value: string) => {
    dispatch(TherapyParticipantActions.setPronouns(value));
  };

  const handleGenderChange = (value: string) => {
    dispatch(TherapyParticipantActions.setGender(value as Gender));
  };

  const handleAvatarChange = (value: Avatar) => {
    dispatch(TherapyParticipantActions.setAvatar(value));
  };

  useEffect(() => {
    dispatch(getProfileConfiguration());
    dispatch(refreshUserState());
  }, []);

  return (
    <>
      <PopUp
        isVisible={deleteAccountConfirmationVisible}
        onClose={() => setDeleteAccountConfirmationVisible(false)}
      >
        <View style={{ paddingVertical: 20 }}>
          <CustomMarkdown
            text={edit_profile_delete_account_confirmation_text}
          />
        </View>
        <Link onPress={handleDeleteAccount} color="#ff0000" disabled={deleting}>
          {edit_profile_delete_account_confirmation_cta}
        </Link>
      </PopUp>
      <ScrollView>
        <Container>
          <View>
            <Pressable
              style={{ paddingVertical: 2, marginBottom: 15 }}
              onPress={() => navigation.goBack()}
            >
              <CustomIcon
                name="chevron-left"
                color={Colors.primary}
                size={26}
              />
            </Pressable>
          </View>
          <MainHeading text={edit_profile_header} size={"large"} />
          <SubHeading text={edit_profile_subheader} />
          <Divider />
          <Text
            style={{
              fontSize: 18,
              lineHeight: 21,
              color: Colors.primary,
              fontWeight: "700",
            }}
          >
            {edit_profile_your_avatar_header}
          </Text>
          <Text
            style={{
              fontSize: 14,
              lineHeight: 28,
              color: Colors.dark,
              opacity: 0.6,
              marginBottom: 10,
            }}
          >
            {edit_profile_your_avatar_subheader}
          </Text>
          <AvatarSelector
            avatars={configuration.available_avatars}
            value={user?.avatar || null}
            onChange={handleAvatarChange}
          />
          <Divider />
          <Text
            style={{
              fontSize: 18,
              lineHeight: 21,
              color: Colors.primary,
              fontWeight: "700",
            }}
          >
            {edit_profile_your_data_header}
          </Text>
          <Text
            style={{
              fontSize: 14,
              lineHeight: 28,
              color: Colors.dark,
              opacity: 0.6,
              marginBottom: 10,
            }}
          >
            {edit_profile_your_data_subheader}
          </Text>
          <Input
            value={user?.name || ""}
            setValue={handleNameChange}
            placeholder={edit_profile_your_data_name_placeholder}
          />
          <View style={{ marginTop: -15 }}>
            <Select
              value={intro_survey?.age}
              setValue={handleAgeChange}
              placeholder={edit_profile_your_data_age_placeholder}
              options={configuration.age_choices}
            />
            <Select
              value={intro_survey?.gender}
              setValue={handleGenderChange}
              placeholder={edit_profile_your_data_gender_placeholder}
              options={configuration.gender_choices}
            />
            <Select
              value={intro_survey?.pronouns}
              setValue={handlePronounsChange}
              placeholder={edit_profile_your_data_pronouns_placeholder}
              options={configuration.pronouns_choices}
            />
          </View>
          <View style={{ marginTop: 15, alignItems: "center" }}>
            <PrimaryButton
              label={edit_profile_save}
              onPress={saveSettings}
              loading={loading}
            />
            <Link onPress={() => setDeleteAccountConfirmationVisible(true)}>
              {edit_profile_delete_account_cta}
            </Link>
          </View>
        </Container>
      </ScrollView>
    </>
  );
};

export default ProfileScreen;
