import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Shadow } from "react-native-shadow-2";
import { Colors } from "../constants/colors";
import PrimaryButton from "./utils/primary_button";

interface Props {
  label: string;
  onPress?: () => void;
}

const SubscriptionOffer: FC<Props> = ({ label, onPress }) => {
  return (
    <View style={styles.container}>
      <Shadow style={styles.shadow}>
        <PrimaryButton label={label} onPress={onPress} />
      </Shadow>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 60,
    left: 0,
    zIndex: 150,
    backgroundColor: Colors.white,
    width: "100%",
  },
  shadow: {
    width: "100%",
    padding: 10,
  },
});

export default SubscriptionOffer;
