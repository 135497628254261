import {
  DefaultTheme, Theme
} from "@react-navigation/native";
import { Colors } from "../constants/colors";

const NavigationTheme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Colors.white,
  },
};

export default NavigationTheme