import { ApiError } from "../types/v2/api";

export function getMessageKey(error: ApiError): string {
  console.log(error);
  if (error.message) {
    return error.message;
  }
  if (typeof error.detail === "string") {
    return error.detail;
  } else {
    return error.detail?.message || "unknown_error";
  }
}
