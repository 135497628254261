import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { QualityQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import QualityPicker from "../inputs/QualityPicker";

const Quality: FC<QuestionProps<string, QualityQuestion>> = ({
  onChange,
  question,
  readOnly,
}) => {
  const {
    template: { choices, content },
    answer: value,
  } = question;
  const choice = choices.find((choice) => choice.value === value);

  return (
    <View style={styles.container}>
      <Text style={styles.label}>
        {content}{" "}
        <Text style={styles.value}>
          {choice !== undefined ? choice.label : null}
        </Text>
      </Text>
      <QualityPicker
        value={value}
        choices={choices}
        onChange={onChange}
        readOnly={readOnly}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
  },
  label: {
    fontWeight: "300",
    fontSize: 18,
    lineHeight: 21,
    color: Colors.dark,
  },
  value: {
    fontWeight: "500",
  },
});

export default Quality;
