import { useNavigation } from "@react-navigation/native";
import { FC, useEffect, useRef } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from "react-native";
import { Colors } from "../../constants/colors";
import { AvailableMenuItem, useFloatingMenu } from "../../hooks/floatingMenu";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SurveyActions } from "../../store/redux/survey";
import { UIActions } from "../../store/redux/ui";
import CustomIcon from "../CustomIcon";
import MenuItem from "./MenuItem";

export interface FloatingMenuItem {
  text: string;
  icon: string;
  route: string;
  params?: any;
}

interface Props {
  items: AvailableMenuItem[];
}

const FloatingMenu: FC<Props> = ({ items }) => {
  const {
    ui: { floatingMenuOpen: isOpen },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const animated = useRef(new Animated.Value(0)).current;
  const navigation = useNavigation<any>();
  const floatingMenuDescription = useFloatingMenu(items);

  const rotation = animated.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "45deg"],
  });

  const useNativeDriver = Platform.OS !== "web"

  useEffect(() => {
    if (isOpen) {
      Animated.spring(animated, {
        toValue: 1,
        friction: 10,
        useNativeDriver: useNativeDriver,
      }).start();
    } else {
      Animated.spring(animated, {
        toValue: 0,
        friction: 10,
        useNativeDriver: useNativeDriver,
      }).start();
    }
  }, [isOpen]);

  const close = () => dispatch(UIActions.setFloatingMenuOpen(false));
  const toggle = () => dispatch(UIActions.setFloatingMenuOpen(!isOpen));

  const onPress = (route: string, params: any) => {
    dispatch(SurveyActions.reset())
    close()
    navigation.navigate(route, params);
  };

  return (
    <View
      style={[
        styles.container,
        Platform.OS === "android" && { width: "100%", height: "100%" },
      ]}
      pointerEvents={"box-none"}
    >
      {isOpen && (
        <Pressable onPress={close} style={styles.innerContainer}></Pressable>
      )}
      <View style={styles.items} pointerEvents={"box-none"}>
        {floatingMenuDescription.length > 1 &&
          floatingMenuDescription.map((item, idx) => (
            <MenuItem
              text={item.text}
              onPress={onPress.bind(this, item.route, item.params)}
              visible={isOpen}
              key={item.route + idx}
              icon={item.icon}
            />
          ))}
      </View>
      <Pressable
        pointerEvents={"box-only"}
        style={(pressed) => [
          styles.plusButton,
          pressed.pressed && { opacity: 0.5 },
        ]}
        onPress={
          floatingMenuDescription.length > 1
            ? toggle
            : onPress.bind(
                this,
                floatingMenuDescription[0].route,
                floatingMenuDescription[0].params
              )
        }
      >
        <Animated.View style={{ transform: [{ rotate: rotation }] }}>
          <CustomIcon name="community:plus" size={34} color={Colors.white} />
        </Animated.View>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 11,
  },
  innerContainer: {
    left: 0,
    top: 0,
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    zIndex: 10,
    backgroundColor: "rgba(0,0,0,.6)",
  },
  items: {
    position: "absolute",
    right: 0,
    bottom: 0,
    margin: 16,
    zIndex: 11,
    marginBottom: 150,
    flexDirection: "column-reverse",
    alignItems: "flex-end",
  },
  plusButton: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: Colors.primary,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    bottom: 60,
    margin: 16,
    zIndex: 11,
  },
});

export default FloatingMenu;
