import axios, { AxiosRequestConfig } from "axios";

export async function call<ResponseType = any>(config: AxiosRequestConfig) {
  const result = await axios.request<ResponseType>({
    ...config,
    headers: {
      "Content-Type": "application/json",
      ...config.headers,
    },
  });
  return result;
}

export async function makeRequest<ResponseType = any>(
  config: AxiosRequestConfig
) {
  const result = await axios.request<ResponseType>({
    ...config,
    headers: {
      "Content-Type": "application/json",
      ...(config.headers || {}),
    },
  });
  return result;
}
