import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { SurveyWithActions } from "../../../types/v2/survey";

export const getIntroSurvey = createAsyncThunk<
  SurveyWithActions,
  undefined,
  IThunkApi
>("survey/getIntroSurvey", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(authorize(ENDPOINTS.survey.INTRO_SURVEY, token));
  return data;
});
