import { useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { ENDPOINTS } from "../../api/config";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import Input from "../../components/utils/input";
import PrimaryButton from "../../components/utils/primary_button";
import { Colors } from "../../constants/colors";
import { useCall } from "../../hooks/useAuthorizedCall";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";

const PasswordChangeScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.PasswordChange>) => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(false);
  const {
    settings_main_auth_change_password,
    settings_main_subheader,
    password_requirements,
    settings_change_password_type_current_password,
    settings_change_password_current_password,
    settings_change_password_type_new_password,
    settings_change_password_new_password,
    settings_change_password_change_password,
  } = useTranslations();
  const call = useCall();

  const changePassword = async () => {
    setLoading(true);
    try {
      setError(false);
      await call(ENDPOINTS.user.CHANGE_PASSWORD(currentPassword, newPassword));
      navigation.goBack();
    } catch (error: any) {
      const message = JSON.parse(error.response.request._response).detail;
      if (message === "password_invalid") setError(true);
    }
    setLoading(false);
  };

  return (
    <Container>
      <View>
        <Pressable
          style={{ paddingVertical: 2, marginBottom: 15 }}
          onPress={() => navigation.goBack()}
        >
          <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
        </Pressable>
      </View>
      <MainHeading text={settings_main_auth_change_password} size={"large"} />
      <View style={{ marginTop: 10 }}>
        <SubHeading text={settings_main_subheader} />
      </View>
      <Divider />
      <View>
        <Text style={style.section}>
          {settings_change_password_type_current_password}
        </Text>
        <Input
          value={currentPassword}
          setValue={setCurrentPassword}
          placeholder={settings_change_password_current_password}
          type={"password"}
        />
        <Text style={style.section}>
          {settings_change_password_type_new_password}
        </Text>
        <Input
          value={newPassword}
          setValue={setNewPassword}
          placeholder={settings_change_password_new_password}
          type={"password"}
          postfix={password_requirements}
        />
      </View>
      <View style={{ flex: 1, justifyContent: "flex-end" }}>
        {error && (
          <Text
            style={{
              textAlign: "center",
              marginBottom: 10,
              color: Colors.error,
            }}
          >
            Invalid password
          </Text>
        )}
        <PrimaryButton
          label={settings_change_password_change_password}
          onPress={changePassword}
          loading={loading}
          disabled={loading}
        />
      </View>
    </Container>
  );
};

const style = StyleSheet.create({
  link: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    marginVertical: 2,
  },
  link_text: {
    fontWeight: "700",
  },
  section: {
    color: Colors.primary,
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 21,
    marginBottom: 10,
  },
});

export default PasswordChangeScreen;
