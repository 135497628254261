import { FC } from "react";
import { StyleSheet, Text } from "react-native";
import { Colors } from "../../constants/colors";

interface Props {
  label: string;
  onPress?: () => void;
}

const SecondaryAction: FC<Props> = ({ label, onPress }) => {
  return (
    <Text style={styles.button} onPress={onPress}>
      {label}
    </Text>
  );
};

const styles = StyleSheet.create({
  button: {
    color: Colors.primary,
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 22,
    textAlign: "center",
    marginBottom: 6,
  },
});

export default SecondaryAction;
