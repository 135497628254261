import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { FC } from "react";
import { View } from "react-native";
import MainHeading from "../components/typography/main_heading";
import { Colors } from "../constants/colors";
import { useAppSelector } from "../hooks/redux";
import { useTranslations } from "../hooks/useTranslations";
import DayPlanScreen from "../screens/therapy/DayPlanScreen";
import TherapyScreen from "../screens/therapy/TherapyScreen";
import { RootBottomTabScreenProps } from "./BottomTabNavigation.types";
import { SCREEN } from "./screens";
import { TherapyTabParamList } from "./TherapyTabNavigation.types";

const Tab = createMaterialTopTabNavigator<TherapyTabParamList>();

export const TherapyTabNavigation: FC<
  RootBottomTabScreenProps<SCREEN.Therapy>
> = ({ navigation }) => {
  const {
    therapy_tab_sessions,
    therapy_tab_plan_of_the_day,
    menu_bottom_therapy,
  } = useTranslations();
  const { subscription } = useAppSelector((state) => state.therapyParticipant);
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          marginHorizontal: 30,
          marginTop: 30,
          marginBottom: 15,
        }}
      >
        <MainHeading text={menu_bottom_therapy} />
      </View>
      <Tab.Navigator
        screenOptions={{
          swipeEnabled: false,
          tabBarActiveTintColor: Colors.primary,
          tabBarInactiveTintColor: Colors.gray,
          tabBarLabelStyle: {
            fontSize: 18,
            fontWeight: "bold",
            textTransform: "capitalize",
          },
          tabBarItemStyle: {
            width: "auto",
            paddingHorizontal: 30,
          },
        }}
      >
        <Tab.Screen
          name={SCREEN.Sessions}
          options={{
            title: therapy_tab_sessions,
          }}
          component={TherapyScreen}
        />
        <Tab.Screen
          name={SCREEN.DayPlan}
          options={{
            title: therapy_tab_plan_of_the_day,
          }}
          component={DayPlanScreen}
          listeners={() => ({
            tabPress: (event) => {
              if (subscription === null) {
                event.preventDefault();
                navigation.navigate(SCREEN.Payments);
              }
            },
          })}
        />
      </Tab.Navigator>
    </View>
  );
};

export default TherapyTabNavigation;
