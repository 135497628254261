import { FC, useEffect, useMemo } from "react";
import Loader from "../components/Loader";
import Survey from "../components/Survey";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { SCREEN } from "../navigation/screens";
import { DashboardActions } from "../store/redux/dashboard";
import { SleepDiarySummaryActions } from "../store/redux/sleepDiarySummary";
import { getAnswers, SurveyActions } from "../store/redux/survey";
import { ThoughtsActions } from "../store/redux/thoughts";
import { getDiary } from "../store/redux/thunk_functions/getDiary";
import { getHabits } from "../store/redux/thunk_functions/getHabits";
import { getIntroSurvey } from "../store/redux/thunk_functions/getIntroSurvey";
import { getOrCreateThought } from "../store/redux/thunk_functions/getOrCreateThought";
import { getSurvey } from "../store/redux/thunk_functions/getSurvey";
import { markAllAsStale } from "../store/redux/thunk_functions/other";
import { updateSurvey } from "../store/redux/thunk_functions/updateSurvey";
import { refreshUserState } from "../store/redux/thunk_functions/user";
import { RootStackScreenProps } from "../types/navigation";
import { Action, SurveyWithActions } from "../types/v2/survey";
import {
  canDisplayStoreReview,
  requestStoreReview,
} from "../utils/store-review";
import { getInsomniaChecker } from "../store/redux/thunk_functions/getInsomniaChecker";
import { Linking } from "react-native";

const SurveyScreen: FC<RootStackScreenProps<SCREEN.Survey>> = ({
  navigation,
  route,
}) => {
  const dispatch = useAppDispatch();
  const { type, uuid, date } = route.params;

  const {
    survey: {
      loading,
      survey,
      actions,
      hidden,
      slideTemplate,
      itemById,
      itemIds,
      questionById,
      questionIds,
      primaryActionDisabled,
      hasQuestions,
      itemIdByQuestionId,
    },
    manifest: { translations },
    therapyParticipant,
  } = useAppSelector((state) => state);

  const content = useMemo(
    () => itemIds.map((uuid) => itemById[uuid]),
    [itemIds]
  );
  const answers = useMemo(
    () => getAnswers(Object.values(questionById)),
    [questionById]
  );
  const questionByItemUuid = useMemo(
    () =>
      questionIds.reduce(
        (prev, curr) => ({
          ...prev,
          [itemIdByQuestionId[curr]]: questionById[curr],
        }),
        {}
      ),
    [questionIds, itemIdByQuestionId, questionById]
  );

  const canRequestStoreReview = canDisplayStoreReview(
    therapyParticipant.subscription?.start_date
  );

  useEffect(() => {
    switch (type) {
      case "sleep_diary":
        dispatch(getDiary(date!));
        return () => {
          dispatch(SleepDiarySummaryActions.markAsStale());
        };
      case "intro_survey":
        dispatch(getIntroSurvey());
        return;
      case "thought_diary":
        dispatch(getOrCreateThought({ uuid }));
        return () => {
          dispatch(ThoughtsActions.markAsStale());
        };
      case "habits":
        dispatch(getHabits(date));
        return () => {
          dispatch(SleepDiarySummaryActions.markAsStale());
          dispatch(DashboardActions.markAsStale());
        };
      case "insomnia_checker":
        dispatch(getInsomniaChecker());
        return;
      case "generic":
        dispatch(getSurvey({ uuid: uuid! }));
        return () => {
          dispatch(markAllAsStale());
        };
      default:
        dispatch(getSurvey({ uuid: uuid! }));
        return () => {
          dispatch(markAllAsStale());
        };
    }
  }, [type]);

  if (
    survey === undefined ||
    slideTemplate === undefined ||
    actions === undefined
  ) {
    return <Loader />;
  }

  const { next, secondary, prev } = actions;

  const handleClose = () => navigation.goBack();

  const onImmediatelyAction = async (next: Action) => {
    if (next.route === "Survey") {
      dispatch(
        getSurvey({ uuid: next.params!.uuid, action: next.params!.action })
      );
    } else {
      dispatch(refreshUserState());
      const route = SCREEN[next.route as keyof typeof SCREEN];
      if (canRequestStoreReview) {
        requestStoreReview();
      }
      navigation.navigate(route as any, next.params as any);
    }
  };

  const handlePrimaryAction = async () => {
    const { uuid } = survey;

    let newSurvey: SurveyWithActions | null = null;
    if (actions.next.url !== null) {
      Linking.openURL(actions.next.url);
    } else if (!hasQuestions && actions.next.route !== "Survey") {
      dispatch(refreshUserState());
      const route = SCREEN[next.route as keyof typeof SCREEN];
      if (canRequestStoreReview) {
        requestStoreReview();
      }
      navigation.navigate(route as any, next.params as any);
    } else if (!hasQuestions) {
      const data = await dispatch(
        getSurvey({ uuid: next.params!.uuid, action: next.params!.action })
      );
      newSurvey = data.payload as SurveyWithActions;
    } else {
      const slide_uuid = next.params?.slide_uuid;
      const payload = await dispatch(
        updateSurvey({ answers, uuid, slide_uuid })
      );
      newSurvey = payload.payload as SurveyWithActions;
    }
    if (newSurvey !== null) {
      const { next: newNext } = newSurvey.actions;
      if (newNext.immediately) {
        onImmediatelyAction(newNext);
      }
    }
  };

  const handleSecondaryAction = () => {
    if (secondary == undefined) {
      return undefined;
    }
    dispatch(
      getSurvey({
        uuid: secondary.params!.uuid,
        action: secondary.params!.action,
      })
    );
  };

  const handlePrevious = () => {
    if (prev === undefined) {
      return;
    }
    dispatch(
      getSurvey({
        uuid: prev.params!.uuid,
        action: prev.params!.action,
      })
    );
  };

  const handleUpdate = (uuid: string, value: any) => {
    dispatch(SurveyActions.setAnswer({ uuid, value }));
  };

  return (
    <Survey
      survey={survey}
      content={content}
      questions={questionByItemUuid}
      hidden={hidden}
      slideTemplate={slideTemplate}
      translations={translations}
      loading={loading}
      showLabel={type !== "intro_survey"}
      primaryLabel={translations[next.label]}
      secondaryLabel={
        secondary !== undefined ? translations[secondary.label] : undefined
      }
      onClose={handleClose}
      onPrimary={handlePrimaryAction}
      onSecondary={handleSecondaryAction}
      onPrevious={prev !== undefined ? handlePrevious : undefined}
      onUpdate={handleUpdate}
      primaryActionDisabled={primaryActionDisabled}
    />
  );
};

export default SurveyScreen;
