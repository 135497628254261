import React, { FC, useState } from "react";
import { Text, View } from "react-native";
import Carousel from "react-native-reanimated-carousel";
import { Colors } from "../../constants/colors";
import { useContainerWidth } from "../../hooks/useContainerWidth";

export interface IProps {
  data: any[];
  text: string;
  loop?: boolean;
  height?: number;
  Element: FC<any>;
  offset?: number;
}

const CarouselBlueprint: FC<IProps> = ({
  data,
  text,
  loop = false,
  height,
  offset = 60,
  Element,
}) => {
  const width = useContainerWidth();

  return (
    <View style={{ marginVertical: 10, alignItems: "center" }}>
      {text !== "" && (
        <View
          style={{ marginBottom: 20, width: "100%", alignItems: "flex-start" }}
        >
          <Text
            style={{
              fontSize: 18,
              lineHeight: 26,
              color: Colors.primary,
              fontWeight: "700",
            }}
          >
            {text}
          </Text>
        </View>
      )}
      <Carousel
        data={data}
        width={width - offset}
        windowSize={width}
        height={height}
        loop={loop}
        renderItem={({ item, index }) => (
            <Element {...(item as any)} height={height} />
        )}
        panGestureHandlerProps={{
          activeOffsetX: [-10, 10],
        }}
        mode="parallax"
        modeConfig={{
          parallaxScrollingOffset: 200,
          parallaxScrollingScale: 0.95,
          parallaxAdjacentItemScale: 0.5,
        }}
      />
    </View>
  );
};

export default CarouselBlueprint;
