import * as React from "react";
import { StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import Close from "./Close";

interface Props {
  children: React.ReactNode;
  isVisible: boolean;
  onClose?: () => void;
}

const PopUp = ({ children, isVisible, onClose }: Props) => {
  return (
    <Modal isVisible={isVisible}>
      <View style={styles.container}>
        {onClose && (
          <View style={{ position: "absolute", right: 10, top: 10 }}>
            <Close onPress={onClose} />
          </View>
        )}
        {children}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    backgroundColor: "white",
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});

export default PopUp;
