import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { SurveyWithActions } from "../../../types/v2/survey";

interface Args {
  uuid: string;
  slide_uuid?: string;
  answers: {
    [uuid: string]: string;
  };
}

export const updateSurvey = createAsyncThunk<
  SurveyWithActions,
  Args,
  IThunkApi
>("survey/update", async (args, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(
    authorize(
      ENDPOINTS.survey.SET_ANSWERS(args.uuid, args.answers, args.slide_uuid),
      token
    )
  );
  return data;
});
