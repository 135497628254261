export const settingsRoutes = {
  GET_LANGUAGES: {
    url: "/manifest/languages",
    method: "GET",
  },
  GET_TIMEZONES: {
    url: "/manifest/timezones",
    method: "GET",
  },
  CHANGE_PASSWORD: (current_password: string, new_password: string) => ({
    url: "/users/me/change-password",
    method: "POST",
    data: {
      current_password,
      new_password,
    },
  }),
  EDIT_USER: (data: any) => ({
    url: "/users/me",
    method: "PUT",
    data,
  }),
  GET_NOTIFICATIONS: (type: "email" | "push") => ({
    url: `/notifications?type=${type}`,
    method: "GET",
  }),
  UPDATE_NOTIFICATION: (uuid: string, subscription: boolean) => ({
    url: `/notifications/${uuid}`,
    method: "PUT",
    data: {
      subscription,
    },
  }),
};
