import { FC, useState } from "react";
import {
  KeyboardAvoidingView,
  Pressable,
  StyleSheet,
  View
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { ENDPOINTS } from "../../api/config";
import MainHeading from "../../components/typography/main_heading";
import CustomText from "../../components/typography/text";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import Input from "../../components/utils/input";
import PrimaryButton from "../../components/utils/primary_button";
import { useAppDispatch } from "../../hooks/redux";
import { useCall } from "../../hooks/useAuthorizedCall";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { refreshUserState } from "../../store/redux/thunk_functions/user";

const EmailValidationScreen: FC<
  RootStackScreenProps<SCREEN.EmailValidation>
> = () => {
  const {
    next,
    auth_email_validation_no_email_received,
    auth_email_validation_no_email_received_cta,
    auth_email_validation_placeholder_verification_code,
    auth_email_validation_content,
    auth_email_validation_header,
    auth_password_reset_verification_content,
  } = useTranslations();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const call = useCall();

  const verify = async () => {
    let hasErrors = false;
    if (!code) {
      setCodeError(auth_password_reset_verification_content);
      hasErrors = true;
    } else {
      setCodeError(null);
    }
    if (hasErrors) {
      return;
    }

    setLoading(true);
    await call(ENDPOINTS.user.VERIFY(code));
    await dispatch(refreshUserState());
    setLoading(false);
  };

  const resendVerificationEmail = async () => {
    await call(ENDPOINTS.user.RESEND_VERIFICATION_EMAIL);
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        keyboardShouldPersistTaps={"handled"}
      >
        <Container>
          <MainHeading text={auth_email_validation_header} size="large" />
          <Divider />
          <CustomText text={auth_email_validation_content} />
          <View style={{ marginBottom: 16, marginTop: 15 }}>
            <Input
              placeholder={auth_email_validation_placeholder_verification_code}
              value={code}
              setValue={setCode}
              keyboardType="numeric"
              error={codeError}
            />
          </View>
          <View style={styles.action_container}>
            <View style={{ flexDirection: "column", justifyContent: "center" }}>
              <CustomText
                center
                text={auth_email_validation_no_email_received}
              />
              <Pressable
                onPress={resendVerificationEmail}
                style={{ flexDirection: "row", justifyContent: "center" }}
              >
                <CustomText
                  center
                  text={auth_email_validation_no_email_received_cta}
                  link
                />
              </Pressable>
            </View>
            <View style={{ marginTop: 20 }}>
              <PrimaryButton
                label={next}
                disabled={loading}
                loading={loading}
                onPress={verify}
              />
            </View>
          </View>
        </Container>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    marginVertical: 16,
  },
  action_container: {
    flex: 1,
    justifyContent: "flex-end",
  },
});

export default EmailValidationScreen;
