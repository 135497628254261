import { FC } from "react";
import {
  SleepRestrictionsQuestion,
  SleepRestrictions as SleepRestrictionsType,
} from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import SleepRestrictionsPicker from "../inputs/SleepRestrictionsPicker";

interface Props
  extends QuestionProps<SleepRestrictionsType, SleepRestrictionsQuestion> {
  labelFromBedContent: string;
  labelFromBedSubcontent: string;
  labelToBedSubcontent: string;
  labelToBedContent: string;
  labelTooLargeDeviation: string;
}

const SleepRestrictions: FC<Props> = ({
  question,
  readOnly,
  labelFromBedContent,
  labelFromBedSubcontent,
  labelToBedSubcontent,
  labelToBedContent,
  labelTooLargeDeviation,
  onChange,
}) => {
  const {
    acceptable_deviation,
    recommended_in_bed_time,
    answer: value,
  } = question;

  return (
    <SleepRestrictionsPicker
      value={value!}
      acceptableDeviation={acceptable_deviation}
      recommendedInBedTime={recommended_in_bed_time || undefined}
      readOnly={readOnly}
      labelFromBedContent={labelFromBedContent}
      labelFromBedSubcontent={labelFromBedSubcontent}
      labelToBedContent={labelToBedContent}
      labelToBedSubcontent={labelToBedSubcontent}
      labelTooLargeDeviation={labelTooLargeDeviation}
      onChange={onChange}
    />
  );
};

export default SleepRestrictions;
