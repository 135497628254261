import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { Note } from "../../../types/v2/note";

export const getNotes = createAsyncThunk<Note[], undefined, IThunkApi>(
  "notes/get",
  async (_, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call(authorize(ENDPOINTS.tools.GET_NOTES, token));
    return data;
  }
);

export const createNote = createAsyncThunk<Note, string, IThunkApi>(
  "notes/create",
  async (content, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call(
      authorize(ENDPOINTS.tools.CREATE_NOTE(content), token)
    );
    return data;
  }
);

export const updateNote = createAsyncThunk<Note, Note, IThunkApi>(
  "notes/update",
  async (note, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call(
      authorize(ENDPOINTS.tools.UPDATE_NOTE(note.uuid, note.content), token)
    );
    return data;
  }
);

export const deleteNote = createAsyncThunk<string, string, IThunkApi>(
  "notes/delete",
  async (uuid, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call(
      authorize(ENDPOINTS.tools.DELETE_NOTE(uuid), token)
    );
    return data;
  }
);
