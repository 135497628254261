import { createSlice } from "@reduxjs/toolkit";
import { Note } from "../../types/v2/note";
import {
  createNote,
  deleteNote,
  getNotes,
  updateNote,
} from "./thunk_functions/notes";

interface NotesState {
  needRefresh: boolean;
  loading: boolean;
  error: boolean;
  byId: { [uuid: string]: Note };
  ids: string[];
}

const initialState: NotesState = {
  needRefresh: true,
  loading: false,
  error: false,
  ids: [],
  byId: {},
};

const notesSlice = createSlice({
  name: "notes",
  initialState: initialState,
  reducers: {
    markAsStale(state) {
      state.needRefresh = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotes.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getNotes.fulfilled, (state, action) => {
        state.needRefresh = false;
        state.loading = false;
        state.error = false;
        state.ids = action.payload.map((note) => note.uuid);
        state.byId = action.payload.reduce(
          (prev, curr) => ({ ...prev, [curr.uuid]: curr }),
          {}
        );
      })
      .addCase(getNotes.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        const note = action.payload;
        state.ids.push(note.uuid);
        state.byId[note.uuid] = note;
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const note = action.payload;
        state.byId[note.uuid] = note;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        const uuid = action.payload;
        state.ids = state.ids.filter((id) => id !== uuid);
        delete state.byId[uuid];
      });
  },
});

export const NotestActions = notesSlice.actions;
export const notesReducer = notesSlice.reducer;
