import { Linking, Pressable, View } from "react-native";
import PrimaryButton from "../../components/controls/PrimaryButton";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";

const NotificationSettingsScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.NotificationsSettings>) => {
  const { settings_main_header, settings_main_notifications_push } =
    useAppSelector((state) => state.manifest.translations);

  return (
    <Container>
      <View style={{}}>
        <Pressable
          style={{ paddingVertical: 2, marginBottom: 15 }}
          onPress={() => navigation.goBack()}
        >
          <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
        </Pressable>
      </View>
      <MainHeading text={settings_main_notifications_push} size={"small"} />
      <View style={{ marginTop: 10 }}>
        <SubHeading text={settings_main_header} />
      </View>
      <Divider />
      <View>
        <View style={{ marginTop: 30 }}>
          <PrimaryButton
            onPress={() => {
              Linking.openSettings();
            }}
          >
            {settings_main_header}
          </PrimaryButton>
        </View>
      </View>
    </Container>
  );
};

export default NotificationSettingsScreen;
