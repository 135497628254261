import { FC } from "react";
import { Text, View } from "react-native";
import Modal from "react-native-modal";
import PrimaryButton from "../utils/primary_button";
import { error_popup_styles } from "./error_popup.styles";

interface IProps {
  visible: boolean;
  dismiss: () => void;
  text: string;
}

const ErrorPopup: FC<IProps> = ({ dismiss, visible, text }) => {
  return (
    <Modal
      isVisible={visible}
      style={error_popup_styles.modal}
      onBackdropPress={dismiss}
    >
      <View style={error_popup_styles.container}>
        <Text style={error_popup_styles.text}>{text}</Text>
        <View style={{ padding: 30 }}>
          <PrimaryButton label={"Zamknij"} onPress={dismiss} />
        </View>
      </View>
    </Modal>
  );
};

export default ErrorPopup;
