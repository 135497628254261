import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { SleepRestrictions as SleepRestrictionsProps } from "../../types/v2/slides/slide_content";
import { secondsToHoursAndMinutes } from "../../utils/secondsToHoursAndMinutes";

const SleepRestrictions: FC<SleepRestrictionsProps> = ({
  label,
  recommended_in_bed_time,
}) => {
  return (
    <View style={styles.container}>
      <Text>{label}</Text>
      <Text style={styles.bold_text}>
        {secondsToHoursAndMinutes(recommended_in_bed_time)}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.primary_light,
    paddingHorizontal: 12,
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  bold_text: {
    fontWeight: "700",
  },
});

export default SleepRestrictions;
