import { RichCard as RichCardProps } from "../../types/v2/richCard";
import CarouselBlueprint from "../carousel_blueprint/carousel_blueprint";
import RichCard from "./RichCard";

interface Props {
  items: RichCardProps[];
}

const RichCards = (props: Props) => {
  return (
    <CarouselBlueprint
      data={props.items}
      text=""
      height={400}
      Element={RichCard}
    />
  );
};

export default RichCards;
