import { Pressable, StyleSheet, Text, View } from "react-native";
import { FC, useState } from "react";
import { Colors } from "./../constants/colors";
import CustomIcon from "./CustomIcon";
import CustomMarkdown from "./utils/markdown";

interface Props {
  title: string;
  text: string;
}

const DropdownText: FC<Props> = ({ text, title }) => {
  const [open, toggle] = useState(false);

  return (
    <View style={styles.container}>
      <Pressable onPress={() => toggle(!open)} style={styles.title_container}>
        <Text style={styles.title}>{title}</Text>
        <CustomIcon
          name={"keyboard-arrow-down"}
          size={26}
          color={Colors.primary}
          style={[open && { transform: [{ rotate: "180deg" }] }]}
        />
      </Pressable>
      <View
        style={[
          styles.text_container,
          open && { height: "auto", paddingBottom: 10 },
        ]}
      >
        <CustomMarkdown text={text} />
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  title_container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    flex: 1,
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "700",
    color: Colors.primary,
  },
  text: {
    fontSize: 14,
    lineHeight: 22,
    color: Colors.dark,
    marginTop: 10,
  },
  text_container: {
    height: 0,
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
});

export default DropdownText;
