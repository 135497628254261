import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import Toast from "react-native-root-toast";
import { call } from "../api";
import { authorize } from "../api/utils";
import { ApiError } from "../types/v2/api";
import { getMessageKey } from "../utils/apiErrors";
import { useAppSelector } from "./redux";
import { useTranslations } from "./useTranslations";

export function useAuthorizedCall() {
  const token = useAppSelector((state) => state.auth.token);

  async function authorizedCall<T = any>(config: AxiosRequestConfig) {
    return call<T>(authorize(config, token));
  }

  return authorizedCall;
}

interface CallConfig {
  authorize?: boolean;
  showToastOnError?: boolean;
}

const defaultConfig: CallConfig = {
  authorize: true,
  showToastOnError: true,
};

export function useCall(config?: CallConfig) {
  const finalConfig = { ...defaultConfig, ...config };
  const token = useAppSelector((state) => state.auth.token);
  const translations = useTranslations();

  async function authorizedCall<T = any>(config: AxiosRequestConfig) {
    if (finalConfig.authorize) {
      config = authorize(config, token);
    }
    try {
      const { data } = await call<T>(config);
      return data;
    } catch (error) {
      const axiosError = error as AxiosError<ApiError>;
      const apiError = axiosError.response?.data;
      let errorKey = "unknown_error";
      if (apiError !== undefined) {
        errorKey = getMessageKey(apiError);
      }
      if (finalConfig.showToastOnError) {
        Toast.show(translations[errorKey]);
      }
      throw {
        message: translations[errorKey],
        code: axiosError.response?.status,
      };
    }
  }

  return authorizedCall;
}
