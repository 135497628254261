import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { useTranslations } from "../../hooks/useTranslations";
import { RichCard as RichCardProps } from "../../types/v2/richCard";
import { addOpacity } from "../../utils/colors";
import CustomIcon from "../CustomIcon";

interface Props extends RichCardProps {}

const RichCard = (props: Props) => {
  const { color, icon, title, subtitle, text } = props;
  const textColor = color || undefined;
  const backgroundColor = textColor ? addOpacity(textColor, 0.1) : undefined;
  const border = textColor === undefined;
  const translations = useTranslations();

  return (
    <View
      style={{
        backgroundColor: Colors.white,
        flex: 1,
        height: "100%",
      }}
    >
      <View
        style={[
          styles.container,
          { backgroundColor },
          border && styles.bordered,
        ]}
      >
        <View style={styles.headline_container}>
          {icon && (
            <View style={{ paddingRight: 5 }}>
              <CustomIcon name={icon} size={36} color={textColor} />
            </View>
          )}
          <View style={{ flexShrink: 1 }}>
            {title && (
              <Text style={[styles.title, { color: textColor }]}>
                {translations[title] || title}
              </Text>
            )}
            {subtitle && (
              <Text style={[styles.subtitle, { color: textColor }]}>
                {translations[subtitle] || subtitle}
              </Text>
            )}
          </View>
        </View>
        <Text style={styles.text}>{translations[text] || text}</Text>
      </View>
    </View>
  );
};

export default RichCard;

const styles = StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 8,
    flex: 1,
    height: "100%",
  },
  bordered: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.mediumgray,
    elevation: 0,
  },
  headline_container: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 22,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 22,
  },
  text: {
    fontWeight: "300",
    fontSize: 14,
    lineHeight: 22,
    color: Colors.dark,
    marginTop: 20,
  },
});
