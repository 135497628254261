import { format, Locale } from "date-fns";
import { pl, uk, enUS as en } from "date-fns/locale";
import { useAppSelector } from "./redux";

const code2locale: { [code: string]: Locale } = {
  pl,
  uk,
  en,
};

export function useLocalizedFormatter() {
  const language = useAppSelector(
    (state) => state.therapyParticipant.user?.language || "pl"
  );

  return (date: string | Date, formatStr: string = "PP") => {
    if (typeof date == "string") {
      date = new Date(date);
    }
    return format(date, formatStr, { locale: code2locale[language] });
  };
}
