import { createSlice } from "@reduxjs/toolkit";
import {
  AppConfiguration,
  FAQItem,
  LanguageChoice,
} from "../../types/v2/appConfiguration";
import { ProfileConfiguration } from "../../types/v2/profileConfiguration";
import {
  getAppConfiguration,
  getFAQ,
  getLanguageConfiguration,
  getProfileConfiguration,
} from "./thunk_functions/configuration";
import { version } from "../../package.json";

interface ConfigurationState {
  profile: ProfileConfiguration;
  app: AppConfiguration;
  languages: LanguageChoice[];
  languagesLoaded: boolean;
  faq: FAQItem[];
  faqLoaded: boolean;
}

const initialState: ConfigurationState = {
  profile: {
    age_choices: [],
    available_avatars: [],
    pronouns_choices: [],
    gender_choices: [],
  },
  app: {
    guide_url: "",
    privacy_policy_url: "",
    terms_url: "",
    license_url: "",
    min_supported_version: "0.0.0",
    upgrade_required: false,
  },
  languages: [],
  languagesLoaded: false,
  faq: [],
  faqLoaded: false,
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppConfiguration.fulfilled, (state, action) => {
        state.app = {
          ...action.payload,
          upgrade_required: action.payload.min_supported_version > version,
        };
      })
      .addCase(getProfileConfiguration.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(getLanguageConfiguration.fulfilled, (state, action) => {
        state.languages = action.payload;
        state.languagesLoaded = true;
      })
      .addCase(getFAQ.fulfilled, (state, action) => {
        state.faq = action.payload;
        state.faqLoaded = true;
      });
  },
});

export const configurationReducer = configurationSlice.reducer;
