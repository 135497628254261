import { FC } from "react";
import { IntensityQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import IntensityPicker from "../inputs/IntensityPicker";
import QuestionWrapper from "./QuestionWrapper";

const Intensity: FC<QuestionProps<number, IntensityQuestion>> = ({
  onChange,
  question,
  readOnly,
}) => {
  const {
    template: { choices, content, subcontent },
    answer: value,
  } = question;

  return (
    <QuestionWrapper
      content={content || undefined}
      subcontent={subcontent || undefined}
    >
      <IntensityPicker
        choices={choices}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />
    </QuestionWrapper>
  );
};

export default Intensity;
