import { ResizeMode } from "expo-av";
import { useEffect, useState } from "react";
import {
  Image,
  ImageBackground,
  Platform,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import Carousel from "react-native-reanimated-carousel";
import CarousePagination from "../components/CarouselPagination";
import Link from "../components/controls/Link";
import PrimaryButtonLink from "../components/controls/PrimaryButtonLink";
import MainHeading from "../components/typography/main_heading";
import { Colors } from "../constants/colors";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { useContainerWidth } from "../hooks/useContainerWidth";
import { SCREEN } from "../navigation/screens";
import { RootStackScreenProps } from "../navigation/StackNavigation.types";
import { fetchOnboardingCarousel } from "../store/redux/thunk_functions/fetchOnboardingCarousel";
import { OnboardingCarouselItem } from "../types/onboarding";

const OnboardingScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.Onboarding>) => {
  const { height } = useWindowDimensions();
  const width = useContainerWidth();
  const [index, setIndex] = useState(0);
  const {
    manifest: {
      language,
      translations: { onboarding_cta, onboarding_has_account, auth_signin_cta },
    },
    onboarding: { items, needRefresh },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh && language !== null) {
      dispatch(fetchOnboardingCarousel(language));
    }
  }, [needRefresh, language]);

  return (
    <>
      <View
        style={{
          position: "absolute",
          top: 0,
          flex: 1,
        }}
      >
        <Image
          source={require("../assets/images/homepage_doodle.png")}
          style={{
            aspectRatio: 1560 / 860,
            width: width,
            resizeMode: "contain",
            maxHeight: "25%",
          }}
        ></Image>
      </View>
      <View style={{ flex: 1 }}>
        <View
          style={[styles.container, { flex: 1, justifyContent: "flex-end" }]}
        >
          {items.length > 0 && (
            <Carousel
              loop
              vertical={false}
              data={items}
              autoPlay={true}
              autoPlayInterval={5000}
              scrollAnimationDuration={200}
              onSnapToItem={(index) => {
                setIndex(index);
              }}
              snapEnabled
              width={width - 60}
              windowSize={width - 60}
              height={height / 2}
              pagingEnabled
              renderItem={(baseData: { item: OnboardingCarouselItem }) => (
                <View>
                  <View
                    style={{
                      width: "100%",
                    }}
                  >
                    <MainHeading text={baseData.item.title} size="medium" />
                    <Text
                      textBreakStrategy={
                        Platform.OS === "web" ? "highQuality" : "balanced"
                      }
                      style={{
                        fontSize: 18,
                        lineHeight: 30,
                        color: Colors.dark,
                        fontWeight: "700",
                        marginTop: 20,
                      }}
                    >
                      {baseData.item.content}
                    </Text>
                  </View>
                </View>
              )}
            />
          )}
          <CarousePagination numberOfDots={4} activeIndex={index} />
          <View style={styles.loginLinkContainer}>
            <Text style={styles.secondaryButton}>
              <Link to={{ screen: SCREEN.Login }}>
                {onboarding_has_account} {auth_signin_cta}
              </Link>
            </Text>
          </View>
          <PrimaryButtonLink
            to={{ screen: SCREEN.Survey, params: { type: "intro_survey" } }}
          >
            {onboarding_cta}
          </PrimaryButtonLink>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  secondaryButton: {
    color: Colors.primary,
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 28,
    textAlign: "center",
    paddingVertical: 17,
  },
  container: {
    marginHorizontal: 30,
    paddingBottom: 30,
    paddingTop: 100,
    justifyContent: "space-between",
    flexGrow: 1,
  },
  loginLinkContainer: {
    marginVertical: 15,
    marginTop: 0,
  },
});

export default OnboardingScreen;
