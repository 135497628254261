import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import CustomMarkdown from "../utils/markdown";

interface Props {
  content?: string;
  subcontent?: string;
}

const isMarkdown = (text: string) => text.includes("*") || text.includes("#");

const QuestionWrapper: FC<Props> = (props) => {
  const renderAsMarkdown =
    props.content !== undefined && isMarkdown(props.content);
  return (
    <View style={styles.container}>
      {props.content !== undefined ? (
        renderAsMarkdown ? (
          <CustomMarkdown text={props.content} />
        ) : (
          <Text style={styles.content}>{props.content}</Text>
        )
      ) : null}
      <View>{props.children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingVertical: 5
  },
  content: {
    lineHeight: 22,
    fontSize: 18,
    fontWeight: '600'
  }
});

export default QuestionWrapper;
