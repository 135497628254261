import React, { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";
import { Choice } from "../../types/v2/questionTemplate";
import RangeSelectRadio from "./RangeSelectRadio";

interface Props {
  choices: Choice<number>[];
  value: number | null;
  onChange: (value: number) => void;
  readOnly?: boolean;
}

const IntensityPicker: FC<Props> = ({ choices, value, readOnly, onChange }) => {
  const handlePress = (pressedValue: number) => {
    if (readOnly) {
      return;
    }
    onChange(pressedValue);
  };

  return (
    <View style={styles.container}>
      {choices.map((choice) => (
        <RangeSelectRadio
          label={choice.label}
          selected={value === choice.value}
          onPress={() => handlePress(choice.value)}
          key={choice.value}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  question: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 22,
    color: Colors.dark,
    marginBottom: 10,
  },
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  btn_container: {
    marginTop: 25,
  },
});

export default IntensityPicker;
