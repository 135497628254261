import { FC } from "react";
import { View } from "react-native";
import Svg, { Polygon } from "react-native-svg";
import { BarDescription, ChartSettings } from "../../types/charts";
import { calculateBarPartDrawData } from "./bar";

interface Props {
  bars: BarDescription[];
  startBarIdx: number;
  endBarIdx: number;
  chart_settings: ChartSettings;
}

interface AreaPartDrawData {
  width: number;
  topLeft: number;
  topRight: number;
  bottomRight: number;
  bottomLeft: number;
  fill: string;
  opacity: number;
}

function calculateAreaPartDrawData(
  start_bar_description: BarDescription,
  end_bar_description: BarDescription,
  width: number,
  chart_settings: ChartSettings
): AreaPartDrawData[] {
  const result: AreaPartDrawData[] = [];
  const start_bar_draw_data = calculateBarPartDrawData(
    start_bar_description,
    chart_settings
  );
  const end_bar_draw_data = calculateBarPartDrawData(
    end_bar_description,
    chart_settings
  );

  for (let i = 0; i < start_bar_description.bar.length; i++) {
    const startBarPart = start_bar_description.bar[i];
    const startBarPartDrawData = start_bar_draw_data[i];
    const endBarPartDrawData = end_bar_draw_data[i];
    result.push({
      fill: startBarPart.connector_color || startBarPart.color,
      opacity: startBarPart.connector_opacity || 0.2,
      width: width,
      topLeft: startBarPartDrawData.y,
      bottomLeft: startBarPartDrawData.y + startBarPartDrawData.height,
      topRight: endBarPartDrawData.y,
      bottomRight: endBarPartDrawData.y + endBarPartDrawData.height,
    });
  }
  return result;
}

const Area: FC<Props> = ({ bars, startBarIdx, endBarIdx, chart_settings }) => {
  const idxDiff = endBarIdx - startBarIdx;
  const width =
    idxDiff * chart_settings.barPadding +
    (idxDiff - 1) * chart_settings.barWidth;

  const drawData = calculateAreaPartDrawData(
    bars[startBarIdx],
    bars[endBarIdx],
    width,
    chart_settings
  );

  return (
    <View style={{ marginLeft: chart_settings.barWidth }}>
      <Svg height={chart_settings.height} width={width}>
        {drawData.map((part, idx) => (
          <Polygon
            points={`0,${part.topLeft} 0,${part.bottomLeft} ${width},${part.bottomRight}, ${width},${part.topRight}`}
            key={`area-between-bars-${idx}`}
            fill={part.fill}
            stroke={0}
            opacity={part.opacity}
          ></Polygon>
        ))}
      </Svg>
    </View>
  );
};

export default Area;
