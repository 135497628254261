import { FC, useRef, useState } from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  View,
} from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";

interface IProps {
  value: string;
  setValue: (arg: string) => void;
  placeholder: string;
  error?: string | null;
  type?: TextInputProps["textContentType"];
  keyboardType?: TextInputProps["keyboardType"];
  postfix?: string;
  prefix?: string;
  numberOfLines?: number;
  editable?: boolean;
  autoCorrect?: TextInputProps["autoCorrect"];
  autoCapitalize?: TextInputProps["autoCapitalize"];
  autoComplete?: TextInputProps["autoComplete"];
}

const Input: FC<IProps> = ({
  value,
  setValue,
  placeholder,
  error = false,
  type = "none",
  keyboardType = "default",
  postfix,
  prefix,
  numberOfLines = 1,
  editable = true,
  autoCorrect,
  autoCapitalize,
  autoComplete,
}) => {
  const [inputType, setInputType] = useState(type);
  const inputRef = useRef<TextInput>(null);

  const togglePassword = () => {
    if (inputType !== "password") setInputType("password");
    else setInputType("none");
  };

  return (
    <View style={[{ marginVertical: 9 }]}>
      <View>
        {Boolean(prefix) && <Text style={styles.prefix}>{prefix}</Text>}
        <View style={[styles.container]}>
          {numberOfLines <= 1 && (
            <Pressable
              style={[styles.label_container]}
              onPress={() => inputRef.current?.focus()}
              onFocus={() => inputRef.current?.focus()}
            >
              <Text style={styles.label}> {placeholder}</Text>
            </Pressable>
          )}
          <TextInput
            style={[
              styles.input,
              numberOfLines > 1 && {
                paddingHorizontal: 10,
                textAlignVertical: "top",
              },
            ]}
            autoCorrect={autoCorrect}
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            secureTextEntry={inputType === "password"}
            textContentType={inputType}
            keyboardType={keyboardType}
            placeholder={numberOfLines > 1 ? placeholder : ""}
            value={value}
            onChangeText={setValue}
            ref={inputRef}
            multiline={numberOfLines > 1}
            numberOfLines={numberOfLines}
            editable={editable}
            returnKeyType="done"
          />
          {/* show/hide password */}
          {type === "password" && (
            <Pressable style={styles.icon} onPress={togglePassword}>
              <View style={styles.icon_circle}>
                {inputType === "password" ? (
                  <CustomIcon
                    name="community:eye"
                    size={13}
                    color={Colors.darkgray}
                  />
                ) : (
                  <CustomIcon
                    name="community:eye-off"
                    size={13}
                    color={Colors.darkgray}
                  />
                )}
              </View>
            </Pressable>
          )}
        </View>
      </View>
      {error !== null && <Text style={styles.error}>{error}</Text>}
      {Boolean(postfix) && error === null && (
        <Text style={styles.postfix}>{postfix}</Text>
      )}
    </View>
  );
};

export default Input;

const styles = StyleSheet.create({
  container: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.lightgray,
    // borderWidth: 1,
    // borderColor: Colors.gray,
    borderRadius: 4,
  },
  label_container: {
    paddingHorizontal: 20,
  },
  label: {
    fontSize: 14,
    color: Colors.darkgray,
    lineHeight: 17,
    fontWeight: "400",
  },
  input: {
    flex: 1,
    fontSize: 14,
    lineHeight: 17,
    color: Colors.dark,
    paddingVertical: 15,
    fontWeight: "700",
  },
  icon: {
    height: 40,
    width: 44,
    justifyContent: "center",
    alignItems: "center",
  },
  icon_circle: {
    width: 22,
    height: 22,
    borderWidth: 2,
    borderColor: Colors.darkgray,
    borderRadius: 14,
    justifyContent: "center",
    alignItems: "center",
  },
  prefix: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 22,
    marginBottom: 5,
  },
  postfix: {
    fontSize: 11,
    color: Colors.darkgray,
  },
  error: {
    fontSize: 11,
    lineHeight: 13,
    fontWeight: "400",
    color: Colors.error,
  },
});
