import { useEffect, useRef } from "react";
import { ScrollView, StyleSheet } from "react-native";
import Container from "../utils/container";

interface Props {
  slideTag?: string
  children: React.ReactNode
}

const SlideContainer = (props: Props) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.scrollTo({
        x: 0,
        y: 0,
        animated: false
      })
    }

  }, [props.slideTag])

  return (
    <ScrollView ref={ref} contentContainerStyle={styles.container}>
      <Container>{props.children}</Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: 30,
  },
});

export default SlideContainer;
