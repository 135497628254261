import { useState } from "react";
import { Alert, Pressable, ScrollView, View, Platform } from "react-native";
import { ENDPOINTS } from "../../api/config";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import Input from "../../components/utils/input";
import PrimaryButton from "../../components/utils/primary_button";
import { Colors } from "../../constants/colors";
import { useAuthorizedCall } from "../../hooks/useAuthorizedCall";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { version } from "../../package.json";

const IssueReportScreen = ({
  navigation,
  route,
}: RootStackScreenProps<SCREEN.IssueReport>) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const {
    ok,
    support_bug_report,
    support_your_opinion,
    support_thank_you,
    save,
    unknown_error,
  } = useTranslations();
  const call = useAuthorizedCall();

  const saveNote = async () => {
    if (message.length > 0) {
      setLoading(true);

      let deviceInfoString = "";
      try {
        const deviceInfo = {
          os: Platform.OS,
          appVersion: version,
          ...Platform.constants,
        };

        deviceInfoString = JSON.stringify(deviceInfo, null, 2);
      } catch (error) {}

      const content = `${message}\n\n${deviceInfoString}`;

      try {
        const { data, status } = await call(
          ENDPOINTS.support.ISSUES(content, route.params.type)
        );
        Alert.alert(support_thank_you, undefined, [{ text: ok }]);
        navigation.goBack();
      } catch (e) {
        Alert.alert(unknown_error);
      }
      setLoading(false);
    }
  };

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <MainHeading
            text={
              route.params.type == "bug"
                ? support_bug_report
                : support_your_opinion
            }
          />
          <Pressable onPress={() => navigation.goBack()}>
            <CustomIcon
              name="community:close"
              color={Colors.primary}
              size={32}
            />
          </Pressable>
        </View>
        <Divider />
        <Input
          value={message}
          setValue={setMessage}
          placeholder=""
          numberOfLines={20}
        />
        <View style={{ flex: 1, justifyContent: "flex-end" }}>
          <PrimaryButton
            label={save}
            onPress={saveNote}
            disabled={loading}
            loading={loading}
          />
        </View>
      </Container>
    </ScrollView>
  );
};

export default IssueReportScreen;
