export interface IChoice {
  value: string | number;
  label: string;
  color?: string;
  comment?: string;
}

export enum EQuestionType {
  SINGLE_CHOICE = "single_choice",
  MULTI_CHOICE = "multi_choice",
  BOOLEAN = "boolean",
  INTENSITY_PICKER = "intensity_picker",
  SLIDER = "slider",
  HABIT = "habit",
  DATE = "date_picker",
  TIME = "time_picker",
  INTEGER = "integer_picker",
  QUALITY = "quality_picker",
  TEXTAREA = "text_area",
  SLEEP_RESTRICTIONS_PICKER = "sleep_restrictions_picker",
  CHECKBOX = "checkbox",
}

type AnswerType =
  | string
  | string[]
  | number
  | boolean
  | { from_bed: string | null; to_bed: string | null };

export interface IQuestion {
  type: EQuestionType;
  answer: AnswerType;
  required: boolean;
  recommended_in_bed_time: number | null;
  acceptable_deviation: number | null;
  template: {
    uuid: string;
    content: string;
    subcontent: string;
    choices: IChoice[];
    max_answers: number | null;
    icon?: string | null;
    // for habit only
    fulfillment_threshold?: number | null;
    is_negative?: boolean;
    min_value?: AnswerType | null;
    default_answer: any;
  };
}

export interface ISurveyScore {
  max_result: number;
  result: number;
  text: string;
}

export interface IVideo {
  url: string;
  thumbnail: string;
}

export interface IImage {
  url: string;
}

export interface IArticle {
  title: string;
  abstract: string;
  uuid: string;
  content: string;
  category: {
    key: string;
    name: string;
  };
  image_url: string | null;
  storage_image_url: string | null;
}

export interface IArticles {
  articles: IArticle[];
  text: string;
}

export interface IFlashCard {
  answer: string;
  choices: string;
  question: string;
  uuid: string;
  category: {
    key: string;
    name: string;
  };
  //this is for carousel to set equal height of elements
  maxHeight?: number;
  setMaxHeight?: (arg: number) => void;
}

export interface IFlashCards {
  horizontal?: boolean;
  flashcards: IFlashCard[];
}

export enum EContentTypes {
  QUESTION = "question",
  QUESTION_PREVIEW = "question_preview",
  TEXT = "text",
  SURVEY_SCORE = "survey_score",
  DIVIDER = "divider",
  VIDEO = "video",
  ARTICLES = "articles",
  FLASHCARDS = "flashcards",
  IMAGE = "image",
  SLEEP_PLOT = "sleep_parameters_plot",
  SUMMARY_LIST = "summary_list",
  STACKED_BARPLOT = "stacked_barplot",
  SLEEP_RESTRICTIONS = "sleep_restrictions",
  TOOLTIP = "tooltip",
  RESULTS_COMPARISON = "results_comparison",
  DROPDOWN_TEXT = "dropdown_text",
  USER_INFO = "user_info",
  USER_SUMMARY = "user_summary",
  TABS = "tabs",
  RICH_CARDS = "rich_cards",
  THERAPY_PROGRESS = "therapy_progress",
  SUBSCRIPTION_OFFER = "subscription_offer",
  PLACEHOLDER = "placeholder",
  THERAPY_PREVIEW = "therapy_preview",
  DAY_PLAN = "day_plan",
  SESSION_HEADER = "session_header",
}

export interface IContent {
  type: EContentTypes;
  text?: string;
  question?: IQuestion;
  survey_score?: ISurveyScore;
  video?: IVideo;
  articles?: IArticles;
  flashcards?: IFlashCards;
  display_condition: string | null;
}

export interface ISlide {
  template: {
    tag: string;
    header: string;
    subheader: string;
    content: string;
    uuid: string;
  };
  content: IContent[];
}

export interface ISurvey {
  uuid: string;
  current_slide: ISlide;
  progress: number;
  next_slide_achievable: boolean;
  prev_slide_achievable: boolean;
  survey_completed: boolean;
  current_slide_number: number;
  number_of_slides: number;
  read_only: boolean;
}
