import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SlideContent } from "../../types/v2/slides/slide_content";
import { getTherapyFolderProfile } from "./thunk_functions/therapyFolder";

interface StaticContentState {
  needRefresh: boolean;
  loading: boolean;
  error: boolean;
  content: SlideContent[];
}

const initialState: StaticContentState = {
  needRefresh: true,
  loading: false,
  error: false,
  content: [],
};

const therapyFolderProfileSlice = createSlice({
  name: "therapyFolderProfile",
  initialState: initialState,
  reducers: {
    markAsStale: (state) => {
      state.needRefresh = true;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTherapyFolderProfile.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getTherapyFolderProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.needRefresh = false;
        state.content = action.payload;
      })
      .addCase(getTherapyFolderProfile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const TherapyFolderProfileActions = therapyFolderProfileSlice.actions;

export const therapyFolderProfileReducer = therapyFolderProfileSlice.reducer;
