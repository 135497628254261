import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { OnboardingCarouselItem } from "../../../types/onboarding";
import { IThunkApi } from "../../../types/redux";

export const fetchOnboardingCarousel = createAsyncThunk<
  OnboardingCarouselItem[],
  string,
  IThunkApi
>("onboarding/fetchCarousel", async (language) => {
  const { data } = await call(ENDPOINTS.onboarding.GET_CAROUSEL(language));
  return data;
});
