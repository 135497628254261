import { useNavigation } from "@react-navigation/native";
import { FC } from "react";
import {
  ImageBackground,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Colors } from "../../constants/colors";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { ISessionPreview } from "../../types/session";
import { TherapyPreview as TherapyPreviewProps } from "../../types/v2/slides/slide_content";
import CarouselBlueprint from "../carousel_blueprint/carousel_blueprint";
import CustomIcon from "../CustomIcon";

const Session: FC<ISessionPreview> = ({ uuid, status, template }) => {
  const {
    sessions_session_status_in_progress,
    sessions_session_status_locked,
    sessions_session_status_completed,
  } = useTranslations();
  const navigation = useNavigation<any>();

  const statusText = () => {
    switch (status) {
      case "in_progress":
        return sessions_session_status_in_progress;
      case "locked":
        return sessions_session_status_locked;
      case "completed":
        return sessions_session_status_completed;
      default:
        return "";
    }
  };

  return (
    <Pressable
      style={styles.container}
      onPress={() =>
        status !== "locked"
          ? navigation.navigate(SCREEN.Session, { uuid })
          : null
      }
    >
      <ImageBackground
        source={
          status === "locked"
            ? require("../../assets/images/session_bg_gray.png")
            : require("../../assets/images/session_bg.png")
        }
        resizeMode="cover"
        style={styles.button_container}
      >
        <View style={{ paddingHorizontal: 30 }}>
          <Text
            style={{ fontSize: 30, fontWeight: "700", color: Colors.primary }}
          >
            {template.name}
          </Text>
          <View style={styles.lock_container}>
            <CustomIcon
              name={
                status === "locked"
                  ? "community:lock-outline"
                  : "community:lock-open"
              }
              size={14}
              style={{ marginRight: 10 }}
              color={Colors.dark}
            />
            <Text style={styles.lock_text}>{statusText()}</Text>
          </View>
        </View>
      </ImageBackground>
      <View style={{ padding: 24 }}>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 17,
            fontWeight: "700",
            color: Colors.dark,
          }}
        >
          {template.topic}
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 22,
            fontWeight: "300",
            color: Colors.dark,
            marginTop: 8,
          }}
        >
          {template.description}
        </Text>
      </View>
    </Pressable>
  );
};

const TherapyPreview: FC<TherapyPreviewProps> = ({ sessions }) => {
  return (
    <View style={{ marginVertical: 10 }}>
      <CarouselBlueprint
        data={sessions}
        text={""}
        Element={Session}
        height={400}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: Colors.mediumgray,
    borderRadius: 8,
    overflow: "hidden",
    flex: 1,
    backgroundColor: Colors.white
  },
  button_container: {
    justifyContent: "center",
    width: "100%",
    height: 120,
    position: "relative",
  },
  lock_container: {
    marginTop: 4,
    marginBottom: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  lock_text: {
    fontSize: 14,
    color: Colors.dark,
    fontWeight: "700",
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
    color: Colors.primary,
    marginRight: 8,
    lineHeight: 21,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 28,
    color: Colors.dark,
    opacity: 0.6,
  },
});

export default TherapyPreview;
