import { FC, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Modal from "react-native-modal";
import { ENDPOINTS } from "../api/config";
import { Colors } from "../constants/colors";
import { useAuthorizedCall } from "../hooks/useAuthorizedCall";
import { useTranslations } from "../hooks/useTranslations";
import SignupConsents from "./signup_consents";
import PrimaryButton from "./utils/primary_button";

interface IProps {
  visible: boolean;
  setPopupVisible: (arg: boolean) => void;
  action: () => void;
}

const SignupAgreementPopup: FC<IProps> = ({
  visible,
  setPopupVisible,
  action,
}) => {
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [userConsents, setUserConsents] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [disabled, setDisabled] = useState(true);
  const { next, auth_consent_show_content, auth_consent_hide_content } =
    useTranslations();
  const call = useAuthorizedCall();

  const saveConsents = async () => {
    setLoading(true);
    try {
      const { status, data } = await call(
        ENDPOINTS.user.SET_CONSENTS(userConsents)
      );
      if (status === 200) {
        await action();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Modal
      isVisible={visible}
      style={styles.modal}
      onBackdropPress={() => setPopupVisible(false)}
    >
      <View style={styles.container}>
        <ScrollView>
          <View>
            <SignupConsents
              userConsents={userConsents}
              setUserConsents={setUserConsents}
              setIsValid={setDisabled}
              setLoading={setLoading}
              showMore={showMore}
            />
          </View>
        </ScrollView>
        <View>
          <Text style={styles.showMore} onPress={() => setShowMore(!showMore)}>
            {showMore ? auth_consent_hide_content : auth_consent_show_content}
          </Text>
        </View>
        <View style={{ marginTop: 5 }}>
          <PrimaryButton
            label={next}
            onPress={saveConsents}
            loading={loading}
            disabled={disabled || loading}
          />
        </View>
      </View>
    </Modal>
  );
};

export default SignupAgreementPopup;

const styles = StyleSheet.create({
  modal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  container: {
    paddingVertical: 24,
    paddingHorizontal: 30,
    backgroundColor: Colors.white,
    maxHeight: "90%",
    position: "relative",
  },
  showMore: {
    color: Colors.primary,
    fontSize: 11,
    fontWeight: "400",
    lineHeight: 22,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
});
