import { FC } from "react";
import { StyleSheet, Text } from "react-native";
import { Colors } from "../../constants/colors";

interface IProps {
  text: string;
  center?: boolean;
  link?: boolean;
}

const CustomText: FC<IProps> = ({ text, center = false, link = false }) => {
  return (
    <Text
      style={[
        styles.text,
        center && { textAlign: "center" },
        link && { color: Colors.primary },
      ]}
    >
      {text}
    </Text>
  );
};

export default CustomText;

const styles = StyleSheet.create({
  text: { fontSize: 14, fontWeight: "300", lineHeight: 22 },
});
