import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import ListLink from "../../components/controls/ListLink";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { version } from "../../package.json";
import { useTranslations } from "../../hooks/useTranslations";
import { FC } from "react";

const ContactAndHelpScreen: FC<
  RootStackScreenProps<SCREEN.ContactAndHelp>
> = ({ navigation }) => {
  const {
    support_header,
    support_subheader,
    support_contact,
    support_help,
    support_bug_report,
    support_your_opinion,
    support_faq,
    support_guide,
    support_terms,
    support_privacy_policy,
    support_license,
  } = useTranslations();
  const { guide_url, license_url, terms_url, privacy_policy_url } =
    useAppSelector((state) => state.configuration.app);
  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <View style={{}}>
          <Pressable
            style={{ paddingVertical: 2, marginBottom: 15 }}
            onPress={() => navigation.goBack()}
          >
            <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
          </Pressable>
        </View>
        <MainHeading text={support_header} size={"large"} />
        <View style={{ marginTop: 10 }}>
          <SubHeading text={support_subheader} />
        </View>
        <Divider />
        <Text style={style.section}>{support_contact}</Text>
        <ListLink to={{ screen: SCREEN.IssueReport, params: { type: "bug" } }}>
          {support_bug_report}
        </ListLink>
        <ListLink
          to={{ screen: SCREEN.IssueReport, params: { type: "opinion" } }}
        >
          {support_your_opinion}
        </ListLink>
        <View style={{ marginTop: 30 }}>
          <Text style={style.section}>{support_help}</Text>
          <ListLink to={{ screen: SCREEN.FAQ }}>{support_faq}</ListLink>
          <ListLink to={guide_url}>{support_guide}</ListLink>
          <ListLink to={terms_url}>{support_terms}</ListLink>
          <ListLink to={privacy_policy_url}>{support_privacy_policy}</ListLink>
          <ListLink to={license_url}>{support_license}</ListLink>
        </View>
      </Container>
      <View style={{ position: "absolute", right: 0, bottom: 0 }}>
        <Text style={{ fontSize: 10 }}>{version}</Text>
      </View>
    </ScrollView>
  );
};

export default ContactAndHelpScreen;

const style = StyleSheet.create({
  section: {
    textTransform: "uppercase",
    color: Colors.darkgray,
  },
});
