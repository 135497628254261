import { FC } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Colors } from "../constants/colors";

interface Props {
  numberOfDots: number;
  activeIndex: number;
  onPress?: (index: number) => void;
}

const Dot = (props: { active: boolean }) => {
  return <View style={[styles.dot, props.active && styles.active]}></View>;
};

const CarousePagination: FC<Props> = ({ numberOfDots, activeIndex }) => {
  return (
    <Pressable hitSlop={10}>
      <View style={styles.container}>
        {new Array(numberOfDots).fill(null).map((_, index) => (
          <Dot active={index === activeIndex} key={index} />
        ))}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
    paddingVertical: 20,
  },
  dot: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: Colors.gray,
  },
  active: {
    backgroundColor: Colors.primary,
  },
});

export default CarousePagination;
