import { StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";

interface Props {
  color?: string;
}

function Arrow(props: Props) {
  return (
    <View style={styles.container}>
      <View style={[styles.arrowLine, { backgroundColor: props.color || Colors.gray }]} />
      <CustomIcon
        name="play-arrow"
        color={props.color || Colors.gray}
        size={14}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 80,
    flexDirection: "row",
    paddingHorizontal: 5,
  },
  arrowLine: {
    height: 2,
    flex: 1,
    backgroundColor: Colors.gray,
    marginRight: -10,
    maxWidth: 100
  },
});

export default Arrow;
