import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";

interface IProps {
  text: string;
}

const ErrorMessage: FC<IProps> = ({ text }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.error}>{text}</Text>
    </View>
  );
};

export default ErrorMessage;

const styles = StyleSheet.create({
  container: {
    marginVertical: 5,
  },
  error: {
    color: Colors.error,
    fontSize: 13,
    textAlign: "center",
  },
});
