import { FC } from "react";
import { SingleChoiceQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import ChoiceList from "../inputs/ChoiceList";
import QuestionWrapper from "./QuestionWrapper";

const SingleChoice: FC<QuestionProps<string, SingleChoiceQuestion>> = ({
  onChange,
  question,
  readOnly,
}) => {
  const {
    template: { choices, content, subcontent },
    answer: value,
  } = question;

  return (
    <QuestionWrapper
      content={content || undefined}
      subcontent={subcontent || undefined}
    >
      <ChoiceList
        choices={choices}
        onChange={(values) => onChange(values[0])}
        value={value === null ? [] : [value]}
        readOnly={readOnly}
      />
    </QuestionWrapper>
  );
};

export default SingleChoice;
