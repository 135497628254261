import { FC } from "react";
import { Text, View } from "react-native";
import Markdown from "react-native-markdown-display";
import { Colors } from "../../constants/colors";

const rules = {
  heading1: (node: any, children: any, parent: any, styles: any) => (
    <Text
      key={node.key}
      style={[
        styles.heading,
        styles.heading1,
        { color: Colors.primary, fontWeight: "700" },
      ]}
    >
      {children}
    </Text>
  ),
  heading2: (node: any, children: any, parent: any, styles: any) => (
    <Text
      key={node.key}
      style={[
        styles.heading,
        styles.heading2,
        { color: Colors.primary, fontWeight: "700" },
      ]}
    >
      {children}
    </Text>
  ),
  heading3: (node: any, children: any, parent: any, styles: any) => (
    <Text
      key={node.key}
      style={[
        styles.heading,
        styles.heading3,
        {
          color: Colors.primary,
          fontWeight: "700",
          fontSize: 22,
          lineHeight: 22,
        },
      ]}
    >
      {children}
    </Text>
  ),
  heading4: (node: any, children: any, parent: any, styles: any) => (
    <Text
      key={node.key}
      style={[
        styles.heading,
        styles.heading4,
        { color: Colors.primary, fontWeight: "700" },
      ]}
    >
      {children}
    </Text>
  ),
  heading5: (node: any, children: any, parent: any, styles: any) => (
    <Text
      key={node.key}
      style={[
        styles.heading,
        styles.heading5,
        { color: Colors.primary, fontWeight: "700" },
      ]}
    >
      {children}
    </Text>
  ),
  paragraph: (node: any, children: any, parent: any, styles: any) => (
    <Text
      key={node.key}
      style={[
        styles.paragraph,
        { color: Colors.dark, lineHeight: 22, fontSize: 14 },
      ]}
    >
      {children}
    </Text>
  ),
  strong: (node: any, children: any, parent: any, styles: any) => (
    <Text
      key={node.key}
      style={[
        styles.strong,
        { color: Colors.dark, fontWeight: "700", lineHeight: 22, fontSize: 14 },
      ]}
    >
      {children}
    </Text>
  ),
  list_item: (node: any, children: any, parent: any, styles: any) => (
    <View key={node.key} style={{ flexDirection: "row", marginVertical: 7 }}>
      <View
        style={{
          width: 8,
          height: 8,
          backgroundColor: Colors.primary,
          borderRadius: 5,
          marginTop: 7,
          marginRight: 10,
        }}
      ></View>
      <Text style={{ fontSize: 14, color: Colors.dark, flex: 1 }}>
        {children}
      </Text>
    </View>
  ),
};

const CustomMarkdown: FC<{ text: string }> = ({ text }) => {
  return <Markdown rules={rules}>{text}</Markdown>;
};

export default CustomMarkdown;
