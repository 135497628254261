import { FC } from "react";
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  View
} from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";

export enum EChoiceType {
  SINGLE_CHOICE = "single_choice",
  MULTIPLE_CHOICE = "multiple_choice",
  BOOLEAN = "boolean",
}

interface IProps {
  text: string;
  selected?: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | undefined | null;
  center?: boolean;
  type?: EChoiceType;
  color?: string;
}

const ChoiceButton: FC<IProps> = ({
  text,
  selected = false,
  onPress,
  center = false,
  type = EChoiceType.SINGLE_CHOICE,
  color,
}) => {
  return (
    <View
      style={[
        styles.container,
        selected && { backgroundColor: color ? color : Colors.primary },
      ]}
    >
      <Pressable
        style={[styles.pressArea, center && { justifyContent: "center" }]}
        onPress={onPress}
      >
        {type === EChoiceType.SINGLE_CHOICE && (
          <View
            style={[
              styles.answerRadio,
              selected && { borderColor: Colors.white },
            ]}
          >
            {selected && (
              <View
                style={[
                  styles.answerRadioInner,
                  { borderColor: color ? color : Colors.primary },
                ]}
              ></View>
            )}
          </View>
        )}
        {type === EChoiceType.MULTIPLE_CHOICE && (
          <View>
            <CustomIcon
              name="check"
              size={22}
              color={selected ? Colors.white : Colors.gray}
            />
          </View>
        )}
        <Text style={[styles.text, selected && { color: Colors.white }]}>
          {text}
        </Text>
      </Pressable>
    </View>
  );
};

export default ChoiceButton;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: Colors.lightgray,
    overflow: "hidden",
    marginVertical: 5,
  },
  pressArea: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 19,
  },
  text: {
    paddingHorizontal: 20,
    paddingVertical: 22,
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "400",
    color: Colors.dark,
  },
  answerRadio: {
    width: 21,
    height: 21,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Colors.gray,
    backgroundColor: Colors.white,
    justifyContent: "center",
    alignItems: "center",
  },
  answerRadioInner: {
    width: 15,
    height: 15,
    borderRadius: 8,
    borderColor: Colors.primary,
    borderWidth: 4,
    backgroundColor: Colors.white,
  },
});
