import { createDrawerNavigator } from "@react-navigation/drawer";
import { useAppSelector } from "../hooks/redux";
import { useDrawerRoutes } from "../hooks/useDrawerRoutes";
import { useLogout } from "../hooks/useLogout";
import { useTranslations } from "../hooks/useTranslations";
import BottomTabNavigation from "./BottomTabNavigation";
import DrawerContent from "./DrawerContent";
import { DrawerParamList } from "./DrawerNavigation.types";
import { SCREEN } from "./screens";


const Drawer = createDrawerNavigator<DrawerParamList>();

const DrawerNavigation = () => {
  const logout = useLogout();
  const { therapyParticipant } = useAppSelector((state) => state);
  const translations = useTranslations();
  const routes = useDrawerRoutes();

  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
        swipeEnabled: false,
      }}
      drawerContent={(props) => (
        <DrawerContent
          {...props}
          onLogout={logout}
          routes={routes}
          translations={translations}
          // @ts-ignore
          therapyState={therapyParticipant}
        />
      )}
    >
      <Drawer.Screen
        name={SCREEN.BottomTabNavigation}
        component={BottomTabNavigation}
      />
    </Drawer.Navigator>
  );
};

export default DrawerNavigation;
