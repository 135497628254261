import { FC } from "react";
import { Dimensions, Pressable, StyleSheet, Text, View } from "react-native";
import DashedLine from "react-native-dashed-line";
import { Colors } from "../../constants/colors";
import { useTranslations } from "../../hooks/useTranslations";
import {
  BarDescription,
  IHorizontalLine,
  StackedBarplot,
  StackedBarplotWithNullBars
} from "../../types/charts";
import Area from "./area";
import Bar from "./bar";
import { CHART_SETTINGS } from "./config";

function fillNullBars(
  stackedBarplot: StackedBarplotWithNullBars
): StackedBarplot {
  const minHorizontalLine =
    stackedBarplot.horizontal_lines.length > 0
      ? Math.min(...stackedBarplot.horizontal_lines.map((line) => line.height))
      : 0;
  const maxHorizontalLine =
    stackedBarplot.horizontal_lines.length > 0
      ? Math.max(...stackedBarplot.horizontal_lines.map((line) => line.height))
      : 1.0;

  return {
    ...stackedBarplot,
    bars: stackedBarplot.bars.map((maybeBar) => {
      if (maybeBar === null) {
        return {
          offset: minHorizontalLine,
          primary_label: null,
          primary_label_color: null,
          primary_label_unit: null,
          secondary_label: null,
          bar: [
            {
              color: Colors.white,
              connector_color: null,
              connector_opacity: null,
              label: null,
              height: 1 - (1 - maxHorizontalLine) - minHorizontalLine,
            },
          ],
        };
      } else {
        return maybeBar;
      }
    }),
  };
}

function calculateAreaPos(
  stackedBarplot: StackedBarplotWithNullBars
): { startBarIdx: number; endBarIdx: number }[] {
  const result: { startBarIdx: number; endBarIdx: number }[] = [];

  let currentStart: number | null = null;

  for (let i = 0; i < stackedBarplot.bars.length; i++) {
    const current = stackedBarplot.bars[i];
    if (current === null) {
      continue;
    } else if (currentStart !== null) {
      result.push({
        startBarIdx: currentStart,
        endBarIdx: i,
      });
      currentStart = i;
    } else {
      currentStart = i;
    }
  }
  return result;
}

const YLabel: FC<{ label: string; height: number }> = ({ label, height }) => (
  <View style={styles.yLabelOuterContainer}>
    <View style={styles.ylabelInnerContainer}>
      <Text style={[styles.yLabelText, { width: height }]}>{label}</Text>
    </View>
  </View>
);

const HorizontalLine: FC<IHorizontalLine> = ({ height }) => (
  <View
    style={{
      width: "100%",
      height: 2,
      position: "absolute",
      top: (1 - height) * CHART_SETTINGS.height,
      left: 0,
    }}
  >
    <DashedLine dashLength={5} dashColor={Colors.lightgray} />
  </View>
);

const LabelContainer: FC<{ maybeBarDescription: BarDescription | null }> = ({
  maybeBarDescription,
}) => (
  <View style={styles.labelContainer}>
    {maybeBarDescription?.primary_label && (
      <View style={styles.primaryLabelContainer}>
        <Text
          style={[
            styles.primaryLabelText,
            {
              color: maybeBarDescription.primary_label_color || undefined,
            },
            maybeBarDescription.primary_label.length > 5 ? {
              fontSize: 20
            } : undefined
          ]}
        >
          {maybeBarDescription.primary_label}
        </Text>
        <Text
          style={[
            styles.primaryLabelUnit,
            {
              color: maybeBarDescription.primary_label_color || undefined,
            },
          ]}
        >
          {maybeBarDescription.primary_label_unit}
        </Text>
      </View>
    )}
    {maybeBarDescription?.secondary_label && (
      <Text style={styles.secondaryLabelText}>
        {maybeBarDescription.secondary_label}
      </Text>
    )}
  </View>
);

const Chart: FC<StackedBarplotWithNullBars> = (stackedBarplot) => {
  const translations = useTranslations()
  const barplot = fillNullBars(stackedBarplot);
  const areaPos = calculateAreaPos(stackedBarplot);
  const areaInitialIdx = areaPos[0]?.startBarIdx || 0;
  const areaInitialOffset =
    areaInitialIdx * (CHART_SETTINGS.barWidth + CHART_SETTINGS.barPadding);

  function handleBarPress(bar: BarDescription, index: number) {
    if (stackedBarplot.onBarPress) {
      stackedBarplot.onBarPress(bar, index);
    }
  }

  return (
    <View>
      <View
        style={{
          marginHorizontal: 30,
          flexDirection: "row",
        }}
      >
        {stackedBarplot.bars.map((maybeBarDescription, idx) => (
          <LabelContainer
            key={`label-${idx}`}
            maybeBarDescription={maybeBarDescription}
          />
        ))}
      </View>
      <View style={[styles.container, { height: CHART_SETTINGS.height }]}>
        {barplot.ylabel && (
          <YLabel label={barplot.ylabel} height={CHART_SETTINGS.height} />
        )}
        {barplot.horizontal_lines.map((line) => (
          <HorizontalLine {...line} key={line.height} />
        ))}
        <View style={styles.chartContainer}>
          <View style={[styles.chartInnerContainer, { zIndex: 2 }]}>
            {barplot.bars.map((bar, idx) => (
              <View key={`bar-${idx}`} style={{ flexDirection: 'row'}}>
                <Pressable
                  onPress={handleBarPress.bind(this, bar, idx)}
                  hitSlop={10}
                >
                  <Bar
                    bar_description={bar}
                    bar_settings={barplot.bar_settings}
                    chart_settings={CHART_SETTINGS}
                  />
                </Pressable>
                <View style={{ height: "100%", width: CHART_SETTINGS.barPadding }}></View>
              </View>
            ))}
          </View>
          <View style={styles.chartInnerContainer}>
            <View style={{ width: areaInitialOffset }}></View>
            {areaPos.map((pos, idx) => (
              <Area
                bars={barplot.bars}
                chart_settings={CHART_SETTINGS}
                {...pos}
                key={`area-${idx}`}
              />
            ))}
          </View>
        </View>
      </View>

      <View style={styles.legendContainer}>
        {barplot.legend.map((l) => {
          return (
            <View style={styles.legendItem} key={l.color}>
              <View
                style={[styles.legendDot, { backgroundColor: l.color }]}
              ></View>
              <Text style={styles.legendText}>{translations[l.label]}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  yLabelOuterContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 28,
    height: "100%",
  },
  ylabelInnerContainer: {
    position: "relative",
    flex: 1,
  },
  yLabelText: {
    height: 20,
    transform: [{ rotateZ: "-90deg" }],
    fontSize: 11,
    position: "absolute",
    top: 130,
    left: -120,
    textAlign: "center",
    color: Colors.darkgray,
  },
  labelContainer: {
    width: 110,
  },
  primaryLabelContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  primaryLabelText: {
    fontSize: 30,
    lineHeight: 36,
    fontWeight: "600",
  },
  primaryLabelUnit: {
    fontSize: 20,
    lineHeight: 24,
    height: 24,
    fontWeight: "300",
  },
  secondaryLabelText: {
    fontSize: 11,
    lineHeight: 12,
    color: Colors.darkgray,
    fontWeight: "400",
  },
  container: {
    height: 230,
    flexGrow: 1,
    flexDirection: "row",
    position: "relative",
    paddingLeft: 30,
    marginVertical: 10,
  },
  chartContainer: {
    position: "relative",
    top: 0,
    left: 0,
    height: "100%",
    flexDirection: "row",
    flexGrow: 1,
  },
  chartInnerContainer: {
    position: "absolute",
    height: "100%",
    flexDirection: "row",
    flexGrow: 1,
  },
  legendContainer: {
    width: Dimensions.get("window").width - 60,
    marginHorizontal: 30,
    flexDirection: "row",
    marginTop: 20,
    flexWrap: "wrap",
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
  legendDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: "blue",
    marginRight: 5,
  },
  legendText: {
    fontSize: 11,
    lineHeight: 25,
    color: Colors.dark,
  },
});

export default Chart;
