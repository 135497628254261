import { FC } from "react";
import { Switch } from "react-native-switch";
import { Colors } from "../../constants/colors";

interface IProps {
  value: boolean;
  setValue: (arg: boolean) => void;
}

const CustomSwitch: FC<IProps> = ({ value, setValue }) => {
  return (
    <Switch
      value={value}
      onValueChange={setValue}
      circleSize={17}
      barHeight={24}
      circleBorderWidth={0}
      backgroundActive={Colors.primary}
      backgroundInactive={Colors.gray}
      circleActiveColor={Colors.white}
      circleInActiveColor={Colors.white}
      renderActiveText={false}
      renderInActiveText={false}
      switchLeftPx={2}
      switchRightPx={2}
      switchWidthMultiplier={2.5}
    />
  );
};

export default CustomSwitch;
