import { useIsFocused } from "@react-navigation/native";
import { FC, useEffect } from "react";
import { RefreshControl, ScrollView, View } from "react-native";
import Loader from "../../components/Loader";
import SessionTeaser from "../../components/sessions/SessionTeaser";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SCREEN } from "../../navigation/screens";
import { TherapyTabScreenProps } from "../../navigation/TherapyTabNavigation.types";
import { getTherapy } from "../../store/redux/thunk_functions/therapy";

const TherapyScreen: FC<TherapyTabScreenProps<SCREEN.Sessions>> = () => {
  const isFocused = useIsFocused();
  const { needRefresh, loading, sessionIds, sessionById } = useAppSelector(
    (state) => state.therapy
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh && isFocused) {
      dispatch(getTherapy());
    }
  }, [isFocused, needRefresh]);

  const handleRefresh = () => {
    dispatch(getTherapy());
  };

  if (loading && sessionIds.length === 0) {
    return <Loader />;
  }

  const sessions = sessionIds.map((id) => sessionById[id]);

  return (
    <ScrollView
      style={{ paddingTop: 10 }}
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
      }
    >
      <View style={{ marginTop: 20, paddingBottom: 30, alignItems: "center" }}>
        {sessions.map((session) => {
          return (
            <SessionTeaser {...session} isLast={false} key={session.uuid} />
          );
        })}
      </View>
    </ScrollView>
  );
};

export default TherapyScreen;
