import { FC } from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";

interface Props {
  onPress?: () => void;
}

const Thumbnail: FC<Props> = ({ onPress }) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <Image
        style={{
          width: "100%",
          height: "auto",
          aspectRatio: 16 / 9,
        }}
        source={require("../../assets/images/thumbnail.jpg")}
      />
      <View style={styles.playButtonContainer}>
        <View style={styles.playButton}>
          <CustomIcon
            name="play-circle-outline"
            size={50}
            color={Colors.white}
          />
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 10,
    position: "absolute",
    width: "100%",
    minWidth: "100%",
    height: "auto",
    aspectRatio: 16 / 9,
    alignItems: "center",
    justifyContent: "center",
  },
  playButtonContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  playButton: {
    width: 60,
    height: 60,
    backgroundColor: Colors.primary,
    borderRadius: 30,
    zIndex: 15,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Thumbnail;
