import { useEffect } from "react";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View
} from "react-native";
import Articles from "../../components/Articles";
import CustomIcon from "../../components/CustomIcon";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import CustomMarkdown from "../../components/utils/markdown";
import { Colors } from "../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { fetchArticles } from "../../store/redux/thunk_functions/fetchArticles";

const ArticleScreen = ({
  navigation,
  route,
}: RootStackScreenProps<SCREEN.Article>) => {
  const dispatch = useAppDispatch();
  const { byId, byCategory } = useAppSelector((state) => state.library);
  const { library_header } = useAppSelector(
    (state) => state.manifest.translations
  );
  const {
    params: { uuid },
  } = route;
  const article = byId[uuid];
  const category = article?.category.name;
  const suggestions = (byCategory[category] || []).map((uuid) => byId[uuid]);

  useEffect(() => {
    if (!article) {
      dispatch(fetchArticles());
    }
  }, [article]);

  const imageUrl = article?.storage_image_url;

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      {imageUrl && <Image source={{ uri: imageUrl }} style={styles.image} />}
      <Container>
        <View style={styles.relaxation_bar}>
          <Text style={styles.relaxations_text}>{library_header}</Text>
          <Pressable onPress={() => navigation.goBack()}>
            <CustomIcon name="close" size={26} color={Colors.primary} />
          </Pressable>
        </View>
        <Text style={styles.title}>{article?.title}</Text>
        <CustomMarkdown text={article?.content || ""} />
        <Divider />
        {suggestions.length > 0 && (
          <Articles articles={suggestions} text={category} />
        )}
      </Container>
    </ScrollView>
  );
};

export const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: 260,
    resizeMode: "cover",
  },
  title: {
    fontSize: 30,
    lineHeight: 36,
    fontWeight: "700",
    color: Colors.dark,
    marginBottom: 10,
  },
  relaxation_bar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 5,
  },
  relaxations_text: {
    fontSize: 18,
    lineHeight: 21,
    fontWeight: "700",
    color: Colors.primary,
  },
});

export default ArticleScreen;
