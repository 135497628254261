export const Colors = {
  primary: "#003CBF",
  primary_dark: "#012573",
  primary_light: "#bdd1ff",
  white: "#fff",
  gray: "#CDCDCD",
  lightgray: "#F5F5F5",
  mediumgray: "#E2E2E2",
  darkgray: "#7F7F7F",
  dark: "#303030",
  error: "#ff3030",
  purple_transparent: "#794FFF1a",
  purple: "#794FFF",
  green_transparent: "#59B6B31a",
  green: "#59B6B3",
  lightblue: "#b6c8fc33",
  black: "#000000",

  plot_lightblue: "#30B1FF",
  plot_azure: "#4F86FF",
  lightgray_transparent: "#CDCDCD33",
  primary_transparent: "#003CBF19",
  featured_color: "#F0F4FE",

  slider_positive: "#59B6B3",
  slider_negative: "#F99F0E",
};

// FOR TESTING ONLY
export const ImagePlaceholder =
  "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";
