import { formatISO } from "date-fns";
import * as FileSystem from "expo-file-system";
import { startActivityAsync } from "expo-intent-launcher";
import * as Sharing from "expo-sharing";
import { useState } from "react";
import { Platform, Pressable, ScrollView, Text, View } from "react-native";
import { CONFIG } from "../../api/config";
import CustomIcon from "../../components/CustomIcon";
import MainHeading from "../../components/typography/main_heading";
import Container from "../../components/utils/container";
import PrimaryButton from "../../components/utils/primary_button";
import Switch from "../../components/utils/switch";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import * as Sentry from "../../utils/sentry";

const SleepReportScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.SleepReport>) => {
  const manifest = useAppSelector((state) => state.manifest.translations);
  const {
    sleep_report_main_page_header,
    sleep_report_main_page_content,
    sleep_report_main_page_generate_report_cta,
    sleep_report_generation_sessions,
  } = useTranslations();
  const [loading, setLoading] = useState(false);
  const [tools, setTools] = useState<string[]>([]);
  const [sessions, setSessions] = useState<string[]>([]);
  const currentSessionTag = useAppSelector(
    (state) => state.therapyParticipant.therapy_status?.current_session.tag
  );
  const token = useAppSelector((state) => state.auth.token);

  const allSessionTags = [
    "session_1",
    "session_2",
    "session_3",
    "session_4",
    "session_5",
    "session_6",
    "post_therapy",
  ];

  const availableSessionTags = currentSessionTag
    ? allSessionTags.filter((tag) =>
        currentSessionTag !== "post_therapy"
          ? tag < currentSessionTag && tag !== "post_therapy"
          : true
      )
    : allSessionTags;

  const toggleSession = (session: string) => {
    let sessionsCopy = [...sessions];
    if (!sessionsCopy.includes(session)) sessionsCopy.push(session);
    else {
      sessionsCopy = sessionsCopy.filter((s) => s !== session);
    }
    setSessions(sessionsCopy);
  };

  const fetchPDF = async () => {
    try {
      const params = new URLSearchParams();
      for (const session of sessions) {
        params.append("sessions", session);
      }
      for (const tool of tools) {
        params.append("tools", tool);
      }

      const request_url = `${
        CONFIG.url
      }/api/v1/cbti/sleep-report?${params.toString()}`;

      const dt = formatISO(new Date(), { format: "basic" });
      const filename = `report-${dt}.pdf`;

      const path =
        Platform.select({
          ios: FileSystem.documentDirectory,
          android: FileSystem.cacheDirectory,
        }) + filename;

      try {
        const downloaded = await FileSystem.downloadAsync(request_url, path, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf",
          },
        });
        if (downloaded !== undefined) {
          if (Platform.OS === "android") {
            const shareableUri = await FileSystem.getContentUriAsync(
              downloaded.uri
            );
            startActivityAsync("android.intent.action.VIEW", {
              data: shareableUri,
              flags: 1,
            });
          } else {
            Sharing.shareAsync(downloaded.uri);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const generateReport = async () => {
    setLoading(true);
    try {
      await fetchPDF();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <View>
          <Pressable
            style={{ paddingVertical: 2, marginBottom: 15 }}
            onPress={() => navigation.goBack()}
          >
            <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
          </Pressable>
        </View>
        <MainHeading text={sleep_report_main_page_header} />
        <Text style={{ marginVertical: 20 }}>
          {sleep_report_main_page_content}
        </Text>
        <View>
          <Text
            style={{
              fontSize: 18,
              fontWeight: "700",
              color: Colors.primary,
            }}
          >
            {sleep_report_generation_sessions}
          </Text>
          {availableSessionTags.map((tag) => (
            <Switch
              name={manifest[`sessions_${tag}_name`]}
              text={""}
              value={sessions.includes(tag)}
              setValue={toggleSession.bind(this, tag)}
              key={tag}
            />
          ))}
        </View>
        <View style={{ flex: 1, justifyContent: "flex-end" }}>
          <PrimaryButton
            label={sleep_report_main_page_generate_report_cta}
            onPress={generateReport}
            disabled={loading}
            loading={loading}
          />
        </View>
      </Container>
    </ScrollView>
  );
};

export default SleepReportScreen;
