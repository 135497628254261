import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import Svg, { Rect } from "react-native-svg";
import { Colors } from "../../constants/colors";
import {
  BarDescription,
  BarSettings,
  ChartSettings,
  BarPart,
} from "../../types/charts";

interface Props {
  bar_description: BarDescription;
  bar_settings: BarSettings;
  chart_settings: ChartSettings;
}

interface BarPartDrawData {
  width: number;
  height: number;
  y: number;
  fill: string;
  label: string | null;
  stroke: string | undefined;
  strokeOpacity: number | undefined;
}

export function calculateBarPartDrawData(
  bar_description: BarDescription,
  chart_settings: ChartSettings
): BarPartDrawData[] {
  const result: BarPartDrawData[] = [];
  const topOffset =
    1 -
    (bar_description.offset +
      bar_description.bar.reduce(
        (prev: number, curr: BarPart) => prev + curr.height,
        0
      ));
  let currentOffset = topOffset * chart_settings.height;
  for (let barPart of bar_description.bar) {
    const barPartHeight = barPart.height * chart_settings.height;
    result.push({
      width: chart_settings.barWidth,
      height: barPartHeight,
      y: currentOffset,
      fill: barPart.color,
      label: barPart.label,
      stroke: barPart.color === Colors.white ? Colors.black : undefined,
      strokeOpacity: barPart.color == Colors.white ? 0.2 : undefined,
    });
    currentOffset += barPartHeight;
  }
  return result;
}

const Bar: FC<Props> = ({ bar_description, bar_settings, chart_settings }) => {
  const barWidth = chart_settings.barWidth;
  const drawData = calculateBarPartDrawData(bar_description, chart_settings);
  return (
    <View
      style={[
        styles.container,
        //  { backgroundColor: bar_settings.background_color || undefined }, TODO
      ]}
    >
      <Svg
        height={chart_settings.height}
        width={barWidth}
        style={{ zIndex: 3 }}
      >
        {drawData.map((part, idx) => (
          <Rect key={`bar-part-${idx}`} {...part} />
        ))}
      </Svg>
      <View style={styles.labelsContainer}>
        {drawData.map((part, idx) => (
          <Text
            key={`bar-part-label-${idx}`}
            style={[
              styles.barPartLabel,
              { top: part.y + part.height / 2 - 10 },
            ]}
          >
            {part.label}
          </Text>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  labelsContainer: {
    position: "absolute",
    left: 10,
    top: 0,
    width: 50,
    height: "100%",
  },
  barPartLabel: {
    position: "absolute",
    left: 10,
    fontSize: 11,
  },
});

export default Bar;
