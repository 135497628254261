import { FC } from "react";
import { CheckboxQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import CheckboxPicker from "../inputs/CheckboxPicker";

const Checkbox: FC<QuestionProps<boolean, CheckboxQuestion>> = ({
  question,
  readOnly,
  onChange,
}) => {
  const {
    template: { content },
    answer: value,
  } = question;

  return (
    <CheckboxPicker
      value={value}
      readOnly={readOnly}
      label={content || ""}
      onChange={onChange}
    />
  );
};

export default Checkbox;
