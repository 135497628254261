import { combineReducers } from "@reduxjs/toolkit";
import { therapyFolderInsomniaMapReducer } from "./therapyFolderInsomniaMap";
import { therapyFolderProfileReducer } from "./therapyFolderProfile";
import { therapyFolderBeliefsReducer } from "./therapyFolderBeliefs";

export const therapyFolderReducer = combineReducers({
  profile: therapyFolderProfileReducer,
  insomniaMap: therapyFolderInsomniaMapReducer,
  beliefs: therapyFolderBeliefsReducer
});
