import { StyleSheet } from "react-native";
import { Colors } from "../../constants/colors";

export const custom_top_tabs_styles = StyleSheet.create({
  container: {
    height: 60,
    minWidth: "100%",
    backgroundColor: Colors.white,
  },
  menu_item: {
    height: "100%",
    paddingHorizontal: 34,
    borderBottomWidth: 3,
    borderBottomColor: Colors.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  tab_title: {
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "capitalize",
    color: Colors.primary,
    textAlign: "center",
  },
});
