import * as WebBrowser from "expo-web-browser";
import { useState } from "react";
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import Modal from "react-native-modal";
import Toast from "react-native-root-toast";
import { ENDPOINTS } from "../../api/config";
import Close from "../../components/Close";
import GoBack from "../../components/GoBack";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import AuthButton from "../../components/utils/auth_button";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import PrimaryButton from "../../components/utils/primary_button";
import { Colors } from "../../constants/colors";
import { useApple, useFacebook, useGoogle } from "../../hooks/providers";
import { useCall } from "../../hooks/useAuthorizedCall";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { EAuthType } from "../../types/auth";
import { Token } from "../../types/v2/token";
import { iOSDevice } from "../../utils/browser";

WebBrowser.maybeCompleteAuthSession();

const LoginScreen = ({ navigation }: RootStackScreenProps<SCREEN.Login>) => {
  const [accountDoesNotExist, setAccountDoesNotExist] =
    useState<boolean>(false);

  const translations = useTranslations();
  const {
    auth_signin_header,
    auth_signin_subheader,
    auth_signin_google,
    auth_signin_facebook,
    auth_signin_apple,
    auth_signin_email,
    auth_signin_no_account,
    auth_signin_no_account_cta,
    auth_signin_account_does_not_exist,
    auth_signin_account_does_not_exist_header,
    auth_signin_go_to_intro_survey,
  } = translations;
  const { width } = useWindowDimensions();
  const call = useCall();

  const handleError = (error: any) => {
    const messageKey =
      error?.response?.data?.detail?.message || "unknown_error";
    if (messageKey === "auth_signin_account_does_not_exist") {
      setAccountDoesNotExist(true);
    } else {
      Toast.show(translations[messageKey]);
    }
  };

  const [facebookSigninDisabled, facebookSignin] = useFacebook(
    (accessToken: string) => call<Token>(ENDPOINTS.user.FB_SIGNIN(accessToken)),
    handleError
  );

  const [googleSigninDisabled, googleSignin] = useGoogle(
    (accessToken: string) =>
      call<Token>(ENDPOINTS.user.GOOGLE_SIGNIN(accessToken)),
    handleError
  );

  const appleSignin = useApple(
    (accessToken: string) =>
      call<Token>(ENDPOINTS.user.APPLE_SIGNIN(accessToken)),
    handleError
  );

  const handleStartIntroSurvey = () => {
    setAccountDoesNotExist(false);
    navigation.navigate(SCREEN.IntroSurvey);
  };

  return (
    <>
      {
        <Modal isVisible={accountDoesNotExist}>
          <View style={[styles.modalContainerOuter, { width: width - 60 }]}>
            <View style={{ position: "absolute", right: 10, top: 10 }}>
              <Close onPress={() => setAccountDoesNotExist(false)}></Close>
            </View>
            <Text style={styles.modalTitle}>
              {auth_signin_account_does_not_exist_header}
            </Text>
            <Text style={styles.modalContent}>
              {auth_signin_account_does_not_exist}
            </Text>
            <PrimaryButton
              label={auth_signin_go_to_intro_survey}
              onPress={handleStartIntroSurvey}
            />
          </View>
        </Modal>
      }

      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Container>
          <GoBack />
          <MainHeading text={auth_signin_header} size="large" />
          <View style={{ marginTop: 10 }}>
            <SubHeading text={auth_signin_subheader} />
          </View>
          <Divider />
          <View>
            {!iOSDevice ? (
              <>
                <AuthButton
                  disabled={googleSigninDisabled}
                  text={auth_signin_google}
                  type={EAuthType.GOOGLE}
                  onPress={googleSignin}
                />
                <AuthButton
                  disabled={facebookSigninDisabled}
                  text={auth_signin_facebook}
                  type={EAuthType.FACEBOOK}
                  onPress={facebookSignin}
                />
              </>
            ) : null}
            {Platform.OS === "ios" ? (
              <AuthButton
                text={auth_signin_apple}
                type={EAuthType.APPLE}
                onPress={appleSignin}
              />
            ) : null}
            <AuthButton
              text={auth_signin_email}
              type={EAuthType.EMAIL}
              onPress={() => navigation.navigate(SCREEN.EmailPasswordLogin)}
            />
          </View>
          <View style={styles.action_container}>
            <Text style={{ fontSize: 18 }}>{auth_signin_no_account}</Text>
            <Pressable onPress={() => navigation.navigate(SCREEN.IntroSurvey)}>
              <Text style={styles.goToSurveyButton}>
                {auth_signin_no_account_cta}
              </Text>
            </Pressable>
          </View>
        </Container>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  goToSurveyButton: {
    color: Colors.primary,
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 28,
    textAlign: "center",
    paddingVertical: 17,
  },
  action_container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  modalContainerOuter: {
    paddingHorizontal: 30,
    paddingVertical: 20,
    backgroundColor: Colors.white,
  },
  modalTitle: {
    fontSize: 18,
    color: Colors.primary,
    marginBottom: 10,
  },
  modalContent: {
    fontSize: 14,
    lineHeight: 22,
    marginBottom: 20,
  },
});

export default LoginScreen;
