import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EContentTypes } from "../../types/survey";
import { SlideTemplate } from "../../types/v2/slides/slide";
import * as SlideContent from "../../types/v2/slides/slide_content";
import {
  Survey,
  SurveyActions as SurveyActionsType,
  SurveyWithoutSlide,
} from "../../types/v2/survey";
import { getDiary } from "./thunk_functions/getDiary";
import { getHabits } from "./thunk_functions/getHabits";
import { getIntroSurvey } from "./thunk_functions/getIntroSurvey";
import { getOrCreateThought } from "./thunk_functions/getOrCreateThought";
import { getSurvey } from "./thunk_functions/getSurvey";
import { updateSurvey } from "./thunk_functions/updateSurvey";
import { query } from "mistql";
import { Question } from "../../types/v2/questions";
import { hasAnswer } from "../../utils/survey";
import { getInsomniaChecker } from "./thunk_functions/getInsomniaChecker";

interface SurveyState {
  survey?: SurveyWithoutSlide;
  actions?: SurveyActionsType;
  loading: boolean;
  error: boolean;
  type?:
    | "sleep_diary"
    | "intro_survey"
    | "thought_diary"
    | "habits"
    | "insomnia_checker"
    | "generic";

  slideTemplate?: SlideTemplate;
  itemIds: string[];
  questionIds: string[];
  itemIdByQuestionId: { [uuid: string]: string };
  questionIdByItemId: { [uuid: string]: string };
  questionById: { [uuid: string]: Question };
  itemById: { [uuid: string]: SlideContent.SlideContent };
  hidden: boolean[];
  primaryActionDisabled: boolean;
  hasQuestions: boolean;
}

const initialState: SurveyState = {
  loading: false,
  error: false,
  itemIds: [],
  questionIds: [],
  itemIdByQuestionId: {},
  questionIdByItemId: {},
  questionById: {},
  itemById: {},
  hidden: [],
  primaryActionDisabled: false,
  hasQuestions: false,
};

const validateAnswers = (questions: Question[]) => {
  for (let question of questions) {
    if (question.required && !hasAnswer(question)) {
      return false;
    }
  }
  return true;
};

export const getAnswers = (questions: Question[]) => {
  return questions.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.template.uuid]: curr.answer,
    }),
    {}
  );
};

const updateVisibility = (
  items: SlideContent.SlideContent[],
  answers: { [uuid: string]: any }
) => {
  return items.map((item) => {
    try {
      return !(
        item.display_condition === null ||
        query(item.display_condition, answers)
      );
    } catch (error) {
      return false;
    }
  });
};

const buildStateFromSurvey = (survey: Survey): Partial<SurveyState> => {
  const {
    current_slide: { content, template },
    ...surveyWithoutSlide
  } = survey;

  const questionOrPreviewItems = content.filter(
    (item) =>
      item.type === EContentTypes.QUESTION ||
      item.type === EContentTypes.QUESTION_PREVIEW
  ) as SlideContent.Question[];
  const answers = getAnswers(
    questionOrPreviewItems.map((item) => item.question)
  );
  const questionItems = questionOrPreviewItems.filter(
    (item) => item.type === EContentTypes.QUESTION
  );

  return {
    survey: surveyWithoutSlide,
    slideTemplate: template,
    itemIds: content.map((item) => item.uuid),
    questionIds: questionOrPreviewItems.map(
      (item) => (item as SlideContent.Question).question.template.uuid
    ),
    itemIdByQuestionId: questionOrPreviewItems.reduce(
      (prev, curr) => ({
        ...prev,
        [(curr as SlideContent.Question).question.template.uuid]: curr.uuid,
      }),
      {}
    ),
    questionIdByItemId: questionOrPreviewItems.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: (curr as SlideContent.Question).question.template.uuid,
      }),
      {}
    ),
    questionById: questionOrPreviewItems.reduce(
      (prev, curr) => ({
        ...prev,
        [(curr as SlideContent.Question).question.template.uuid]: (
          curr as SlideContent.Question
        ).question,
      }),
      {}
    ),
    itemById: content.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: { ...curr, question: null },
      }),
      {}
    ),
    hidden: updateVisibility(content, answers),
    primaryActionDisabled: !validateAnswers(
      questionItems.map((item) => item.question)
    ),
    hasQuestions: questionItems.length > 0 && !survey.read_only,
  };
};

const surveySlice = createSlice({
  name: "survey",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.error = false;
      state.loading = false;
      state.survey = undefined;
      state.actions = undefined;
    },
    setAnswer: (state, action: PayloadAction<{ uuid: string; value: any }>) => {
      const { uuid, value } = action.payload;
      if (state.questionById[uuid]) {
        state.questionById[uuid].answer = value;
      } else {
        console.warn("No question to update", { uuid, value });
      }
      const questions = Object.values(state.questionById);
      state.hidden = updateVisibility(
        state.itemIds.map((uuid) => state.itemById[uuid]),
        getAnswers(questions)
      );
      state.primaryActionDisabled = !validateAnswers(questions);
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getDiary.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getDiary.fulfilled, (state, action) => {
        return {
          ...state,
          ...buildStateFromSurvey(action.payload.response),
          loading: false,
          actions: action.payload.actions,
        };
      })
      .addCase(getDiary.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.survey = undefined;
      })
      .addCase(getSurvey.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSurvey.fulfilled, (state, action) => {
        return {
          ...state,
          ...buildStateFromSurvey(action.payload.response),
          loading: false,
          actions: action.payload.actions,
        };
      })
      .addCase(getSurvey.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.survey = undefined;
      })
      .addCase(getIntroSurvey.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIntroSurvey.fulfilled, (state, action) => {
        return {
          ...state,
          ...buildStateFromSurvey(action.payload.response),
          loading: false,
          actions: action.payload.actions,
        };
      })
      .addCase(getIntroSurvey.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.survey = undefined;
      })
      .addCase(updateSurvey.pending, (state) => {
        state.loading = true;
        state.error = false;
      })

      // thought diary
      .addCase(getOrCreateThought.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOrCreateThought.fulfilled, (state, action) => {
        return {
          ...state,
          ...buildStateFromSurvey(action.payload.response),
          loading: false,
          actions: action.payload.actions,
        };
      })
      .addCase(getOrCreateThought.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.survey = undefined;
      })

      // habits
      .addCase(getHabits.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getHabits.fulfilled, (state, action) => {
        return {
          ...state,
          ...buildStateFromSurvey(action.payload.response),
          loading: false,
          actions: action.payload.actions,
        };
      })
      .addCase(getHabits.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.survey = undefined;
      })

      // insomnia checker

      .addCase(getInsomniaChecker.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInsomniaChecker.fulfilled, (state, action) => {
        return {
          ...state,
          ...buildStateFromSurvey(action.payload.response),
          loading: false,
          actions: action.payload.actions,
        };
      })
      .addCase(getInsomniaChecker.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.survey = undefined;
      })

      // update survey
      .addCase(updateSurvey.fulfilled, (state, action) => {
        return {
          ...state,
          ...buildStateFromSurvey(action.payload.response),
          loading: false,
          actions: action.payload.actions,
        };
      })
      .addCase(updateSurvey.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.survey = undefined;
      });
  },
});

export const SurveyActions = surveySlice.actions;
export const surveyReducer = surveySlice.reducer;
