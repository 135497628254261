import { Pressable, StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { EAuthType } from "../../types/auth";

import CustomIcon from "../CustomIcon";

interface Props {
  text: string;
  type: EAuthType;
  disabled?: boolean;
  onPress?: () => void;
}

const AuthButton = ({ text, type, disabled, onPress }: Props) => {
  const returnIcon = () => {
    switch (type) {
      case EAuthType.GOOGLE:
        return (
          <CustomIcon name="community:google" size={24} style={styles.image} />
        );
      case EAuthType.FACEBOOK:
        return <CustomIcon name="facebook" size={24} style={styles.image} />;
      case EAuthType.APPLE:
        return <CustomIcon name="community:apple" size={24} style={styles.image} />;
      case EAuthType.EMAIL:
        return <CustomIcon name="email" size={24} style={styles.image} />;
      default:
        return "";
    }
  };

  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={({ pressed }) => [styles.container, pressed && { opacity: 0.5 }]}
    >
      <View>{returnIcon()}</View>
      <Text style={styles.text}>{text}</Text>
    </Pressable>
  );
};

export default AuthButton;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: Colors.lightgray,
    borderRadius: 4,
    paddingVertical: 20,
    paddingHorizontal: 17,
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 21,
    color: Colors.dark,
    flexWrap: "wrap",
    flex: 1,
  },
  image: {
    width: 24,
    height: 24,
    marginRight: 25,
  },
});
