import { useEffect } from "react";
import { Alert, Linking } from "react-native";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { PaymentActions } from "../../store/redux/payments";
import {
  addPromoCode,
  getPlans,
} from "../../store/redux/thunk_functions/payments";
import CheckoutLayout from "./common/CheckoutLayout";
import { ENDPOINTS } from "../../api/config";
import { Checkout } from "../../types/checkout";
import { useAuthorizedCall } from "../../hooks/useAuthorizedCall";
import { refreshUserState } from "../../store/redux/thunk_functions/user";
import { refreshAll } from "../../store/redux/thunk_functions/other";

const PaymentScreen = ({
  navigation,
}: RootStackScreenProps<SCREEN.Payments>) => {
  const { loading, promoCode, processing, plans } = useAppSelector(
    (state) => state.payments
  );
  const dispatch = useAppDispatch();
  const {
    ok,
    payments_heading,
    payments_promo_code_cta,
    payments_promo_code_does_not_exist,
    payments_promo_code_placeholder,
    payments_promo_code_add,
    pricing_make_payment,
    pricing_subheader,
    payments_temporary_unavailable,
    payments_free_access_info,
  } = useAppSelector((state) => state.manifest.translations);
  const { user } = useAppSelector((state) => state.therapyParticipant);
  const call = useAuthorizedCall();

  const handleCheckout = async (
    planUuid: string,
    paymentLink: string,
    promoCode?: string
  ) => {
    const email = encodeURIComponent(user!.email);
    const uuid = user!.uuid;
    if (promoCode && promoCode.length > 0) {
      const { data: checkout } = await call<Checkout>(
        ENDPOINTS.payments.CHECKOUT(
          planUuid,
          promoCode && promoCode.length > 0 ? promoCode : undefined
        )
      );
      if (checkout.type == "payment") {
      } else {
        navigation.navigate("ThankYou" as any);
      }
    }

    await Linking.openURL(
      `${paymentLink}?client_reference_id=${uuid}&prefilled_email=${email}`
    );
  };

  const handleAddPromo = async (code: string) => {
    try {
      dispatch(PaymentActions.setPromoCode(code));
      const success = await dispatch(addPromoCode(code));
      if (!success) {
        throw "failed";
      }
      return 0;
    } catch (error: any) {
      throw { message: payments_promo_code_does_not_exist };
    }
  };

  useEffect(() => {
    const init = async () => {
      await dispatch(getPlans());
    };
    init();

    return () => {
      dispatch(PaymentActions.setProcessing(false));
    };
  }, []);

  return (
    <CheckoutLayout
      loading={processing || loading}
      onCheckout={(planUuid: string, p?: string, paymentLink?: string) =>
        handleCheckout(planUuid, paymentLink!, promoCode)
      }
      onSetPromoCode={(code: string) => handleAddPromo(code)}
      plans={plans}
      header={payments_heading}
      subheader={pricing_subheader}
      makePaymentText={pricing_make_payment}
      planUnavailableText={payments_temporary_unavailable}
      promoCode={{
        addText: payments_promo_code_cta,
        buttonText: ok,
        placeholder: payments_promo_code_placeholder,
        text: payments_promo_code_add,
      }}
    />
  );
};

export default PaymentScreen;
