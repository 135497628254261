import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IRelaxation } from "../../../components/relaxations_carousel/relaxations_carousel.types";
import { IThunkApi } from "../../../types/redux";

export const fetchRelaxations = createAsyncThunk<
  IRelaxation[],
  undefined,
  IThunkApi
>("relaxations/fetchRelaxations", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call<IRelaxation[]>(
    authorize(ENDPOINTS.tools.GET_RELAXATIONS(), token)
  );
  return data;
});
