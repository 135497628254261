export const supportRoutes = {
  FAQ: {
    url: "/support/faq",
    method: "GET",
  },
  ISSUES: (message: string, type: string) => ({
    url: "/support/issues",
    method: "POST",
    data: {
      content: message,
      type,
    },
  }),
};
