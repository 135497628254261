import React, { FC, useEffect, useState } from "react";
import { Image as RNImage, View } from "react-native";
import { Image as ImageProps } from "../../types/v2/slides/slide_content";

const Image: FC<ImageProps> = ({ storage_url }) => {
  const [aspectRatio, setAspectRatio] = useState(0);

  useEffect(() => {
    async function prepareImage() {
      RNImage.getSize(storage_url, (width, height) => {
        setAspectRatio(width / height);
      });
    }
    prepareImage();
  }, [storage_url]);

  return (
    <View style={{ marginVertical: 15 }}>
      <RNImage
        source={{ uri: storage_url }}
        style={{ width: "100%", aspectRatio }}
      />
    </View>
  );
  ``;
};

export default Image;
