import { FC, useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import Link from "../../components/controls/Link";
import GoBack from "../../components/GoBack";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import ErrorMessage from "../../components/utils/error_message";
import Input from "../../components/utils/input";
import PrimaryButton from "../../components/utils/primary_button";
import { useEmailPasswordLogin } from "../../hooks/auth";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { validateEmail } from "../../utils/email";

const EmailPasswordLoginScreen: FC<
  RootStackScreenProps<SCREEN.EmailPasswordLogin>
> = () => {
  const {
    auth_signin_header,
    auth_signin_subheader,
    auth_password_reset_placeholder_email,
    auth_password_reset_placeholder_password,
    auth_signin_password_remind_cta,
    auth_signin_cta,
    auth_back_to_main_screen,
    auth_email_required,
    auth_password_required,
    auth_email_invalid_format,
    auth_signin_failed,
  } = useTranslations();
  const login = useEmailPasswordLogin();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const [signinError, setSignInError] = useState("");

  const handleLogin = async () => {
    let hasErrors = false;

    if (!email) {
      hasErrors = true;
      setEmailError(auth_email_required);
    } else {
      if (!validateEmail(email)) setEmailError(auth_email_invalid_format);
      else setEmailError(null);
    }
    if (!password) {
      hasErrors = true;
      setPasswordError(auth_password_required);
    } else {
      setPasswordError(null);
    }

    if (hasErrors || loading) return;

    setLoading(true);
    try {
      await login(email, password);
    } catch (error: any) {
      setSignInError(auth_signin_failed);
    }
    setLoading(false);
  };

  const MainContainer = Platform.OS == "web" ? View : KeyboardAvoidingView;

  return (
    <MainContainer style={{ flex: 1, height: "100%" }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Container>
          <GoBack />

          <View style={[styles.container, { height: "100%" }]}>
            <View>
              <MainHeading text={auth_signin_header} size="large" />
              <View style={{ marginTop: 10 }}>
                <SubHeading text={auth_signin_subheader} />
              </View>
              <Divider />
              <View>
                <Input
                  value={email}
                  setValue={setEmail}
                  placeholder={auth_password_reset_placeholder_email}
                  type="emailAddress"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoComplete="email"
                  error={emailError}
                />
                <Input
                  value={password}
                  setValue={setPassword}
                  placeholder={auth_password_reset_placeholder_password}
                  type="password"
                  error={passwordError}
                />
                <Link to={{ screen: SCREEN.ForgotPassword }}>
                  {auth_signin_password_remind_cta}
                </Link>
              </View>
              <ErrorMessage text={signinError} />
            </View>
          </View>
          <View style={styles.action_container}>
            <Link
              to={{ screen: SCREEN.Onboarding }}
              style={{ alignSelf: "center" }}
            >
              {auth_back_to_main_screen}
            </Link>
            <View style={{ marginTop: 20 }}>
              <PrimaryButton
                label={auth_signin_cta}
                loading={loading}
                onPress={handleLogin}
              />
            </View>
          </View>
        </Container>
      </ScrollView>
    </MainContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flex: 1,
  },
  link: {
    textDecorationLine: "underline",
    marginTop: 8,
  },
  action_container: {
    flex: 1,
    justifyContent: "flex-end",
  },
});

export default EmailPasswordLoginScreen;
