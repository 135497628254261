import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useTranslations } from "../../hooks/useTranslations";
import { SleepQuality as SleepQualityProps } from "../../types/v2/sleepDiary";
import CustomIcon from "../CustomIcon";
import { emotes } from "../inputs/QualityPicker";
import DiarySummaryTexts from "../typography/diary_summary_texts";

const QUALITY_VALUES = ["very_poor", "poor", "moderate", "good", "very_good"];

const SleepQuality: FC<SleepQualityProps> = ({
  data,
  primary_text_label,
  secondary_text_label,
}) => {
  const translations = useTranslations()

  return (
    <View>
      <DiarySummaryTexts
        primary_text={translations[primary_text_label]}
        secondary_text={translations[secondary_text_label]}
      />
      <View style={styles.container}>
        {data.map((q) => {
          return (
            <View key={q.primary_text_label}>
              <Text style={styles.param_name}>
                {translations[q.primary_text_label]}
              </Text>
              <View style={styles.icon_row}>
                {q.data.map((e, index) => {
                  return (
                    <View style={styles.icon_container} key={index}>
                      {e && (
                        <CustomIcon
                          name={emotes[QUALITY_VALUES.indexOf(e)]}
                          size={30}
                        />
                      )}
                    </View>
                  );
                })}
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default SleepQuality;

const styles = StyleSheet.create({
  container: {
    paddingLeft: 30,
  },
  icon_row: {
    flexDirection: "row",
  },
  icon_container: {
    paddingVertical: 10,
    width: 108,
  },
  param_name: {
    fontSize: 14,
    lineHeight: 28,
    fontWeight: "600",
  },
});
