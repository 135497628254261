import { useIsFocused } from "@react-navigation/native";
import { FC, useEffect } from "react";
import { ScrollView } from "react-native";
import FloatingMenu from "../../components/FloatingMenu";
import GoBack from "../../components/GoBack";
import Loader from "../../components/Loader";
import Thought from "../../components/thought/thought";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { getThoughts } from "../../store/redux/thunk_functions/getThoughts";

const ThoughtsScreen: FC<RootStackScreenProps<SCREEN.Thoughts>> = () => {
  const isFocused = useIsFocused();
  const { needRefresh, thoughts, loading } = useAppSelector(
    (state) => state.thoughts
  );
  const { tools_thought_diary, thought_diary_list_subheader } = useAppSelector(
    (state) => state.manifest.translations
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh && isFocused) {
      dispatch(getThoughts());
    }
  }, [needRefresh, isFocused]);

  return (
    <>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Container>
          <GoBack />
          <MainHeading text={tools_thought_diary} size="large" />
          <SubHeading text={thought_diary_list_subheader} />
          <Divider />
          {loading && <Loader />}
          {thoughts.map((thought) => {
            return <Thought key={thought.uuid} {...thought} />;
          })}
        </Container>
      </ScrollView>
      <FloatingMenu items={["thought_diary"]} />
    </>
  );
};

export default ThoughtsScreen;
