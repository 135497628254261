import { Link, StackActionType, useLinkProps } from "@react-navigation/native";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import { Linking, Platform, Pressable, StyleSheet, Text } from "react-native";
import { Colors } from "../../constants/colors";
import { RootStackParamList } from "../../navigation/StackNavigation.types";
import CustomIcon from "../CustomIcon";

interface Props {
  to: To<RootStackParamList>;
  action?: StackActionType;
  children: React.ReactNode;
}

const ListLink = ({ to, action, children }: Props) => {
  const { onPress, ...props } = useLinkProps({ to, action });

  const handlePress = () => {
    if (typeof to == "string") {
      Linking.openURL(to);
    } else {
      onPress();
    }
  };

  if (Platform.OS === "web" && typeof to !== "string") {
    return (
      <Link to={to} style={style.link}>
        <Text style={style.link_text}>{children}</Text>
        <CustomIcon name="chevron-right" color={Colors.primary} size={20} />
      </Link>
    );
  }

  return (
    <Pressable onPress={handlePress} style={style.link} {...props}>
      <Text style={style.link_text}>{children}</Text>
      <CustomIcon name="chevron-right" color={Colors.primary} size={20} />
    </Pressable>
  );
};

const style = StyleSheet.create({
  link: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    marginVertical: 2,
  },
  link_text: {
    flex: 1,
    fontWeight: "700",
  },
  section: {
    textTransform: "uppercase",
    color: Colors.darkgray,
  },
});

export default ListLink;
