import NetInfo from "@react-native-community/netinfo";
import { useEffect, useState } from "react";

export function useNetworkConnection() {
  const [connected, setConnected] = useState(true);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      setConnected(Boolean(state.isInternetReachable));
    });
    return () => unsubscribe();
  }, []);

  return connected;
}
