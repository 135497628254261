import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { Thought } from "../../../types/thought";

export const getThoughts = createAsyncThunk<Thought[], undefined, IThunkApi>(
  "thoughts/get",
  async (_, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call(authorize(ENDPOINTS.tools.GET_THOUGHTS, token));
    return data;
  }
);
