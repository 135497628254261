import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { SlideContent } from "../../../types/v2/slides/slide_content";

export const getTherapyFolderProfile = createAsyncThunk<
  SlideContent[],
  undefined,
  IThunkApi
>("therapyFolder/Profile", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(authorize(ENDPOINTS.therapy.PROFILE, token));
  return data.content;
});

export const getTherapyFolderInsomniaMap = createAsyncThunk<
  SlideContent[],
  undefined,
  IThunkApi
>("therapyFolder/InsomniaMap", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(authorize(ENDPOINTS.therapy.INSOMNIA_MAP, token));
  return data.content;
});

export const getTherapyFolderBeliefs = createAsyncThunk<
  SlideContent[],
  undefined,
  IThunkApi
>("therapyFolder/Beliefs", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(authorize(ENDPOINTS.therapy.BELIEFS, token));
  return data.content;
});
