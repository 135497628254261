import { FC } from "react";
import { IntegerQuestion } from "../../types/v2/questions";
import { QuestionProps } from "../../types/v2/slides/questionProps";
import IntegerPicker from "../inputs/IntegerPicker";
import QuestionWrapperInline from "./QuestionWrapperInline";

const Integer: FC<QuestionProps<number, IntegerQuestion>> = ({
  onChange,
  question,
  readOnly,
}) => {
  const {
    template: { content, subcontent },
    answer: value,
  } = question;

  return (
    <QuestionWrapperInline
      content={content || undefined}
      subcontent={subcontent || undefined}
    >
      <IntegerPicker onChange={onChange} value={value} readOnly={readOnly} />
    </QuestionWrapperInline>
  );
};

export default Integer;
