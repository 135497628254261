import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { Tab } from "../../types/v2/sleepDiary";
import { Translations } from "../../types/v2/manifest";

interface Props {
  tabs: Tab[];
  translations: Translations;
}

const DiarySummaryDays: FC<Props> = ({ tabs, translations }) => {
  return (
    <View style={styles.container}>
      {tabs.map((t) => {
        return (
          <View style={styles.day} key={t.key}>
            <Text style={styles.text}>{translations[t.label]}</Text>
          </View>
        );
      })}
    </View>
  );
};

export default DiarySummaryDays;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 46,
    backgroundColor: Colors.primary,
    paddingHorizontal: 30,
    flexDirection: "row",
  },
  day: {
    width: 108,
    height: 46,
    marginRight: 1,
  },
  text: {
    fontSize: 14,
    lineHeight: 46,
    color: Colors.white,
    fontWeight: "700",
  },
});
