import { useEffect } from "react";
import { FlatList, Pressable, View } from "react-native";
import CustomIcon from "../../components/CustomIcon";
import DropdownText from "../../components/DropdownText";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { Colors } from "../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { SCREEN } from "../../navigation/screens";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { getFAQ } from "../../store/redux/thunk_functions/configuration";

const FAQScreen = ({ navigation }: RootStackScreenProps<SCREEN.FAQ>) => {
  const { support_header, support_faq_long } = useTranslations();
  const { faq, faqLoaded } = useAppSelector((state) => state.configuration);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!faqLoaded) {
      dispatch(getFAQ());
    }
  }, [faqLoaded]);

  return (
    <Container>
      <View style={{}}>
        <Pressable
          style={{ paddingVertical: 2, marginBottom: 15 }}
          onPress={() => navigation.goBack()}
        >
          <CustomIcon name="chevron-left" color={Colors.primary} size={26} />
        </Pressable>
      </View>
      <MainHeading text={support_header} size={"small"} />
      <View style={{ marginTop: 10 }}>
        <SubHeading text={support_faq_long} />
      </View>
      <Divider />
      <FlatList
        data={faq}
        renderItem={({ item }) => (
          <DropdownText
            text={item.answer}
            title={item.question}
            key={item.order}
          />
        )}
        ItemSeparatorComponent={Divider}
      />
    </Container>
  );
};

export default FAQScreen;
