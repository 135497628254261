import jwtDecode from "jwt-decode";
import { call } from "../api";
import { ENDPOINTS } from "../api/config";
import { STORE_KEY } from "../store/redux/auth";
import { storeObject } from "../store/storage";
import { Token } from "../types/v2/token";

export function isTokenValid(token: string): boolean {
  const decoded = jwtDecode(token);
  const exp = (decoded as any).exp;
  return Boolean(exp) && new Date(exp * 1000) < new Date();
}

export function isGuest(token: string): boolean {
  const decoded = jwtDecode(token);
  const scopes: string[] = (decoded as any)?.scopes || [];
  return scopes.includes("guest");
}

export async function createGuestAccount(
  language: string,
  timezone: string
): Promise<Token> {
  if (language.startsWith("en")) {
    language = "pl";
  }
  const { data } = await call<Token>(ENDPOINTS.user.GUEST(language, timezone));
  const { access_token: token } = data;
  await storeObject(STORE_KEY, { token });
  return data;
}
