import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { SurveyWithActions } from "../../../types/v2/survey";

interface Args {
  uuid: string;
  action?: string;
}

export const getSurvey = createAsyncThunk<SurveyWithActions, Args, IThunkApi>(
  "survey/get",
  async (args, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call(
      authorize(ENDPOINTS.survey.GET_SURVEY(args.uuid, args.action), token)
    );
    return data;
  }
);
