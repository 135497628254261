import { useIsFocused } from "@react-navigation/native";
import { FC, useEffect } from "react";
import { RefreshControl, ScrollView } from "react-native";
import Loader from "../../components/Loader";
import Slide from "../../components/slides/Slide";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { PatientCardTabScreenProps } from "../../navigation/PatientCardTabNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { getTherapyFolderProfile } from "../../store/redux/thunk_functions/therapyFolder";

const TherapyProfileScreen: FC<
  PatientCardTabScreenProps<SCREEN.TherapyProfile>
> = () => {
  const isFocused = useIsFocused();
  const {
    therapyFolder: {
      profile: { needRefresh, content, loading },
    },
  } = useAppSelector((state) => state);
  const translations = useTranslations();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh && isFocused) {
      dispatch(getTherapyFolderProfile());
    }
  }, [needRefresh, isFocused]);

  const handleRefresh = () => {
    dispatch(getTherapyFolderProfile());
  };

  return (
    <ScrollView
      contentContainerStyle={{
        paddingTop: 30,
        paddingHorizontal: 30,
      }}
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
      }
    >
      {loading && content.length === 0 && <Loader />}
      <Slide content={content} translations={translations} />
    </ScrollView>
  );
};

export default TherapyProfileScreen;
