import { FC } from "react";
import { View } from "react-native";
import { Translations } from "../../types/v2/manifest";
import { TherapyProgress as TherapyProgressProps } from "../../types/v2/slides/slide_content";
import TherapyProgressInner from "../TherapyProgress";

interface Props extends TherapyProgressProps {
  translations: Translations;
}

const TherapyProgress: FC<Props> = (props) => (
  <View style={{ alignItems: "center" }}>
    <TherapyProgressInner
      translations={props.translations}
      therapy_progress={props.therapy_progress}
      session_progress={props.session_progress || undefined}
      current_week={props.current_week || undefined}
      comment_content_session_progress={
        props.comment_content_session_progress || undefined
      }
      comment_content_therapy_progress={
        props.comment_content_therapy_progress || undefined
      }
      comment_heading_session_progress={
        props.comment_heading_session_progress || undefined
      }
      comment_heading_therapy_progress={
        props.comment_heading_therapy_progress || undefined
      }
    />
  </View>
);

export default TherapyProgress;
