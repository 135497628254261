import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { storeObject } from "../../storage";
import { ManifestState, STORE_KEY } from "../manifest";

interface Args {
  language: string;
  token?: string;
}

export const fetchManifest = createAsyncThunk<ManifestState, Args, IThunkApi>(
  "manifest/fetch",
  async ({ language, token }, thunkApi) => {
    if (token === undefined) {
      token = thunkApi.getState().auth.token;
    }
    const { data } = await call(authorize(ENDPOINTS.MANIFEST(language), token));
    const translations = data.cta_translations;
    const state = { translations, language };
    await storeObject(STORE_KEY, state);
    return state;
  }
);
