import { StyleSheet } from "react-native";
import { Colors } from "../../constants/colors";

const styles = StyleSheet.create({
  percent_container: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 30,
  },
  percent: {
    fontSize: 60,
    lineHeight: 72,
    fontWeight: "700",
    color: Colors.primary,
    marginRight: 16,
  },
  bold_text: {
    fontSize: 14,
    fontWeight: "700",
    lineHeight: 22,
    color: Colors.dark,
  },
  frames_container: {
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bar_container: {
    height: 300,
    marginTop: 14,
    backgroundColor: Colors.lightblue,
    paddingVertical: 30,
    paddingHorizontal: 30,
  },
  bar_segment: {
    flexDirection: "row",
    marginTop: -1,
    position: "relative",
    height: 50,
    minHeight: 14,
  },
  bar_element: {
    width: 10,
    backgroundColor: Colors.primary_dark,
    marginRight: 17,
  },
  top_bar_element: {
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
  },
  bottom_bar_element: {
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
  bar_values: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    left: 30,
    top: 0,
    height: "100%",
  },
  legend_element: {
    flexDirection: "row",
    alignItems: "center",
  },
  legend_circle: {
    width: 8,
    height: 8,
    borderRadius: 10,
    marginRight: 10,
    backgroundColor: Colors.primary_dark,
  },
});

export default styles