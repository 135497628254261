import { FC } from "react";
import { StyleSheet, View } from "react-native";
import Svg, { Circle, Path } from "react-native-svg";
import { Colors } from "../../constants/colors";
import { ArcProps, CircleBarProps } from "../../types/therapy_progress";

const Arc: FC<ArcProps> = ({ endAngle, startAngle, x, y, r, color }) => {
  if (startAngle > endAngle) {
    let tmp = startAngle;
    startAngle = endAngle;
    endAngle = tmp;
  }

  if (endAngle - startAngle > Math.PI * 2) {
    endAngle = Math.PI * 1.99999;
  }

  const largeArc = endAngle - startAngle <= Math.PI ? 0 : 1;

  const d = [
    "M",
    x,
    y,
    "L",
    x + Math.cos(startAngle) * r,
    y - Math.sin(startAngle) * r,
    "A",
    r,
    r,
    0,
    largeArc,
    0,
    x + Math.cos(endAngle) * r,
    y - Math.sin(endAngle) * r,
    "L",
    x,
    y,
  ].join(" ");

  return <Path fill={color} d={d} />;
};

const CircleBar: FC<CircleBarProps> = ({
  barWidth,
  radius,
  progress,
  barColor,
}) => {
  const startAngle = (Math.PI * 5) / 4;
  const endAngle = (Math.PI * 7) / 4;
  const barContainerLength = ((1 - progress) * Math.PI * 3) / 2;
  const barBackgroundStartAngle = -Math.PI / 4;
  const barBackgroundEndAngle = barBackgroundStartAngle + barContainerLength;
  return (
    <View>
      <Svg width={2 * radius} height={2 * radius}>
        <Circle cx={radius} cy={radius} r={radius} fill={barColor} />
        <Arc
          color={styles.bar.backgroundColor}
          startAngle={barBackgroundStartAngle}
          endAngle={barBackgroundEndAngle}
          x={radius}
          y={radius}
          r={radius}
        />
        <Circle
          cx={radius}
          cy={radius}
          r={radius - barWidth}
          fill={Colors.white}
        />
        <Arc
          color={Colors.white}
          startAngle={startAngle}
          endAngle={endAngle}
          x={radius}
          y={radius}
          r={radius + 1}
        />
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  bar: {
    backgroundColor: "#D8D8D8",
  },
});

export default CircleBar;
