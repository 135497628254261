import Constants from "expo-constants";
import { devToolsRoutes } from "./routes/devTools";
import { notificationsUrls } from "./routes/notifications";
import { onboardingRoutes } from "./routes/onboarding";
import { paymentsRoutes } from "./routes/payments";
import { sessionRoutes } from "./routes/session";
import { settingsRoutes } from "./routes/settings";
import { supportRoutes } from "./routes/support";
import { surveyRoutes } from "./routes/survey";
import { therapyFolderRoutes } from "./routes/tharapy-folder";
import { toolsRoutes } from "./routes/tools";
import { userRoutes } from "./routes/user";

export const CONFIG = {
  url: Constants.expoConfig?.extra?.server?.url,
  headers: {
    "Content-Type": "application/json",
  },
};

export const ENDPOINTS = {
  onboarding: onboardingRoutes,
  user: userRoutes,
  survey: surveyRoutes,
  session: sessionRoutes,
  tools: toolsRoutes,
  devTools: devToolsRoutes,
  therapy: therapyFolderRoutes,
  payments: paymentsRoutes,
  settings: settingsRoutes,
  support: supportRoutes,
  CONFIGURATION: {
    method: "GET",
    url: "/configuration",
  },
  DASHBOARD: {
    method: "GET",
    url: "/api/v1/cbti/dashboard",
  },
  MANIFEST: (language: string) => {
    const url = `/manifest?language=${language}`;
    return {
      method: "GET",
      url,
    };
  },
  notifications: notificationsUrls,
};
