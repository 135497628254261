import { useWindowDimensions } from "react-native";
import { SCREEN } from "../navigation/screens";
import { DrawerMenuSection } from "../types/v2/navigation/drawer";
import { TherapyParticipant } from "../types/v2/user";
import { useIsLandscape } from "./useIsLandscape";

export function useDrawerRoutes(): DrawerMenuSection[] {
  const { width, height } = useWindowDimensions();
  const isLandscape = useIsLandscape();

  return [
    {
      title: "menu_drawer_section_learning",
      links: [
        {
          screen: SCREEN.Library,
          title: "library_header",
          icon: "community:bookshelf",
        },
        {
          screen: SCREEN.Flashcards,
          title: "menu_drawer_flashcards",
          icon: "inbox",
        },
      ],
    },
    {
      title: "menu_drawer_section_tools",
      links: [
        {
          screen: SCREEN.Relaxations,
          title: "relaxations_header",
          icon: "community:weather-sunny",
          locked: (therapyState: TherapyParticipant) =>
            !therapyState.therapy_status?.relaxations_enabled,
        },
        {
          screen: SCREEN.Thoughts,
          title: "menu_drawer_thought_diary",
          icon: "community:brain",
          locked: (therapyState: TherapyParticipant) =>
            !therapyState.therapy_status?.thought_diary_enabled,
        },
        {
          screen: SCREEN.Notes,
          title: "tools_notepad",
          icon: "community:pencil",
        },
        {
          screen: SCREEN.SleepReport,
          title: "sleep_report_main_page_header",
          icon: "note",
          locked: (therapyState) =>
            therapyState.therapy_status?.current_session.tag === "session_1",
        },
      ],
    },
    {
      title: "menu_drawer_section_others",
      links: [
        {
          screen: SCREEN.Profile,
          title: "menu_drawer_account",
          icon: "community:account-outline",
        },
        {
          screen: SCREEN.Payments,
          title: "menu_drawer_payments",
          icon: "account-balance-wallet",
          hidden: (therapyState: TherapyParticipant) =>
            therapyState.subscription !== null,
        },
        {
          screen: SCREEN.Settings,
          title: "settings_main_header",
          icon: "settings",
        },
        {
          screen: SCREEN.ContactAndHelp,
          title: "support_header",
          icon: "help-center",
        },
      ],
    },
  ];
}
