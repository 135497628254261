import { FC, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import {
  GestureEvent,
  HandlerStateChangeEvent,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
} from "react-native-gesture-handler";
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { Colors } from "../constants/colors";

interface Props {
  width: number;
  startStep?: number;
  steps: number;
  value: number | null;
  disabled?: boolean;
  onChange?: (value: number) => void;
}

const MARKER_RADIUS = 12;

const Slider: FC<Props> = ({
  width = 300,
  startStep = 0,
  value = null,
  disabled = false,
  steps = 5,
  onChange,
}) => {
  const stepSize = width / (steps - 1);
  const currentValue = value !== null ? value : startStep;
  const markerOffset = useSharedValue(startStep * stepSize);
  const dirty = value !== null;

  function update(x: number) {
    if (onChange !== undefined) {
      const val = Math.min(Math.max(0, Math.round(x)), width);
      const step = Math.round(val / stepSize);
      markerOffset.value = step * stepSize;
      onChange(step);
    }
  }

  const markerStyles = useAnimatedStyle(() => {
    const mid = startStep * stepSize;
    return {
      left: markerOffset.value,
      backgroundColor:
        !dirty || disabled
          ? Colors.gray
          : startStep === 0
          ? Colors.primary
          : markerOffset.value > mid
          ? Colors.slider_positive
          : markerOffset.value < mid
          ? Colors.slider_negative
          : Colors.primary,
    };
  });

  const barStyles = useAnimatedStyle(() => {
    const mid = startStep * stepSize;
    return {
      left: markerOffset.value < mid ? markerOffset.value : mid,
      width: Math.abs(markerOffset.value - startStep * stepSize),
      backgroundColor:
        startStep === 0
          ? Colors.primary
          : markerOffset.value > mid
          ? Colors.slider_positive
          : Colors.slider_negative,
    };
  });

  const onUpdate = (event: GestureEvent<PanGestureHandlerEventPayload>) => {
    const { x } = event.nativeEvent;
    const boundedX = Math.min(Math.max(x, 0), width);
    markerOffset.value = boundedX;
  };

  const onFinish = (
    event: HandlerStateChangeEvent<PanGestureHandlerEventPayload>
  ) => {
    const { x } = event.nativeEvent;
    const boundedX = Math.min(Math.max(x, 0), width);
    if (onChange !== undefined) {
      runOnJS(update)(boundedX);
    }
  };

  useEffect(() => {
    markerOffset.value = withTiming(currentValue * stepSize, { duration: 500 });
  }, []);

  return (
    <PanGestureHandler
      onGestureEvent={onUpdate}
      enabled={!disabled}
      onHandlerStateChange={onFinish}
    >
      <View style={{ width: width }}>
        <View style={[styles.track, { width }]} />
        <Animated.View style={[styles.bar, barStyles]} />
        <Animated.View
          style={[
            styles.marker,
            (!dirty || disabled) && {
              backgroundColor: Colors.gray,
            },
            markerStyles,
          ]}
        />
      </View>
    </PanGestureHandler>
  );
};

const styles = StyleSheet.create({
  track: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: 4,
    backgroundColor: Colors.mediumgray,
    zIndex: 10,
  },
  bar: {
    position: "absolute",
    left: 0,
    height: 4,
    zIndex: 20,
  },
  marker: {
    zIndex: 30,
    borderWidth: 3,
    position: "absolute",
    left: 0,
    top: -MARKER_RADIUS,
    width: 2 * MARKER_RADIUS,
    height: 2 * MARKER_RADIUS,
    borderRadius: MARKER_RADIUS,
    backgroundColor: Colors.primary,
    alignSelf: "center",
    borderColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
});

export default Slider;
