import dayjs from "dayjs";
import { FC } from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { Colors } from "../../constants/colors";
import { DayPlanItem as DayPlanItemProps } from "../../types/v2/dayPlan";
import { Translations } from "../../types/v2/manifest";
import CustomIcon from "../CustomIcon";
import Task from "./Task";

const roman_months = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII",
];

interface Props extends DayPlanItemProps {
  translations: Translations;
}

const DayPlanItem: FC<Props> = ({
  translations,
  date,
  daytime,
  icon,
  details,
  messages,
  number,
}) => {
  const item_date =
    daytime === "day" ? dayjs(date).hour(6) : dayjs(date).hour(22);
  const now = dayjs();

  const isAvailable = () => now.diff(item_date) >= 0;

  const calcProgress = () => {
    if (daytime === "day") {
      if (item_date.isBefore(now, "day")) return 1;

      const time_past = now.hour() * 60 + now.minute() - 360;
      return time_past / 960;
    } else {
      if (now.diff(item_date, "day") >= 2) return 1;
      else {
        return now.diff(item_date) / 480;
      }
    }
  };

  return (
    <View style={[styles.container]}>
      {daytime === "night" && (
        <View
          style={[
            {
              width: "100%",
              height: "100%",
              backgroundColor: Colors.lightblue,
              position: "absolute",
              left: 0,
              top: -22,
              zIndex: 0,
            },
            !isAvailable() && { backgroundColor: Colors.lightgray_transparent },
          ]}
        ></View>
      )}

      <View style={styles.indicator_container}>
        <View
          style={[
            styles.indicator_dot,
            { backgroundColor: isAvailable() ? Colors.primary : Colors.gray },
          ]}
        ></View>
        <View style={styles.indicator_line}>
          <View
            style={[
              styles.indicator_line_inner,
              isAvailable() && { height: calcProgress() * 100 + "%" },
            ]}
          ></View>
        </View>
      </View>
      <View style={styles.content_container}>
        <View style={styles.headline_container}>
          <View style={styles.daytime_container}>
            <CustomIcon
              name={icon}
              size={24}
              color={isAvailable() ? Colors.primary : Colors.gray}
            />
            <Text
              style={[styles.daytime, !isAvailable() && { color: Colors.gray }]}
            >
              {daytime === "day" ? "Dzień " : "Noc "}
              {number}
            </Text>
          </View>
          <Text
            style={[styles.daytime, !isAvailable() && { color: Colors.gray }]}
          >
            {daytime === "day"
              ? dayjs(date).date() + " " + roman_months[dayjs(date).month()]
              : dayjs(date).date() +
                " - " +
                (dayjs(date).date() + 1) +
                " " +
                roman_months[dayjs(date).month()]}
          </Text>
        </View>
        {Boolean(messages.length > 0) &&
          isAvailable() &&
          messages.map((m, i) => (
            <Text key={i} style={styles.message}>
              {translations[m]}
            </Text>
          ))}
        {Boolean(details.length > 0) &&
          isAvailable() &&
          details.map((d, i) => {
            return (
              <View style={{ marginBottom: 15 }} key={i}>
                <Text style={styles.header}>{translations[d.header]}</Text>
                <View>
                  {Boolean(d.tasks.length > 0) &&
                    d.tasks.map((t, i) => {
                      return (
                        <Task {...t} key={i} translations={translations} />
                      );
                    })}
                </View>
              </View>
            );
          })}
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: -10,
    paddingHorizontal: 30,
    flexDirection: "row",
    width: "100%",
    position: "relative",
  },
  content_container: {
    // paddingVertical: 20,
    flex: 1,
    paddingBottom: 35,
  },
  headline_container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginTop: -8,
  },
  daytime_container: {
    flexDirection: "row",
    alignItems: "center",
  },
  daytime: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "700",
    color: Colors.primary,
    marginLeft: 12,
  },
  message: {
    fontSize: 14,
    color: Colors.dark,
    fontWeight: "200",
    marginVertical: 10,
  },
  header: {
    fontSize: 14,
    fontWeight: "700",
    color: Colors.dark,
    textTransform: "uppercase",
  },
  indicator_container: {
    alignItems: "center",
    marginRight: 20,
    width: 8,
  },
  indicator_dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: Colors.gray,
  },
  indicator_line: {
    width: 2,
    flex: 1,
    backgroundColor: Colors.gray,
  },
  indicator_line_inner: {
    backgroundColor: Colors.primary,
    width: 2,
  },
});

export default DayPlanItem;
