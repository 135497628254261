import React, { FC, useEffect } from "react";
import { ScrollView } from "react-native";
import GoBack from "../../components/GoBack";
import Loader from "../../components/Loader";
import RelaxationsCarousel from "../../components/relaxations_carousel/relaxation_carousel";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { fetchRelaxations } from "../../store/redux/thunk_functions/fetchRelaxations";

const RelaxationsScreen: FC<RootStackScreenProps<SCREEN.Relaxations>> = () => {
  const { relaxations_header, relaxations_subheader } = useAppSelector(
    (state) => state.manifest.translations
  );

  const { needRefresh, byCategory, byId, loading } = useAppSelector(
    (state) => state.relaxations
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh) {
      dispatch(fetchRelaxations());
    }
  });

  const categories = Object.keys(byCategory).sort();

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <GoBack />
        <MainHeading text={relaxations_header} size="large" />
        <SubHeading text={relaxations_subheader} />
        <Divider />
        {categories.map((category) => {
          {
            loading && categories.length === 0 && <Loader />;
          }
          return (
            <React.Fragment key={category}>
              <RelaxationsCarousel
                relaxations={byCategory[category].map((uuid) => byId[uuid])}
                category={category}
              />
              <Divider />
            </React.Fragment>
          );
        })}
      </Container>
    </ScrollView>
  );
};

export default RelaxationsScreen;
