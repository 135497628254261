import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { Placeholder as PlaceholderProps } from "../../types/v2/slides/slide_content";
import CustomIcon from "../CustomIcon";

const Placeholder = (props: PlaceholderProps) => {
  return (
    <View style={styles.container}>
      {props.icon && (
        <View style={styles.iconContainer}>
          <CustomIcon name={props.icon} size={34} color="#979797" />
        </View>
      )}
      <Text style={styles.text}>{props.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 30,
    backgroundColor: Colors.lightgray,
    borderRadius: 8,
    alignItems: "center",
  },
  iconContainer: {
    paddingBottom: 15,
  },
  text: {
    color: Colors.dark,
    textAlign: "center",
    fontSize: 14,
    lineHeight: 22,
  },
});

export default Placeholder;
