import React, { FC, useCallback } from "react";
import { Text as RNText } from "react-native";
import { EContentTypes } from "../../types/survey";
import { Question as QuestionType } from "../../types/v2/questions";
import * as SlideContent from "../../types/v2/slides/slide_content";
import { SlideContentProps } from "../../types/v2/slides/slide_content";
import Articles from "./Articles";
import DayPlan from "./DayPlan";
import Divider from "./Divider";
import DropdownText from "./DropdownText";
import Flashcards from "./Flashcards";
import Image from "./Image";
import Placeholder from "./Placeholder";
import Question from "./Question";
import ResultsComparison from "./ResultsComparison";
import RichCards from "./RichCards";
import SessionHeader from "./SessionHeader";
import SleepParametersPlot from "./SleepParametersPlot";
import SleepRestrictions from "./SleepRestrictions";
import StackedBarplot from "./StackedBarplot";
import SubscriptionOffer from "./SubscriptionOffer";
import SummaryList from "./SummaryList";
import SurveyScore from "./SurveyScore";
import Tabs from "./Tabs";
import Text from "./Text";
import TherapyPreview from "./TherapyPreview";
import TherapyProgress from "./TherapyProgress";
import Tooltip from "./Tooltip";
import UserInfo from "./UserInfo";
import UserSummary from "./UserSummary";
import Video from "./Video";

interface Props {
  contentProps: SlideContentProps;
  question?: QuestionType;
  translations: { [key: string]: string };
  readOnly?: boolean;
  hidden?: boolean;
  onUpdate?: (uuid: string, value: any) => void;
}

const SlideContentWrapper: FC<Props> = ({
  contentProps,
  question,
  translations,
  readOnly,
  hidden,
  onUpdate,
}) => {
  if (hidden) {
    return null;
  }

  switch (contentProps.type) {
    case EContentTypes.TEXT:
      return <Text {...(contentProps as SlideContent.Text)} />;
    case EContentTypes.DIVIDER:
      return <Divider />;
    case EContentTypes.SURVEY_SCORE:
      return <SurveyScore {...(contentProps as SlideContent.SurveyScore)} />;
    case EContentTypes.VIDEO:
      return <Video {...(contentProps as SlideContent.Video)} />;
    case EContentTypes.ARTICLES:
      return <Articles {...(contentProps as SlideContent.Articles)} />;
    case EContentTypes.FLASHCARDS:
      return <Flashcards {...(contentProps as SlideContent.Flashcards)} />;
    case EContentTypes.IMAGE:
      return <Image {...(contentProps as SlideContent.Image)} />;
    case EContentTypes.SLEEP_PLOT:
      return (
        <SleepParametersPlot
          {...(contentProps as SlideContent.SleepParametersPlot)}
          translations={translations}
        />
      );
    case EContentTypes.SUMMARY_LIST:
      return (
        <SummaryList
          {...(contentProps as SlideContent.SummaryList)}
          translations={translations}
        />
      );
    case EContentTypes.TOOLTIP:
      return <Tooltip {...(contentProps as SlideContent.Tooltip)} />;
    case EContentTypes.RESULTS_COMPARISON:
      return (
        <ResultsComparison
          {...(contentProps as SlideContent.ResultsComparison)}
          translations={translations}
        />
      );
    case EContentTypes.DROPDOWN_TEXT:
      return <DropdownText {...(contentProps as SlideContent.DropdownText)} />;
    case EContentTypes.USER_INFO:
      return (
        <UserInfo
          {...(contentProps as SlideContent.UserInfo)}
          label_edit_profile={translations.edit_profile_header}
        />
      );
    case EContentTypes.THERAPY_PREVIEW:
      return (
        <TherapyPreview {...(contentProps as SlideContent.TherapyPreview)} />
      );
    case EContentTypes.SLEEP_RESTRICTIONS:
      const props = contentProps as SlideContent.SleepRestrictions;
      const translatedProps = { ...props, label: translations[props.label] };
      return <SleepRestrictions {...translatedProps} />;
    case EContentTypes.RICH_CARDS:
      return <RichCards {...(contentProps as SlideContent.RichCards)} />;
    case EContentTypes.PLACEHOLDER:
      return <Placeholder {...(contentProps as SlideContent.Placeholder)} />;
    case EContentTypes.SUBSCRIPTION_OFFER:
      return (
        <SubscriptionOffer
          {...(contentProps as SlideContent.SubscriptionOffer)}
          label_buy_subscription={translations.payments_buy_subscription_cta}
        />
      );
    case EContentTypes.DAY_PLAN:
      return (
        <DayPlan
          {...(contentProps as SlideContent.DayPlan)}
          translations={translations}
          // allElementsCompleted={true}  // TODO!!!
        />
      );
    case EContentTypes.USER_SUMMARY:
      const userSummaryProps = contentProps as SlideContent.UserSummary;
      return <UserSummary {...userSummaryProps} />;
    case EContentTypes.TABS:
      return (
        <Tabs
          {...(contentProps as SlideContent.Tabs)}
          renderer={(item) => (
            <SlideContentWrapper
              contentProps={item}
              translations={translations}
            />
          )}
        />
      );
    case EContentTypes.STACKED_BARPLOT:
      return (
        <StackedBarplot {...(contentProps as SlideContent.StackedBarplot)} />
      );
    case EContentTypes.QUESTION:
    case EContentTypes.QUESTION_PREVIEW:
      const isReadOnly =
        readOnly || contentProps.type === EContentTypes.QUESTION_PREVIEW;

      const handleUpdate = useCallback(
        (value: any) => {
          if (onUpdate !== undefined) {
            onUpdate(question!.template.uuid, value);
          }
        },
        [question]
      );

      return (
        <Question
          {...(contentProps as SlideContent.Question)}
          question={question!}
          readOnly={isReadOnly}
          onChange={handleUpdate}
          translations={translations}
        />
      );
    case EContentTypes.THERAPY_PROGRESS:
      return (
        <TherapyProgress
          {...(contentProps as SlideContent.TherapyProgress)}
          translations={translations}
        />
      );
    case EContentTypes.SESSION_HEADER:
      return (
        <SessionHeader {...(contentProps as SlideContent.SessionHeader)} />
      );
    default:
      return <RNText>Unknown item {contentProps.type}</RNText>;
  }
};

export default React.memo(SlideContentWrapper, (prev, curr) => {
  if (prev.contentProps.type !== EContentTypes.QUESTION) {
    return (
      prev.hidden === curr.hidden &&
      prev.contentProps.uuid === curr.contentProps.uuid
    );
  } else {
    return (
      prev.hidden === curr.hidden &&
      prev.contentProps.uuid === curr.contentProps.uuid &&
      prev.question?.answer === curr.question?.answer
    );
  }
});
