import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { IThunkApi } from "../../../types/redux";
import { Gender, TherapyParticipant } from "../../../types/v2/user";
import { retrieveObject, storeData, storeObject } from "../../storage";
import { LibraryActions } from "../library";
import { TherapyFolderProfileActions } from "../therapyFolderProfile";
import { STORE_KEY } from "../user";
import { logout, restoreAuthState } from "./auth";

export const restoreTherapyParticipantState = createAsyncThunk<
  TherapyParticipant | null,
  undefined,
  IThunkApi
>("therapyParticipant/restore", async () => {
  const state = await retrieveObject(STORE_KEY);
  if (Object.keys(state).length === 0) {
    return null;
  }
  return state as TherapyParticipant;
});

export const refreshUserState = createAsyncThunk<
  TherapyParticipant,
  undefined,
  IThunkApi
>("therapyParticipant/refreshState", async (arg, thunkApi) => {
  const {
    therapyParticipant: { therapy_status },
    auth: { token },
  } = thunkApi.getState();

  const allElementsCompleted = Boolean(
    therapy_status?.current_session.all_elements_completed
  );

  const { data } = await call<TherapyParticipant>(
    authorize(ENDPOINTS.user.ME, token)
  );
  if (
    data.therapy_status?.current_session.all_elements_completed &&
    !allElementsCompleted
  ) {
    thunkApi.dispatch(LibraryActions.markAsStale());
  }
  storeObject(STORE_KEY, data);
  storeData("@flavour", data.user?.flavour || "default");

  return data;
});

interface UpdateProfileArgs {
  avatar_uuid?: string;
  name: string;
  gender: Gender;
  age: string;
  pronouns: string;
}

export const updateProfile = createAsyncThunk<
  TherapyParticipant,
  undefined,
  IThunkApi
>("therapyParticipant/updateProfile", async (_, thunkApi) => {
  const {
    therapyParticipant: { user, intro_survey },
    auth: { token },
  } = thunkApi.getState();

  const args: UpdateProfileArgs = {
    name: user?.name!,
    gender: intro_survey?.gender!,
    age: intro_survey?.age!,
    pronouns: intro_survey?.pronouns!,
  };
  if (user !== undefined) {
    args.avatar_uuid = user.avatar?.uuid!;
  }

  const { data } = await call<TherapyParticipant>(
    authorize(ENDPOINTS.user.EDIT_PROFILE(args), token)
  );
  thunkApi.dispatch(TherapyFolderProfileActions.markAsStale());
  storeObject(STORE_KEY, data);
  storeData("@flavour", data.user?.flavour || "default");
  return data;
});

export const deleteProfile = createAsyncThunk<boolean, undefined, IThunkApi>(
  "therapyParticipant/deleteProfile",
  async (_, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    try {
      await call(authorize(ENDPOINTS.user.DELETE_ACCOUNT, token));
      await thunkApi.dispatch(logout());
      await thunkApi.dispatch(restoreAuthState());
      return true;
    } catch (error: any) {
      return false;
    }
  }
);
