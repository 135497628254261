import { useNavigation } from "@react-navigation/native";
import { FC } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { Colors } from "../../constants/colors";
import { useAppDispatch } from "../../hooks/redux";
import { SurveyActions } from "../../store/redux/survey";
import { Task as TaskProps } from "../../types/v2/dayPlan";
import { Translations } from "../../types/v2/manifest";
import CustomIcon from "../CustomIcon";

interface Props extends TaskProps {
  translations: Translations;
}

const Task: FC<Props> = ({ translations, ...props }) => {
  const navigation = useNavigation<any>();
  const dispatch = useAppDispatch();

  const handlePress = () => {
    if (props.route) {
      dispatch(SurveyActions.reset());
      navigation.navigate(props.route);
    }
  };

  return (
    <Pressable onPress={handlePress}>
      <View style={styles.task_container}>
        <View style={[styles.daytime_container, { flex: 1 }]}>
          {props.icon !== null && (
            <CustomIcon
              name={props.icon}
              size={24}
              color={Colors.primary}
              style={{ marginRight: 15 }}
            />
          )}
          <Text style={styles.task_label}>{translations[props.label]}</Text>
        </View>
        <View style={[styles.daytime_container]}>
          {props.fulfillment_label !== null && (
            <Text style={[styles.task_label, { fontWeight: "700" }]}>
              {props.fulfillment_label}
              {props.unit ? ` ${translations[props.unit]}` : null}
            </Text>
          )}
          {props.fulfillment !== null && (
            <View style={styles.icon_container}>
              <View style={styles.circle_container}>
                <AnimatedCircularProgress
                  width={10}
                  size={20}
                  fill={props.fulfillment * 100}
                  rotation={0}
                  tintColor={"#59B6B3"}
                  backgroundColor={Colors.lightgray}
                />
                {props.fulfillment * 100 >= 100 && (
                  <View style={styles.checkmark_container}>
                    <CustomIcon name="check" size={14} color={Colors.white} />
                  </View>
                )}
              </View>
            </View>
          )}
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  task_label: {
    fontSize: 14,
    color: Colors.dark,
    lineHeight: 28,
  },
  checkmark_container: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 11,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  circle_container: {
    position: "relative",
    width: 20,
    height: 20,
  },
  icon_container: {
    paddingLeft: 10,
    paddingVertical: 10,
  },
  daytime_container: {
    flexDirection: "row",
    alignItems: "center",
  },
  task_container: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default Task;
