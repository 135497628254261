import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Question } from "../../types/v2/questions";
import { SlideTemplate } from "../../types/v2/slides/slide";
import { SlideContent } from "../../types/v2/slides/slide_content";
import { SurveyWithoutSlide } from "../../types/v2/survey";
import Slide from "../slides/Slide";
import MainHeading from "../typography/main_heading";
import SubHeading from "../typography/sub_heading";
import ActionsContainer from "./ActionsContainer";
import PrimaryAction from "./PrimaryAction";
import ProgressBar from "./ProgressBar";
import SecondaryAction from "./SecondaryAction";
import SlideContainer from "./SlideContainer";
import TopControls from "./TopControls";

interface Props {
  survey: SurveyWithoutSlide;
  questions?: { [uuid: string]: Question };
  content: SlideContent[];
  hidden: boolean[];
  slideTemplate: SlideTemplate;
  loading?: boolean;
  translations: { [key: string]: string };
  primaryLabel: string;
  secondaryLabel?: string;
  primaryActionDisabled?: boolean;
  showProgressBar?: boolean;
  showLabel?: boolean;
  onUpdate?: (uuid: string, value: any) => void;
  onPrevious?: () => void;
  onClose?: () => void;
  onSecondary?: () => void;
  onPrimary: () => void;
}

const Survey: FC<Props> = ({
  survey,
  content,
  questions = {},
  hidden,
  slideTemplate,
  translations,
  primaryLabel,
  secondaryLabel,
  primaryActionDisabled = false,
  loading = false,
  showProgressBar = true,
  showLabel = true,
  onUpdate,
  onPrevious,
  onClose,
  onPrimary,
  onSecondary,
}) => {
  const { number_of_slides, current_slide_number } = survey;
  const { subheader, header, tag } = slideTemplate;

  if (showProgressBar === undefined) {
    showProgressBar = number_of_slides > 1;
  }
  const progressBarlabel = showLabel
    ? `${translations.step} ${current_slide_number} ${translations.step_from} ${number_of_slides}`
    : undefined;
  const readOnly = survey.read_only;

  return (
    <View style={styles.container}>
      <SlideContainer slideTag={tag}>
        <TopControls onClose={onClose} onPrevious={onPrevious} />
        {subheader !== null && (
          <View style={{ marginTop: 0 }}>
            <SubHeading text={subheader} />
          </View>
        )}
        {header !== null && <MainHeading text={header} size={"small"} />}
        {showProgressBar && (
          <ProgressBar
            label={progressBarlabel}
            progress={current_slide_number / number_of_slides}
          />
        )}
        <Slide
          readOnly={readOnly}
          translations={translations}
          hidden={hidden}
          content={content}
          questions={questions}
          onUpdate={onUpdate}
        />
      </SlideContainer>
      <ActionsContainer>
        {onSecondary !== undefined && secondaryLabel !== undefined && (
          <SecondaryAction label={secondaryLabel} onPress={onSecondary} />
        )}
        <PrimaryAction
          label={primaryLabel}
          onPress={onPrimary}
          disabled={primaryActionDisabled}
          loading={loading}
        />
      </ActionsContainer>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default Survey;
