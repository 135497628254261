import { StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";

const Divider = () => {
  return <View style={styles.line}></View>;
};

export default Divider;

const styles = StyleSheet.create({
  line: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.mediumgray,
    marginVertical: 30,
  },
});
