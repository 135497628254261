import { FC } from "react";
import { ActivityIndicator, View } from "react-native";
import { Colors } from "../constants/colors";

const Loader: FC = () => {
  return (
    <View style={{ flex: 1, justifyContent: "center" }}>
      <ActivityIndicator color={Colors.primary} size="small" />
    </View>
  );
};

export default Loader;
