import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";

interface Props {
  label?: string;
  showLabel?: boolean;
  progress: number;
}

const ProgressBar: FC<Props> = ({ progress, label, showLabel = true }) => {
  return (
    <View style={styles.container}>
      <View style={styles.bar_container}>
        <View style={[styles.inner, { width: progress * 100 + "%" }]}></View>
      </View>
      {label !== undefined && (
        <View>
          <Text style={styles.counter}>{label}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  bar_container: {
    flex: 1,
    marginRight: 15,
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.lightgray,
    overflow: "hidden",
    marginVertical: 20,
  },
  inner: {
    width: 50,
    height: "100%",
    backgroundColor: Colors.primary,
    borderRadius: 2,
  },
  counter: {
    fontSize: 11,
    lineHeight: 13,
    color: Colors.primary,
    fontWeight: "700",
  },
});
export default ProgressBar;
