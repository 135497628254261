// @ts-nocheck

export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && safari.pushNotification)
  );

export const iOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
