import { useNavigation } from "@react-navigation/native";
import { Pressable, StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";
import { useAppSelector } from "../../hooks/redux";
import { SCREEN } from "../../navigation/screens";
import { Translations } from "../../types/v2/manifest";
import { DayPlan as DayPlanProps } from "../../types/v2/slides/slide_content";
import DayPlanItem from "../DayPlanItem";

interface Props extends DayPlanProps {
  translations: Translations;
}

const DayPlan = (props: Props) => {
  const allElementCompleted = Boolean(
    useAppSelector(
      (state) =>
        state.therapyParticipant.therapy_status?.current_session
          .all_elements_completed
    )
  );
  const navigation = useNavigation<any>();

  const handlePress = () => {
    if (!allElementCompleted) {
      navigation.navigate(SCREEN.Therapy);
    }
  };

  return (
    <Pressable onPress={handlePress}>
      <View style={[styles.container]}>
        <DayPlanItem {...props.plan} translations={props.translations} />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    marginHorizontal: -30,
    paddingHorizontal: 10,
    paddingVertical: 10,
    backgroundColor: Colors.featured_color,
  },
});

export default DayPlan;
