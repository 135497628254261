import { useIsFocused } from "@react-navigation/native";
import React, { useEffect } from "react";
import { NativeScrollEvent, NativeSyntheticEvent } from "react-native";
import Dashboard from "../components/Dashboard";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { RootBottomTabScreenProps } from "../navigation/BottomTabNavigation.types";
import { SCREEN } from "../navigation/screens";
import { getDashboard } from "../store/redux/thunk_functions/dashboard";
import { refreshUserState } from "../store/redux/thunk_functions/user";
import { UIActions } from "../store/redux/ui";

function isCloseToBottom(event: NativeScrollEvent): boolean {
  const paddingToBottom = 300;
  return (
    event.layoutMeasurement.height + event.contentOffset.y >=
    event.contentSize.height - paddingToBottom
  );
}

/*
interface MemoizedDashboardProps {
  loading?: boolean;
  content?: SlideContent[];
  translations: { [key: string]: string };
  timestamp?: number;
  onRefresh?: () => void;
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

const DashboardWithTimestamp: FC<MemoizedDashboardProps> = (props) => (
  <Dashboard {...props} />
);

const MemoizedDashboard = React.memo(
  DashboardWithTimestamp,
  (prev: MemoizedDashboardProps, next: MemoizedDashboardProps) =>
    prev.timestamp === next.timestamp && prev.onScroll === next.onScroll
);
*/

const DashboardScreen = ({}: RootBottomTabScreenProps<SCREEN.Dashboard>) => {
  const focused = useIsFocused();
  const {
    therapyParticipant: { subscription },
    manifest: { translations },
    dashboard: { content, loading, timestamp },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (focused) {
      dispatch(getDashboard());
    }
  }, [focused]);

  const onRefresh = React.useCallback(() => {
    dispatch(getDashboard());
    dispatch(refreshUserState());
  }, []);

  const onScroll = React.useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (subscription === null) {
        if (isCloseToBottom(event.nativeEvent)) {
          dispatch(UIActions.hideFloatingMenuButton());
        } else {
          dispatch(UIActions.showFloatingMenuButton());
        }
      }
    },
    [subscription]
  );

  useEffect(() => {

  }, []);

  return (
    <Dashboard
      loading={loading}
      content={content || undefined}
      translations={translations}
      onRefresh={onRefresh}
      onScroll={onScroll}
    />
  );
};

export default DashboardScreen;
