import { useIsFocused } from "@react-navigation/native";
import React, { FC, useEffect } from "react";
import { ScrollView } from "react-native";
import Articles from "../../components/Articles";
import GoBack from "../../components/GoBack";
import Loader from "../../components/Loader";
import MainHeading from "../../components/typography/main_heading";
import SubHeading from "../../components/typography/sub_heading";
import Container from "../../components/utils/container";
import Divider from "../../components/utils/divider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootStackScreenProps } from "../../navigation/StackNavigation.types";
import { SCREEN } from "../../navigation/screens";
import { RootState } from "../../store/redux";
import { fetchArticles } from "../../store/redux/thunk_functions/fetchArticles";

const LibraryScreen: FC<RootStackScreenProps<SCREEN.Library>> = () => {
  const isFocused = useIsFocused();
  const { needRefresh, byCategory, byId, loading, error } = useAppSelector(
    (state) => state.library
  );
  const { library_header, library_subheader } = useAppSelector(
    (state: RootState) => state.manifest.translations
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (needRefresh && isFocused) {
      dispatch(fetchArticles());
    }
  }, [needRefresh, isFocused]);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <GoBack />
        <MainHeading text={library_header} size="large" />
        <SubHeading text={library_subheader} />
        <Divider />
        {loading && <Loader />}
        {!error &&
          Object.entries(byCategory).map(([category, articleUuids]) => {
            return (
              <React.Fragment key={category}>
                <Articles
                  articles={articleUuids.map((uuid) => byId[uuid])}
                  text={category}
                />
                <Divider />
              </React.Fragment>
            );
          })}
      </Container>
    </ScrollView>
  );
};

export default LibraryScreen;
