import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../../constants/colors";

interface Props {}

const ActionsContainer: FC<Props> = ({ children }) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 30,
    paddingVertical: 20,
    bottom: 0,
    left: 0,
    backgroundColor: Colors.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 15,
  },
});

export default ActionsContainer;
