import { FC } from "react";
import { SleepParametersPlot as SleepParametersPlotProps } from "../../types/v2/slides/slide_content";
import SleepParametersPlotInner from "../SleepParametersPlot";

interface Props extends SleepParametersPlotProps {
  translations: { [key: string]: string };
}

const SleepParametersPlot: FC<Props> = (props) => {
  const {
    sleep_calendar_summary_barplot_legend_awakenings,
    sleep_calendar_summary_barplot_legend_fall_asleep,
    sleep_calendar_summary_barplot_legend_sleep_time,
    sleep_calendar_summary_barplot_legend_in_bed_after_wake_up,
    sleep_calendar_summary_barplot_legent_sleep_efficiency,
  } = props.translations;
  return (
    <SleepParametersPlotInner
      {...props}
      label_awakenings={sleep_calendar_summary_barplot_legend_awakenings}
      label_fall_asleep={sleep_calendar_summary_barplot_legend_fall_asleep}
      label_in_bed_after_wake_up={
        sleep_calendar_summary_barplot_legend_in_bed_after_wake_up
      }
      label_sleep_efficiency={
        sleep_calendar_summary_barplot_legent_sleep_efficiency
      }
      label_sleep_time={sleep_calendar_summary_barplot_legend_sleep_time}
    />
  );
};

export default SleepParametersPlot;
