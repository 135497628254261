import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";

interface Props {
  content?: string
  subcontent?: string
}

const QuestionWrapperInline: FC<Props> = ({
  children,
  content,
  subcontent
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <Text style={styles.title}>{content}</Text>
        <Text style={styles.subtitle}>{subcontent}</Text>
      </View>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  labelContainer: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    lineHeight: 28,
    color: Colors.dark,
    fontWeight: "700",
  },
  subtitle: {
    fontSize: 11,
    lineHeight: 16,
    color: Colors.dark,
    opacity: 0.6,
  },
});

export default QuestionWrapperInline;
