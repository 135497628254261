import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UIState {
  floatingMenuOpen: boolean;
  floatingMenuButtonVisible: boolean;
}

const initialState: UIState = {
  floatingMenuOpen: false,
  floatingMenuButtonVisible: true,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setFloatingMenuOpen(state, action: PayloadAction<boolean>) {
      state.floatingMenuOpen = action.payload;
    },
    openFloatingMenu(state) {
      state.floatingMenuOpen = true;
    },
    closeFloatingMenu(state) {
      state.floatingMenuOpen = false;
    },
    showFloatingMenuButton(state) {
      state.floatingMenuButtonVisible = true;
    },
    hideFloatingMenuButton(state) {
      state.floatingMenuButtonVisible = false;
    },
  },
});

export const UIActions = uiSlice.actions;

export default uiSlice.reducer;
