import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "../../../api";
import { ENDPOINTS } from "../../../api/config";
import { authorize } from "../../../api/utils";
import { Configuration } from "../../../types/configuration";
import { IThunkApi } from "../../../types/redux";
import {
  AppConfiguration,
  FAQItem,
  LanguageChoice,
} from "../../../types/v2/appConfiguration";
import { ProfileConfiguration } from "../../../types/v2/profileConfiguration";

export const getAppConfiguration = createAsyncThunk<
  AppConfiguration,
  undefined,
  IThunkApi
>("configuration/getAppConfiguration", async () => {
  const { data } = await call<Configuration>(ENDPOINTS.CONFIGURATION);
  return data;
});

export const getProfileConfiguration = createAsyncThunk<
  ProfileConfiguration,
  undefined,
  IThunkApi
>("configuration/getProfileConfiguration", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(
    authorize(ENDPOINTS.user.USER_CONFIGURATION, token)
  );
  return data;
});

export const getLanguageConfiguration = createAsyncThunk<
  LanguageChoice[],
  undefined,
  IThunkApi
>("configuration/getLanguageConfiguration", async (_, thunkApi) => {
  const {
    auth: { token },
  } = thunkApi.getState();
  const { data } = await call(
    authorize(ENDPOINTS.settings.GET_LANGUAGES, token)
  );
  return data;
});

export const getFAQ = createAsyncThunk<FAQItem[], undefined, IThunkApi>(
  "configuration/getFAQ",
  async (_, thunkApi) => {
    const {
      auth: { token },
    } = thunkApi.getState();
    const { data } = await call(authorize(ENDPOINTS.support.FAQ, token));
    return data;
  }
);
