import { FC } from "react";
import { Image, Pressable, Text, View } from "react-native";
import { IRelaxation } from "../relaxations_carousel.types";
import { relaxation_item_styles } from "./relaxation_item.styles";
import { useNavigation } from "@react-navigation/native";
import CustomIcon from "../../CustomIcon";
import { SCREEN } from "../../../navigation/screens";

const RelaxationItem: FC<IRelaxation> = ({
  name,
  image_url,
  duration_str,
  uuid,
}) => {
  const navigation = useNavigation<any>();

  const handlePress = () => {
    navigation.navigate(SCREEN.Relaxation, { uuid });
  };

  return (
    <Pressable style={relaxation_item_styles.container} onPress={handlePress}>
      <Image source={{ uri: image_url }} style={relaxation_item_styles.image} />
      <View style={relaxation_item_styles.description_container}>
        <Text style={relaxation_item_styles.name}>{name}</Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CustomIcon
            name="community:clock-time-nine-outline"
            size={17}
            style={{ marginRight: 5, marginTop: 3 }}
          />
          <Text style={relaxation_item_styles.duration}>{duration_str}</Text>
        </View>
      </View>
    </Pressable>
  );
};

export default RelaxationItem;
