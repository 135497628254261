export const sessionRoutes = {
  CREATE_SESSION: {
    method: "GET",
    url: "/api/v1/cbti/",
  },
  READ_SESSION: (uuid: string) => ({
    url: `/api/v1/cbti/sessions/${uuid}`,
    method: "GET",
  }),
  SESSION_SLIDES: (uuid: string) => ({
    url: `/api/v1/surveys/${uuid}`,
    method: "GET",
  }),
  READ_DIARY: {
    url: "/api/v1/cbti/sleep-diary",
    method: "GET",
  },
  FILL_DIARY: (date: string) => ({
    url: `/api/v1/cbti/sleep-diary/${date}`,
    method: "GET",
  }),
  DAY_PLAN: () => ({
    url: `/api/v1/cbti/day-plan`,
    method: "GET",
  }),
};
