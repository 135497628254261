import { FC, useEffect, useRef } from "react";
import { Animated, Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import CustomIcon from "../CustomIcon";

interface Props {
  text: string;
  icon: string;
  visible: boolean;
  onPress: () => void;
}

const MenuItem: FC<Props> = ({ text, icon, visible, onPress }) => {
  const animated = useRef(new Animated.Value(0)).current;

  const position = animated.interpolate({
    inputRange: [0, 1],
    outputRange: [200, 0],
  });

  const opacity = animated.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  const useNativeDriver = Platform.OS !== "web"

  useEffect(() => {
    if (visible) {
      Animated.spring(animated, {
        toValue: 1,
        friction: 10,
        useNativeDriver,
      }).start();
    } else {
      Animated.spring(animated, {
        toValue: 0,
        friction: 10,
        useNativeDriver,
      }).start();
    }
  }, [visible]);

  return (
    <Animated.View
      style={[{ transform: [{ translateX: position }], opacity: opacity }]}
    >
      <Pressable onPress={onPress} style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={styles.text}>{text}</Text>
        </View>
        <View style={styles.icon}>
          <CustomIcon name={icon} size={22} color={Colors.primary} />
        </View>
      </Pressable>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 6,
    alignItems: "center",
    marginVertical: 5,
  },
  textContainer: {
    height: 40,
    marginRight: 20,
    borderRadius: 3,
    backgroundColor: Colors.white,
    paddingHorizontal: 14,
    elevation: 20,
    justifyContent: "center",
  },
  text: {
    fontSize: 14,
    fontWeight: "700",
  },
  icon: {
    width: 38,
    height: 38,
    backgroundColor: Colors.white,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    elevation: 20,
  },
});

export default MenuItem;
