import { useNavigation } from "@react-navigation/native";
import { FC } from "react";
import { Pressable, Text, View } from "react-native";
import { Colors } from "../../constants/colors";
import { useLocalizedFormatter } from "../../hooks/dates";
import { useAppDispatch } from "../../hooks/redux";
import { useTranslations } from "../../hooks/useTranslations";
import { SurveyActions } from "../../store/redux/survey";
import { Thought as ThoughtProps } from "../../types/thought";
import CustomIcon from "../CustomIcon";
import { thought_styles } from "./thought.styles";

const Thought: FC<ThoughtProps> = ({
  alternative_thought,
  hot_thought,
  alternative_thought_formulated,
  discussion,
  uuid,
  date,
}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<any>();
  const {
    thought_diary_add_hot_thought_header,
    thought_diary_add_alternative_thought_header,
    thought_diary_preview_debate,
    thought_diary_add_discussion_no,
    thought_diary_add_discussion_yes,
    thought_diary_preview_add_alternative_thought,
  } = useTranslations();
  const formatter = useLocalizedFormatter();
  const formattedDate = formatter(date, "d MMMM yyyy");

  const openThought = () => {
    dispatch(SurveyActions.reset());
    navigation.navigate("Survey", { uuid, type: "thought_diary" });
  };

  return (
    <Pressable onPress={openThought}>
      <View
        style={[
          thought_styles.container,
          alternative_thought_formulated && {
            backgroundColor: Colors.green_transparent,
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={[
              thought_styles.title,
              alternative_thought_formulated && { color: Colors.green },
            ]}
          >
            {alternative_thought_formulated
              ? thought_diary_add_alternative_thought_header
              : thought_diary_add_hot_thought_header}
          </Text>
          <CustomIcon
            name="community:arrow-top-right-bold-outline"
            size={22}
            style={[
              thought_styles.arrow,
              alternative_thought_formulated && { color: Colors.green },
            ]}
          />
        </View>
        <Text style={thought_styles.detail}>
          {formattedDate} |{thought_diary_preview_debate}:{" "}
          {discussion
            ? thought_diary_add_discussion_yes
            : thought_diary_add_discussion_no}
        </Text>
        <Text style={thought_styles.text}>
          {alternative_thought_formulated ? alternative_thought : hot_thought}
        </Text>
        {!alternative_thought_formulated && (
          <Text style={thought_styles.action}>
            {thought_diary_preview_add_alternative_thought}
          </Text>
        )}
      </View>
    </Pressable>
  );
};

export default Thought;
