import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAQtbTPueu36i8wIsqTgy-UQDC9Nt0Ie9E",
  authDomain: "goodsleeper-332508.firebaseapp.com",
  projectId: "goodsleeper-332508",
  storageBucket: "goodsleeper-332508.appspot.com",
  messagingSenderId: "827907790138",
  appId: "1:827907790138:web:6868c991211b279b1f2c03",
  measurementId: "G-SY7NEE99F5",
};

const app = initializeApp(firebaseConfig);
const webAnalytics = getAnalytics(app);

export async function sendAnalyticsEventAsync(
  eventName: string,
  eventParams?: Record<string, any>
) {
  await logEvent(webAnalytics, eventName, eventParams);
}
