import * as StoreReview from "expo-store-review";
import { retrieveData, storeData } from "../store/storage";
import * as Sentry from "../utils/sentry";

const STORE_REVIEW_KEY = "store_review_timestamp";

const WEEK = 7 * 24 * 60 * 60 * 1000;

export function canDisplayStoreReview(
  subscriptionStartDate: string | null | undefined
) {
  if (subscriptionStartDate == undefined) {
    return false;
  }
  try {
    const ts = +new Date(subscriptionStartDate);
    const now = +new Date();
    return now - ts >= 2 * WEEK;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
}

export async function requestStoreReview() {
  try {
    const available = await StoreReview.isAvailableAsync();
    if (available) {
      const now = +new Date();
      const last = await retrieveData(STORE_REVIEW_KEY);

      if (last === null || now - parseInt(last) > WEEK) {
        await StoreReview.requestReview();
        storeData(STORE_REVIEW_KEY, now.toString());
      }
    }
  } catch (error: any) {
    Sentry.captureException(error);
  }
}
