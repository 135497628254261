import * as AppleAuth from "expo-apple-authentication";
import { makeRedirectUri } from "expo-auth-session";
import * as Facebook from "expo-auth-session/providers/facebook";
import * as Google from "expo-auth-session/providers/google";
import Constants from "expo-constants";
import { useEffect } from "react";
import { Platform } from "react-native";
import { AuthActions, STORE_KEY } from "../store/redux/auth";
import { refreshUserState } from "../store/redux/thunk_functions/user";
import { TherapyParticipantActions } from "../store/redux/user";
import { storeObject } from "../store/storage";
import { EAuthType } from "../types/auth";
import { Token } from "../types/v2/token";
import * as Sentry from "../utils/sentry";
import { useAppDispatch } from "./redux";

const useProxy =
  (Constants.appOwnership === "expo" && Platform.OS !== "web") ||
  Platform.OS === "android";

export function useGoogle(
  callback: (idToken: string) => Promise<Token>,
  errorCallback: (error: any) => void
): [boolean, () => Promise<void>] {
  const dispatch = useAppDispatch();

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    androidClientId: Constants.expoConfig?.extra?.googleSignin?.androidClientId,
    iosClientId: Constants.expoConfig?.extra?.googleSignin?.webClientId,
    webClientId: Constants.expoConfig?.extra?.googleSignin?.webClientId,
  });

  const disabled = !request;

  const googleLogin = async (idToken: string) => {
    try {
      if (idToken) {
        const { access_token: token, user } = await callback(idToken);
        await storeObject(STORE_KEY, {
          token,
          loginType: EAuthType.GOOGLE,
        });
        await dispatch(TherapyParticipantActions.setUser(user));
        await dispatch(AuthActions.login(token));
        await dispatch(refreshUserState());
      }
    } catch (error: any) {
      Sentry.captureException(error);
      errorCallback(error);
    }
  };

  useEffect(() => {
    if (response?.type === "success" && response.params?.id_token) {
      googleLogin(response.params.id_token);
    }
  }, [response]);

  return [
    disabled,
    async () => {
      await promptAsync();
    },
  ];
}

export function useFacebook(
  callback: (accessToken: string) => Promise<Token>,
  errorCallback: (error: any) => void
): [boolean, () => Promise<void>] {
  const dispatch = useAppDispatch();

  const [request, response, promptAsync] = Facebook.useAuthRequest({
    clientId: Constants.expoConfig?.extra?.facebookLogin?.appId,
    redirectUri: makeRedirectUri({
      useProxy,
    }),
  });

  const disabled = !request;

  const facebookLogin = async (accessToken: string) => {
    try {
      const { access_token: token, user } = await callback(accessToken);
      await storeObject(STORE_KEY, {
        token,
        loginType: EAuthType.FACEBOOK,
      });
      await dispatch(TherapyParticipantActions.setUser(user));
      await dispatch(AuthActions.login(token));
      await dispatch(refreshUserState());
    } catch (error: any) {
      Sentry.captureException(error);
      errorCallback(error);
    }
  };

  useEffect(() => {
    if (response?.type === "success" && response.authentication?.accessToken) {
      facebookLogin(response.authentication.accessToken);
    }
  }, [response]);

  return [
    disabled,
    async () => {
      await promptAsync();
    },
  ];
}

export function useApple(
  callback: (idToken: string, name?: string) => Promise<Token>,
  errorCallback: (error: any) => void
) {
  const dispatch = useAppDispatch();
  const appleLogin = async () => {
    try {
      const { fullName, identityToken } = await AppleAuth.signInAsync({
        requestedScopes: [
          AppleAuth.AppleAuthenticationScope.EMAIL,
          AppleAuth.AppleAuthenticationScope.FULL_NAME,
        ],
      });

      if (identityToken !== null) {
        const { access_token: token, user } = await callback(
          identityToken,
          fullName?.givenName ? fullName.givenName : ""
        );
        await storeObject(STORE_KEY, { token, loginType: EAuthType.APPLE });
        await dispatch(AuthActions.login(token));
        await dispatch(TherapyParticipantActions.setUser(user));
      }
    } catch (error: any) {
      errorCallback(error);
    }
  };
  return appleLogin;
}
