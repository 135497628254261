import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../../types/redux";
import { restoreAuthState } from "./auth";
import { restoreManifestState } from "./restoreManifestState";
import * as SplashScreen from "expo-splash-screen";

import { restoreTherapyParticipantState } from "./user";

export const restoreAppState = createAsyncThunk<void, undefined, IThunkApi>(
  "root/restore",
  async (_, thunkApi) => {
    try {
      await thunkApi.dispatch(restoreManifestState());
      await thunkApi.dispatch(restoreAuthState());
      await thunkApi.dispatch(restoreTherapyParticipantState());
    } catch (error: any) {
    }
    await SplashScreen.hideAsync();
  }
);
