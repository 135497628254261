import { FC } from "react";
import PrimaryButton from "../utils/primary_button";

interface Props {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  onPress?: () => void;
}

const PrimaryAction: FC<Props> = ({ label, disabled, loading, onPress }) => {
  return (
    <PrimaryButton
      label={label}
      onPress={onPress}
      disabled={disabled}
      loading={loading}
    />
  );
};

export default PrimaryAction;
